import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localforage from 'localforage'

const customStore = localforage.createInstance({
  name: 'localforagetest'
})

const vuexLocal = new VuexPersistence({
  storage: customStore,
  asyncStorage: true
})

Vue.use(Vuex)

function isNewLesson (oldState, schoolpk, coursepk, lesson) {
  for (let schoolIndex = 0; schoolIndex < oldState.length; schoolIndex++) {
    if (oldState[schoolIndex].pk === schoolpk) {
      for (let courseIndex = 0; courseIndex < oldState[schoolIndex].courses.length; courseIndex++) {
        if (oldState[schoolIndex].courses[courseIndex].pk === coursepk) {
          for (let lessonIndex = 0; lessonIndex < oldState[schoolIndex].courses[courseIndex].lessons.length; lessonIndex++) {
            if (oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].pk === lesson.pk) {
              const avilableOffline = { studentDocOffline: false, teacherDocOffline: false, teacherBlackboardOffline: false, teacherProjectorPresentationOffline: false, teacherProjectorDocOffline: false }
              if (oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].studentDocLink === lesson.studentDocLink) {
                avilableOffline.studentDocOffline = oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].studentDocOffline || false
              }
              if (oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherDocLink === lesson.teacherDocLink) {
                avilableOffline.teacherDocOffline = oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherDocOffline || false
              }
              if (oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherBlackboardDocLink === lesson.teacherBlackboardDocLink) {
                avilableOffline.teacherBlackboardOffline = oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherBlackboardOffline || false
              }
              if (oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherProjectorDocLink === lesson.teacherProjectorDocLink) {
                avilableOffline.teacherProjectorDocOffline = oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherProjectorDocOffline || false
              }
              if (oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherProjectorPresentationLink === lesson.teacherProjectorPresentationLink) {
                avilableOffline.teacherProjectorPresentationOffline = oldState[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherProjectorPresentationOffline || false
              }
              return avilableOffline
            }
          }
          return { studentDocOffline: false, teacherDocOffline: false, teacherBlackboardOffline: false, teacherProjectorPresentationOffline: false, teacherProjectorDocOffline: false }
        }
      }
      return { studentDocOffline: false, teacherDocOffline: false, teacherBlackboardOffline: false, teacherProjectorPresentationOffline: false, teacherProjectorDocOffline: false }
    }
  }
  return { studentDocOffline: false, teacherDocOffline: false, teacherBlackboardOffline: false, teacherProjectorPresentationOffline: false, teacherProjectorDocOffline: false }
}

const store = new Vuex.Store({
  state: {
    documents: {},
    glossaries: {},
    choiceBackLessons: {},
    questions: {},
    StudentListoflessons: {},
    TeacherListoflessons: {},
    offlineAnswers: {},
    externalDocuments: {},
    externalWordDocuments: {},
    names: {},
    isLoggedIn: false,
    userData: null
  },
  mutations: {
    addDocument (state, payload) {
      state.documents[payload[0]] = payload[1]
    },
    addExternalWordDocument (state, payload) {
      state.externalWordDocuments[payload[0]] = payload[1]
    },
    addGlossary (state, payload) {
      state.glossaries[payload[0]] = payload[1]
    },
    addChoiceBackLesson (state, payload) {
      state.choiceBackLessons[payload[0]] = payload[1]
    },
    addQuestion (state, payload) {
      state.questions[payload[0]] = payload[1]
    },
    addStudentListOfLessons (state, payload) {
      const oldList = JSON.parse(JSON.stringify(state.StudentListoflessons))
      for (let schoolIndex = 0; schoolIndex < payload.length; schoolIndex++) {
        const payloadSchoolpk = payload[schoolIndex].pk
        for (let courseIndex = 0; courseIndex < payload[schoolIndex].courses.length; courseIndex++) {
          const payloadCoursepk = payload[schoolIndex].courses[courseIndex].pk
          for (let lessonIndex = 0; lessonIndex < payload[schoolIndex].courses[courseIndex].lessons.length; lessonIndex++) {
            const offline = isNewLesson(oldList, payloadSchoolpk, payloadCoursepk, payload[schoolIndex].courses[courseIndex].lessons[lessonIndex])
            payload[schoolIndex].courses[courseIndex].lessons[lessonIndex].studentDocOffline = offline.studentDocOffline
            payload[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherDocOffline = offline.teacherDocOffline
            payload[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherBlackboardOffline = offline.teacherBlackboardOffline
            payload[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherProjectorPresentationOffline = offline.teacherProjectorPresentationOffline
            payload[schoolIndex].courses[courseIndex].lessons[lessonIndex].teacherProjectorDocOffline = offline.teacherProjectorDocOffline
          }
        }
      }
      state.StudentListoflessons = payload
    },
    addTeacherListOfLessons (state, payload) {
      state.TeacherListoflessons = payload
    },
    addOfflineAnswer (state, payload) {
      /* 0 is lesson id, 1 is question id, 2 is name, 3 is answer */
      if (!Object.prototype.hasOwnProperty.call(state.offlineAnswers, payload[0])) {
        state.offlineAnswers[payload[0]] = {}
      }
      if (!Object.prototype.hasOwnProperty.call(state.offlineAnswers[payload[0]], payload[1])) {
        state.offlineAnswers[payload[0]][payload[1]] = {}
      }
      if (!Object.prototype.hasOwnProperty.call(state.offlineAnswers[payload[0]][payload[1]], payload[2])) {
        state.offlineAnswers[payload[0]][payload[1]][payload[2]] = {}
      }
      state.offlineAnswers[payload[0]][payload[1]][payload[2]][payload[3]] = payload[4]
    },
    deleteOfflineAnswer (state, payload) {
      if (Object.prototype.hasOwnProperty.call(state.offlineAnswers, payload)) {
        delete state.offlineAnswers[payload]
      }
    },
    addExternalDocuments (state, payload) {
      state.externalDocuments[payload[0]] = payload[1]
    },
    addName (state, payload) {
      state.names[payload[0]] = payload[1]
    },
    makeAvailableOfflineState (state, payload, courseIndex, lessonIndex) {
      state.StudentListoflessons[0].courses[payload[0]].lessons[payload[1]][payload[2]] = true
    },
    logginin (state) {
      state.isLoggedIn = true
    },
    loggedout (state) {
      state.isLoggedIn = false
      state.userData = null
    },
    addUserData (state, payload) {
      state.userData = payload
    }
  },
  actions: {
    makeAvailableOffline (context, payload) {
      for (let courseIndex = 0; courseIndex < context.state.StudentListoflessons[0].courses.length; courseIndex++) {
        if (context.state.StudentListoflessons[0].courses[courseIndex].slug === payload[0]) {
          for (let lessonIndex = 0; lessonIndex < context.state.StudentListoflessons[0].courses[courseIndex].lessons.length; lessonIndex++) {
            if (context.state.StudentListoflessons[0].courses[courseIndex].lessons[lessonIndex].slug === payload[1]) {
              const newPayload = [courseIndex, lessonIndex, payload[2]]
              context.commit('makeAvailableOfflineState', newPayload)
              return true
            }
          }
        }
      }
      return false
    },
    LoginIn (context) {
      context.commit('logginin')
    },
    Logout (context) {
      context.commit('loggedout')
    },
    commitUserData (context, payload) {
      context.commit('addUserData', payload)
    }
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})

export default store
