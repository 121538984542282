<template>
  <draggable
    class="contentBoard board py-2"
    v-model="cardsModel"
    :options="{disabled : disable}"
    :group="'words-'+dragIndex.toString()"
    @start="drag=true"
    @end="drag=false"
  >
    <DragCard
      class="mx-4"
      v-for="(card, index) in cardsModel"
      :id="card.id"
      :title="card.title"
      :correct="card.correct"
      :explanationCorrect="card.explanationCorrect"
      :explanationIncorrect="card.explanationIncorrect"
      :key="index"
      @finish="finish"
    />
  </draggable>
</template>

<script>
import DragCard from '@/components/customObjects/DragCard'
import draggable from 'vuedraggable'
export default {
  name: 'Board',
  props: ['id', 'cards', 'dragIndex', 'disable'],
  data () {
    return {
      ignore: false
    }
  },
  mounted: function () {
  },
  methods: {
    drop: function (e) {
      const cardId = e.dataTransfer.getData('card_id')
      const cardTitle = e.dataTransfer.getData('card_title')
      const board = e.dataTransfer.getData('board')
      const correct = e.dataTransfer.getData('card_correct')
      const explanationCorrect = e.dataTransfer.getData('card_explanation_correct')
      const explanationIncorrect = e.dataTransfer.getData('card_explanation_incorrect')
      if (board && cardId && cardTitle) {
      // if (board && cardId && cardTitle && board !== this.id) {
        this.$emit('newCards', { id: cardId, title: cardTitle, correct, explanationCorrect, explanationIncorrect, board })
        // this.ignore = false
      // } else {
      //   // this.ignore = true
      }
    },
    finish: function (id) {
      this.$emit('finish', id)
      // if (!this.ignore) {
      //   this.$emit('finish', id)
      // }
      // this.ignore = false
    }
  },
  computed: {
    cardsModel: {
      get () { return this.cards },
      set (value) { this.$emit('update:cards', value) }
    }
  },
  components: {
    DragCard,
    draggable
  }
}
</script>

<style lang="scss" scoped>
  .board {
    border:1px solid gray;
    background-color: white;
    border-radius: .25rem;
  }
</style>
