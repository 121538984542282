<template>
  <div
    :id="id"
    :title="title"
    :correct="correct"
    :explanationCorrect="explanationCorrect"
    :explanationIncorrect="explanationIncorrect"
    class="card my-1"
    :class="paint"
  >
    <span class="cardText" :draggable="false">{{title}}</span>
  </div>
</template>

<script>
export default {
  name: 'DragCard',
  props: ['id', 'title', 'correct', 'explanationCorrect', 'explanationIncorrect', 'paint'],
  data () {
    return {
      moving: null
    }
  },
  methods: {
    dragStart: function (e) {
      this.moving = e.target
      const target = e.target
      if (e.dataTransfer) {
        e.dataTransfer.effectAllowed = 'move'
        e.dataTransfer.setData('card_id', target.id)
        e.dataTransfer.setData('card_title', target.title)
        e.dataTransfer.setData('card_correct', this.correct)
        e.dataTransfer.setData('card_explanation_correct', this.explanationCorrect)
        e.dataTransfer.setData('card_explanation_incorrect', this.explanationIncorrect)
        e.dataTransfer.setData('board', target.parentNode.id)
      } else {
        this.moving.style.height = this.moving.clientHeight
        this.moving.style.width = this.moving.clientWidth
        this.moving.style.position = 'fixed'
      }
      target.style.opacity = '0.7'
    },
    dragEnd: function (e) {
      const target = e.target
      target.style.opacity = '1'
      if (e.dataTransfer && e.dataTransfer.dropEffect === 'move') {
        this.$emit('finish', this.id)
      } else {
        this.moving.style.left = ''
        this.moving.style.top = ''
        this.moving.style.height = ''
        this.moving.style.width = ''
        this.moving.style.position = ''
      }
      this.moving = null
    },
    move: function (event) {
      if (this.moving) {
        if (event.clientX) {
          // mousemove
          this.moving.style.left = event.clientX - this.moving.clientWidth / 2
          this.moving.style.top = event.clientY - this.moving.clientHeight / 2
        } else {
          // touchmove - assuming a single touchpoint
          this.moving.style.left = event.changedTouches[0].clientX - this.moving.clientWidth / 2
          this.moving.style.top = event.changedTouches[0].clientY - this.moving.clientHeight / 2
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-color: black;
    background-color: #aaaaaa ;
    border-radius: 1.25rem;
    display: inline-block;
    cursor: move;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
  }
  .cardText {
     -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;
    text-align: center;
    margin: 0px auto;
    padding: 1vh;
  }

  .yes {
    background-color: rgb(49, 144, 49);
    color: white;
  }

  .no {
    background-color: rgb(146, 44, 44);
    color: white;
  }
</style>
