<template>
  <div>
    <mainBanner></mainBanner>
    <b-container class="p-0">
      <b-row
        class="bg-white pb-lg-5">
        <b-col
          cols="12"
          lg="4"
          class="mt-4">
          <b-button
            block
            size="lg"
            variant="mainLanding"
            :to="{name: 'otherContent', params: {'documentid': '1vX9z2NUMkZHMMyAECJOY8yfwRW9RoYc5IwD6IP6bpTA'}}">Blackboard lessons</b-button>
        </b-col>
        <b-col
          cols="12"
          lg="4"
          class="mt-4">
          <b-button
            block
            size="lg"
            variant="mainLanding"
            :to="{name: 'otherContent', params: {'documentid': '1HalIx5pjweAvAOKKuAPcgPYbU4T8QcpQAwUpuB8G7DY'}}">Projector lessons</b-button>
        </b-col>
        <b-col
          lg="4"
          class="mt-4 d-none d-lg-inline">
          <b-button
            block
            size="lg"
            variant="mainLanding"
            :to="{name: 'otherContent', params: {'documentid': '1snngMuXvtAyJaXNuvDKyTh43ZkWNNIXi2Dq8FdMLQ9Y'}}">
            Teachers' guide
          </b-button>
        </b-col>
      </b-row>
      <b-row
        class="pt-4 bg-white d-lg-none">
        <b-col>
          <b-button
            block
            variant="mainLanding"
            :to="{name: 'otherContent', params: {'documentid': '1snngMuXvtAyJaXNuvDKyTh43ZkWNNIXi2Dq8FdMLQ9Y'}}">
            Teacher's guide
          </b-button>
        </b-col>
      </b-row>
      <b-row
        class="pt-4 pt-lg-0">
        <b-col
          cols="12">
          <p class="text-center">
            Learning resources for helping secondary school students think critically and make smart health choices.
          </p>
          <p class="text-center">
            Includes everything that teachers and students need to complete 10 lessons.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'landing',
  data () {
    return {}
  },
  components: {
    mainBanner: () => import('@/components/landing/mainBanner')
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";

.btn-mainLanding,
.btn-secondaryLanding {
  border-color: $fuchsia;
  border-radius: 2rem;
}
.btn-mainLanding {
  background-color: $fuchsia;
  font-weight: bold;
  text-transform: uppercase;
}
.btn-mainLanding,
.btn-secondaryLanding {
  font-size: 18pt;
}
.btn-mainLanding:hover {
  background-color: $fuchsia_strong;
  border-color: $fuchsia_strong;
}
.btn-mainLanding:active {
  background-color: $black;
  border-color: $black;
}

@media (max-width: 767px) {
  .btn-mainLanding,
  .btn-secondaryLanding {
    font-size: 17pt;
  }
}
</style>
