export default {
  methods: {
    dynamicStyle (redComp = null,
      greenComp = null,
      blueComp = null,
      bold = false,
      size = null,
      italic = false,
      underline = false,
      strikethrough = false,
      bgRedComp = null,
      bgGreenComp = null,
      bgBlueComp = null) {
      let newRed = 0
      let newGreen = 0
      let newBlue = 0
      let newBgRed = 0
      let newBgGreen = 0
      let newBgBlue = 0
      let color = ''
      let backgroundColor = ''
      if (redComp === null && greenComp === null && blueComp === null) {
        color = 'inherit'
      } else {
        newRed = redComp * 255
        newGreen = greenComp * 255
        newBlue = blueComp * 255
        color = `rgb(${newRed}, ${newGreen}, ${newBlue})`
      }
      if (bgRedComp === null && bgGreenComp === null && bgBlueComp === null) {
        backgroundColor = 'inherit'
      } else {
        newBgRed = bgRedComp * 255
        newBgGreen = bgGreenComp * 255
        newBgBlue = bgBlueComp * 255
        backgroundColor = `rgb(${newBgRed}, ${newBgGreen}, ${newBgBlue})`
      }
      let weight = 'inherit'
      let style = 'inherit'
      let decoration = 'inherit'
      if (bold) {
        weight = 'bold'
      }
      if (italic) {
        style = 'italic'
      }
      if (underline || strikethrough) {
        decoration = ''
        if (underline) {
          decoration = decoration + 'underline '
        }
        if (strikethrough) {
          decoration = decoration + 'line-through '
        }
      }
      let fontsize = 'inherit'
      if (size) {
        fontsize = `${2 * size}pt`
      }
      const dinStyle = {
        color,
        'font-weight': weight,
        'font-size': fontsize,
        'font-style': style,
        'white-space': 'pre-line',
        'text-decoration': decoration,
        'background-color': backgroundColor
      }
      return dinStyle
    },
    paragraphDynamicStyle (indent = 'inherit', alignment = 'inherit', spaceAbove = 'inherit', spaceBelow = 'inherit', borderBottom = {}, borderLeft = {}, borderRight = {}, borderTop = {}) {
      if (alignment === 'CENTER') {
        alignment = 'center'
      } else if (alignment === 'JUSTIFIED') {
        alignment = 'justify'
      } else if (alignment === 'END') {
        alignment = 'right'
      }
      let marginTop = spaceAbove.magnitude ? spaceAbove.magnitude : 'inherit'
      if (marginTop !== 'inherit') {
        marginTop = `${marginTop}pt`
      }
      let marginBelow = spaceBelow.magnitude ? spaceBelow.magnitude : 'inherit'
      if (marginBelow !== 'inherit') {
        marginBelow = `${marginBelow}pt`
      }
      if (indent !== 'inherit') {
        indent = `${indent}pt`
      }
      let borderBottomColorRed = 0
      let borderBottomColorBlue = 0
      let borderBottomColorGreen = 0
      let borderBottomColorStyle = 'none'
      let borderBottomColorWidth = 'medium'
      if (borderBottom) {
        if (borderBottom.color && borderBottom.color.color && borderBottom.color.color.rgbColor) {
          if (borderBottom.color.color.rgbColor.red) {
            borderBottomColorRed = 255 * borderBottom.color.color.rgbColor.red
          }
          if (borderBottom.color.color.rgbColor.blue) {
            borderBottomColorBlue = 255 * borderBottom.color.color.rgbColor.blue
          }
          if (borderBottom.color.color.rgbColor.green) {
            borderBottomColorGreen = 255 * borderBottom.color.color.rgbColor.green
          }
          if (borderBottom.dashStyle) {
            borderBottomColorStyle = borderBottom.dashStyle.toLowerCase()
          }
          if (borderBottom.width && borderBottom.width.magnitude) {
            borderBottomColorWidth = borderBottom.width.magnitude.toString() + borderBottom.width.unit.toLowerCase()
          }
        }
      }
      let borderLeftColorRed = 0
      let borderLeftColorBlue = 0
      let borderLeftColorGreen = 0
      let borderLeftColorStyle = 'none'
      let borderLeftColorWidth = 'medium'
      if (borderLeft) {
        if (borderLeft.color && borderLeft.color.color && borderLeft.color.color.rgbColor) {
          if (borderLeft.color.color.rgbColor.red) {
            borderLeftColorRed = 255 * borderLeft.color.color.rgbColor.red
          }
          if (borderLeft.color.color.rgbColor.blue) {
            borderLeftColorBlue = 255 * borderLeft.color.color.rgbColor.blue
          }
          if (borderLeft.color.color.rgbColor.green) {
            borderLeftColorGreen = 255 * borderLeft.color.color.rgbColor.green
          }
          if (borderLeft.dashStyle) {
            borderLeftColorStyle = borderLeft.dashStyle.toLowerCase()
          }
          if (borderLeft.width && borderLeft.width.magnitude) {
            borderLeftColorWidth = borderLeft.width.magnitude.toString() + borderLeft.width.unit.toLowerCase()
          }
        }
      }
      let borderRightColorRed = 0
      let borderRightColorBlue = 0
      let borderRightColorGreen = 0
      let borderRightColorStyle = 'none'
      let borderRightColorWidth = 'medium'
      if (borderRight) {
        if (borderRight.color && borderRight.color.color && borderRight.color.color.rgbColor) {
          if (borderRight.color.color.rgbColor.red) {
            borderRightColorRed = 255 * borderRight.color.color.rgbColor.red
          }
          if (borderRight.color.color.rgbColor.blue) {
            borderRightColorBlue = 255 * borderRight.color.color.rgbColor.blue
          }
          if (borderRight.color.color.rgbColor.green) {
            borderRightColorGreen = 255 * borderRight.color.color.rgbColor.green
          }
          if (borderRight.dashStyle) {
            borderRightColorStyle = borderRight.dashStyle.toLowerCase()
          }
          if (borderRight.width && borderRight.width.magnitude) {
            borderRightColorWidth = borderRight.width.magnitude.toString() + borderRight.width.unit.toLowerCase()
          }
        }
      }
      let borderTopColorRed = 0
      let borderTopColorBlue = 0
      let borderTopColorGreen = 0
      let borderTopColorStyle = 'none'
      let borderTopColorWidth = 'medium'
      if (borderTop) {
        if (borderTop.color && borderTop.color.color && borderTop.color.color.rgbColor) {
          if (borderTop.color.color.rgbColor.red) {
            borderTopColorRed = 255 * borderTop.color.color.rgbColor.red
          }
          if (borderTop.color.color.rgbColor.blue) {
            borderTopColorBlue = 255 * borderTop.color.color.rgbColor.blue
          }
          if (borderTop.color.color.rgbColor.green) {
            borderTopColorGreen = 255 * borderTop.color.color.rgbColor.green
          }
          if (borderTop.dashStyle) {
            borderTopColorStyle = borderTop.dashStyle.toLowerCase()
          }
          if (borderTop.width && borderTop.width.magnitude) {
            borderTopColorWidth = borderTop.width.magnitude.toString() + borderTop.width.unit.toLowerCase()
          }
        }
      }
      return {
        'padding-left': indent,
        'text-align': alignment,
        'margin-top': marginTop,
        'margin-bottom': marginBelow,
        'border-bottom': borderBottomColorWidth + ' ' + borderBottomColorStyle + ` rgb(${borderBottomColorRed}, ${borderBottomColorGreen}, ${borderBottomColorBlue})`,
        'border-top': borderTopColorWidth + ' ' + borderTopColorStyle + ` rgb(${borderTopColorRed}, ${borderTopColorGreen}, ${borderTopColorBlue})`,
        'border-right': borderRightColorWidth + ' ' + borderRightColorStyle + ` rgb(${borderRightColorRed}, ${borderRightColorGreen}, ${borderRightColorBlue})`,
        'border-left': borderLeftColorWidth + ' ' + borderLeftColorStyle + ` rgb(${borderLeftColorRed}, ${borderLeftColorGreen}, ${borderLeftColorBlue})`
      }
    },
    dynamicFontStyle (fontFamily = 'Arial', weight = 400) {
      return {
        'font-family': fontFamily,
        'font-weight': weight
      }
    },
    headingStyle (paragraphStyle = {}, textStyle = {}, mode = true) {
      const firstStyle = this.dynamicStyle(
        textStyle.foregroundColor ? textStyle.foregroundColor.color.rgbColor.red : null,
        textStyle.foregroundColor ? textStyle.foregroundColor.color.rgbColor.green : null,
        textStyle.foregroundColor ? textStyle.foregroundColor.color.rgbColor.blue : null,
        textStyle.bold,
        textStyle.fontSize ? textStyle.fontSize.magnitude : null,
        textStyle.italic,
        textStyle.underline,
        textStyle.strikethrough,
        textStyle.backgroundColor ? (textStyle.backgroundColor.color ? textStyle.backgroundColor.color.rgbColor.red : null) : null,
        textStyle.backgroundColor ? (textStyle.backgroundColor.color ? textStyle.backgroundColor.color.rgbColor.green : null) : null,
        textStyle.backgroundColor ? (textStyle.backgroundColor.color ? textStyle.backgroundColor.color.rgbColor.blue : null) : null
      )
      const secondStyle = this.paragraphDynamicStyle(
        paragraphStyle.indentStart ? paragraphStyle.indentStart.magnitude : 'inherit',
        paragraphStyle.alignment,
        paragraphStyle.spaceAbove,
        paragraphStyle.spaceBelow,
        paragraphStyle.borderBottom,
        paragraphStyle.borderLeft,
        paragraphStyle.borderRight,
        paragraphStyle.borderTop
      )
      let thirdStyle = {}
      if (Object.prototype.hasOwnProperty.call(textStyle, 'weightedFontFamily')) {
        thirdStyle = this.dynamicFontStyle(textStyle.weightedFontFamily.fontFamily, textStyle.weightedFontFamily.weight)
      }
      if (mode) {
        return secondStyle
      } else {
        if (Object.prototype.hasOwnProperty.call(thirdStyle, 'font-family')) {
          firstStyle['font-family'] = thirdStyle['font-family']
        }
        if (Object.prototype.hasOwnProperty.call(thirdStyle, 'font-family') && firstStyle.weight === 'inherit') {
          firstStyle.weight = thirdStyle.weight
        }
        return firstStyle
      }
    },
    applyHeadingStyle (headingKey = null, paragraphStyle = {}, textStyle = {}, mode = true, listId = null, nestingLevel = null) {
      let headingStyle = {}
      if (headingKey) {
        if (mode) {
          headingStyle = this.headingParStyles[headingKey] || {}
        } else {
          headingStyle = this.headingTextStyles[headingKey] || {}
        }
      }
      const currentParagraphStyle = this.headingStyle(paragraphStyle, textStyle, mode) || {}
      const finalStyle = JSON.parse(JSON.stringify(headingStyle))
      const keys = Object.keys(currentParagraphStyle)
      for (let i = 0; i < keys.length; i++) {
        if (currentParagraphStyle[keys[i]] !== 'inherit') {
          finalStyle[keys[i]] = currentParagraphStyle[keys[i]]
        }
      }
      if (listId) {
        finalStyle['list-style'] = 'none'
        finalStyle['text-indent'] = '-2.1rem'
        finalStyle['margin-left'] = '2.1rem'
      }
      return finalStyle
    }
  }
}
