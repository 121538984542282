<template>
  <div class="contentTextAreaQuestion">
    <b-container class="px-5 py-3" v-if="l_esson.content.length">
      <div v-for="(content, index) in l_esson.content" :key="index" :class="{'d-none':  content && content.type === 'ONLYINTERNALLINK'}">
        <template v-if="content && content.customObject > customObject">
          <span v-for="(elem, index1) in content.content" :key="index1">
            <span v-if="elem && elem.type=='STARTCUSTOM'">
              <div v-if="customObjects[content.customObject].type=='online'" class="d-print-none">
                <textArea
                  :spreadname="customObjects[content.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
                  :id="id"
                  :name="name"
                  :userId="userId"
                  :question="checkQuestion()"
                />
              </div>
              <div v-if="customObjects[content.customObject].type=='offline'" class="d-none d-print-block">
                <div v-for="(content, index) in customObjects[content.customObject].content" :key="index" >
                  <span v-if="content.listId"
                  :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType,
                  {}, content.bulletStyle, false)"
                >
                  {{listsIndex(content.listId, content.nestingLevel)}}
                </span>
                  <span v-for="(cont, index1) in content.content" :key="index1"
                  :style="applyHeadingStyle(cont.paragraphStyle.namedStyleType, {}, cont.textStyle, false)">
                    {{cont.content}}
                  </span>
                </div>
              </div>
            </span>
          </span>
        </template>
        <template v-else>
          <headingsContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="l_esson.nameSectionMenu"
            :name="name"
            :id="id"
            :lessonpk="id"
            :userId="userId"
            :type="type"
            :customObjects="customObjects"
            :customObject="customObject"
            :lists="lists"
            :listsCounter="listsCounter"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            :index="index"
            @listCounterUpdate="listsIndex"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import TextArea from '@/components/customObjects/TextArea'
import headingsContent from '@/components/lessonSubElements/headingsContent'

export default {
  name: 'TextAreaQuestion',
  props: {
    document: Object,
    lists: Object,
    headingParStyles: Object,
    headingTextStyles: Object,
    customObjects: Array,
    listsCounter: Object,
    l_esson: Object,
    customObject: Number,
    name: String,
    id: String,
    userId: String,
    getImage: Function,
    lessonpk: String,
    type: String,
    glossary: Array,
    glossaryUrls: Array
  },
  data: function () {
    return {
    }
  },
  methods: {
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
    },
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    checkQuestion () {
      const question = []
      for (let i = 0; i < this.l_esson.content.length; i++) {
        if (this.l_esson.content[i].customObject === this.customObject) {
          for (let j = 0; j < this.l_esson.content[i].content.length; j++) {
            if (this.l_esson.content[i].content[j].type !== 'STARTCUSTOM' && this.l_esson.content[i].content[j].type !== 'ENDCUSTOM') {
              question.push(this.l_esson.content[i].content[j].content)
            }
          }
        }
      }
      const finalQuestion = question.join(' ')
      return finalQuestion
    }
  },
  components: {
    TextArea,
    headingsContent
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  #fluidMainLessonInfo {
    background-color: #ffe96f;
  }
  .HEADING_1 {
    padding: 2rem 0;
    display: block;
  }
  .HEADING_2 {
    padding: 1rem 0;
    display: block;
  }
  .HEADING_3 {
    padding: 0.5rem 0;
    display: block;
  }
  .contentLink {
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;
  }
  // h3 {
  //   margin: 40px 0 0;
  // }

  // ul {
  //   list-style-type: none;
  //   padding: 0;
  // }

  // li {
  //   display: inline-block;
  //   margin: 0 10px;
  // }

  // a {
  //   color: #42b983;
  // }

  // .page-break {
  //   page-break-after: always;
  // }

  // .decimal {
  //   counter-increment: section;
  //   content: counter(section)". ";
  // }

  // table,
  // th {
  //   border: 1px solid black;
  // }

</style>
