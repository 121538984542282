<template>
  <span class="position-relative">
    <img class="img img-fluid" :class="{fullImage: fullImage}" :src="imageUrl" :alt="imageDescription">
  </span>
</template>

<script>

export default {
  name: 'AsyncImage',
  props: [
    'imageId',
    'getImage',
    'fullImage'
  ],
  created: async function () {
    const imageInfo = await this.getImage(this.imageId)
    this.imageUrl = imageInfo[0]
    this.imageDescription = imageInfo[1]
  },
  data () {
    return {
      imageUrl: null,
      imageDescription: null
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.fullImage {
  display: block;
  width: 100%;
}
</style>
