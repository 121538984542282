<template>
  <div class="container-navbar header-min" :class="{'sticky-top': !['Home','teachersLessonsBlackBoard', 'teachersLessonsProjector', 'teachersLessons'].includes($route.name)}">
    <b-container class="p-0">
      <b-row class="d-none d-print-block">
        <b-col
          cols="12" class="pt-4 pl-md-4">
          <Logo></Logo>
        </b-col>
      </b-row>
      <b-navbar toggleable="xxl" class="p-0">
        <template v-if="['Home'].includes($route.name)">
          <template v-if="!specialCase.includes($route.params.documentid)">
            <b-navbar-brand :to="{name: 'Home'}">
              <Logo></Logo>
            </b-navbar-brand>
          </template>
        </template>

        <template v-if="['Lesson'].includes($route.name)">
          <b-navbar-nav is-nav class="mr-auto nav-section">
            <b-nav-item :to="{name: 'Students'}" class="backTo"><font-awesome-icon icon="arrow-left" /> For students</b-nav-item>
          </b-navbar-nav>
        </template>

        <template v-if="!['Home'].includes($route.name)">
          <b-navbar-brand :to="{name: 'Home'}">
            <SmallLogo></SmallLogo>
          </b-navbar-brand>
          <template v-if="!['1snngMuXvtAyJaXNuvDKyTh43ZkWNNIXi2Dq8FdMLQ9Y'].includes($route.params.documentid)">
          </template>
          <div class="mr-auto nav-section">
            <template v-if="teachersGuideDocs.includes($route.params.documentid)">
              <b-link :to="{name: 'otherContent', params: {'documentid': '1snngMuXvtAyJaXNuvDKyTh43ZkWNNIXi2Dq8FdMLQ9Y'}}" class="backTo">
                <font-awesome-icon icon="arrow-left" /> Teachers' guide</b-link>
            </template>
            <template v-if="specialCase.includes($route.params.documentid) || ['teachersLessonsBlackBoard', 'teachersLessonsProjector', 'teachersLessons'].includes($route.name)">
              <template v-if="!['otherContent'].includes($route.name)">
                <template v-if="['teachersLessonsBlackBoard'].includes($route.name)">
                  <b-link :to="{name: 'otherContent', params: {'documentid': '1vX9z2NUMkZHMMyAECJOY8yfwRW9RoYc5IwD6IP6bpTA'}}" class="backTo">
                    <font-awesome-icon icon="arrow-left" /> Blackboard lesson plans</b-link>
                </template>
                <template v-else-if="['teachersLessonsProjector'].includes($route.name)">
                  <b-link :to="{name: 'otherContent', params: {'documentid': '1HalIx5pjweAvAOKKuAPcgPYbU4T8QcpQAwUpuB8G7DY'}}" class="backTo">
                    <font-awesome-icon icon="arrow-left" /> Projector lesson plans
                  </b-link>
                </template>
                <template v-else>
                  Computer-based lesson plan
                </template>
              </template>
            </template>
          </div>
        </template>

        <b-navbar-toggle target="sidebarMenu" v-if="showMenu === 'TRUE'">
          <template>
            MENU <font-awesome-icon icon="bars"></font-awesome-icon>
          </template>
        </b-navbar-toggle>
      </b-navbar>
    </b-container>
    <b-sidebar id="sidebarMenu" bg-variant="sidebarMenu" width="430px">
      <b-navbar-nav class="pl-4">
        <b-nav-item :to="{name: 'Home'}">
          <SmallLogo></SmallLogo> Home
        </b-nav-item>
        <!-- <b-nav-item v-if="online" v-b-toggle.loginMenu>My Account</b-nav-item>
        <b-nav-item v-else v-b-toggle.loginMenu>Login</b-nav-item> -->
        <b-nav-item @click="tgview=!tgview">
          <font-awesome-icon :icon="tgview ? 'minus-square' : 'plus-square'" class="faicon" :class="{visible: tgview}"/>
          Teachers' guide
        </b-nav-item>
        <b-collapse v-model="tgview">
          <b-nav-item :to="{name: 'otherContent', params: {documentid: '1snngMuXvtAyJaXNuvDKyTh43ZkWNNIXi2Dq8FdMLQ9Y'}}">Teachers' guide contents</b-nav-item>
          <b-nav-item :to="{name: 'otherContent', params: {documentid: '1wg3VQ2v3YprkG5s1xcGNVeIpnqVgQcCcbPSevuGtTSk'}}">Introduction</b-nav-item>
          <b-nav-item :to="{name: 'otherContent', params: {documentid: '1luc4ZB9bVAFGnBhqsCb607MWYexx93A4P42bTT4PkDg'}}">Overview of lessons</b-nav-item>
          <b-nav-item :to="{name: 'otherContent', params: {documentid: '1ZTgtqj_h6DBIRXlSfFPjWuDICipWYDaG5fp3KZtUQf8'}}">Using the resources</b-nav-item>
          <b-nav-item :to="{name: 'otherContent', params: {documentid: '1caB02doLf8TkCHxvbIhfL9PdkKBwe8ylBF2ymMjqZp4'}}">Background for teachers</b-nav-item>
          <b-nav-item :to="{name: 'otherContent', params: {documentid: '10ybBR8HI9ZJoXhGhyrYa4Ej9pBm1eS6admwSlEWCXpA'}}">Development and evaluation</b-nav-item>
          <b-nav-item :to="{name: 'otherContent', params: {documentid: '1eI19kSR5s3IedeLzXkIe2A0rBMzCozVSNFS-HpQaZoo'}}">Other relevant resources</b-nav-item>
        </b-collapse>
        <b-nav-item @click="uno=!uno">
          <font-awesome-icon :icon="uno ? 'minus-square' : 'plus-square'" class="faicon" :class="{visible: uno}"/>
          Blackboard lesson plans
        </b-nav-item>
        <b-collapse v-model="uno">
          <b-nav-item :to="{name: 'otherContent', params: {'documentid': '1vX9z2NUMkZHMMyAECJOY8yfwRW9RoYc5IwD6IP6bpTA'}}">Blackboard lesson contents</b-nav-item>

          <span v-for="(lesson,id) in blackboardLessons" :key="'bl'+id">
            <span v-if="lesson.valid">
              <b-nav-item
                :to="{name: 'teachersLessonsBlackBoard', params: {coursesslug: lesson.courseSlug, lessonsslug: lesson.lessonSlug}, query:{section:'lesson'}}">
                <template v-if="lesson.avilableOffline">
                <span class="downloaded-icon" data-toggle="tooltip" data-placement="top" title="Accessible offline">
                  <font-awesome-icon icon="download"/>
                </span>
                </template>
                <template v-else><span style="padding-right: 1.3rem;"></span></template>
                {{lesson.title}}
              </b-nav-item>
            </span>
            <span v-else>{{lesson.title}} (Not available)</span>
          </span>
        </b-collapse>
        <b-nav-item @click="dos=!dos">
          <font-awesome-icon :icon="dos ? 'minus-square' : 'plus-square'" class="faicon" :class="{visible: dos}"/>
          Projector lesson plans
        </b-nav-item>
        <b-collapse v-model="dos">

          <b-nav-item :to="{name: 'otherContent', params: {'documentid': '1HalIx5pjweAvAOKKuAPcgPYbU4T8QcpQAwUpuB8G7DY'}}">Projector lesson contents</b-nav-item>

          <span v-for="(lesson,id) in projectorDocLessons" :key="'pr'+id">
            <span v-if="lesson.valid">
              <b-nav-item
                :to="{name: 'teachersLessonsProjector', params: {coursesslug: lesson.courseSlug, lessonsslug: lesson.lessonSlug}, query:{section:'lesson'}}">
                <template v-if="lesson.avilableOffline">
                <span class="text-muted downloaded-icon" data-toggle="tooltip" data-placement="top" title="Accessible offline">
                  <font-awesome-icon icon="download"/>
                </span>
                </template>
                <template v-else><span style="padding-right: 1.3rem;"></span></template>
                {{lesson.title}}
              </b-nav-item>
            </span>
            <span v-else>{{lesson.title}} (Not available)</span>
          </span>

        </b-collapse>
        <!--<b-nav-item :to="{name: 'otherContent', params: {documentid: '1k7UpL7FdEsDY6g1v_l0GnECnB3NrlxYFRLXTZN23woU'}}">Extra resources</b-nav-item>-->
        <b-nav-item @click="tres=!tres">
          <font-awesome-icon :icon="tres ? 'minus-square' : 'plus-square'" class="faicon" :class="{visible: tres}"/>
          Extra resources
        </b-nav-item>
        <b-collapse v-model="tres">
          <b-nav-item
            :to="{ name: 'glossary'}">Glossary</b-nav-item>
          <b-nav-item
            :to="{name: 'HealthActions'}">
            Examples of health actions
          </b-nav-item>
          <b-nav-item
            :to="{name: 'otherContent', params: {documentid: '138eQkEYyTfXh-swIy-bBjDIrYoIlrF11gLut7DfqI1Y'}}">
            Printouts
          </b-nav-item>
          <b-nav-item
            :to="{name: 'otherContent', params: {documentid: '17hvpgg3shWpvY8rfQl5HCcnK_o57-CpzFxiXK6Iyu3E'}}">
            Teacher training materials
          </b-nav-item>
          <b-nav-item
            href="//drive.google.com/file/d/1t_Cv3tfCY5hFo2Yr_qgzPaEY7-YpCH-B/view" target="_blank">
            Teaching strategies
          </b-nav-item>
          <b-nav-item
            :to="{name: 'otherContent', params: {documentid: '1b7mS7VYoKxTVpZQCNarN8ze_ov7djKol0-hrbAEgqU4'}}">
            Underlying principles
          </b-nav-item>
        </b-collapse>
        <b-nav-item :to="{name: 'otherContent', params: {documentid: '1WsDwJy_v0Rz48OA1dP_aA1ueUoNaE2a69VucK9x9Uzk'}}">Help</b-nav-item>
        <b-nav-item :to="{name: 'otherContent', params: {documentid: '1gdgH3QxWKOZceNAxDoqtZyLTbxsRkBYc75MG9cDXCYs'}}">About</b-nav-item>
      </b-navbar-nav>
    </b-sidebar>
    <b-sidebar id="loginMenu" bg-variant="sidebarMenu">
      <Login/>
    </b-sidebar>
    <template v-if="!['Home','teachersLessonsBlackBoard', 'teachersLessonsProjector', 'teachersLessons'].includes($route.name) && showFloatMenu">
    <div class="menuShadow menuShadow--top"></div>
    </template>
  </div>
</template>

<script>
import Logo from '@/components/logo'
import SmallLogo from '@/components/SmallLogo'
import Login from '@/components/Login'
import axios from 'axios'

export default {
  name: 'mainHeaderLanding',
  mounted: async function () {
    this.online = await this.$store.state.isLoggedIn
  },
  props: {
    showFloatMenu: Boolean
  },
  data () {
    return {
      showMenu: process.env.VUE_APP_SHOW_TEACHERS,
      specialCase: [
        '1j8AsEXU2LW31nuk3hApNJ7s4D4gjZHMFaVKdAHAPJqQ',
        '17TiN73BoxyPXeFcOfKkzWQNVV-wenk_5zLKm_zmjgIw',
        '1xs_e94MessOhRiCNgrb52-y2CUUkWPTfB8XpTmgGFW0'
      ],
      teachersGuideDocs: [
        '1wg3VQ2v3YprkG5s1xcGNVeIpnqVgQcCcbPSevuGtTSk',
        '1luc4ZB9bVAFGnBhqsCb607MWYexx93A4P42bTT4PkDg',
        '1ZTgtqj_h6DBIRXlSfFPjWuDICipWYDaG5fp3KZtUQf8',
        '1caB02doLf8TkCHxvbIhfL9PdkKBwe8ylBF2ymMjqZp4',
        '10ybBR8HI9ZJoXhGhyrYa4Ej9pBm1eS6admwSlEWCXpA',
        '1eI19kSR5s3IedeLzXkIe2A0rBMzCozVSNFS-HpQaZoo'
      ],
      online: false,
      tgview: false,
      uno: false,
      dos: false,
      tres: false,
      computerRoomLessons: [],
      blackboardLessons: [],
      projectorLessons: [],
      projectorDocLessons: []
    }
  },
  watch: {
    '$store.state.isLoggedIn': async function () {
      this.online = await this.$store.state.isLoggedIn
    }
  },
  created: function () {
    this.initialParseList()
  },
  methods: {
    async initialParseList () {
      const newconfig = {}
      if (!window.navigator.onLine) {
        newconfig.timeout = 2
      }
      this.loading = true
      axios.get(process.env.VUE_APP_BACK_URL + 'all-courses/', newconfig)
        .then(async response => {
          await this.$store.commit('addStudentListOfLessons', response.data)
          response.data = await this.$store.state.StudentListoflessons
          for (let school = 0; school < response.data.length; school++) {
            // for (let course = 0; course < response.data[school].courses.length; course++) {
            // TODO: This must show the lessons available to the selected course instead of the hardcoded one.
            const course = 1
            for (let lesson = 0; lesson < response.data[school].courses[course].lessons.length; lesson++) {
              this.computerRoomLessons.push({
                title: response.data[school].courses[course].lessons[lesson].title,
                docId: response.data[school].courses[course].lessons[lesson].pk,
                lessonSlug: response.data[school].courses[course].lessons[lesson].slug,
                courseSlug: response.data[school].courses[course].slug,
                valid: this.validId(response.data[school].courses[course].lessons[lesson].teacherDocLink),
                avilableOffline: response.data[school].courses[course].lessons[lesson].teacherDocOffline
              })
              this.blackboardLessons.push({
                title: response.data[school].courses[course].lessons[lesson].title,
                docId: response.data[school].courses[course].lessons[lesson].pk,
                lessonSlug: response.data[school].courses[course].lessons[lesson].slug,
                courseSlug: response.data[school].courses[course].slug,
                valid: this.validId(response.data[school].courses[course].lessons[lesson].teacherBlackboardDocLink),
                avilableOffline: response.data[school].courses[course].lessons[lesson].teacherBlackboardOffline
              })
              this.projectorLessons.push({
                title: response.data[school].courses[course].lessons[lesson].title,
                docId: response.data[school].courses[course].lessons[lesson].pk,
                projectorLink: 'https://docs.google.com/presentation/d/' + response.data[school].courses[course].lessons[lesson].teacherProjectorPresentationLink,
                valid: this.validId(response.data[school].courses[course].lessons[lesson].teacherProjectorPresentationLink),
                avilableOffline: response.data[school].courses[course].lessons[lesson].teacherProjectorPresentationOffline
              })
              this.projectorDocLessons.push({
                title: response.data[school].courses[course].lessons[lesson].title,
                docId: response.data[school].courses[course].lessons[lesson].pk,
                lessonSlug: response.data[school].courses[course].lessons[lesson].slug,
                courseSlug: response.data[school].courses[course].slug,
                valid: this.validId(response.data[school].courses[course].lessons[lesson].teacherProjectorDocLink),
                avilableOffline: response.data[school].courses[course].lessons[lesson].teacherProjectorDocOffline
              })
            }
            // }
          }
          this.loading = false
        }).catch(async error => {
          console.log(error)
          const response = await this.$store.state.StudentListoflessons
          if (response) {
            for (let school = 0; school < response.length; school++) {
              // for (let course = 0; course < response[school].courses.length; course++) {
              const course = 1
              for (let lesson = 0; lesson < response[school].courses[course].lessons.length; lesson++) {
                this.computerRoomLessons.push({
                  title: response[school].courses[course].lessons[lesson].title,
                  docId: response[school].courses[course].lessons[lesson].pk,
                  lessonSlug: response[school].courses[course].lessons[lesson].slug,
                  courseSlug: response[school].courses[course].slug,
                  valid: this.validId(response[school].courses[course].lessons[lesson].teacherDocLink),
                  avilableOffline: response[school].courses[course].lessons[lesson].teacherDocOffline
                })
                this.blackboardLessons.push({
                  title: response[school].courses[course].lessons[lesson].title,
                  docId: response[school].courses[course].lessons[lesson].pk,
                  lessonSlug: response[school].courses[course].lessons[lesson].slug,
                  courseSlug: response[school].courses[course].slug,
                  valid: this.validId(response[school].courses[course].lessons[lesson].teacherBlackboardDocLink),
                  avilableOffline: response[school].courses[course].lessons[lesson].teacherBlackboardOffline
                })
                this.projectorDocLessons.push({
                  title: response[school].courses[course].lessons[lesson].title,
                  docId: response[school].courses[course].lessons[lesson].pk,
                  lessonSlug: response[school].courses[course].lessons[lesson].slug,
                  courseSlug: response[school].courses[course].slug,
                  valid: this.validId(response[school].courses[course].lessons[lesson].teacherProjectorDocLink),
                  avilableOffline: response[school].courses[course].lessons[lesson].teacherProjectorDocOffline
                })
                this.projectorLessons.push({
                  title: response[school].courses[course].lessons[lesson].title,
                  docId: response[school].courses[course].lessons[lesson].pk,
                  projectorLink: 'https://docs.google.com/presentation/d/' + response[school].courses[course].lessons[lesson].teacherProjectorPresentationLink,
                  valid: this.validId(response[school].courses[course].lessons[lesson].teacherProjectorPresentationLink),
                  avilableOffline: response[school].courses[course].lessons[lesson].teacherProjectorPresentationOffline
                })
              }
              // }
            }
          }
          this.loading = false
        })
    },
    validId (docId) {
      if (docId && docId.length && docId.length > 43) {
        return true
      }
      return false
    }
  },
  components: {
    Logo,
    Login,
    SmallLogo
  }
}
</script>

<style>
.bg-sidebarMenu {
  background-color: #ffe96f !important;
}

</style>

<style lang="scss" scoped>
@import '@/assets/styles/_colors.scss';

.menuShadow {
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 10px;
  background: red;
}

.menuShadow--top {
  background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.35), transparent);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.35), transparent);
  opacity: 1;
}

.container-navbar {
  background-color: #ffe96f;
}

.navbar-light .navbar-toggler {
  border-color: #ffe96f;
}
.nav-section {
  flex-direction: row;
  font-size: 16pt;
  color: $black;
}
.backTo {
  text-decoration: underline;

  color: $black !important;

  padding-right: 0.5rem;
}
.pipe {
  padding-right: 0.5rem;
}
.pipe, .lesson-type {
  color: $black;
}
#sidebarMenu {
  .navbar-nav {
    & .nav-link {
      color: #222222;
      // text-transform: uppercase;
      font-weight: bold;
      font-size: 1.375rem;
    }
    & .collapse {
      & .nav-link {
      color: #222222;
      padding-left: 2.17rem;
      margin-right: 2.25rem;
      font-size: 1.375rem;
      font-weight: normal;
    }
    }
  }
  .downloaded-icon {
    & svg {
      font-size: 11pt;
      color: #555555;
      display: inline-block;
      vertical-align: baseline;
    }
  }
}
#wideMenu {
  .navbar-nav {
    & .nav-link {
      color: #222222;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 15pt;
    }
  }
}

.faicon {
  color: $fuchsia;
  background-color: white;
  margin-right: 0.625rem;
}

@media (max-width: 767px) {
  .backTo {
    font-size: 1rem;
  }
  #sidebarMenu {
    .navbar-nav .collapse {
      & .nav-link {
        font-size: 1.3rem;
      }
    }
  }
  .lesson-type {
    font-size: 10pt;
    margin-top: 0.69rem;
  }
}
</style>
