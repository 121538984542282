<template>
<div v-scroll="handleScroll">
  <b-toast v-model="isReady" variant="info" id="offline-toast" title="Offline ready!" toaster="b-toaster-top-right">
    <template>
      The current content is ready to be used offline.
    </template>
  </b-toast>
  <div class="container glossary pt-5">
    <div v-if="loading" class="d-flex justify-content-center mb-3">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
      <h1>Glossary</h1>
      <Glossary
        :glossary="glossary"
        @glossaryScrollTo="scrollTo"
      />
    </div>
  </div>
  <div class="text-right d-print-none">
    <b-button
      id="scrollToTopBtn"
      class="m-4 btn-goToTop"
      @click="scrollToTop">
      Go to top
    </b-button>
  </div>
</div>
</template>

<script>
import Glossary from '@/components/customObjects/Glossary'
import axios from 'axios'
import createJWT from '@/mixins/createJWT'
export default {
  mixins: [createJWT],
  name: 'Glossary_View',
  created: async function () {
    if (window.navigator.onLine) {
      await this.createJwt()
    }
    await this.initialParseGlossary()
  },
  data: function () {
    return {
      glossary: [],
      id: process.env.VUE_APP_GLOSSARY_URL,
      loading: true,
      isReady: false
    }
  },
  watch: {
    '$route' (to, from) {
      if (to.hash !== from.hash) {
        this.scrollTo()
      }
    }
  },
  methods: {
    scrollTo: function () {
      if (this.$route.hash) {
        const hash = this.$route.hash
        const el = document.querySelector(hash)
        el.scrollIntoView({ behavior: 'instant', block: 'center' })
        // const HEADER_HEIGHT = -10000
        // const elementPosition = el.getBoundingClientRect().top
        // const offsetPosition = elementPosition - HEADER_HEIGHT
        // console.log(offsetPosition)
        // window.scrollBy({
        //   top: offsetPosition
        // })
      }
    },
    async initialParseGlossary () {
      await this.makeGlossary(this.id)
    },
    async makeGlossary (glossary) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
      }
      if (!window.navigator.onLine) {
        config.timeout = 2
      }
      await axios.get('https://sheets.googleapis.com/v4/spreadsheets/' + glossary + '?includeGridData=true', config).then(async response => {
        const document = response.data
        await this.$store.commit('addGlossary', ['https://sheets.googleapis.com/v4/spreadsheets/' + glossary, response.data])
        this.parseGlossary(document)
        this.loading = false
        this.isReady = true
      }).catch(async error => {
        console.log(error)
        const document = await this.$store.state.glossaries['https://sheets.googleapis.com/v4/spreadsheets/' + glossary]
        this.parseGlossary(document)
        this.loading = false
      }
      )
    },
    parseGlossary (document) {
      for (let k = 0; k < document.sheets[0].data[0].rowData.length; k++) {
        if (k === 0) {
          continue
        }
        if (document.sheets[0].data[0].rowData[k].values[0].formattedValue && document.sheets[0].data[0].rowData[k].values[0].formattedValue.length) {
          this.glossary.push({
            key_term: document.sheets[0].data[0].rowData[k].values[0].formattedValue,
            short_definition: document.sheets[0].data[0].rowData[k].values[1].formattedValue,
            synonyms: document.sheets[0].data[0].rowData[k].values[2].formattedValue,
            research_terms: document.sheets[0].data[0].rowData[k].values[3].formattedValue,
            explanation: (Object.prototype.hasOwnProperty.call(document.sheets[0].data[0].rowData[k].values[4], 'formattedValue')) ? document.sheets[0].data[0].rowData[k].values[4].formattedValue.replace(/\n\n/g, '<br><br>') : '',
            examples: document.sheets[0].data[0].rowData[k].values[5].formattedValue,
            related_to: (document.sheets[0].data[0].rowData[k].values.length === 9) ? ((Object.prototype.hasOwnProperty.call(document.sheets[0].data[0].rowData[k].values[8], 'formattedValue')) ? document.sheets[0].data[0].rowData[k].values[8].formattedValue : '') : ''
          })
        }
      }
    },
    handleScroll: function () {
      var scrollToTopBtn = document.getElementById('scrollToTopBtn')
      var rootElement = document.documentElement
      var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
      if (scrollToTopBtn !== null) {
        if ((rootElement.scrollTop / scrollTotal) > 0.10) {
          scrollToTopBtn.classList.add('showBtn')
        } else {
          scrollToTopBtn.classList.remove('showBtn')
        }
      }
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  },
  components: {
    Glossary
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/assets/styles/_colors.scss';
  .glossary {
    min-height: 60vh;
  }
  .btn-goToTop {
    width: 5rem;
    max-width: 5rem;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;
    opacity: 0;
    transform: translateY(100px);
    transition: all .5s ease;
    border-color: $fuchsia;
    background-color: $fuchsia;
    color: white;
    font-size: 14pt;
    line-height: 1.2;
    &.showBtn {
      opacity: 1;
      transform: translateY(0)
    }
    &:hover {
      background-color: $pink;
    }
  }
</style>
