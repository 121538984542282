<template>
  <div>
    <div class="forTeachers">
      <generalContent></generalContent>
    </div>
  </div>
</template>

<script>
import generalContent from '@/components/teachers/generalContent'

export default {
  name: 'landingForTeachers',
  data () {
    return {}
  },
  components: {
    generalContent
  }
}
</script>

<style lang="scss" scoped>

</style>
