<template>
  <div>
    <b-container fluid id="mainHeaderStudents">
      <b-container>
        <h2 class="pb-3">For students</h2>
      </b-container>
    </b-container>
    <template v-if="ui.displaySpinner">
      <div class="text-center py-5">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </template>
    <template v-else>
      <template v-if="showMenu === 'FALSE'">
        <mainBanner></mainBanner>
        <b-container id="forStudents">
          <b-row>
            <b-col cols="12" class="pt-5">
              <lessonLists :lessons="lessons"></lessonLists>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <template v-else>
        <b-container id="forStudents">
          <b-row>
            <b-col
              md="7"
              cols="12">
              <!--  -->
              <div class="pt-2 pt-sm-5">
                <div v-if="introductions.length">
                  <h3>Introduction</h3>
                  <ul class="list-unstyled">
                    <li v-for="(item, index) in introductions" :key="'in' + item.title + index.toString()" >
                      <b-link :to="'courses/'+item.courseSlug + '/lessons/' + item.lessonSlug + '/students'">{{item.title}}</b-link>
                    </li>
                  </ul>
                </div>
                <div v-if="lessons.length">
                  <h3>Blackboard and projector-based lessons</h3>
                </div>
                <div v-if="miniLessons.length">
                  <h3>Mini Lessons</h3>
                  <ul class="list-unstyled">
                    <li v-for="(item, index) in miniLessons" :key="'miniles' + item.title + index.toString()" >
                      <b-link :to="'courses/'+item.courseSlug + '/lessons/' + item.lessonSlug + '/students'">{{item.title}}</b-link>
                    </li>
                  </ul>
                </div>
              </div>
              <!--  -->
            </b-col>
            <b-col
              md="5"
              cols="12">
              <b-img src="/img/410x218.png" alt="image description" fluid class="d-none d-md-block"></b-img>
            </b-col>
          </b-row>
          <b-row>
            <!-- <b-col cols="12">
              <div v-if="lessons.length">
                <p>These are interactive lessons for students to use on a computer.</p>
                <ul class="lesson-list">
                  <li v-for="(item, index) in lessons" :key="'les' + item.title + index.toString()" class="mb-3">
                    <b-link :to="'courses/'+item.courseSlug + '/lessons/' + item.lessonSlug + '/students'">{{item.title}}</b-link>
                    <span v-if="item.avilableOffline" class="ml-4 downloaded-icon" data-toggle="tooltip" data-placement="top" title="Accessible offline">
                    <font-awesome-icon icon="download"/>
                    </span>
                  </li>
                </ul>
              </div>
            </b-col> -->
            <b-col cols="12" class="pt-3">
              <p>For blackboard and projector-based lessons, there are no separate resources for students; all content is in the teacher’s lesson plan, and the teacher provides content to students via blackboard or projector.</p>
            </b-col>
            <b-col cols="12">
              <h3 class="pt-5">Computer-based lessons</h3>
              <p>For computer-based lessons (lessons where students are using computers), there are interactive resources for students to use with support from the teacher.</p>
              <p><b>Resources for students using computers:</b></p>
              <lessonLists :lessons="lessons"></lessonLists>
            </b-col>
            <b-col cols="12">
              <h2 @click="visibleLearning = !visibleLearning" class="pt-5 ls">
                <font-awesome-icon :icon="visibleLearning?'minus-square':'plus-square'" class="branch mr-3 d-print-none" role="button"></font-awesome-icon>
                Learning strategies
              </h2>
              <b-collapse v-model="visibleLearning" id="collapse-1">
                <div v-for="(item, index) in docs" :key="index">
                  <ExternalWordDocument
                    :docId="item"
                    name="name"
                    :id="'id-'+index"
                    :lessonpk="'index-'+index"
                    userId="userId"
                    type="type"
                    nameSectionMenu="nameSectionMenu"
                    :customObject="0"
                    :glossary="[]"
                    :glossaryUrls="[]"
                  />
                </div>
              </b-collapse>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import ExternalWordDoc from '@/components/customObjects/ExternalWordDoc'
import mainBanner from '@/components/landing/mainBanner'
import lessonLists from '@/components/lessonLists'

export default {
  name: 'gDocsSheetStudents',
  props: {
    msg: String
  },
  created: function () {
    this.initialParseList()
  },
  data: function () {
    return {
      showMenu: process.env.VUE_APP_SHOW_TEACHERS,
      ui: {
        displaySpinner: false
      },
      docs: [
        '1JBuzkMHqIy0xSt9nk1DWr8nkf_v7wjzI73jdlh7uht8',
        '1B21ddppj1472ujziTFSnY_UgwM-loz0rsv4p6-My20E',
        '1SoJs0HN85JAaELQo96EVuNwuSg-tyfLGM7JVOzafobc'
      ],
      document: { title: '', body: { content: [] } },
      lists: {},
      listsCounter: {},
      introductions: [],
      lessons: [],
      miniLessons: [],
      visibleLearning: false
    }
  },
  methods: {
    async initialParseList () {
      const newconfig = {}
      if (!window.navigator.onLine) {
        newconfig.timeout = 2
      }
      this.ui.displaySpinner = true
      axios.get(process.env.VUE_APP_BACK_URL + 'all-courses/', newconfig)
        .then(async response => {
          this.document = response.data
          await this.$store.commit('addStudentListOfLessons', response.data)
          response.data = await this.$store.state.StudentListoflessons
          for (let school = 0; school < response.data.length; school++) {
            // for (let course = 0; course < response.data[school].courses.length; course++) {
            const course = 1
            for (let lesson = 0; lesson < response.data[school].courses[course].lessons.length; lesson++) {
              this.lessons.push({
                title: response.data[school].courses[course].lessons[lesson].title,
                docId: response.data[school].courses[course].lessons[lesson].pk,
                lessonSlug: response.data[school].courses[course].lessons[lesson].slug,
                courseSlug: response.data[school].courses[course].slug,
                avilableOffline: response.data[school].courses[course].lessons[lesson].studentDocOffline
              })
            }
            // }
          }
          this.ui.displaySpinner = false
        }).catch(async error => {
          console.log(error)
          const response = await this.$store.state.StudentListoflessons
          if (response) {
            for (let school = 0; school < response.length; school++) {
              // for (let course = 0; course < response[school].courses.length; course++) {
              const course = 1
              for (let lesson = 0; lesson < response[school].courses[course].lessons.length; lesson++) {
                this.lessons.push({
                  title: response[school].courses[course].lessons[lesson].title,
                  docId: response[school].courses[course].lessons[lesson].pk,
                  lessonSlug: response[school].courses[course].lessons[lesson].slug,
                  courseSlug: response[school].courses[course].slug,
                  avilableOffline: response[school].courses[course].lessons[lesson].studentDocOffline
                })
              }
              // }
            }
          }
          this.ui.displaySpinner = false
        })
    }
  },
  components: {
    ExternalWordDocument: ExternalWordDoc,
    mainBanner: mainBanner,
    lessonLists: lessonLists
  }
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/_colors.scss";

  #mainHeaderStudents {
    background-color: #FFE96F;
  }
  h2 {
    font-size: 28pt;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
  }
  h3 {
    font-size: 2.25rem;
    font-weight: bold;
  }

  #collapse-1 {
    margin-left: 4rem;
  }
  #forStudents {
    h3 {
      margin-top: 0;
    }
  }

  .students-min {
    min-height: 77vh;
  }

@media (max-width: 767px) {
  #mainHeaderStudents {
    h2 {
      font-size: 24pt;
    }
  }

}
</style>
