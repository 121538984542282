
<template>
  <div class="externalDoc">
    <div>
      <a name="top-lesson"></a>
    </div>
    <template v-if="loading">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </template>
    <template v-else>
      <div :class="type">
        <template v-if="l_esson.content.length">
          <div v-for="(content, index) in l_esson.content" :key="index" :class="{'d-none':  content && content.type === 'ONLYINTERNALLINK'}">
            <template v-if="content && content.customObject > -1">
              <template v-if="customObjects[content.customObject].parentCustomObject === -1">
                <div v-for="(elem, index1) in content.content" :key="index1">
                  <template v-if="elem && elem.type=='STARTCUSTOM'">
                    <componentsContent
                        :elem="elem"
                        :customObjects="customObjects"
                        :document="document"
                        :lists="lists"
                        :headingParStyles="headingParStyles"
                        :headingTextStyles="headingTextStyles"
                        :listsCounter="listsCounter"
                        :content="content"
                        :glossary="glossary"
                        :glossaryUrls="glossaryUrls"
                        :name="name"
                        :answerLink="answerLink"
                        :lessonpk="lessonpk"
                        :id="id"
                        :userId="userId"
                        :type="type"
                        :index="index"
                        :index1="index1"
                        :getImage="getImage"
                        :nameSectionMenu="nameSectionMenu"
                        @listCounterUpdate="listsIndex"
                        @changeSection="changeSection"
                        @movingto="movingto"
                        @ready="updateDocLoaded"
                        />
                  </template>
                </div>
              </template>
            </template>
            <template v-else>
              <headingsContent
                :content="content"
                :getImage="getImage"
                :nameSectionMenu="nameSectionMenu"
                :name="name"
                :id="id"
                :lessonpk="id"
                :userId="userId"
                :type="type"
                :customObjects="customObjects"
                :customObject="customObject"
                :lists="lists"
                :listsCounter="listsCounter"
                :headingParStyles="headingParStyles"
                :headingTextStyles="headingTextStyles"
                :glossary="glossary"
                :glossaryUrls="glossaryUrls"
                :index="index"
                @listCounterUpdate="listsIndex"
                @changeSection="changeSection"
                @movingto="movingto"
              />
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import parseLessons from '@/mixins/parseLesson'
import createJWT from '@/mixins/createJWT'

export default {
  mixins: [parseLessons, createJWT],
  name: 'ExternalWordDocument',
  props: {
    docId: String,
    name: String,
    id: String,
    lessonpk: String,
    userId: String,
    type: String,
    nameSectionMenu: String,
    customObject: Number,
    glossary: Array,
    glossaryUrls: Array
  },
  created: async function () {
    this.initialParseLesson()
    const vm = this
    this.$root.$on('MoveTo', (movetosection, ref, wait) => {
      if (vm.$refs[ref]) {
        vm.scroll = true
        vm.movingto(vm.nameSectionMenu, movetosection, ref, wait)
      }
    })
    this.$root.$on('Scroll', (ref) => {
      if (vm.scroll) {
        vm.scrollMeTo(ref, vm)
        vm.scroll = false
      }
    })
  },
  beforeDestroy () {
    this.$root.$off('MoveTo')
    this.$root.$off('Scroll')
    this.listsCounter = {}
  },
  data: function () {
    return {
      document: {
        title: '',
        body: {
          content: []
        }
      },
      lists: {},
      headingParStyles: {},
      customObjects: [],
      currentCustomObject: { indent: -1, nesting: -1, parent: null },
      contentCount: 0,
      headingTextStyles: {},
      listsCounter: {},
      lesson: {
        title: [],
        menu: [],
        content: []
      },
      l_esson: {
        content: []
      },
      inputName: '',
      glossaryCount: {},
      selectedLanguage: 'En',
      languages: {
        En: 1,
        Es: 2
      },
      answerLink: '',
      loading: true,
      shouldMakeGlossary: false,
      docLoaded: { root: false },
      scroll: false
    }
  },
  methods: {
    printLesson: function () {
      window.print()
    },
    // 'https://docs.googleapis.com/v1/documents/1tJqsqAEyCYzNf7Z46IKOwUx1ZEd1t2xk'
    initialParseLesson () {
      axios.get(process.env.VUE_APP_DOCUMENTS_URL + this.docId, {}).then(response => {
        this.document = response.data
        this.$store.commit('addExternalWordDocument', [process.env.VUE_APP_DOCUMENTS_URL + this.docId, response.data])
        this.parseDocument()
        this.loading = false
        this.docLoaded.root = true
        if (Object.values(this.docLoaded).every(Boolean)) {
          this.$emit('ready', this.customObject)
        }
      }).catch(error => {
        console.log(error)
        this.document = this.$store.state.externalWordDocuments[process.env.VUE_APP_DOCUMENTS_URL + this.docId]
        if (this.document) {
          this.parseDocument()
        }
        this.loading = false
      })
      // }
    },
    tableCellStyle (paddingLeft = 0, paddingRight = 0, paddingTop = 0, paddingBottom = 0, minRowHeight = 0) {
      return {
        'padding-left': `${paddingLeft}pt`,
        'padding-right': `${paddingRight}pt`,
        'padding-bottom': `${paddingBottom}pt`,
        'padding-top': `${paddingTop}pt`,
        'min-height': `${minRowHeight}pt`
      }
    },
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    capitalize (string) {
      return string && string[0].toUpperCase() + string.slice(1)
    },
    moveToLink (content) {
      const ref = content.split('-')[0]
      this.$root.$emit('MoveTo', this.nameSectionMenu, ref, true)
    },
    getMoveToLink (content) {
      const word = content.split(/-(.+)/)[1]
      return word
    },
    scrollMeTo (ref, help = this) { /* https://stackoverflow.com/questions/42645964/vue-js-anchor-to-div-within-the-same-component */
      if (help.$refs[ref] && help.$refs[ref].length) {
        const element = help.$refs[ref][0]
        const top = element.offsetTop
        window.scrollTo(0, top)
      }
    },
    updateDocLoaded (customObject) {
      this.docLoaded[customObject] = true
      if (Object.values(this.docLoaded).every(Boolean)) {
        this.$emit('ready', this.customObject)
      }
    }
  },
  components: {
    headingsContent: () => import('@/components/lessonSubElements/headingsContent'),
    componentsContent: () => import('@/components/lessonSubElements/componentsContent')
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .page-break-style {
    page-break-after: always;
  }
</style>
