<template>
  <div class="contentRegularText">
    <template v-if="l_esson.content.length">
      <div v-for="(content, index) in l_esson.content" :key="index" :class="{'d-none':  content && content.type === 'ONLYINTERNALLINK'}">
        <template v-if="content && content.customObject > customObject">
          <template v-if="customObjects[content.customObject].parentCustomObject === customObject">
            <div v-for="(elem, index1) in content.content" :key="index1">
              <template v-if="elem && elem.type=='STARTCUSTOM'">
                <componentsContent
                :elem="elem"
                :customObjects="customObjects"
                :document="document"
                :lists="lists"
                :headingParStyles="headingParStyles"
                :headingTextStyles="headingTextStyles"
                :listsCounter="listsCounter"
                :content="content"
                :glossary="glossary"
                :glossaryUrls="glossaryUrls"
                :name="name"
                :answerLink="answerLink"
                :lessonpk="lessonId"
                :id="id"
                :userId="userId"
                :type="type"
                :index="index"
                :index1="index1"
                :getImage="getImage"
                :nameSectionMenu="nameSectionMenu"
                :login="login"
                @listCounterUpdate="listsIndex"
                @changeSection="changeSection"
                @movingto="movingto"
                @ready="updateDocLoaded"
                />
                <template v-if="customObjects[content.customObject].type=='collapsable-menu' || customObjects[content.customObject].type=='collapsible-menu'">
                  <div class="inside-content-menu">
                    <ul class="d-print-none">
                      <li v-for="(me, indexcm) in menuElements" :key="content.customObject + '-' + indexcm">
                        <a :href="'#'+me.customId" @click="moveToLink(me.customId, false)">
                          <span
                            v-for="(elem, index) in me.content"
                            :key="index"
                            :class="elem.type === 'gototop' ? 'gtt' : ''">
                              <template v-if="elem.type === 'BR'">
                                <br/>
                              </template>
                              <template v-else>
                                {{elem.content}}
                              </template>
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </template>
              </template>
            </div>
          </template>
        </template>
        <template v-else>
        <headingsContent
          :content="content"
          :getImage="getImage"
          :nameSectionMenu="nameSectionMenu"
          :name="name"
          :id="id"
          :lessonpk="lessonId"
          :userId="userId"
          :type="type"
          :customObjects="customObjects"
          :customObject="customObject"
          :lists="lists"
          :listsCounter="listsCounter"
          :headingParStyles="headingParStyles"
          :headingTextStyles="headingTextStyles"
          :glossary="glossary"
          :glossaryUrls="glossaryUrls"
          :index="index"
          @listCounterUpdate="listsIndex"
          @changeSection="changeSection"
          @movingto="movingto"
        />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
// import replaceSpecialChars from 'replace-special-characters'

export default {
  name: 'Regular_Text',
  props: {
    document: Object,
    lists: Object,
    headingParStyles: Object,
    headingTextStyles: Object,
    customObjects: Array,
    listsCounter: Object,
    l_esson: Object,
    customObject: Number,
    glossary: Array,
    glossaryUrls: Array,
    lessonId: String,
    name: String,
    answerLink: String,
    userId: String,
    id: String,
    type: String,
    getImage: Function,
    nameSectionMenu: String,
    login: Boolean
  },
  data: function () {
    return {
      menuElements: [],
      scroll: false
    }
  },
  components: {
    componentsContent: () => import('@/components/lessonSubElements/componentsContent'),
    headingsContent: () => import('@/components/lessonSubElements/headingsContent')
  },
  created () {
    const vm = this
    this.$root.$on('MoveTo', (movetosection, ref, wait) => {
      if (vm.$refs[ref]) {
        vm.scroll = true
        vm.movingto(vm.sectionmenu, movetosection, ref, wait)
      }
    })
    this.$root.$on('Scroll', (ref) => {
      if (vm.scroll) {
        vm.scrollMeTo(ref, vm)
        vm.scroll = false
      }
    })
  },
  mounted () {
    this.buildList()
  },
  beforeDestroy () {
    this.$root.$off('MoveTo')
    this.$root.$off('Scroll')
  },
  methods: {
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
    },
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    moveToLink (content, wait = true) {
      const ref = content.split('-')[0]
      this.$root.$emit('MoveTo', this.nameSectionMenu, ref, wait)
    },
    getMoveToLink (content) {
      const word = content.split(/-(.+)/)[1]
      return word
    },
    printLesson: function () {
      window.print()
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    buildList () {
      for (let i = 0; i < this.l_esson.content.length; i++) {
        if (this.l_esson.content[i].type && this.l_esson.content[i].type === 'HEADING_3') {
          const menuItem = JSON.parse(JSON.stringify(this.l_esson.content[i]))
          menuItem.customId = 'mi_' + this.l_esson.content[i].customObject.toString() + '_' + i.toString()
          this.menuElements.push(menuItem)
        }
      }
    },
    scrollMeTo (ref, help = this) { /* https://stackoverflow.com/questions/42645964/vue-js-anchor-to-div-within-the-same-component */
      if (help.$refs[ref] && help.$refs[ref].length) {
        const element = help.$refs[ref][0]
        const top = element.offsetTop
        window.scrollTo(0, top)
      }
    },
    capitalize (string) {
      return string && string[0].toUpperCase() + string.slice(1)
    },
    updateDocLoaded (customObject) {
      this.$emit('ready', customObject)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
