<template>
  <div>
    <Board id="board-1" :cards.sync="cards" @newCards="newCards" @finish="finish" :drag-index="dragIndex" :disable="disable"/>
    <BoardQuestions id="board-3" :answerCards="answerCards" :question="question" @restoreCard="restoreCard" @newCard="newCard" :drag-index="dragIndex"/>
  </div>
</template>

<script>
import Board from '@/components/customObjects/Board'
import BoardQuestions from '@/components/customObjects/BoardQuestions'
import axios from 'axios'
export default {
  name: 'DragNDrop',
  props: ['id', 'name', 'dragIndex', 'spreadname', 'userId', 'login'],
  data () {
    return {
      question: [],
      questions: [],
      value: [],
      explanationsCorrect: [],
      explanationsIncorrect: [],
      answerCards: null,
      cards: [],
      questionIndex: -1,
      answersIndex: -1,
      wordsIndex: -1,
      disable: false
    }
  },
  mounted: async function () {
    await this.initialParseDragNDrop()
  },
  methods: {
    async initialParseDragNDrop () {
      // if (this.$gapi.isAuthenticated() !== true) {
      //   this.$login()
      // } else {
      // await this.$gapi.getGapiClient().then(async (gapi) => {
      const config = {
      }
      if (!window.navigator.onLine) {
        config.timeout = 2
      }
      const data = {
        type_of_question: 'DragNDrop',
        spreadname: this.spreadname
      }
      await axios.post(process.env.VUE_APP_BACK_URL + 'lesson/' + this.id + '/answer/', data, config).then(async response => {
        this.document = response.data
        await this.$store.commit('addQuestion', [process.env.VUE_APP_BACK_URL + 'lesson/' + this.id + '/answer/', response.data])
        await this.$store.commit('deleteOfflineAnswer', this.id)
        await this.parseDragNDrop()
      }
      ).catch(async error => {
        console.log(error)
        this.document = await this.$store.state.questions[process.env.VUE_APP_BACK_URL + 'lesson/' + this.id + '/answer/']
        if (this.document) {
          await this.parseDragNDrop()
        }
      })
      // })
      //   .catch((error) => { console.log(error) })
    },
    async parseDragNDrop () {
      for (let k = 0; k < this.document.sheets.length; k++) {
        if (this.document.sheets[k].properties.title === this.spreadname + '-DragNDrop') {
          this.questionIndex = k
        } else if (this.document.sheets[k].properties.title === this.spreadname + '-Words') {
          this.wordsIndex = k
        } else if (this.document.sheets[k].properties.title === this.spreadname + '-Answers') {
          this.answersIndex = k
        }
      }
      for (let k = 0; k < this.document.sheets[this.questionIndex].data[0].rowData.length; k++) {
        this.question.push(this.document.sheets[this.questionIndex].data[0].rowData[k].values[0].formattedValue)
      }
      for (let k = 0; k < this.document.sheets[this.wordsIndex].data[0].rowData.length; k++) {
        if (Object.hasOwnProperty.call(this.document.sheets[this.wordsIndex].data[0].rowData[k].values[0], 'formattedValue')) {
          if (this.document.sheets[this.wordsIndex].data[0].rowData[k].values[0].formattedValue === 'Words') {
            continue
          } else {
            this.questions.push(this.document.sheets[this.wordsIndex].data[0].rowData[k].values[0].formattedValue)
            this.value.push(this.document.sheets[this.wordsIndex].data[0].rowData[k].values[1].formattedValue)
            this.explanationsCorrect.push(this.document.sheets[this.wordsIndex].data[0].rowData[k].values[2].formattedValue)
            this.explanationsIncorrect.push(this.document.sheets[this.wordsIndex].data[0].rowData[k].values[3].formattedValue)
          }
        }
      }
      this.buildDeck()
      await this.getAnswer()
    },
    async writeAnswerCheck (input) {
      if (this.name || this.login) {
        let row = -1
        // await this.$gapi.getGapiClient().then((gapi) => {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
        }
        axios.get('https://sheets.googleapis.com/v4/spreadsheets/' + this.spreadsheet + '?includeGridData=true', config).then(response => {
          const document = response.data
          const length = document.sheets[this.answersIndex].data[0].rowData ? document.sheets[this.answersIndex].data[0].rowData.length : -1
          for (let k = 0; k < length; k++) {
            if (Object.prototype.hasOwnProperty.call(document.sheets[this.answersIndex].data[0].rowData[k], 'values')) {
              if (document.sheets[this.answersIndex].data[0].rowData[k].values[0].formattedValue === this.name) {
                row = k + 1
                break
              }
            }
          }
          if (row === -1) {
            row = document.sheets[this.answersIndex].data[0].rowData ? document.sheets[this.answersIndex].data[0].rowData.length + 1 : 1
          }
          let config = {}
          if (this.login) {
            config = {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
              }
            }
          }
          const requestBody = {
            majorDimension: 'ROWS',
            range: this.spreadname + '-Answers!A' + row.toString() + ':B' + row.toString(),
            values: [[this.name, input]]
          }
          axios.put('https://sheets.googleapis.com/v4/spreadsheets/' + this.spreadsheet + '/values/' + this.spreadname + '-Answers!A' + row.toString() + ':B' + row.toString() + '?valueInputOption=USER_ENTERED', requestBody, config).then(res => { console.log(res) })
        }
        ).catch(error => {
          console.log(error)
        })
        // })
        //   .catch((error) => { console.log(error) })
      }
    },
    async writeAnswer (input, isCorrect) {
      if ((this.name || this.login) && !this.disable) {
        const data = {
          spreadname: this.spreadname,
          answersIndex: this.answersIndex,
          answer: input,
          name: this.name,
          userId: this.userId,
          correct: isCorrect
        }
        let config = {}
        if (this.login) {
          config = {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        }
        await this.$store.commit('addOfflineAnswer', [this.id, this.spreadname, this.name, this.userId, input])
        axios.patch(process.env.VUE_APP_BACK_URL + 'lesson/' + this.id + '/answer/', data, config)
        // })
        //   .catch((error) => { console.log(error) })
      }
    },
    async getAnswer () {
      const offlineAnswers = await this.$store.state.offlineAnswers
      if (this.name && this.userId && this.document.sheets[this.answersIndex].data[0].rowData && this.answersIndex > -1) {
        for (let k = 0; k < this.document.sheets[this.answersIndex].data[0].rowData.length; k++) {
          if (Object.prototype.hasOwnProperty.call(this.document.sheets[this.answersIndex].data[0].rowData[k], 'values')) {
            if (this.document.sheets[this.answersIndex].data[0].rowData[k].values[0].formattedValue === this.name &&
            this.document.sheets[this.answersIndex].data[0].rowData[k].values[1].formattedValue === this.userId) {
              const answers = this.document.sheets[this.answersIndex].data[0].rowData[k].values[2].formattedValue.split(';')
              answers.pop()
              for (let j = 0; j < answers.length; j++) {
                const firstIndex = this.cards.findIndex((card) => { return card.title === answers[j] })
                if (firstIndex > -1) {
                  if (this.answerCards === null) {
                    this.answerCards = []
                  }
                  this.answerCards.push(this.cards[firstIndex])
                  this.cards.splice(firstIndex, 1)
                }
              }
              break
            } else if (Object.prototype.hasOwnProperty.call(offlineAnswers, this.id) &&
                Object.prototype.hasOwnProperty.call(offlineAnswers[this.id], this.spreadname) &&
                Object.prototype.hasOwnProperty.call(offlineAnswers[this.id][this.spreadname], this.name) &&
                Object.prototype.hasOwnProperty.call(offlineAnswers[this.id][this.spreadname][this.name], this.userId)) {
              const answers = offlineAnswers[this.id][this.spreadname][this.name][this.userId].split(';')
              answers.pop()
              for (let j = 0; j < answers.length; j++) {
                const firstIndex = this.cards.findIndex((card) => { return card.title === answers[j] })
                if (firstIndex > -1) {
                  if (this.answerCards === null) {
                    this.answerCards = []
                  }
                  this.answerCards.push(this.cards[firstIndex])
                  this.cards.splice(firstIndex, 1)
                }
              }
              break
            }
          }
        }
      }
    },
    restoreCard: function (card) {
      this.cards.push(card)
    },
    newCard: function (newCards) {
      this.answerCards = newCards
      if (newCards.length) {
        let answer = ''
        let isCorrect = ''
        for (let i = 0; i < newCards.length; i++) {
          answer += newCards[i].title + ';'
          isCorrect += (newCards[i].index === (i + 1).toString() ? 'Correct' : 'Incorrect') + ';'
        }
        this.writeAnswer(answer, isCorrect)
      } else {
        this.answerCards = null
        this.writeAnswer(null, null)
      }
    },
    newCards: function (newCard) {
      this.cards.push(newCard)
    },
    finish: function (id) {
      const firstIndex = this.cards.findIndex((card) => { return card.id === id })
      if (firstIndex > -1) {
        this.cards.splice(firstIndex, 1)
      }
    },
    buildDeck: function () {
      this.cards = []
      for (let i = 0; i < this.questions.length; i++) {
        const card = {
          id: i,
          title: this.questions[i],
          index: this.value[i],
          explanationCorrect: this.explanationsCorrect[i],
          explanationIncorrect: this.explanationsIncorrect[i]
        }
        this.cards.push(card)
      }
    },
    disableDraggable () {
      this.disable = true
    },
    async createSheetIfItNotExists () {
      let createSheets = false
      const prop = {
        requests: [
        ]
      }
      if (this.questionIndex < 0) {
        const request = {
          addSheet: {
            properties: {
              title: this.spreadname + '-DragNDrop',
              gridProperties: {
                rowCount: 200,
                columnCount: 12
              }
            }
          }
        }
        prop.requests.push(request)
        createSheets = true
      }
      if (this.answersIndex < 0) {
        const request = {
          addSheet: {
            properties: {
              title: this.spreadname + '-Answers',
              gridProperties: {
                rowCount: 200,
                columnCount: 12
              }
            }
          }
        }
        prop.requests.push(request)
        createSheets = true
      }
      if (this.wordsIndex < 0) {
        const request = {
          addSheet: {
            properties: {
              title: this.spreadname + '-Words',
              gridProperties: {
                rowCount: 200,
                columnCount: 12
              }
            }
          }
        }
        prop.requests.push(request)
        createSheets = true
      }
      if (createSheets) {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
        }
        await axios.post('https://sheets.googleapis.com/v4/spreadsheets/' + this.spreadsheet + ':batchUpdate', prop, config)
          .then(async (_) => {
            await axios.get('https://sheets.googleapis.com/v4/spreadsheets/' + this.spreadsheet + '?includeGridData=true', config).then(async response => {
              this.document = response.data
              for (let k = 0; k < this.document.sheets.length; k++) {
                if (this.document.sheets[k].properties.title === this.spreadname + '-DragNDrop') {
                  this.questionIndex = k
                } else if (this.document.sheets[k].properties.title === this.spreadname + '-Words') {
                  this.wordsIndex = k
                } else if (this.document.sheets[k].properties.title === this.spreadname + '-Answers') {
                  this.answersIndex = k
                }
              }
            })
          })
      }
    }
  },
  watch: {
    name: async function () {
      if (this.document && Object.prototype.hasOwnProperty.call(this.document, 'sheets')) {
        await this.getAnswer()
      }
    },
    userId: async function () {
      if (this.document && Object.prototype.hasOwnProperty.call(this.document, 'sheets')) {
        await this.getAnswer()
      }
    }
  },
  components: {
    BoardQuestions,
    Board
  }
}
</script>

<style lang="scss" scoped>
</style>
