
<template>
<span>
  <template v-if="elem && elem.type=='STARTCUSTOM'">
    <template v-if="customObjects[content.customObject].type=='box-yellow'">
        <yellow-box
        :document="document"
        :lists="lists"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :customObjects="customObjects"
        :listsCounter="listsCounter"
        :l_esson="customObjects[content.customObject]"
        :customObject="content.customObject"
        :glossary="glossary"
        :glossaryUrls="glossaryUrls"
        :lessonId="lessonpk"
        :getImage="getImage"
        :id="id"
        :userId="userId"
        :type="type"
        :answerLink="answerLink"
        :nameSectionMenu="nameSectionMenu"
        @changeSection="changeSection"
        @movingto="movingto"
        @listCounterUpdate="listsIndex"
        @ready="updateDocLoaded"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='box-white'">
        <white-box
        :document="document"
        :lists="lists"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :customObjects="customObjects"
        :listsCounter="listsCounter"
        :l_esson="customObjects[content.customObject]"
        :customObject="content.customObject"
        :glossary="glossary"
        :glossaryUrls="glossaryUrls"
        :lessonpk="lessonpk"
        :getImage="getImage"
        :id="id"
        :userId="userId"
        :type="type"
        :answerLink="answerLink"
        :nameSectionMenu="nameSectionMenu"
        @changeSection="changeSection"
        @movingto="movingto"
        @listCounterUpdate="listsIndex"
        @ready="updateDocLoaded"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='blackboard'">
        <blackboard
        :document="document"
        :lists="lists"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :customObjects="customObjects"
        :listsCounter="listsCounter"
        :l_esson="customObjects[content.customObject]"
        :customObject="content.customObject"
        :glossary="glossary"
        :getImage="getImage"
        :lessonpk="lessonpk"
        :id="id"
        :userId="userId"
        :type="type"
        :glossaryUrls="glossaryUrls"
        @listCounterUpdate="listsIndex"
        @changeSection="changeSection"
        @movingto="movingto"
        @ready="updateDocLoaded"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='collapsable' || customObjects[content.customObject].type === 'collapsible'">
        <collapsable
        :ref="'colap-'+content.customObject.toString() + '-'+index.toString()"
        :document="document"
        :lists="lists"
        :getImage="getImage"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :customObjects="customObjects"
        :listsCounter="listsCounter"
        :l_esson="customObjects[content.customObject]"
        :customObject="content.customObject"
        :glossary="glossary"
        :glossaryUrls="glossaryUrls"
        :name="name"
        :answerLink="answerLink"
        :lessonId="lessonpk"
        :id="id"
        :userId="userId"
        :type="type"
        :nameSectionMenu="nameSectionMenu"
        :login="login"
        @changeSection="changeSection"
        @movingto="movingto"
        @listCounterUpdate="listsIndex"
        @ready="updateDocLoaded"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='quiz'">
        <quiz
        :document="document"
        :lists="lists"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :customObjects="customObjects"
        :listsCounter="listsCounter"
        :l_esson="customObjects[content.customObject]"
        :customObject="content.customObject"
        :name="name"
        :id="lessonpk"
        :userId="userId"
        :getImage="getImage"
        :type="type"
        :glossary="glossary"
        :glossaryUrls="glossaryUrls"
        :login="login"
        @listCounterUpdate="listsIndex"
        @changeSection="changeSection"
        @movingto="movingto"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='select'">
        <quiz-select
        :document="document"
        :lists="lists"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :customObjects="customObjects"
        :listsCounter="listsCounter"
        :l_esson="customObjects[content.customObject]"
        :customObject="content.customObject"
        :name="name"
        :id="lessonpk"
        :userId="userId"
        :getImage="getImage"
        :type="type"
        :glossary="glossary"
        :glossaryUrls="glossaryUrls"
        :login="login"
        @listCounterUpdate="listsIndex"
        @changeSection="changeSection"
        @movingto="movingto"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='textarea'">
        <TextAreaQuestion
        :document="document"
        :lists="lists"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :customObjects="customObjects"
        :listsCounter="listsCounter"
        :l_esson="customObjects[content.customObject]"
        :customObject="content.customObject"
        :name="name"
        :id="lessonpk"
        :userId="userId"
        :getImage="getImage"
        :type="type"
        :glossary="glossary"
        :glossaryUrls="glossaryUrls"
        :login="login"
        @listCounterUpdate="listsIndex"
        @changeSection="changeSection"
        @movingto="movingto"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='drag-and-drop'">
        <DragNDrop
        :spreadname="customObjects[content.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
        :name="name"
        :drag-index="index1"
        :id="lessonpk"
        :userId="userId"
        :login="login"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='external-document'">
        <ExternalDocument
        :id="customObjects[content.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
        :lesson="id"
        :spreadname="customObjects[content.customObject].content[2].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='external-word-document'">
        <ExternalWordDocument
        :docId="customObjects[content.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
        :name="name"
        :id="id"
        :lessonpk="lessonpk"
        :userId="userId"
        :type="type"
        :customObject="content.customObject"
        :nameSectionMenu="nameSectionMenu"
        :glossary="glossary"
        :glossaryUrls="glossaryUrls"
        @listCounterUpdate="listsIndex"
        @changeSection="changeSection"
        @movingto="movingto"
        @ready="updateDocLoaded"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='dialog-text'">
        <DialogBox
        :text="customObjects[content.customObject]"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :document="document"
        :getImage="getImage"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='dialog-text-left'">
        <DialogBoxLeft
        :text="customObjects[content.customObject]"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :document="document"
        :getImage="getImage"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='image-text'">
        <ImageText
        :text="customObjects[content.customObject]"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :document="document"
        :getImage="getImage"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='image-text-left'">
        <ImageTextLeft
        :text="customObjects[content.customObject]"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :document="document"
        :getImage="getImage"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='image-text-white'">
        <WhiteImageText
        :text="customObjects[content.customObject]"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :document="document"
        :getImage="getImage"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='image-text-left-white'">
        <WhiteImageTextLeft
        :text="customObjects[content.customObject]"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :document="document"
        :getImage="getImage"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='image-light-blue'">
        <ImageLightBlue
        :text="customObjects[content.customObject]"
        :document="document"
        :getImage="getImage"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='image-white'">
        <ImageWhite
        :text="customObjects[content.customObject]"
        :document="document"
        :getImage="getImage"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='teaching-strategies'">
        <teaching-strategies
        :id="customObjects[content.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
        :lesson="id"
        :spreadname="customObjects[content.customObject].content[2].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='key-term'">
        <key-term
        :word="customObjects[content.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
        :glossary="glossary"
        />
    </template>
    <template v-if="customObjects[content.customObject].type=='section'">
      <section-content
        :ref="'sec-'+content.customObject.toString() + '-'+index.toString()"
        :document="document"
        :lists="lists"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :customObjects="customObjects"
        :listsCounter="listsCounter"
        :l_esson="customObjects[content.customObject]"
        :customObject="content.customObject"
        :glossary="glossary"
        :glossaryUrls="glossaryUrls"
        :indentifier="index"
        :name="name"
        :answerLink="answerLink"
        :lessonpk="lessonpk"
        :userId="userId"
        :id="id"
        :type="type"
        :login="login"
        :getImage="getImage"
        @changeSection="changeSection"
        @movingto="movingto"
        @listCounterUpdate="listsIndex"
        @ready="updateDocLoaded"
      >
      </section-content>
    </template>
    <template v-if="customObjects[content.customObject].type=='teaching-tips'">
      <teaching-tips
        :document="document"
        :lists="lists"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :customObjects="customObjects"
        :listsCounter="listsCounter"
        :l_esson="customObjects[content.customObject]"
        :glossary="glossary"
        :glossaryUrls="glossaryUrls"
        :lessonId="lessonpk"
        :customObject="content.customObject"
        :name="name"
        :answerLink="answerLink"
        :id="id"
        :userId="userId"
        :getImage="getImage"
        :type="type"
        :nameSectionMenu="nameSectionMenu"
        @changeSection="changeSection"
        @movingto="movingto"
        @listCounterUpdate="listsIndex"
      >
      </teaching-tips>
    </template>
    </template>
  </span>
</template>

<script>
import Vue from 'vue'

import YellowBox from '@/components/customObjects/YellowBox'
import WhiteBox from '@/components/customObjects/WhiteBox'
import Blackboard from '@/components/customObjects/BlackBoard'
import Quiz from '@/components/customObjects/Quiz'
import QuizSelect from '@/components/customObjects/QuizSelect'
import TextAreaQuestion from '@/components/customObjects/TextAreaQuestion'
import DragNDrop from '@/components/customObjects/DragNDrop'
// import Glossary from '@/components/customObjects/Glossary'
import Collapsable from '@/components/customObjects/Collapsable'
import ExternalDocument from '@/components/customObjects/externalDocument'
import TeachingStrategies from '@/components/customObjects/TeachingStrategies'
import DialogBox from '@/components/customObjects/DialogBox'
import DialogBoxLeft from '@/components/customObjects/DialogBoxLeft'
import ImageText from '@/components/customObjects/ImageText'
import ImageTextLeft from '@/components/customObjects/ImageTextLeft'
import WhiteImageText from '@/components/customObjects/WhiteImageText'
import WhiteImageTextLeft from '@/components/customObjects/WhiteImageTextLeft'
import ImageLightBlue from '@/components/customObjects/ImageLightBlue'
import KeyTerm from '@/components/customObjects/KeyTerm'
import Section from '@/components/customObjects/Section'
import TeachingTips from '@/components/customObjects/TeachingTips'
import ImageWhite from '@/components/customObjects/ImageWhite'

Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

export default {
  name: 'ComponentsContent',
  props: {
    elem: Object,
    customObjects: Array,
    document: Object,
    lists: Object,
    headingParStyles: Object,
    headingTextStyles: Object,
    listsCounter: Object,
    content: Object,
    glossary: Array,
    glossaryUrls: Array,
    name: String,
    answerLink: String,
    lessonpk: String,
    id: String,
    userId: String,
    type: String,
    index: Number,
    index1: Number,
    getImage: Function,
    nameSectionMenu: String,
    login: Boolean
  },
  data: function () {
    return {
    }
  },
  created () {
    const vm = this
    this.$root.$on('MoveTo', (movetosection, ref, wait) => {
      if (vm.$refs[ref]) {
        vm.movingto(vm.nameSectionMenu, movetosection, ref, wait)
      }
    })
  },
  beforeDestroy () {
    this.$root.$off('MoveTo')
  },
  methods: {
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
    },
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    updateDocLoaded (customObject) {
      this.$emit('ready', customObject)
    }
  },
  components: {
    'yellow-box': YellowBox,
    Quiz,
    TextAreaQuestion,
    'quiz-select': QuizSelect,
    DragNDrop,
    Blackboard,
    // Glossary,
    Collapsable,
    ExternalDocument,
    'teaching-strategies': TeachingStrategies,
    ExternalWordDocument: () => import('@/components/customObjects/ExternalWordDoc'),
    DialogBox,
    DialogBoxLeft,
    ImageText,
    ImageTextLeft,
    WhiteImageText,
    WhiteImageTextLeft,
    ImageLightBlue,
    ImageWhite,
    'key-term': KeyTerm,
    'section-content': Section,
    'teaching-tips': TeachingTips,
    WhiteBox
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/assets/styles/_colors.scss';

  .btn-goToTop {
    width: 5rem;
    max-width: 5rem;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    opacity: 0;
    transform: translateY(100px);
    transition: all .5s ease;
    border-color: $fuchsia;
    background-color: $fuchsia;
    color: white;
    font-size: 14pt;
    &.showBtn {
      opacity: 1;
      transform: translateY(0)
    }
    &:hover {
      background-color: $pink;
    }
  }

  .page-break-style {
    page-break-after: always;
  }
</style>
