import { render, staticRenderFns } from "./RegularText.vue?vue&type=template&id=2870b676&scoped=true&"
import script from "./RegularText.vue?vue&type=script&lang=js&"
export * from "./RegularText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2870b676",
  null
  
)

export default component.exports