<template>
  <div class="teaching-tips-section">
    <b-link v-b-toggle="'tip-'+id" class="title">Teaching tip</b-link>
    <b-collapse v-model="visible" :id="`tip-`+id">
      <div class="content-tip">
        <b-container>
          <b-row class="mb-4">
            <b-col>
              <regular-text
                :document="document"
                :lists="lists"
                :headingParStyles="headingParStyles"
                :headingTextStyles="headingTextStyles"
                :customObjects="customObjects"
                :listsCounter="listsCounter"
                :l_esson="l_esson"
                :customObject="customObject"
                :glossary="glossary"
                :glossaryUrls="glossaryUrls"
                :lessonId="lessonId"
                :id="id"
                :name="name"
                :answerLink="answerLink"
                :userId="userId"
                :getImage="getImage"
                :nameSectionMenu="nameSectionMenu"
                @changeSection="changeSection"
                @movingto="movingto"
                @listCounterUpdate="listsIndex"
                />
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12">
              <!-- <p><a class="link-teaching-strategies" href="#">See teaching strategies</a></p> -->
              <b-button v-b-toggle="'tip-'+id" pill variant="outline-primary" class="btn-close-teaching-tip d-print-none">Close tip</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-collapse>
  </div>
</template>

<script>

export default {
  name: 'TeachingTips',
  props: {
    document: Object,
    lists: Object,
    headingParStyles: Object,
    headingTextStyles: Object,
    customObjects: Array,
    listsCounter: Object,
    l_esson: Object,
    glossary: Array,
    glossaryUrls: Array,
    lessonId: String,
    customObject: Number,
    name: String,
    answerLink: String,
    id: String,
    userId: String,
    getImage: Function,
    type: String,
    nameSectionMenu: String
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.visible = true
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
    }
  },
  components: {
    'regular-text': () => import('@/components/customObjects/RegularText')
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_colors.scss';

.teaching-tips-section {
  .content-tip {
    background-color: $glossary_blue_trans;
    padding: 1.5rem;
  }
  .link-teaching-strategies {
    color: $blue;
    text-decoration: underline;
  }
  .btn-close-teaching-tip {
    // padding: 0 10px;
    font-size: .875rem;
    padding: .25rem .75rem;
    line-height: 1.5;
    background-color: #fff;
    color: $light_blue;
    border-color: $glossary_blue;
    &:hover {
      color: #fff;
      background-color: $light_blue;
      border-color: $glossary_blue;
    }
    &:active {
      color: #fff;
      background-color: $glossary_blue;
      border-color: $glossary_blue;
    }
  }
}

@media (max-width: 767px) {
  .teaching-tips-section {
    .content-tip {
      padding: 0.5rem;
    }
  }
}
</style>

<style lang="scss">

</style>
