<template>
  <div class="myPage">
    <b-container class="my-4">
      <h1> My Account </h1>
      <p v-if="userData">{{userData.first_name}} {{userData.last_name}} </p>
      <div class="mb-4">
        <span class="h2 title"> My Schools </span>
          <span v-if="userData">
            <div v-for="school in userData.schools_as_teacher" :key="school.pk" class="my-3">
              {{school.name}} - Country: {{school.country}}
            </div>
            <div v-for="school in userData.schools_as_student" :key="school.pk" class="my-3">
              {{school.name}} - Country: {{school.country}}
            </div>
        </span>
      </div>
      <div class="mb-4">
        <span class="h2 title"> My Lessons </span>
        <div v-if="courses.length" class="my-3">
          <div v-for="(school,schoolIndex) in courses" :key="schoolIndex">
            <h4> {{school.name}} </h4>
            <div v-for="(course,courseIndex) in school.courses" :key="courseIndex">
              <h5 class="ml-2"> {{course.name}} </h5>
              <b-row class="ml-4 p-2" v-for="(lesson,lessonIndex) in course.lessons" :key="lessonIndex">
                 <b-col> <b-link :to="'courses/'+course.slug + '/lessons/' + lesson.slug + '/students'"> {{lesson.title}}</b-link> </b-col>
              </b-row>
            </div>
            </div>
        </div>
        <div class="my-3" v-else>
          You have no lessons!
        </div>
      </div>
      <div class="mb-4">
        <div v-if="coursesTeachers.length">
        <span class="h2 title my-2"> My Lessons as the teacher </span>
          <div class="my-3">
            <div v-for="(school,schoolIndex) in coursesTeachers" :key="schoolIndex">
            <h4> {{school.name}} </h4>
            <div v-for="(course,courseIndex) in school.courses" :key="courseIndex">
              <h5 class="ml-2"> {{course.name}} </h5>
              <b-row class="ml-4 p-2" v-for="(lesson,lessonIndex) in course.lessons" :key="lessonIndex">
                 <b-col> {{lesson.title}} </b-col>
                 <b-col> <b-link :to="'courses/'+course.slug + '/lessons/' + lesson.slug + '/students'"> Student lesson</b-link> </b-col>
                 <b-col> <b-link :to="'courses/'+course.slug + '/lessons/' + lesson.slug + '/blackboard'"> Blackboard lesson</b-link> </b-col>
                 <b-col> <b-link :to="'courses/'+course.slug + '/lessons/' + lesson.slug + '/projector'"> Projector lesson</b-link> </b-col>
                 <b-col> <b-link :to="'courses/'+course.slug + '/lessons/' + lesson.slug + '/teachers'"> Computer-based lesson</b-link> </b-col>
                 <!-- <b-col> <a @click="getAnswers(lesson.pk)"> Get answers </a></b-col> -->
                 <b-col> <a target="_blank" :href="'https://docs.google.com/spreadsheets/d/'+lesson.answerDocLink"> Read answers </a></b-col>
              </b-row>
            </div>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
import RefreshToken from '@/mixins/refreshToken'
export default {
  mixins: [RefreshToken],
  name: 'myPage',
  props: {},
  mounted: async function () {
    await this.getUserData()
  },
  data: function () {
    return {
      text: '',
      userData: {},
      courses: [],
      coursesTeachers: []
    }
  },
  methods: {
    sortCourseData (coursesList) {
      const allCourses = []
      for (let schoolIndex = 0; schoolIndex < coursesList.length; schoolIndex++) {
        for (let courseIndex = 0; courseIndex < coursesList[schoolIndex].courses.length; courseIndex++) {
          for (let lessonIndex = 0; lessonIndex < coursesList[schoolIndex].courses[courseIndex].lessons.length; lessonIndex++) {
            allCourses.push({
              lessonSlug: coursesList[schoolIndex].courses[courseIndex].lessons[lessonIndex].slug,
              courseSlug: coursesList[schoolIndex].courses[courseIndex].slug,
              school: coursesList[schoolIndex].name,
              course: coursesList[schoolIndex].courses[courseIndex].name,
              lesson: coursesList[schoolIndex].courses[courseIndex].lessons[lessonIndex].title
            })
          }
        }
      }
      return allCourses
    },
    getUserData () {
      if (localStorage.getItem('token')) {
        let config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
        axios.get(process.env.VUE_APP_BACK_URL + 'user/', config)
          .then((res) => {
            this.userData = res.data
            this.$store.dispatch('commitUserData', res.data)
          })
          .catch(async (err) => {
            console.log(err)
            if (err.response.status === 401) {
              await this.getNewAccessToken()
              config = {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
                }
              }
              axios.get(process.env.VUE_APP_BACK_URL + 'user/', config)
                .then((res) => { this.userData = res.data })
                .catch((err) => { this.expiredTokensActions(err) })
            } else {
              this.expiredTokensActions(err)
            }
          })
        axios.get(process.env.VUE_APP_BACK_URL + 'my-courses/', config)
          .then((res) => { this.courses = res.data })
          .catch(async (err) => {
            console.log(err)
            if (err.response.status === 401) {
              await this.getNewAccessToken()
              config = {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
                }
              }
              axios.get(process.env.VUE_APP_BACK_URL + 'my-courses/', config)
                .then((res) => { this.courses = res.data })
                .catch((err) => { this.expiredTokensActions(err) })
            } else {
              this.expiredTokensActions(err)
            }
          })
        axios.get(process.env.VUE_APP_BACK_URL + 'my-courses-teachers/', config)
          .then((res) => { this.coursesTeachers = res.data })
          .catch(async (err) => {
            console.log(err)
            if (err.response.status === 401) {
              await this.getNewAccessToken()
              config = {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem('token')
                }
              }
              axios.get(process.env.VUE_APP_BACK_URL + 'my-courses/', config)
                .then((res) => { this.coursesTeachers = res.data })
                .catch((err) => { this.expiredTokensActions(err) })
            } else {
              this.expiredTokensActions(err)
            }
          })
      } else {
        this.$router.push('/login')
      }
    },
    getAnswers (pk) {
      if (localStorage.getItem('token')) {
        const config = {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
          }
        }
        axios.get(process.env.VUE_APP_BACK_URL + 'lesson/' + pk + '/answers/', config)
          .then((res) => {
            console.log(res.data)
          })
      } else {
        this.$router.push('/login')
      }
    }
  },
  watch: {
    '$store.state.isLoggedIn': async function () {
      await this.getUserData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/_colors.scss';

.title {
  color: $fuchsia;
  font-weight: bold;
}
</style>
