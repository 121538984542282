
<template>
  <span>
    <template>
      <template v-if="content && content.type === 'HEADING_1'">
        <h1>
          <paragraphContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="nameSectionMenu"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </h1>
      </template>
      <template v-if="content && content.type === 'HEADING_2'">
        <h2>
          <paragraphContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="nameSectionMenu"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </h2>
      </template>
      <template v-if="content && content.type === 'HEADING_3'">
        <h3 :ref="'mi_'+(content.customObject || 0).toString() + '_'+index.toString()">
          <paragraphContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="nameSectionMenu"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </h3>
      </template>
      <template v-if="content && content.type === 'HEADING_4'">
        <h4>
          <paragraphContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="nameSectionMenu"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </h4>
      </template>
      <template v-if="content && content.type === 'HEADING_5'">
        <h5>
          <paragraphContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="nameSectionMenu"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </h5>
      </template>
      <template v-if="content && content.type === 'HEADING_6'">
        <h6>
          <paragraphContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="nameSectionMenu"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </h6>
      </template>
      <template v-if="content && content.type === 'NORMAL_TEXT'">
        <p class="txt-content-lesson" :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType,
          content.content[0].paragraphStyle,
          {}, true)">
          <paragraphContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="nameSectionMenu"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </p>
      </template>
      <template v-if="content && content.type === 'ONLYINTERNALLINK'" class="d-none">
        <span class="txt-content-lesson" :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType,
          content.content[0].paragraphStyle,
          {}, true)">
          <paragraphContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="nameSectionMenu"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </span>
      </template>
      <template v-if="content && content.type === 'BR'">
        <br/>
      </template>
      <template v-if="content && content.type === 'LIST'">
        <ul
          :class="{'firstLevel': content.nestingLevel === 0, 'secondLevel': content.nestingLevel === 1, 'thirdLevel': content.nestingLevel === 2}"
          :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType,
          content.content[0].paragraphStyle,
          {}, true, content.listId, content.nestingLevel)">
          <li>
          <bulletContent
            :content="content"
            :lists="lists"
            :listsCounter="listsCounter"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            @listCounterUpdate="listsIndex"
          />
          <paragraphContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="nameSectionMenu"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            @changeSection="changeSection"
            @movingto="movingto"
          />
          </li>
        </ul>
      </template>
      <template v-if="content && content.type === 'TABLE'">
        <b-table-simple responsive bordered>
          <b-tr v-for="(trItem, trIndex) in content.content.tableRows" :key="trIndex">
            <b-td v-for="(tdItem, tdIndex) in trItem.tableCells" :rowspan="tdItem.tableCellStyle.rowSpan" :colspan="tdItem.tableCellStyle.columnSpan" :style="tableCellDynamicStyle(tdItem.tableCellStyle)" :key="tdIndex">
              <span v-for="(cont, contIndex) in tdItem.content" :key="contIndex">
                <template>
                  <template v-if="cont && cont.customObject > customObject">
                    <template v-if="customObjects[cont.customObject].parentCustomObject === customObject">
                      <div v-for="(elem, index1) in cont.content" :key="index1">
                        <template v-if="elem && elem.type=='STARTCUSTOM'">
                          <template v-if="customObjects[cont.customObject].type=='external-word-document'">
                            <ExternalWordDocument
                              :docId="customObjects[cont.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
                              :name="name"
                              :id="id"
                              :lessonpk="lessonpk"
                              :userId="userId"
                              :type="type"
                              :customObject="content.customObject"
                              :nameSectionMenu="nameSectionMenu"
                              :glossary="glossary"
                              :glossaryUrls="glossaryUrls"
                              @listCounterUpdate="listsIndex"
                              @changeSection="changeSection"
                              @movingto="movingto"
                            />
                          </template>
                        </template>
                      </div>
                    </template>
                  </template>
                  <template v-else>
                    <headingsContent
                      :content="cont"
                      :getImage="getImage"
                      :nameSectionMenu="nameSectionMenu"
                      :name="name"
                      :id="id"
                      :lessonpk="lessonpk"
                      :userId="userId"
                      :type="type"
                      :customObjects="customObjects"
                      :customObject="customObject"
                      :lists="lists"
                      :listsCounter="listsCounter"
                      :headingParStyles="headingParStyles"
                      :headingTextStyles="headingTextStyles"
                      :glossary="glossary"
                      :glossaryUrls="glossaryUrls"
                      :index="contIndex"
                      @listCounterUpdate="listsIndex"
                      @changeSection="changeSection"
                      @movingto="movingto"
                    />
                  </template>
                </template>
              </span>
            </b-td>
          </b-tr>
        </b-table-simple>
      </template>
    </template>
  </span>
</template>

<script>
import Vue from 'vue'
import bulletContent from '@/components/lessonSubElements/bulletContent'
import paragraphContent from '@/components/lessonSubElements/paragraphContent'
import ExternalWordDocument from '@/components/customObjects/ExternalWordDoc'
import headingsStyle from '@/mixins/headingsStyle'

Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

export default {
  components: {
    bulletContent,
    headingsContent: () => import('@/components/lessonSubElements/headingsContent'),
    ExternalWordDocument,
    paragraphContent
  },
  name: 'headingsContent',
  props: {
    content: Object,
    getImage: Function,
    nameSectionMenu: String,
    customObjects: Array,
    customObject: Number,
    name: String,
    id: String,
    lessonpk: String,
    type: String,
    lists: Object,
    listsCounter: Object,
    glossary: Array,
    glossaryUrls: Array,
    headingParStyles: Object,
    headingTextStyles: Object,
    userId: String,
    index: Number
  },
  mixins: [headingsStyle],
  created: function () {
    const vm = this
    this.$root.$on('MoveTo', (movetosection, ref, wait) => {
      if (vm.$refs[ref]) {
        vm.scroll = true
        vm.movingto(vm.nameSectionMenu, movetosection, ref, wait)
      }
    })
    this.$root.$on('Scroll', (ref) => {
      if (vm.scroll) {
        vm.scrollMeTo(ref, vm)
        vm.scroll = false
      }
    })
  },
  beforeDestroy () {
    this.$root.$off('MoveTo')
    this.$root.$off('Scroll')
  },
  data: function () {
    return {
      scroll: false
    }
  },
  methods: {
    // 'https://docs.googleapis.com/v1/documents/1tJqsqAEyCYzNf7Z46IKOwUx1ZEd1t2xk'
    getMoveToLink (content) {
      const word = content.split(/-(.+)/)[1]
      return word
    },
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    capitalize (string) {
      return string && string[0].toUpperCase() + string.slice(1)
    },
    moveToLink (content) {
      const ref = content.split('-')[0]
      this.$root.$emit('MoveTo', this.nameSectionMenu, ref, true)
    },
    scrollMeTo (ref, help = this) { /* https://stackoverflow.com/questions/42645964/vue-js-anchor-to-div-within-the-same-component */
      if (help.$refs[ref]) {
        let element = help.$refs[ref]
        if (help.$refs[ref].length) {
          element = help.$refs[ref][0]
        }
        const top = element.offsetTop
        window.scrollTo(0, top)
      }
    },
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
    },
    tableCellDynamicStyle (tableCellStyle) {
      let paddingLeft = 0
      let paddingRight = 0
      let paddingTop = 0
      let paddingBottom = 0
      if (tableCellStyle.paddingLeft && tableCellStyle.paddingLeft.magnitude) {
        paddingLeft = tableCellStyle.paddingLeft.magnitude
      }
      if (tableCellStyle.paddingRight && tableCellStyle.paddingRight.magnitude) {
        paddingRight = tableCellStyle.paddingRight.magnitude
      }
      if (tableCellStyle.paddingTop && tableCellStyle.paddingTop.magnitude) {
        paddingTop = tableCellStyle.paddingTop.magnitude
      }
      if (tableCellStyle.paddingBottom && tableCellStyle.paddingBottom.magnitude) {
        paddingBottom = tableCellStyle.paddingBottom.magnitude
      }
      const borderTop = this.tableBorderOption(tableCellStyle, 'borderTop')
      const borderLeft = this.tableBorderOption(tableCellStyle, 'borderLeft')
      const borderBottom = this.tableBorderOption(tableCellStyle, 'borderBottom')
      const borderRight = this.tableBorderOption(tableCellStyle, 'borderRight')
      return {
        'padding-left': `${paddingLeft}pt`,
        'padding-right': `${paddingRight}pt`,
        'padding-bottom': `${paddingBottom}pt`,
        'padding-top': `${paddingTop}pt`,
        'border-top': borderTop,
        'border-left': borderLeft,
        'border-bottom': borderBottom,
        'border-right': borderRight
      }
    },
    tableBorderOption (tableCellStyle, borderkey) {
      // when the table have white borders, tableCellStyle[borderkey] will be undefined
      // when the table have black borders, the attributes of rgbColor (red, green and blue) will be empty
      if (tableCellStyle[borderkey]) {
        let color = ''
        let borderWidth = ''
        let style = ''
        if (tableCellStyle[borderkey].color && tableCellStyle[borderkey].color.color && tableCellStyle[borderkey].color.color.rgbColor) {
          let newRed = 0
          let newGreen = 0
          let newBlue = 0
          if (tableCellStyle[borderkey].color.color.rgbColor.red) {
            newRed = tableCellStyle[borderkey].color.color.rgbColor.red * 255
          }
          if (tableCellStyle[borderkey].color.color.rgbColor.green) {
            newGreen = tableCellStyle[borderkey].color.color.rgbColor.green * 255
          }
          if (tableCellStyle[borderkey].color.color.rgbColor.blue) {
            newBlue = tableCellStyle[borderkey].color.color.rgbColor.blue * 255
          }
          color = `rgb(${newRed}, ${newGreen}, ${newBlue})`
        } else {
          color = 'black'
        }
        if (tableCellStyle[borderkey].width && tableCellStyle[borderkey].width.magnitude) {
          borderWidth = tableCellStyle[borderkey].width.magnitude.toString() + 'pt '
        } else {
          borderWidth = '0pt '
        }
        if (tableCellStyle[borderkey].dashStyle) {
          const dashstyles = {
            SOLID: 'solid ',
            DOT: 'dotted ',
            DASH: 'dashed '
          }
          style = dashstyles[tableCellStyle[borderkey].dashStyle]
        } else {
          style = 'solid '
        }
        return borderWidth + style + color
      } else {
        return '1pt solid white'
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/assets/styles/_colors.scss';

  .btn-goToTop {
    width: 5rem;
    max-width: 5rem;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    opacity: 0;
    transform: translateY(100px);
    transition: all .5s ease;
    border-color: $fuchsia;
    background-color: $fuchsia;
    color: white;
    font-size: 14pt;
    &.showBtn {
      opacity: 1;
      transform: translateY(0)
    }
    &:hover {
      background-color: $pink;
    }
  }

  .page-break-style {
    page-break-after: always;
  }
</style>
