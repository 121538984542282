<template>
<div id="landingPage">
  <b-container>
    <b-row>
      <b-col
        cols="12" class="pt-3 pt-md-5 pb-3 pb-md-0">
        <h1 class="text-center">Be smart about your health</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p class="text-center subtitle">Think critically, make smart choices</p>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        class="text-center">
        <b-img src="/img/landingImage.svg" alt="image home" id="mainLandingImage"></b-img>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
export default {
  name: 'landingMainBanner',
  data () {
    return {}
  },
  components: {}
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";

#landingPage {
  background-color: $landingColor;
  h1 {
    font-size: 42pt;
    font-weight: bold;
    color: #fff;
  }
  .subtitle {
    font-size: 24pt;
    color: $yellow;
  }
}
</style>
