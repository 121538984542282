<template>
  <div>
    <myPage></myPage>
  </div>
</template>

<script>
import myPage from '@/components/myPage'

export default {
  data () {
    return {}
  },
  components: {
    myPage
  }
}
</script>
