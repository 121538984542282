<template>
  <div class="contentBlackBoard">
    <b-container class="px-4 py-3" v-if="l_esson.content.length">
      <div v-for="(content, index) in l_esson.content" :key="index" :class="{'d-none':  content && content.type === 'ONLYINTERNALLINK'}">
        <template v-if="content && content.customObject > customObject">
          <template v-if="customObjects[content.customObject].parentCustomObject === customObject">
            <div v-for="(elem, index1) in content.content" :key="index1">
              <template v-if="elem && elem.type=='STARTCUSTOM'">
                <template v-if="customObjects[content.customObject].type=='external-document'">
                  <ExternalDocument
                    :id="customObjects[content.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
                    :lesson="id"
                    :spreadname="customObjects[content.customObject].content[2].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
                  />
                </template>
                <template v-if="customObjects[content.customObject].type=='external-word-document'">
                  <ExternalWordDocument
                    :docId="customObjects[content.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
                    :name="name"
                    :id="id"
                    :lessonpk="lessonpk"
                    :userId="userId"
                    :type="type"
                    :nameSectionMenu="l_esson.nameSectionMenu"
                    :customObject="content.customObject"
                    :glossary="glossary"
                    :glossaryUrls="glossaryUrls"
                    @listCounterUpdate="listsIndex"
                    @changeSection="changeSection"
                    @movingto="movingto"
                    @ready="updateDocLoaded"
                  />
                </template>
                <template v-if="customObjects[content.customObject].type=='teaching-strategies'">
                  <TeachingStrategies
                    :id="customObjects[content.customObject].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
                    :lesson="id"
                    :spreadname="customObjects[content.customObject].content[2].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')"
                  />
                </template>
              </template>
            </div>
          </template>
        </template>
        <template v-else>
          <headingsContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="l_esson.nameSectionMenu"
            :name="name"
            :id="id"
            :lessonpk="lessonpk"
            :userId="userId"
            :type="type"
            :customObjects="customObjects"
            :customObject="customObject"
            :lists="lists"
            :listsCounter="listsCounter"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            :index="index"
            @listCounterUpdate="listsIndex"
            @changeSection="changeSection"
            @movingto="movingto"
          />
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import ExternalWordDocument from '@/components/customObjects/ExternalWordDoc'

export default {
  name: 'Black_Board',
  props: {
    document: Object,
    lists: Object,
    headingParStyles: Object,
    headingTextStyles: Object,
    customObjects: Array,
    listsCounter: Object,
    l_esson: Object,
    customObject: Number,
    glossary: Array,
    glossaryUrls: Array,
    id: String,
    lessonpk: String,
    name: String,
    userId: String,
    type: String,
    getImage: Function
  },
  data: function () {
    return {
    }
  },
  methods: {
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
    },
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    updateDocLoaded (customObject) {
      this.$emit('ready', customObject)
    }
  },
  components: {
    ExternalWordDocument,
    headingsContent: () => import('@/components/lessonSubElements/headingsContent')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap');

.contentBlackBoard {
  margin: 1.5rem 0rem;
  background-color: #4a7667;
  border-radius: 10px;
  border: 6px solid #aaaaaa ;
  color: white;
  @media print {
    border: none;
  }
  & p, p > span {
    font-family: 'Comic Neue' !important;
    font-size: 24pt !important;
    font-weight: bolder !important;
    padding-left: 0px !important;
  }

  & table {
    border-collapse: initial;
    th, td {
      border-color: white;
    }
    & span {
      color: white !important;
      font-family: 'Comic Neue' !important;
      font-size: 24pt !important;
      font-weight: bolder !important;
      padding-left: 0px !important;
    }
  }
}

  .contentLink {
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;
  }

</style>

<style>
  .ctooltip {
    cursor: pointer;
    position: relative;
    display: inline-block;
    visibility: visible;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  .ctooltip .tooltiptext {
    visibility: hidden;
    max-width: 200px;
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: .25rem .5rem;
    border-radius: .25rem;
    position: absolute;
    z-index: 9999;
    bottom: 110%;
    left: 50%;
    transform:translate(-50%,0%);
    opacity: 0;
    transition: 0.3s opacity;
    font-size: 0.875rem;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .ctooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: calc(50% - 10px);;
    margin: 0 .25rem;
    border-width: .4rem;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }

  .ctooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
</style>
