<template>
  <div>
    <lesson :coursesslug="$route.params.coursesslug" :lessonsslug="$route.params.lessonsslug" type="projector"></lesson>
  </div>
</template>

<script>
import Lesson from '@/components/Lesson'

export default {
  data () {
    return {}
  },
  components: {
    lesson: Lesson
  }
}
</script>
