<template>
  <div class="initial p-3 image-light-blue">
    <b-row no-gutters>
      <b-col>
        <span class="text" v-for="(content, index) in text.content" :key="index">
          <span class="text" v-for="(content1, index1) in content.content" :key="index1">
            <div class="text" v-if="content1.type == 'IMAGE' ">
              <async-image v-if="content1.content.inlineObjectId" :imageId="content1.content.inlineObjectId" :getImage="getImage" :fullImage="true"></async-image>
            </div>
            <div v-if="content1.type === 'IMAGELINK'">
              <a :href="content1.link" target="_blank">
                <async-image v-if="content1.content.inlineObjectId" :imageId="content1.content.inlineObjectId" :getImage="getImage" :fullImage="true"></async-image>
              </a>
            </div>
          </span>
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AsyncImage from '@/components/customObjects/AsyncImage'

export default {
  name: 'ImageLightBlue',
  props: ['text', 'document', 'getImage'],
  data () {
    return {
      ignore: false
    }
  },
  mounted: function () {
  },
  methods: {
  },
  computed: {
  },
  components: {
    'async-image': AsyncImage
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_colors.scss';

  .initial {
    background: #DDEDFF;
    position: relative;
  }

  .img {
    display: block;
    width:100%;
    border-radius: .25rem;
  }

  .image-light-blue {
    border: 1px solid $gray;
    border-radius: 5px;
  }
</style>
