
<template>
<span>
  <template v-if="!['STARTCUSTOM', 'ENDCUSTOM'].includes(content.content[0].type)">
  <span
    v-for="(elem, index) in content.content"
    :style="applyHeadingStyle(elem.paragraphStyle.namedStyleType, {}, elem.textStyle, false)"
    :key="index"
    :class="{'fa-span':(elem.type==='fontawesome' && elem.content==='pen'), 'd-print-none':['sectionchanger','sectionchangerback','sectionchangerforward','print'].includes(elem.type)}">
    <template v-if="elem.type === 'IMAGELINK'">
      <a :href="elem.link" target="_blank">
        <async-image v-if="elem.content.inlineObjectId" :imageId="elem.content.inlineObjectId" :getImage="getImage"></async-image>
      </a>
    </template>
    <template v-else-if="elem.type === 'IMAGE'">
      <async-image v-if="elem.content.inlineObjectId" :imageId="elem.content.inlineObjectId" :getImage="getImage"></async-image>
    </template>
    <template v-else-if="elem.type === 'BR'">
      <br/>
    </template>
    <template v-else-if="elem.type === 'PAGE_BREAK'">
      <p style="page-break-after: always;">&nbsp;</p>
    </template>
    <template v-else-if="elem.type === 'HORIZONTAL_LINE'">
      <hr>
    </template>
    <template v-else-if="elem.isLink">
      <a :href="elem.textStyle.link.url" target="_blank" class="new-tab-link">{{elem.content}} <font-awesome-icon :icon="['fas', 'external-link-alt']"></font-awesome-icon></a>
    </template>
    <template v-else-if="elem.type === 'glossary'">
      <span class="newGlossary" :class="{'on': !elem.hide}" @click="elem.hide = !elem.hide">{{elem.content.split(/-(.+)/)[1].trim()}}</span>
      <div :class="{ 'd-none' : elem.hide }" class="glossaryDefinition d-print-block">
        <b-container class="p-4">
          <b-row class="mb-4">
            <b-col>
              <p class="glossaryDefinition_txt">{{getGlossaryDefinition(elem.content)}}</p>
            </b-col>
          </b-row>
          <b-row class="d-print-none">
            <b-col cols="12" class="newGlossary_link">
              <router-link :to="{ name: 'glossary', hash: '#' + getHash(elem.content.split(/-(.+)/)[1].toLowerCase().trim())}" target="_blank">See glossary for synonyms and more</router-link>
              <br>
              <b-button pill variant="outline-primary" size="sm" class="d-print-none" @click="elem.hide = !elem.hide">Close definition</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </template>
    <template v-else-if="elem.type === 'fontawesome'">
      <template v-if="['clock', 'smile', 'frown'].includes(elem.content)">
        <font-awesome-icon :icon="['far', elem.content]"/>
      </template>
      <template v-else>
        <template v-if="elem.content === 'pen'">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="pencil" d="M18.218.219a.75.75,0,0,1,1.062,0l4.5,4.5a.75.75,0,0,1,0,1.062l-15,15a.75.75,0,0,1-.252.165l-7.5,3a.75.75,0,0,1-.975-.975l3-7.5a.75.75,0,0,1,.165-.252l15-15ZM16.81,3.75,20.249,7.19,22.188,5.25,18.749,1.811Zm2.379,4.5L15.749,4.811,6,14.56V15h.75a.75.75,0,0,1,.75.75v.75h.75a.75.75,0,0,1,.75.75V18h.439ZM4.548,16.012l-.159.159L2.1,21.9l5.731-2.292.159-.159a.75.75,0,0,1-.487-.7V18H6.75A.75.75,0,0,1,6,17.25V16.5H5.25a.75.75,0,0,1-.7-.487Z" transform="translate(0 0.001)" fill="#ab005c"/>
          </svg>
        </template>
        <template v-else>
          <font-awesome-icon :icon="elem.content"/>
        </template>
      </template>
    </template>
    <template v-else-if="elem.type === 'sectionchanger'">
      <a class="d-print-none see-also btn" @click="changeSection(elem.content)">
        Go to {{capitalize(elem.content)}} <font-awesome-icon class="ml-2" :icon="['fas', 'arrow-right']"/>
      </a>
    </template>
    <template v-else-if="elem.type === 'sectionchangerback'">
      <a class="d-print-none see-also back" @click="changeSection(elem.content)">
        <font-awesome-icon class="ml-2" :icon="['fas', 'arrow-left']"/> Go to {{capitalize(elem.content)}}
      </a>
    </template>
    <template v-else-if="elem.type === 'sectionchangerforward'">
      <a class="d-print-none see-also forward" :class="{'float-right': index == 1}" @click="changeSection(elem.content)">
        Go to {{capitalize(elem.content)}} <font-awesome-icon class="ml-2" :icon="['fas', 'arrow-right']"/>
      </a>
    </template>
    <template v-else-if="elem.type === 'print'">
      <a class="d-print-none print-lesson" @click="printLesson">
        <svg id="printer" xmlns="http://www.w3.org/2000/svg" width="33" height="30" viewBox="0 0 22.216 19.439">
          <path id="Trazado_14" data-name="Trazado 14" d="M2.694,8.388A.694.694,0,1,0,2,7.694.694.694,0,0,0,2.694,8.388Z" transform="translate(0.777 1.331)" fill="#014aa0"/>
          <path id="Trazado_15" data-name="Trazado 15" d="M6.942,1A2.777,2.777,0,0,0,4.165,3.777V6.554H2.777A2.777,2.777,0,0,0,0,9.331V13.5a2.777,2.777,0,0,0,2.777,2.777H4.165v1.388a2.777,2.777,0,0,0,2.777,2.777h8.331a2.777,2.777,0,0,0,2.777-2.777V16.273h1.388A2.777,2.777,0,0,0,22.216,13.5V9.331a2.777,2.777,0,0,0-2.777-2.777H18.05V3.777A2.777,2.777,0,0,0,15.273,1ZM5.554,3.777A1.388,1.388,0,0,1,6.942,2.388h8.331a1.388,1.388,0,0,1,1.388,1.388V6.554H5.554Zm1.388,6.942A2.777,2.777,0,0,0,4.165,13.5v1.388H2.777A1.388,1.388,0,0,1,1.388,13.5V9.331A1.388,1.388,0,0,1,2.777,7.942H19.439a1.388,1.388,0,0,1,1.388,1.388V13.5a1.388,1.388,0,0,1-1.388,1.388H18.05V13.5a2.777,2.777,0,0,0-2.777-2.777ZM16.662,13.5v4.165a1.389,1.389,0,0,1-1.388,1.389H6.942a1.389,1.389,0,0,1-1.388-1.389V13.5a1.388,1.388,0,0,1,1.388-1.388h8.331A1.388,1.388,0,0,1,16.662,13.5Z" transform="translate(0 -1)" fill="#014aa0"/>
        </svg> Print
      </a>
    </template>
    <template v-else-if="elem.type === 'gototop'">
      <a name="top" class="go-to-top d-print-none" @click="scrollToTop">
        <font-awesome-icon icon="arrow-up"></font-awesome-icon>
        Go to top
      </a>
    </template>
    <template v-else-if="elem.type === 'internallink'">
      <span :ref="elem.content"></span>
    </template>
    <template v-else-if="elem.type === 'moveto'">
      <a name="top" :ref="elem.content.split('-')[0] + '-moveto'" class="internal-link d-print-none" @click="moveToLink(elem.content)">
        {{getMoveToLink(elem.content)}}
      </a>
    </template>
    <template v-else-if="elem.type === 'pathnewwindow'">
      <a name="top" class="new-tab-link" @click="newWindow(getPath(elem.content))">{{getMoveToPath(elem.content)}} <font-awesome-icon :icon="['fas', 'external-link-alt']"></font-awesome-icon></a>
    </template>
    <template v-else-if="elem.type === 'pathsamewindow'">
      <a name="top" class="internal-link" @click="sameWindow(getPath(elem.content))">{{getMoveToPath(elem.content)}}</a>
    </template>
    <template v-else>{{elem.content}}</template>
  </span>
  </template>
</span>
</template>

<script>
import Vue from 'vue'
import replaceSpecialChars from 'replace-special-characters'
import headingsStyle from '@/mixins/headingsStyle'
import AsyncImage from '@/components/customObjects/AsyncImage'

Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

export default {
  name: 'ParagraphContent',
  props: {
    content: Object,
    getImage: Function,
    nameSectionMenu: String,
    glossary: Array,
    headingParStyles: Object,
    headingTextStyles: Object,
    glossaryUrls: Array
  },
  mixins: [headingsStyle],
  created: function () {
    const vm = this
    this.$root.$on('MoveTo', (movetosection, ref, wait) => {
      if (vm.$refs[ref]) {
        vm.scroll = true
        vm.movingto(vm.nameSectionMenu, movetosection, ref, wait)
      }
    })
    this.$root.$on('Scroll', (ref) => {
      if (vm.scroll) {
        vm.scrollMeTo(ref, vm)
        vm.scroll = false
      }
    })
  },
  beforeDestroy () {
    this.$root.$off('MoveTo')
    this.$root.$off('Scroll')
  },
  data: function () {
    return {
      scroll: false
    }
  },
  methods: {
    getHash: function (txt = '') {
      if (txt.length < 1) {
        return ''
      }
      return replaceSpecialChars(txt.replace(/\s+/g, '-'))
    },
    printLesson: function () {
      window.print()
    },
    // 'https://docs.googleapis.com/v1/documents/1tJqsqAEyCYzNf7Z46IKOwUx1ZEd1t2xk'
    getGlossaryDefinition (content) {
      const definitiontype = content.split('-')[0]
      const word = content.split(/-(.+)/)[1].toLowerCase().trim()
      const getDefinition = definition => {
        const definitions = {
          short: 1,
          synonym: 2,
          synonyms: 2,
          explanation: 4,
          long: 4,
          example: 5,
          examples: 5,
          default: 4
        }
        return definitions[definition] || definitions.default
      }
      for (let i = 0; i < this.glossary.length; i++) {
        if (this.glossary[i].word.toLowerCase() === word.toLowerCase()) {
          return this.glossary[i].meaning[getDefinition(definitiontype)]
        }
      }
      return ''
    },
    getMoveToLink (content) {
      const word = content.split(/-(.+)/)[1]
      return word
    },
    getMoveToPath (content) {
      const word = content.split(/--(.+)/)[1]
      return word
    },
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    capitalize (string) {
      return string && string[0].toUpperCase() + string.slice(1)
    },
    async moveToLink (content) {
      const ref = content.split('-')[0]
      if (this.$route.query.internallink !== ref + '-moveto') {
        await this.$router.push({ params: { coursesslug: this.$route.params.coursesslug, lessonsslug: this.$route.params.lessonsslug }, query: { section: this.nameSectionMenu, internallink: ref + '-moveto' } })
      }
      if (this.$route.query.internallink !== ref) {
        await this.$router.push({ params: { coursesslug: this.$route.params.coursesslug, lessonsslug: this.$route.params.lessonsslug }, query: { section: this.nameSectionMenu, internallink: ref } })
      }
    },
    getPath (content) {
      return content.split('--')[0]
    },
    scrollMeTo (ref, help = this) { /* https://stackoverflow.com/questions/42645964/vue-js-anchor-to-div-within-the-same-component */
      if (help.$refs[ref] && help.$refs[ref].length) {
        const element = help.$refs[ref][0]
        const top = element.offsetTop
        window.scrollTo(0, top)
      }
    },
    sameWindow (url) {
      window.open(url, '_self')
    },
    newWindow (url) {
      window.open(url, '_blank')
    }
  },
  components: {
    'async-image': AsyncImage
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/assets/styles/_colors.scss';

  .btn-goToTop {
    width: 5rem;
    max-width: 5rem;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    opacity: 0;
    transform: translateY(100px);
    transition: all .5s ease;
    border-color: $fuchsia;
    background-color: $fuchsia;
    color: white;
    font-size: 14pt;
    &.showBtn {
      opacity: 1;
      transform: translateY(0)
    }
    &:hover {
      background-color: $pink;
    }
  }

  .btn-outline-primary:not(:disabled):not(.disabled) {
    // padding: 0 10px;
    font-size: .875rem;
    padding: .25rem .75rem;
    line-height: 1.5;
    background-color: #fff;
    color: $light_blue;
    border-color: $glossary_blue;
    &:hover {
      color: #fff;
      background-color: $light_blue;
      border-color: $glossary_blue;
    }
    &:active {
      color: #fff;
      background-color: $glossary_blue;
      border-color: $glossary_blue;
    }
  }

  .page-break-style {
    page-break-after: always;
  }

  .new-tab-link {
    svg {
      width: 1rem;
      height: 100%;
      margin-bottom: 0.2rem;
    }
  }

</style>
