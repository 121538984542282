<template>
  <div class="sticky-top d-print-none">
    <div class="lesson-menu-content">
      <div id="submenu-lesson" class="submenu-lesson">
        <b-container>
          <template v-if="showFloatMenu">
            <div class="float-right menuFloating pr-md-5">
              <b-navbar-toggle target="sidebarMenu" class="px-1">
                <template>
                  MENU <font-awesome-icon icon="bars"></font-awesome-icon>
                </template>
              </b-navbar-toggle>
            </div>
          </template>
          <ul class="list-inline" v-if="lesson.menu.length">
            <li v-for="(item, index) in lesson.menu" :key="index" :class="(index === 0)?'active':''" class="py-sm-2 mt-1 pb-1 pl-sm-2 px-1 pr-sm-2 list-inline-item submenu-item" :data-title="getTrimedName(item.content)" @click="newQueryRoute(item.content)">{{item.content}}</li>
          </ul>
        </b-container>
      </div>
    </div>
    <template v-if="showFloatMenu">
    <div class="menuShadow menuShadow--top"></div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'lessonNavigation',
  props: {
    lesson: Object,
    type: String,
    showFloatMenu: Boolean
  },
  mounted () {
    this.$nextTick(function () {
      this.$emit('initialchange')
    })
  },
  data: function () {
    return {
      visible: false
    }
  },
  methods: {
    newQueryRoute: function (content) {
      const selectedItem = this.getTrimedName(content)
      if (selectedItem !== this.$route.query.section) {
        this.$router.push({ params: { coursesslug: this.$route.params.coursesslug, lessonsslug: this.$route.params.lessonsslug }, query: { section: selectedItem } })
      }
    },
    changeContent: function (content) {
      let selectedItem = this.getTrimedName(content)
      const divContent = document.getElementsByClassName('contentSection')
      divContent.forEach(element => {
        if (element.classList.contains('on')) {
          element.classList.remove('on')
        }
      })
      const subMenuItems = document.getElementsByClassName('submenu-item')
      let missingSection = true
      subMenuItems.forEach(element => {
        if (Object.prototype.hasOwnProperty.call(element.dataset, 'title')) {
          if (element.classList.contains('active')) {
            element.classList.remove('active')
          }
          if (element.dataset.title === selectedItem) {
            element.classList.add('active')
            missingSection = false
          }
        }
      })
      if (missingSection) {
        subMenuItems.forEach((element, index) => {
          if (Object.prototype.hasOwnProperty.call(element.dataset, 'title')) {
            if (element.classList.contains('active')) {
              element.classList.remove('active')
            }
            if (index === 0) {
              element.classList.add('active')
              missingSection = false
              selectedItem = element.dataset.title
            }
          }
        })
      }
      const el = document.getElementById(selectedItem)
      el.classList.add('on')
    },
    getTrimedName: function (txt) {
      if (txt.length < 1) {
        return ''
      }
      return txt.toLowerCase().trim().replace(/ /g, '-')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_colors.scss';

.menuShadow {
  position: absolute;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 10px;
  background: red;
}

.menuShadow--top {
  background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 0.35), transparent);
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.35), transparent);
  opacity: 1;
}

.menuFloating {
  button {
    padding: 0.8rem 1rem;
    background-color: $yellow;
    border: 1px solid $yellow;
    border-radius: 0 0 5px 5px;
  }
}

.lesson-menu-content {
  background-color: $fuchsia;
  color: #fff;
  & ul {
    margin-bottom: 0rem;
  }
  & p, li {
    font-weight: 700;
    font-size: 1.83rem;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      background-color: #fff;
      color: $fuchsia;
      border-radius: 5px 5px 0 0;
    }
    &.active, .active:hover {
      color: $gray;
      text-decoration: none;
      background-color: #fff;
      border-radius: 5px 5px 0 0;
    }
  }
}

@media (max-width: 426px) {
  .menuFloating {
    button {
      padding: 0.3rem 0;
      color: $black;
      font-size: 12pt;
    }
  }
  .lesson-menu-content {
    & li {
      font-size: 13pt !important;
    }
  }
}
</style>
