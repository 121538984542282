<template>
  <div class="container mt-5">
    <b-row>
      <b-col cols="6">
        <template v-if="position>0">
          <b-link class="nav-sp-link" :to="{name: 'otherContent', params: {documentid: pages[position-1]}}">
            <font-awesome-icon icon="arrow-left" /> Previous
          </b-link>
        </template>
      </b-col>
      <b-col cols="6" class="text-right">
        <template v-if="position<(pages.length-1)">
          <b-link class="nav-sp-link" :to="{name: 'otherContent', params: {documentid: pages[position+1]}}">
            Next <font-awesome-icon icon="arrow-right" />
          </b-link>
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'NavigationStaticPage',
  props: {
    position: Number,
    pages: Array
  },
  data () {
    return {

    }
  }
}
</script>

<style type="text/css" scoped>
  .nav-sp-link {
    font-size: 1.8333rem;
    cursor: pointer;
    color: #222222 !important;
    text-decoration: underline;
    font-weight: bold;
  }
  @media (max-width: 767px) {
   .nav-sp-link {
      font-size: 1.4167rem;
    }
  }
</style>
