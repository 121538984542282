import axios from 'axios'
export default {
  methods: {
    getNewAccessToken () {
      axios.post(process.env.VUE_APP_BACK_URL + 'token/refresh/', {
        refresh: localStorage.getItem('refreshtoken')
      }).then(
        (refreshRes) => {
          localStorage.setItem('token', refreshRes.data.access)
        }
      )
        .catch((err) => {
          console.log(err)
          localStorage.removeItem('refreshtoken')
          localStorage.removeItem('token')
          this.$router.push('/login')
        })
    },
    expiredTokensActions (err) {
      console.log(err)
      localStorage.removeItem('refreshtoken')
      localStorage.removeItem('token')
      this.$router.push('/login')
    }
  }
}
