<template>
  <div class="container exdoc my-4">
    <p>{{this.content}}</p>
  </div>
</template>

<script>
import createJWT from '@/mixins/createJWT'
import axios from 'axios'
export default {
  mixins: [createJWT],
  name: 'ExternalDocument',
  props: {
    id: {
      type: String,
      default: process.env.VUE_APP_GLOSSARY_URL
    },
    course: {
      type: String,
      default: '1'
    },
    lesson: {
      type: String,
      default: '1'
    },
    spreadname: {
      type: String,
      default: 'NULL'
    }
  },
  created: async function () {
    await this.createJwt()
    this.initialParseExDoc()
  },
  data: function () {
    return {
      content: '',
      answerSheetIndex: -1
    }
  },
  methods: {
    initialParseExDoc () {
      this.makeGlossary(this.id)
    },
    async makeGlossary (glossary) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
      }
      if (!window.navigator.onLine) {
        config.timeout = 2
      }
      await axios.get('https://sheets.googleapis.com/v4/spreadsheets/' + glossary + '?includeGridData=true', config)
        .then(async response => {
          const document = response.data
          await this.$store.commit('addExternalDocuments', ['https://sheets.googleapis.com/v4/spreadsheets/' + glossary, response.data])
          this.processExternalDoc(document)
        }).catch(async error => {
          console.log(error)
          const document = await this.$store.state.externalDocuments['https://sheets.googleapis.com/v4/spreadsheets/' + glossary]
          if (document) {
            this.processExternalDoc(document)
          }
        })
    },
    processExternalDoc (document) {
      for (let k = 0; k < document.sheets.length; k++) {
        if (document.sheets[k].properties.title === this.spreadname) {
          this.answerSheetIndex = k
          break
        }
      }
      for (let k = 0; k < document.sheets[this.answerSheetIndex].data[0].rowData.length; k++) {
        if (document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === 'Course' && document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === 'Lesson') {
          continue
        }
        if ((document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === 'All' || document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === this.course) &&
          (document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === 'All' || document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === this.lesson)) {
          this.content = document.sheets[this.answerSheetIndex].data[0].rowData[k].values[2].formattedValue
          break
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/styles/_colors.scss';

</style>
