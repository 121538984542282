<template>
  <div id="app">
    <b-toast v-model="updateExists" id="example-toast" title="Update available" toaster="b-toaster-bottom-right" no-auto-hide>
      <template>
        <div class="toast-content">
          An update is available!
          <div class="button-alignment">
            <b-button variant="primary" @click="refreshApp">Update</b-button>
          </div>
        </div>
      </template>
    </b-toast>
    <main-header :showFloatMenu="showFloatMenu"></main-header>
    <div class="flex-content">
      <Intersect @enter="showFloatMenu = false" @leave="showFloatMenu = true">
        <div></div>
      </Intersect>
      <router-view :key="$route.path"/>
    </div>
    <div class="flex-footer">
      <main-footer></main-footer>
    </div>
  </div>
</template>

<script>
import update from '@/mixins/update'
import Header from '@/components/landing/mainHeader'
import Footer from '@/components/landing/mainFooter'
import Intersect from 'vue-intersect'

export default {
  components: {
    'main-header': Header,
    'main-footer': Footer,
    Intersect
  },
  data () {
    return {
      showFloatMenu: false
    }
  },
  mixins: [update]
}
</script>

<style lang="scss">
  @import "./assets/styles/main.scss";
  .toast-content {
    text-align: center;
  }
  .button-alignment {
    margin-top: 5px;
  }
  #app {
    display: flex;
    flex-flow: column;
    height: auto;
    .flex-content {
      flex: 1 1 auto;
    }
    .flex-footer {
      flex: 0 1 110px;
      right: 0;
      bottom: 0px;
      left: 0;
    }
  }
</style>
