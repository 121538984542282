<template>
  <div class="container">
    <h2 class="mt-5">Examples of health actions</h2>
    <health-action-table></health-action-table>
  </div>
</template>

<script>
import HealthActionTable from '@/components/HealthActionTable.vue'
export default {
  name: 'HealthAction',
  data () {
    return {}
  },
  components: {
    HealthActionTable
  }
}
</script>

<style>

</style>
