<template>
  <div>
    <div id="fluidMainLessonInfo">
      <div class="lesson-menu-header p-4 container">
        <h2>{{lesson.title[0]}}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SecondaryMenu',
  props: {
    lesson: Object
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_colors.scss';

#fluidMainLessonInfo {
  background-color: $rose_submenu_head;
  & .lesson-menu-header {
    h2 {
      font-weight: bold;
      font-size: 28pt;
      color: $white;
    }
  }
}

@media (max-width: 767px) {
  #fluidMainLessonInfo {
    & .lesson-menu-header {
      h2 {
        font-size: 24pt;
      }
    }
  }
}
</style>
