<template>
  <div>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
   viewBox="0 0 1125 214.2" style="enable-background:new 0 0 1125 214.2;" xml:space="preserve">

<g>
  <g>
    <path class="st0" d="M179.3,150.5c9.9-15.3,15.7-33.4,15.7-53c0-17.2-4.5-33.4-12.3-47.5c-1.2-13.8-9.9-25.4-24-31.2
      c-2.2-0.9-4.4-1.6-6.6-2.1c-2.2-1.5-4.6-2.9-6.9-4.3c0,0,0,0,0,0c-1.1-0.6-2.2-1.2-3.3-1.8c-0.1-0.1-3.9-1.9-3.9-1.9
      c-5.9-2.7-12.2-4.8-18.7-6.3c0,0-2.5-0.5-3.7-0.8c-0.2,0-2.9-0.5-4-0.7c-0.2,0-3.9-0.5-5.5-0.6c-0.9-0.1-3.8-0.3-4.3-0.3
      C100.4,0,99.2,0,98.1,0c-0.2,0-0.9,0-1.1,0c-1.5,0-4.5,0.1-4.6,0.1C71.1,1.2,49.9,9.2,32.7,24.5C20.9,35,12.2,47.8,6.8,61.6
      C2.4,72.7,0,84.8,0,97.5C0,151.3,43.6,195,97.5,195c7.1,0,14-0.8,20.7-2.2c1.1,4.7,4.5,8.9,9.5,11.3l12.3,6c4,2,8.3,3.2,12.6,3.8
      c1.7,0.2,3.3,0.3,5,0.3c3,0,6.1-0.4,9-1.1c9.2-2.2,16.7-7.9,20.7-15.5c3.7-7.1,4.2-15.3,1.2-23.1L179.3,150.5z"/>
    <path class="st1" d="M156.4,145.4c-0.5-1.3-0.4-2.8,0.3-3.9c0.7-1.2,1.9-2,3.5-2.3c2.8-0.6,6,1.3,7.1,4l12.6,33.2
      c4.7,12.3-2.6,25-16.2,28.4c-6.6,1.6-13.6,0.8-19.9-2.3l-12.3-6c-3.7-1.8-5.6-5.5-4.5-8.9l10.3-25.8l-32-15.7
      c-1.1-0.6-1.9-1.5-2.3-2.5c-0.3-1-0.2-2,0.4-2.9c1.1-1.6,3.7-2,5.8-0.9l55.3,27.2L156.4,145.4z"/>
    <path class="st1" d="M90.7,70.5c-0.8-1.3-2.1-2.2-3.7-2.5c-1.5-0.3-3.1,0-4.4,0.8c-1.3,0.8-2.2,2.1-2.5,3.7
      c-0.7,3.1,1.3,6.2,4.5,6.9c0.4,0.1,0.8,0.1,1.2,0.1c2.7,0,5.1-1.9,5.7-4.6C91.8,73.3,91.6,71.8,90.7,70.5z"/>
    <path class="st1" d="M151,40.2c-1.5-0.3-3.1,0-4.4,0.8c-1.3,0.8-2.2,2.1-2.5,3.7c-0.3,1.5,0,3.1,0.8,4.4c0.8,1.3,2.1,2.2,3.7,2.5
      c0.4,0.1,0.8,0.1,1.2,0.1c2.7,0,5.1-1.9,5.7-4.6C156.2,44,154.2,40.9,151,40.2z"/>
    <path class="st2" d="M119.8,126.3c1.2-0.3,5.3,0,8,0.3c3,0.3,5.8,0.5,8.5,0.5c4.2,0,7.8-0.6,10.1-2.8c1.6-1.5,1.6-4,0.1-5.5
      c-1.5-1.6-3.9-1.6-5.5-0.1c-1.5,1.1-9,0.5-12.6,0.2c-6.3-0.5-10-0.8-12.5,0.7c-1.8,1.1-2.4,3.5-1.3,5.4
      C115.7,126.6,118,127.3,119.8,126.3z"/>
    <path class="st2" d="M62.9,77.4c-2.2,10.2,4.4,20.2,14.6,22.4c10.2,2.2,20.2-4.4,22.4-14.6C102,75,95.5,65,85.3,62.8
      C75.1,60.7,65,67.2,62.9,77.4z"/>
    <path class="st2" d="M141,72.3c10.2,2.2,20.2-4.4,22.4-14.6c2.2-10.2-4.4-20.2-14.6-22.4c-10.2-2.2-20.2,4.4-22.4,14.6
      C124.2,60.1,130.8,70.1,141,72.3z"/>
    <path class="st2" d="M52.2,71.5c0.3,0.1,0.6,0.1,0.9,0.1c1.7,0,3.2-1.1,3.6-2.8c2.1-8,7.5-12.4,16.2-13.2c2-0.2,3.6-2,3.4-4
      c-0.2-2-2-3.6-4-3.4c-11.8,1-19.9,7.6-22.7,18.7C49,69,50.2,71,52.2,71.5z"/>
    <path class="st1" d="M125.6,166.9l0.4-1.1l-24.8-12.2c-3.3-1.6-5.9-4.4-7-7.7c-1.1-3.3-0.6-6.7,1.4-9.5c3.5-5,11.2-6.5,17.5-3.4
      l35.5,17.4l-0.9-2.3c-1.3-3.5-1-7.4,0.9-10.5c1.9-3.2,5.1-5.3,9.1-6.1c5-1,10.2,0.7,13.9,3.9c12-23.5,12.3-51.8,0.3-75.7
      c2.1-10-1.2-20.8-9.6-27.9c-6.6-5.6-14.9-7.7-22.8-6.5c0,0-0.1-0.1-0.1-0.1c-7.1,0.4-14.2,3.2-19.1,9.4c-1.7,2.1-4.7,2.5-6.8,0.8
      c-2.1-1.7-2.5-4.7-0.8-6.8c3.4-4.3,7.9-7.6,12.9-9.9c-27.8-10-60.1-4.8-83.7,16.1C14,59.7,6.7,99.3,21.3,131.7
      c1.9,2.6,4,5.2,6.3,7.7C52.8,167.2,91.4,176.7,125.6,166.9z M126.4,49.9c2.2-10.2,12.2-16.7,22.4-14.6
      c10.2,2.2,16.7,12.2,14.6,22.4c-2.2,10.2-12.2,16.7-22.4,14.6C130.8,70.1,124.2,60.1,126.4,49.9z M116,119.5
      c2.5-1.5,6.2-1.3,12.5-0.7c3.6,0.3,11.1,0.9,12.6-0.2c1.6-1.5,4-1.4,5.5,0.1c1.5,1.6,1.4,4-0.1,5.5c-2.3,2.2-5.9,2.8-10.1,2.8
      c-2.7,0-5.5-0.2-8.5-0.5c-2.7-0.2-6.8-0.6-8-0.3c-1.8,1-4.1,0.4-5.2-1.4C113.5,123,114.1,120.6,116,119.5z M85.3,62.8
      C95.5,65,102,75,99.9,85.2c-2.2,10.2-12.2,16.7-22.4,14.6c-10.2-2.2-16.7-12.2-14.6-22.4C65,67.2,75.1,60.7,85.3,62.8z M49.5,67
      c2.8-11.1,10.9-17.7,22.7-18.7c2-0.2,3.8,1.3,4,3.4c0.2,2-1.3,3.8-3.4,4c-8.7,0.7-14.1,5.1-16.2,13.2c-0.4,1.7-1.9,2.8-3.6,2.8
      c-0.3,0-0.6,0-0.9-0.1C50.2,71,49,69,49.5,67z"/>
    <path class="st1" d="M121.5,177.3l4.1-10.4c-34.3,9.8-72.8,0.3-98.1-27.5c-2.3-2.5-4.3-5-6.3-7.7c3.4,7.5,7.9,14.6,13.7,21.1
      C57.2,177.9,91.3,186.3,121.5,177.3z"/>
    <path class="st3" d="M121.5,177.3l4.1-10.4c-34.3,9.8-72.8,0.3-98.1-27.5c-2.3-2.5-4.3-5-6.3-7.7c3.4,7.5,7.9,14.6,13.7,21.1
      C57.2,177.9,91.3,186.3,121.5,177.3z"/>
  </g>
  <g>
    <g>
      <g>
        <path class="st0" d="M254.2,67.6c1.9,0,4-0.3,5.7-0.3c0.3,0,0.7,0,1,0c4.8,0.6,10.8,1.4,15.6,3.6c4.4,2,7.6,5.3,7.6,10.7
          c0,3.8-0.9,6.7-1.8,8.9c-1.8,4.3-8,7.7-8,9c0,1.7,2.5,2,4.8,4c3.7,3.4,6.8,6.1,6.8,12.4c0,7.8-2.3,11.5-7.6,15.7
          c-4.2,3.3-3.2,2.8-6.1,4.1c-4.8,2.2-7,2.6-12.7,2.6c-1.1,0-2.4,0.2-3.6,0.2c-3.6,0-6.1-0.1-9.8-0.1c-4.1,0-6.8-4.1-6.8-7.6
          c0-0.6-0.5-4.7-0.5-5.2c0-3.6-0.2-4.2-0.2-8c0-3.3,0-6.5,0.1-10.2c0.1-3.2,1.1-6.4,1.1-9.5c0-3.3,0.1-6.5,0.1-9.9
          c0-2.5-0.1-4.9-0.1-7.7c0-2-0.5-3.9-0.5-5.7c0-5.5,6.9-5.5,10.7-6.5C251.3,67.6,252.8,67.6,254.2,67.6z M257.4,107.5
          c-4.4,0-4.9,0.7-4.9,4.4c0,3.1,0.3,6,0.3,9.1c0,0.3-0.1,0.9-0.1,1.5c0,3.1,3.4,3.2,7.4,3.2c0.2,0,0.6,0,0.8,0
          c6.2,0,10-3.5,11.1-9.3c0.2-0.8,0.2-1.6,0.2-2.3c0-3.2-1.8-5.2-5.6-6c-0.7-0.2-1.1-0.3-1.7-0.3
          C261.7,107.6,259.2,107.5,257.4,107.5z M253.1,82.7c0,1.5,0.1,4.2,0.1,5.5c0,0.9-0.1,3.1-0.1,4.8c0,1.9,0.5,2.7,2,2.7
          c1.7,0,13.9-1.8,13.9-9.8c0-4.1-2.4-6.9-9.1-6.9c-1.2,0-2.4,0.1-3.2,0.2C253.7,79.7,253.1,80.5,253.1,82.7z"/>
        <path class="st0" d="M307.6,67.5c1.7,0,3.4-0.3,5.5-0.3c3,0,5.9-0.1,9.3-0.1c2.5,0,10.3,0,10.3,5.3c0,0.1,0,0.1,0,0.2
          c-0.1,6.2-5.3,7.5-9.7,7.5c-0.6,0-1,0-1.6,0c-0.5,0-2-0.2-3.9-0.2c-3.5,0-8.2,0.7-8.3,4.8c0,1.4,0.1,3,0.1,4
          c0,1.4-0.5,2.7-0.5,4.1c0,0.1,0,0.2,0,0.3c0,2.5,1.2,3.1,2.8,3.1c3,0,5.6-1,6.9-1c0.7,0,1.5,0.1,2.5,0.1c1.4,0,2.7-0.6,4.2-0.6
          c3.3,0,7.4,1.7,7.4,5.1v0.1c-0.2,6.2-5.5,7.8-10.5,8.1c-5.3,0.2-6,1-9.3,1c-5,0-5.5,3.1-5.5,6.4c0,0.7,0,1.5,0,2.2
          c0,0.2,0,0.5,0,0.7c0,2.6,0.5,5.5,2.7,5.5c0.7,0,1.5-0.2,2.3-0.2c0.7,0,1.4,0.1,2,0.1c4.7,0,8.4-1.4,13.6-1.4
          c2.7,0,6.2,1.8,6.2,5.2v0.1c-0.2,5-3.1,7.6-7.7,7.6c-1,0-2-0.1-3-0.1c-2,0.1-3.5,0.6-5.3,0.8c-4,0.5-8.2,0.7-12.2,0.7
          c-0.6,0-1.1,0-1.7,0c-1.7,0-3.1,0.8-4.4,0.8c-4,0-6.2-5.1-6.2-7.8c0.1-2.5,0.3-5.1,0.3-8.1c0.1-3,0.5-5.9,0.8-8.9
          c0.2-2.6,0.6-5,0.7-7.7c0-1.5,0-3,0-4.5c0-1.6,0-3.3,0.1-5.3c0.2-5.2,1.2-10.2,1.5-15.5c0-1.4-0.3-3-0.3-4.4c0-0.2,0-0.3,0-0.6
          c0.1-3.2,2.6-5.7,5.3-6.2C304.6,67.8,305.8,67.5,307.6,67.5z"/>
        <path class="st0" d="M381.8,66.7c0.7,0,1.2,0,1.9,0.1c1.9,0.3,4.8,0.1,6.5,0.3c2.2,0.1,4.2,0.5,6,1.1c1,0.3,2.3,0.7,3,1.4
          c0.8,0.8,1.1,2.3,1.1,3.8c0,2.2-0.7,4.4-1.6,5.5c-1,1.1-2.2,1.5-3.3,1.5c-1.4,0-3-0.5-4.5-0.6c-1-0.2-2.3-0.3-3.3-0.3
          c-0.2,0-0.5,0-0.7,0c-0.5,0-0.9,0-1.4,0c-0.9,0-1.9,0-3,0c-0.9,0-1.8,0.1-2.5,0.2c-2.3,0.9-4.9,1.4-6.5,3.5
          c-0.5,0.7-1,1.7-1,2.7c0,0.1,0,0.3,0,0.5c0.3,2.7,2.4,4.1,4.3,5.2c2.3,1.4,4.2,2.7,6.5,3.7c1.8,0.9,3.2,1.4,5,2
          c1.1,0.6,4.4,2.2,5.6,2.6c1.5,0.6,2.5,1.5,3.9,2.3c3.1,2,4.7,4.5,5.8,8.4c0.6,2,0.8,4.1,0.8,6.1c0,0.3,0,0.8,0,1.1
          c0,2-0.5,4.2-1,6c-0.9,3.1-2.7,6.5-4.5,9c-2,2.7-4.3,3.4-6.9,4.5c-1.1,0.6-2.2,1-3.5,1.4c-1.6,0.5-2.7,0.7-4.3,0.8
          c-0.7,0.1-1.2,0.1-1.9,0.1c-0.2,0-0.6,0-0.9,0c-1.7,0-3.5-0.2-5.2-0.3c-1-0.2-2.3-1-3.2-1.1c-2.3-0.3-4.5-1.4-6.4-2.6
          c-2-1.5-5.1-4.4-5.1-7.4c0-1,0.3-1.9,1.1-2.8c1.5-1.5,2.6-2.5,4.1-2.5c0.5,0,0.9,0.1,1.5,0.3c1.1,0.6,1.8,1.5,3.1,1.8
          c1.4,0.3,2.4,0.9,3.6,0.9c1.1,0.1,2.3,0.3,3.5,0.3c0.2,0,0.3,0,0.6,0c1.8-0.1,3.4-0.3,5-1c1.5-0.7,3-1.2,4.2-2.3
          c1.6-1,2.3-3.3,2.3-5.3c0-0.1,0-0.3,0-0.5c-0.1-1.7-1.2-3.5-2.5-4.2c-1.4-1-3.2-2-4.9-2.5c-1.4-0.6-2.8-0.6-4.1-1.2
          c-1.2-0.6-3.3-1.5-4.7-2c-2.4-1.2-5.2-2.2-7.6-3.6c-2-1.5-3.6-3.2-5.2-5.2c-1.5-1.8-1.3-4.2-1.8-6.7c-0.5-1.8-0.8-4-0.8-6
          c0-1.1,0.1-2.2,0.3-3.1c0.5-2.2,1.4-4.1,2.4-5.8c1.2-2.4,3.5-4.3,5.6-5.7c0.9-0.6,1.8-1.4,2.8-1.9c2-1.2,5.8-1.5,8.1-2
          C379.3,66.8,380.6,66.7,381.8,66.7z"/>
        <path class="st0" d="M466.2,104c-0.9,2.3-1.5,4.7-2.4,6.8c-0.9,2-2,4-2.8,6c-0.8,1.9-1,3.9-1.6,5.7c-0.9,2.8-2.6,6.4-4.4,8.7
          c-0.6,0.7-1.2,1.2-1.8,1.9c-1.9,2.6-2.8,5.6-5.7,5.6c-0.6,0-1.4-0.1-2.2-0.5c-0.7-0.3-2.4-0.7-3-1.1c-0.7-0.6-1.5-1.4-2-2.2
          c-1.7-2.4-2.2-5.7-3.2-8.3c-0.8-1.9-1.9-3.7-2.6-5.7c-1.7-4.5-3.3-9.3-4.8-14c-0.8-2.3-1.7-4.5-2.5-6.8
          c-0.2-0.5-0.5-1.9-1.1-1.9c-0.6,0-0.9,1.5-1,1.9c-0.7,2.4-0.5,5.1-0.9,7.5c-1.6,9-0.9,18.3-3,27.3c-0.5,2.7-2.8,4.7-5.5,4.7
          c-0.9,0-1.8-0.2-2.7-0.8c-1.1-0.7-2.4-1.8-3.2-2.8c-1.1-1.4-1.5-2.8-1.5-4.5c0-2.8,1.1-6,1.5-8.6c0.2-2,0.7-4.1,0.9-6.2
          c0-0.5,0-0.9,0-1.4c0-0.9,0-1.7,0-2.5c0-0.5,0-1,0.1-1.5c0.1-2.4,0.8-4.5,1-6.9c0-0.5,0-1,0-1.5c0-0.5,0-0.9,0.1-1.5
          c0.6-4.7,1.5-8.5,2.3-13.2c0.2-1.2,0.1-3.4,0.2-4.7c0.1-2.4,0.6-5,0.9-7.5c0.1-1.9,0.3-4.1,2.5-4.8c0.8-0.2,1.7-0.2,2.6-0.3
          c1.7-0.2,4-0.7,6.1-0.7c1.8,0,3.4,0.2,4.7,1.4c1.2,1,1.7,3.1,2.3,4.5c0.8,2.6,1.7,5,2.5,7.6c0.7,2.3,1.8,4.8,2.7,7
          c1,2.7,1.7,5.6,2.7,8.3c1.7,4,2.6,8.2,4.2,12.2c0.2,0.6,1,3.4,1.9,3.4c0.1,0,0.1,0,0.2,0c0.8-0.5,1.1-2.3,1.4-3.1
          c1-3,2.3-5.8,3.3-8.7c2.8-8.1,6.1-16,8.7-24.2c0.9-2.7,1.7-8.4,4.1-10.3c1.2-1,2.8-1.3,4.4-1.4c1.7-0.2,3.5-0.6,5.2-0.6
          c0.5,0,0.9,0.1,1.5,0.1c1.8,0.1,4.3,0.9,5.6,2.4c0.8,1,0.8,2.3,1,3.4c0.2,0.7,0.2,1.4,0.2,2.2c0,1.1-0.1,2.5-0.1,3.6
          c0,0.6,0,1.1,0.1,1.7c0.9,3.7,0.6,7.6,0.8,11.4c0.2,3.9,0.6,7.8,0.7,11.7c0,1.7,0,3.3,0.1,4.9c0.2,4.5,0.6,8.9,0.6,13.3v9.4
          c0,2.7-0.1,5.3-2.6,7c-1.2,0.8-2.6,1.1-3.9,1.1c-3.7,0-6.8-3.3-7.6-7.4c-0.2-0.7-0.2-1.5-0.2-2.2c0-1.5,0.2-3,0.2-4.4
          c0-0.2,0-0.3,0-0.6c-0.1-2.8,0-5.6-0.2-8.4c-0.1-2.7-0.7-5.5-0.7-8.3c0-1.7,0.1-3.6,0.1-5.5c0-2-0.1-4.1-0.8-5.9
          C468.7,98.7,467.4,101.4,466.2,104z"/>
        <path class="st0" d="M522.1,66.8c0.2,0,0.6,0,0.8,0c6,0.2,6.8,7.7,8.7,12.2c0.6,1.4,1.1,2.8,1.7,4.2c0.8,2.3,1.8,4.1,2.6,6.4
          c2.5,7.2,4.4,14.5,6.7,21.8c1,3.5,3.2,8.3,4.2,11.7c0.5,0.9,0.6,1.8,0.8,2.6c0.7,2.4,1.6,5,1.6,7.4c0,2-0.7,4-2.4,5.6
          c-0.6,0.8-1.2,1.2-2.2,1.5c-0.8,0.2-1.7,0.3-2.5,0.3c-2.4,0-4.7-0.9-5.7-3c-1.9-4.1-2.6-7.7-3.9-12c-0.5-1.5-0.5-3.2-1.7-4.2
          c-0.3-0.3-0.7-0.5-1.1-0.5c-0.7,0-1.4,0.2-1.7,0.3c-0.2,0-0.5,0-0.6,0c-0.2,0-0.3,0-0.9,0c-0.2,0-0.5,0-0.8,0
          c-0.9,0-2.5,0.1-3.4,0.1c-1.4,0-4,0.2-5.6,0.3c-0.3,0.1-0.7,0.1-0.9,0.1c-0.8,0-1.2-0.2-2-0.2c-0.3,0-0.7,0-1.1,0.1
          c-1.4,0.1-3.1,0-4.1,1c-0.7,0.9-1.2,2.6-1.6,3.6c-1.4,3.6-0.9,4.5-2.6,8.1c-1.1,2-3.4,3.2-5.7,3.2c-1.6,0-3.2-0.6-4.4-1.8
          c-1.1-1.1-1.5-3.1-1.6-4.5c0-0.5-0.1-0.8-0.1-1.2c0-1.5,0.3-2.7,0.7-4.3c0.7-2.5,1.2-3.8,1.9-6.2c0.7-1.7,1.1-3.1,1.7-4.8
          c0.3-1.1,0.8-2.3,1.1-3.5c1.4-3.8,2.6-7.2,3.9-10.9c1-2.6,1.7-4.9,2.4-7.4c0.3-1.1,0.8-2.5,1.2-3.6c1.4-4,2.7-8.2,4.4-12.2
          c1.4-3.2,2.4-6.8,5.7-9c1-0.6,3.5-0.8,4.4-1C520.9,66.8,521.5,66.8,522.1,66.8z M518.2,90.2c-1.5,3.3-3.5,12.3-4.3,15.8
          c-0.1,0.7-0.5,1.8-0.5,2.7c0,0.5,0.1,0.9,0.3,1.2s0.7,0.5,1.1,0.5c0.7,0,1.5-0.2,2-0.2c1.4,0,3.1,0.1,4.9,0.1
          c1.7,0,3.5-0.1,4.9-0.7c0.7-0.2,0.9-0.8,0.9-1.4c0-0.9-0.5-2-0.7-2.7c-1-3.7-2.4-11.8-4.1-15.3c-0.5-0.9-1.1-3.6-2.3-3.6h-0.1
          C519.4,86.8,518.5,89.3,518.2,90.2z"/>
        <path class="st0" d="M565.7,67.7c0.2,0,0.3,0,0.6,0c0.6,0.1,1,0.1,1.6,0.1c1.1,0,2.3-0.1,3.3-0.1c2,0,4.2-0.1,6.4-0.1
          c3.5,0,7,0.2,10.2,1.1c6.7,1.9,12.5,10.6,12.5,17.8c0,0.1,0,0.3,0,0.5c-0.1,2.6-1.6,5-2.8,7.2c-0.9,1.5-1.5,3-2.6,4.2
          c-1.1,1.5-5.5,2.8-5.5,4.8c0.1,0.8,0.6,1.6,0.9,2.3c0.9,1.9,2.2,3.7,3.3,5.7c0.8,1.4,1.8,4.2,2.7,5.6c1.5,2.3,3.2,4.3,4.3,6.8
          c1,1.8,1.5,3.8,2.4,5.7c0.3,0.9,0.9,1.7,1.1,2.6c0.1,0.5,0.1,1,0.1,1.5c0,3.1-1.7,6.1-4.8,7c-0.7,0.2-1.2,0.2-1.8,0.2
          c-2.6,0-4.7-1.7-6-4.1c-1.5-2.7-2.4-4.5-3.9-7.3c-3.2-6.1-5.9-12.4-9.8-18.1c-1.5-2.2-1.7-3.3-4-3.6c-0.6,0-1-0.2-1.5-0.2
          c-0.6,0-1.2,0.2-1.7,0.9c-0.5,0.8-0.5,2-0.6,3c-0.1,0.8-0.1,1.7-0.1,2.6c0,2.8,0.3,6.1,0.3,8.6c0,0.3,0,0.6,0,0.9
          c0,2.8,0.5,5.7,0.5,8.5c0,1-0.1,2-0.2,3.1c-0.6,4.3-4.7,6.8-8.7,6.8c-1.1,0-2.2,0-3.1-0.7c-2.2-1.6-2.3-4.7-2.4-7.3
          c-0.1-1.8-0.1-3.5-0.1-5.3c0-0.8,0-1.5,0-2.3c0-1,0.1-2.2,0.1-3.2c0-4.3-0.3-9-0.3-13.3c0-1.6,0-3.2,0.1-4.8
          c0.2-2.7,0.3-6.7,0.6-9.3c0-0.3,0.1-0.7,0.1-1c0-0.9-0.2-1.8-0.2-2.8c0.1-1.8,0.1-3.7,0.1-5.7c0-0.8,0-1.5,0-2.3
          c0-1.1,0-2.3,0-3.3c0-0.6,0-1,0-1.6c-0.1-1.1-0.2-2.4-0.2-3.5c0-2.7,0.8-5.2,4.4-6.4C562.5,68.5,564.1,67.7,565.7,67.7z
           M571.1,83.2c0,0.7,0,1.4,0.1,1.6c0.1,0.6,0.1,1.2,0.1,2c0,1.5-0.1,3.3-0.1,4.7c0,0.3,0,0.7,0,1c0,0.2,0,0.6,0,0.9
          c0,0.8,0.1,1.8,0.6,2.2c0.2,0.2,0.7,0.3,1.1,0.3c0.7,0,1.5-0.2,1.9-0.2c3.1,0,3.7-0.5,6.4-2.3c0.8-0.3,2.2-1.6,2.8-2.5
          c0.3-0.5,0.9-1.4,1.2-2.3c0.2-0.2,0.2-0.7,0.2-1.1c0-0.2,0-0.6,0-0.8c0-1-1.1-4.1-1.8-4.9c-1.6-1.2-3.2-2.4-6-2.4
          c-0.9,0-2.4-0.2-3.7-0.2c-0.8,0-1.5,0.1-2,0.3C571.3,80,571.1,81.8,571.1,83.2z"/>
        <path class="st0" d="M647.6,69.3c2.2,0,5.3,1.9,6.9,3.9c0.5,0.7,0.7,1.6,0.7,2.5s-0.2,1.7-0.3,2.5c-1.5,5.2-6.4,5.5-10.7,5.5
          c-0.8,0-1.8-0.1-2.7-0.1c-1.4,0-2.6,0.2-3.3,1.4c-0.7,1.1-0.8,2.6-0.8,4c0,0.7,0,1.2,0,1.8c0.2,2.8,0,4.4,0.3,7.2
          c0,0.1,0,0.3,0,0.5c0,0.9-0.2,1.7-0.2,2.6c0,0.2,0.1,0.6,0.1,0.8c0,1.6,0.3,3,0.3,4.4c0,0.3-0.1,0.8-0.1,1.1c0,0.6,0,1.1,0,1.7
          c0,1.1,0,2.3,0,3.3c0,0.5,0,0.9,0,1.4c0,1.2,0,2.4,0,3.6c0,0.6,0,1.2,0,1.8c0.1,1.2,0.3,2.4,0.3,3.5c0,0.7-0.1,1.4-0.2,2
          c-0.1,0.5-0.1,0.9-0.1,1.5c0,1,0.1,2.3,0.1,3.3c0,1.2-0.1,2.5-0.6,3.5c-0.7,1.8-2.4,3-4,3.8c-0.7,0.3-2.2,0.8-3,0.9
          c-0.1,0-0.1,0-0.2,0c-4.8,0-6.1-5.8-6.1-10.1c0-0.9,0-1.8,0.1-2.5c0.2-2,0.1-4.8,0.2-6.9c0-2.4,0.1-4.1,0.1-6.4
          c0.1-1.8,0.1-4,0.2-5.6c0-1.8-0.1-3-0.1-4.8c-0.1-3.2,0-6.2-0.2-9.4c0-0.6,0-0.9,0-1.4c0-1.1,0.1-1.9,0.2-3.4c0-0.3,0-0.7,0-1
          c0-0.9-0.1-1.9-0.6-2.6c-0.3-0.5-0.7-0.6-1-0.6c-0.3,0-0.7,0.1-1,0.2c-0.1,0-0.2,0-0.3,0c-1.2,0-3-0.6-4.2-0.6
          c-0.1,0-0.2,0-0.3,0c-1.5,0.1-3,0.2-4.4,0.2c-3.2,0-6.1-0.6-8.4-3.4c-0.8-0.9-1.2-2.6-1.2-4.2c0-0.8,0.2-1.6,0.5-2.2
          c0.6-1.4,3.2-3.4,4.7-3.6c0.7-0.1,1.5-0.2,2.3-0.2c1.6,0,3.1,0.2,4.5,0.2c0.2,0,0.5,0,0.7,0c0.6,0,1.2,0,1.8,0
          c1.2,0,2.5,0,3.7,0c0.5,0,1,0,1.5,0c0.5,0,0.9,0,1.4,0c2.4,0,4.7,0.3,6.8,0.3c5.5-0.2,10.9-0.5,16.5-0.5
          C647.5,69.3,647.5,69.3,647.6,69.3z"/>
        <path class="st0" d="M848,139.3c-2.2-1-2.7-3.2-2.7-5.6c0-2.5,0.7-5.2,0.9-6.9c0.3-2.4,0.3-3.4,0.6-5.8c0.1-1,0.5-2.7,0.5-4.4
          c0-1.8-0.5-3.5-1.7-4.1c-0.7-0.3-1.4-0.3-2-0.3c-0.9,0-1.8,0.1-2.8,0.1c-2.2,0-4.2,0.1-6.4,0.1c-2.2,0-4.3-0.1-6.4-0.3
          c-1-0.1-2.5-0.6-3.9-0.6c-1,0-1.8,0.2-2.4,0.9c-0.6,0.7-0.7,1.7-0.7,2.8c0,1.1,0.1,2.4,0.1,3.2c0,2.7-0.3,4.4-0.3,7.3
          c0,1,0.2,3.3,0.2,5.2c0,1.1,0,2-0.3,2.6c-0.6,1.1-1.6,1.7-2.5,2.4c-1.4,0.9-3.2,1.6-5,1.6c-1.4,0-2.6-0.3-3.6-1.2
          c-0.9-0.7-1.7-2.4-1.7-3.8c0-0.1,0-0.3,0-0.5c0-0.1,0-0.5,0-0.7c0-1.1-0.1-2.8-0.1-4c0-0.2,0-0.5,0-0.6c0-0.1,0-0.2,0-0.3
          c0-0.8-0.2-1.6-0.2-2.4c0-2,0.3-4.1,0.3-6c0-3.7-0.2-7.4-0.2-11c0-1.1-0.3-2-0.3-3.1c0-0.1,0-0.2,0-0.3c0.2-1.4,0.6-2.5,0.6-3.9
          c0-2.5-0.3-4.2-0.3-6.5c0-0.5,0-0.9,0-1.5c0.1-1.3,0.7-2.5,0.8-3.8c0.1-0.5,0.1-1,0.1-1.5c0-1-0.1-2-0.1-3c0-2.4,0.1-4.7,0.2-7
          c0.1-1.7,0.2-3.4,1.1-4.9c0.9-1.5,3.2-2.5,4.8-3.1c0.7-0.2,1.4-0.3,2-0.3c2.7,0,4.8,1.9,5.5,4.9c0.3,1.7,0.6,3.6,0.6,5.3
          c0,1.2-0.6,2.5-0.6,3.7c0,1,0.3,2,0.3,3.1c0,1.6-0.2,4.4-0.3,6.1c-0.1,0.9-0.3,2-0.3,3.1c0,0.8,0.2,1.6,0.7,2.4
          c1,1.7,4.2,1.7,6,1.9c0.6,0.1,1.1,0.1,1.7,0.1c0.6,0,1,0,1.7,0c0.8,0,1.6,0,2.4,0c0.3,0,0.8,0,1.1,0c0.7-0.1,1.5-0.1,2.3-0.1
          c0.8,0,1.5,0,2.3,0c1.5,0,2.7,0.2,4,0.2c0.7,0,1.2,0,1.9-0.2c1.7-0.5,1.9-2,1.9-3.6c0-0.7,0-1.5,0-2.2c0-0.3,0.1-0.7,0.1-1
          c0.2-1.8,0.8-5.2,0.9-7c0.2-2.4,0.3-4.9,0.3-7.3c0-1.2,0.1-2.5,0.3-3.7c0.7-3.5,4-6.8,7.6-6.8c0.5,0,0.8,0,1.2,0.1
          c4.7,1,5.5,5.7,5.5,10.1c0,1.9-0.1,3.9-0.2,5.3c-0.1,5.1-1.2,9.9-1.2,15.1c0,1.1-0.2,2.7-0.2,3.9c0,3.6-1,7.4-1.1,11
          c0,1.8,0,3.9,0,5.9c0,3.3-0.1,6.7-0.6,9.7c-0.2,1.2-0.6,2.5-0.8,3.9c-0.1,0.9-0.7,2.6-1.1,3.5c-1,2.2-4.3,4.5-6.8,4.5
          c-0.3,0-0.6,0-0.8-0.1C849.8,139.9,848.8,139.6,848,139.3z"/>
        <path class="st0" d="M887,67.5c1.7,0,3.4-0.3,5.5-0.3c3,0,5.9-0.1,9.3-0.1c2.5,0,10.3,0,10.3,5.3c0,0.1,0,0.1,0,0.2
          c-0.1,6.2-5.3,7.5-9.7,7.5c-0.6,0-1,0-1.6,0c-0.5,0-2-0.2-3.9-0.2c-3.5,0-8.2,0.7-8.3,4.8c0,1.4,0.1,3,0.1,4
          c0,1.4-0.5,2.7-0.5,4.1c0,0.1,0,0.2,0,0.3c0,2.5,1.2,3.1,2.8,3.1c3,0,5.6-1,6.9-1c0.7,0,1.5,0.1,2.5,0.1c1.4,0,2.7-0.6,4.2-0.6
          c3.3,0,7.4,1.7,7.4,5.1v0.1c-0.2,6.2-5.5,7.8-10.5,8.1c-5.3,0.2-6,1-9.3,1c-5,0-5.5,3.1-5.5,6.4c0,0.7,0,1.5,0,2.2
          c0,0.2,0,0.5,0,0.7c0,2.6,0.5,5.5,2.7,5.5c0.7,0,1.5-0.2,2.3-0.2c0.7,0,1.4,0.1,2,0.1c4.7,0,8.4-1.4,13.6-1.4
          c2.7,0,6.2,1.8,6.2,5.2v0.1c-0.2,5-3.1,7.6-7.7,7.6c-1,0-2-0.1-3-0.1c-2,0.1-3.5,0.6-5.3,0.8c-4,0.5-8.2,0.7-12.2,0.7
          c-0.6,0-1.1,0-1.7,0c-1.7,0-3.1,0.8-4.4,0.8c-4,0-6.2-5.1-6.2-7.8c0.1-2.5,0.3-5.1,0.3-8.1c0.1-3,0.5-5.9,0.8-8.9
          c0.2-2.6,0.6-5,0.7-7.7c0-1.5,0-3,0-4.5c0-1.6,0-3.3,0.1-5.3c0.2-5.2,1.2-10.2,1.5-15.5c0-1.4-0.3-3-0.3-4.4c0-0.2,0-0.3,0-0.6
          c0.1-3.2,2.6-5.7,5.3-6.2C883.9,67.8,885.2,67.5,887,67.5z"/>
        <path class="st0" d="M946.5,66.8c0.2,0,0.6,0,0.8,0c6,0.2,6.8,7.7,8.7,12.2c0.6,1.4,1.1,2.8,1.7,4.2c0.8,2.3,1.8,4.1,2.6,6.4
          c2.5,7.2,4.4,14.5,6.7,21.8c1,3.5,3.2,8.3,4.2,11.7c0.5,0.9,0.6,1.8,0.8,2.6c0.7,2.4,1.6,5,1.6,7.4c0,2-0.7,4-2.4,5.6
          c-0.6,0.8-1.2,1.2-2.2,1.5c-0.8,0.2-1.7,0.3-2.5,0.3c-2.4,0-4.7-0.9-5.7-3c-1.9-4.1-2.6-7.7-3.9-12c-0.5-1.5-0.5-3.2-1.7-4.2
          c-0.3-0.3-0.7-0.5-1.1-0.5c-0.7,0-1.4,0.2-1.7,0.3c-0.2,0-0.5,0-0.6,0c-0.2,0-0.3,0-0.9,0c-0.2,0-0.5,0-0.8,0
          c-0.9,0-2.5,0.1-3.4,0.1c-1.4,0-4,0.2-5.6,0.3c-0.3,0.1-0.7,0.1-0.9,0.1c-0.8,0-1.2-0.2-2-0.2c-0.3,0-0.7,0-1.1,0.1
          c-1.4,0.1-3.1,0-4.1,1c-0.7,0.9-1.2,2.6-1.6,3.6c-1.4,3.6-0.9,4.5-2.6,8.1c-1.1,2-3.4,3.2-5.7,3.2c-1.6,0-3.2-0.6-4.4-1.8
          c-1.1-1.1-1.5-3.1-1.6-4.5c0-0.5-0.1-0.8-0.1-1.2c0-1.5,0.3-2.7,0.7-4.3c0.7-2.5,1.2-3.8,1.9-6.2c0.7-1.7,1.1-3.1,1.7-4.8
          c0.3-1.1,0.8-2.3,1.1-3.5c1.4-3.8,2.6-7.2,3.9-10.9c1-2.6,1.7-4.9,2.4-7.4c0.3-1.1,0.8-2.5,1.2-3.6c1.4-4,2.7-8.2,4.4-12.2
          c1.4-3.2,2.4-6.8,5.7-9c1-0.6,3.5-0.8,4.4-1C945.3,66.8,945.8,66.8,946.5,66.8z M942.5,90.2c-1.5,3.3-3.5,12.3-4.3,15.8
          c-0.1,0.7-0.5,1.8-0.5,2.7c0,0.5,0.1,0.9,0.3,1.2c0.2,0.3,0.7,0.5,1.1,0.5c0.7,0,1.5-0.2,2-0.2c1.4,0,3.1,0.1,4.9,0.1
          c1.7,0,3.5-0.1,4.9-0.7c0.7-0.2,0.9-0.8,0.9-1.4c0-0.9-0.5-2-0.7-2.7c-1-3.7-2.4-11.8-4.1-15.3c-0.5-0.9-1.1-3.6-2.3-3.6h-0.1
          C943.8,86.8,942.9,89.3,942.5,90.2z"/>
        <path class="st0" d="M987.3,68.6c0.8,0,1.6,0.1,2.4,0.3c1.4,0.3,3.1,0.8,4.1,1.8c2.3,2.4,1.7,7,2,10.1c0.5,4.8,0.3,9.5,0.7,14.3
          c0,0.5,0,0.8,0,1.2c0,0.8,0,1.7,0,2.6c0,0.3,0,0.8,0,1.1c0,3.1-0.3,6-0.3,9.1c0,2.3-0.2,4.5-0.2,6.8c0,0.9,0,1.7,0.1,2.6
          c0.1,1.1-0.1,2.4,0.6,3.3c1,1.8,2.7,1.5,4.5,1.7c2.7,0.3,5.5,0.3,8.3,0.3c3,0,6.6,1,9,3.1c1.4,1.1,1.9,2.6,1.9,4.1
          c0,2.5-1.6,5.1-4.2,6.1c-1.4,0.6-2.8,0.8-4.3,0.8c-3.2,0-6.5-1-9.5-1.5c-1.8-0.3-3.6-0.3-5.5-0.3c-1,0-2.2,0-3.1,0
          c-1.4,0-2.6,0.1-4,0.1c-1.5,0-4.1-0.8-5.3-1.6c-0.9-0.7-1.6-1.1-2-2.3c-0.6-1.4-0.8-3.3-0.9-4.8c-0.1-1.4-0.1-3.2-0.1-4.5
          c0-0.7,0-1.5,0-2.2c0-3.1-0.1-5.8-0.2-9c0-2.6,0.1-5.2,0.1-7.8c0-1.4,0.1-2.7,0.1-4.1c0-1.4-0.1-2.8-0.1-4.2
          c-0.1-1.2-0.1-2.5-0.1-3.9c0-1.2,0-2.6,0-4c0-1.1,0-2.4-0.1-3.5c-0.2-2.2-0.1-4.3-0.5-6.5c-0.1-0.7-0.1-1.4-0.1-2
          c0-0.7,0-1.4,0-2.2c0.1-2,4-5,6.2-5.3C986.8,68.6,987.1,68.6,987.3,68.6z"/>
        <path class="st0" d="M1049.6,81.9c-0.2-0.1-0.5-0.1-0.7-0.1c-0.5,0-0.9,0.2-1.4,0.3c-1.1,0.3-2.2,0.2-2.6,1.7
          c0,0.2-0.1,0.6-0.1,0.9c0,0.9,0.2,1.9,0.3,2.8c0.1,3.3,0.3,6.6,0.8,9.9c0.5,3.9,0.2,7.2,0.7,11.1c0.2,1.1,0.2,2.5,0.2,3.9
          c0,0.5,0,0.9,0,1.2c0.5,2.2,0.5,4.4,0.5,6.7c0,0.9,0,1.8,0,2.7c0,0.7,0,1.2,0,1.9c0,1.7,0.5,3.3,0.5,4.9c0,0.6,0,1.2-0.2,1.9
          c-0.7,2.3-3.6,5.6-6.5,5.6c-0.3,0-0.7,0-1-0.1c-2.5-0.8-4.5-3.6-5.7-5.9c-0.8-1.7-0.6-3.4-0.8-5.2c-0.1-1.2-0.3-2.5-0.5-3.9
          c0-0.5,0-0.8,0-1.2c0-0.8,0-1.7,0-2.6c0-0.3,0-0.7,0-1c0-2.7-0.5-5.7-0.9-8.3c-0.1-1.1-0.1-2.3-0.2-3.4c0-1.8,0.1-3.6,0.1-5.5
          c0-0.5,0-0.9,0-1.4c-0.1-1.4-0.3-2.7-0.6-4.2c-0.1-0.9-0.1-1.8-0.1-2.8c0-0.9,0-1.9,0-3c0-0.8,0-1.7-0.1-2.5
          c0-0.5,0.1-0.9,0.1-1.4c0-0.3,0-0.6-0.2-0.9c-0.5-1.1-1.8-1.4-3.2-1.4c-0.8,0-1.6,0.1-2.3,0.1c-1.9,0.2-4,0.3-5.9,0.3
          c-0.7,0-1.2,0-1.9,0c-0.6,0-1.1,0.1-1.6,0.1c-0.2,0-0.5,0-0.7-0.1c-2-0.9-3.3-3.3-4.3-5.1c-0.6-0.9-1.4-1.9-1.4-3.1
          c0-0.1,0-0.2,0-0.3c0.1-1.1,0.8-1.2,1.5-1.9c0.1-0.3,0.1-0.9,0.5-1.2c1.7-1.8,4.2-2,6.7-2c1.1,0,2.2,0,3.2,0
          c0.6,0,1.1-0.1,1.7-0.1c2.2-0.2,4.4-0.1,6.6-0.3c0.7,0,1.4-0.1,2-0.1c2,0,4.1,0.2,6.1,0.2c1.7-0.1,3.6-0.3,5.3-0.5
          c0.2,0,0.3,0,0.6,0c1.6,0,3.2,0.3,4.8,0.3c1.5,0,2.6-0.1,4.1-0.3c0.6-0.1,1.4-0.1,2-0.1c1,0,2,0.1,2.8,0.3
          c1.8,0.6,4.2,2.3,4.7,4.2c0.1,0.5,0.1,0.9,0.1,1.4c0,2.3-1.2,4.7-2.8,5.8c-2.6,1.9-4.3,1-7.5,1.5
          C1052.3,81.8,1049.9,81.9,1049.6,81.9z"/>
        <path class="st0" d="M1109.4,139.3c-2.2-1-2.7-3.2-2.7-5.6c0-2.5,0.7-5.2,0.9-6.9c0.3-2.4,0.3-3.4,0.6-5.8
          c0.1-1,0.5-2.7,0.5-4.4c0-1.8-0.5-3.5-1.7-4.1c-0.7-0.3-1.4-0.3-2-0.3c-0.9,0-1.8,0.1-2.8,0.1c-2.2,0-4.2,0.1-6.4,0.1
          c-2.2,0-4.3-0.1-6.4-0.3c-1-0.1-2.5-0.6-3.9-0.6c-1,0-1.8,0.2-2.4,0.9c-0.6,0.7-0.7,1.7-0.7,2.8c0,1.1,0.1,2.4,0.1,3.2
          c0,2.7-0.3,4.4-0.3,7.3c0,1,0.2,3.3,0.2,5.2c0,1.1,0,2-0.3,2.6c-0.6,1.1-1.6,1.7-2.5,2.4c-1.4,0.9-3.2,1.6-5,1.6
          c-1.4,0-2.6-0.3-3.6-1.2c-0.9-0.7-1.7-2.4-1.7-3.8c0-0.1,0-0.3,0-0.5c0-0.1,0-0.5,0-0.7c0-1.1-0.1-2.8-0.1-4c0-0.2,0-0.5,0-0.6
          c0-0.1,0-0.2,0-0.3c0-0.8-0.2-1.6-0.2-2.4c0-2,0.3-4.1,0.3-6c0-3.7-0.2-7.4-0.2-11c0-1.1-0.3-2-0.3-3.1c0-0.1,0-0.2,0-0.3
          c0.2-1.4,0.6-2.5,0.6-3.9c0-2.5-0.3-4.2-0.3-6.5c0-0.5,0-0.9,0-1.5c0.1-1.3,0.7-2.5,0.8-3.8c0.1-0.5,0.1-1,0.1-1.5
          c0-1-0.1-2-0.1-3c0-2.4,0.1-4.7,0.2-7c0.1-1.7,0.2-3.4,1.1-4.9c0.9-1.5,3.2-2.5,4.8-3.1c0.7-0.2,1.4-0.3,2-0.3
          c2.7,0,4.8,1.9,5.5,4.9c0.3,1.7,0.6,3.6,0.6,5.3c0,1.2-0.6,2.5-0.6,3.7c0,1,0.3,2,0.3,3.1c0,1.6-0.2,4.4-0.3,6.1
          c-0.1,0.9-0.3,2-0.3,3.1c0,0.8,0.2,1.6,0.7,2.4c1,1.7,4.2,1.7,6,1.9c0.6,0.1,1.1,0.1,1.7,0.1c0.6,0,1,0,1.7,0c0.8,0,1.6,0,2.4,0
          c0.3,0,0.8,0,1.1,0c0.7-0.1,1.5-0.1,2.3-0.1c0.8,0,1.5,0,2.3,0c1.5,0,2.7,0.2,4,0.2c0.7,0,1.2,0,1.9-0.2c1.7-0.5,1.9-2,1.9-3.6
          c0-0.7,0-1.5,0-2.2c0-0.3,0.1-0.7,0.1-1c0.2-1.8,0.8-5.2,0.9-7c0.2-2.4,0.3-4.9,0.3-7.3c0-1.2,0.1-2.5,0.3-3.7
          c0.7-3.5,4-6.8,7.6-6.8c0.5,0,0.8,0,1.2,0.1c4.7,1,5.5,5.7,5.5,10.1c0,1.9-0.1,3.9-0.2,5.3c-0.1,5.1-1.2,9.9-1.2,15.1
          c0,1.1-0.2,2.7-0.2,3.9c0,3.6-1,7.4-1.1,11c0,1.8,0,3.9,0,5.9c0,3.3-0.1,6.7-0.6,9.7c-0.2,1.2-0.6,2.5-0.8,3.9
          c-0.1,0.9-0.7,2.6-1.1,3.5c-1,2.2-4.3,4.5-6.8,4.5c-0.3,0-0.6,0-0.8-0.1C1111.3,139.9,1110.2,139.6,1109.4,139.3z"/>
      </g>
    </g>
    <g>
      <path class="st0" d="M678.7,74.1c1.8-0.1,3.6-0.1,5.7,0.1c1.8,0.2,3.7,1,4.2,2.9c0.1,0.4,0.2,0.7,0.2,1c0.1,0.8-0.1,1.3-0.2,2.2
        c-0.2,1.4-0.4,3-0.3,4.4c0,1-0.2,1.9-0.1,2.8c0,0.5-0.2,1.1-0.2,1.6c0.2,2,0.3,4.1,0.7,6.1c0.2,1.2,0.5,2.4,0.6,3.6
        c0,0.2,0,0.5,0.1,0.7c0,1.3-2,2.7-3.3,3c-0.2,0-0.5,0.1-0.6,0.1c-1.6,0.1-2.5-0.6-3.2-2.2c-0.2-0.6-0.3-1.1-0.4-1.7
        c0-0.2-0.1-0.5-0.3-0.5c-0.1,0-0.1,0-0.1,0c-0.3,0-0.6,0.4-0.8,0.6c-0.9,0.7-1.7,1.7-2.5,2.5c-1.2,1-3,1.5-4.6,1.8
        c-0.1,0-0.2,0-0.2,0c-1.7,0.1-3.8-0.8-5-1.9c-1.7-1.8-2.3-4.2-2.8-6.5c-0.2-0.7-0.3-1.5-0.4-2.2c-0.2-2,0-3.9,0.4-5.9
        c0.1-0.5,0.3-0.9,0.5-1.4c0.1-0.5,0.4-1,0.6-1.5c1.2-4,4.4-8.2,8.8-9C676.5,74.4,677.6,74.2,678.7,74.1z M675.6,81.5
        c-0.3,0.3-0.6,0.7-0.9,1.1c-1.8,2.3-2.1,4.2-2.2,7.1c0,0.2,0,0.5,0.1,0.8c0,0.6,0.1,1.3,0.1,1.7c0,0.1,0,0.2,0,0.3
        c0.1,0.7,0.2,1.4,0.5,2c0.1,0.5,0.9,1.1,1.6,1.1l0.1,0c0.5,0,1.1-0.4,1.6-0.7c1.8-1,2.4-1.8,3.7-3.3c0.6-0.6,0.8-1.6,1-2.3
        c0.3-1-0.1-2.3,0-3.4c0.1-1.4,0.4-2.7,0.3-4.1c0-0.2,0-0.4,0-0.6c0-0.4-0.1-0.6-0.4-0.8c-0.3-0.2-0.9-0.2-1.4-0.1
        c-0.2,0-0.4,0-0.5,0C678,80.4,676.4,80.8,675.6,81.5z"/>
      <path class="st0" d="M697.3,59.9c0.2,0,0.4,0,0.7,0c0.5,0,1,0.2,1.4,0.4c1.2,0.5,1.8,1.3,2.1,2.3c0.2,0.6,0.3,1.3,0.3,1.9
        c0.1,1.3,0,2.5-0.1,3.8c0,0.5-0.2,1.7-0.1,2.6c0,0.6,0.1,1.1,0.5,1.3c0.2,0.2,0.4,0.2,0.7,0.2c0.2,0,0.5-0.1,0.6-0.2
        c0.9-0.3,1.7-0.8,2.7-0.9c0.8-0.1,1.5-0.4,2.2-0.4c0.4,0,0.8,0,1.2,0.1c0.6,0.1,1.3,0.2,1.9,0.3c1.1,0.3,1.5,0.6,2.4,1.3
        c0.8,0.7,1.6,1.7,2,2.8c0.2,0.5,0.6,1,0.8,1.5c0.4,1.2,0.5,2.4,0.6,3.7c0,0.7,0.1,1.4,0,2.1c0,1.3,0.1,2.9-0.3,4.1
        c-0.4,1.1-0.2,2.4-0.8,3.4c-0.5,0.9-1,1.9-1.6,2.7c-1,1.4-2.2,2.6-3.6,3.7c-1.1,1-2.5,1.7-3.8,2.5c-1.3,0.7-2.7,1-4.1,1.4
        c-0.5,0.1-1.3,0.2-1.8,0.3c-0.6,0.2-1.3,0.3-2,0.5c-0.5,0.1-2.4,0.3-2.9,0.4c-0.1,0-0.3,0.1-0.5,0.1c-0.7,0.1-1.4-0.1-1.9-0.4
        c-0.6-0.4-0.9-0.9-1-1.7c-0.1-1.1,0.3-2.4,0.4-3.8c0.2-2.3,0.4-4.6,0.5-6.8c0-0.2,0-0.4,0-0.5c0-0.3,0-0.5-0.1-0.8
        c0-0.2,0-0.4,0-0.5c0-0.7,0.3-1.3,0.3-2.1c0-0.8-0.1-1.7-0.1-2.6c-0.1-0.8-0.1-1.7,0-2.6c0-1,0.5-1.9,0.6-2.8c0-0.1,0-0.2,0-0.2
        c0-0.3-0.1-0.6-0.1-0.9c0-0.1,0-0.3,0-0.4c0.1-2.3-0.1-4.6,0.3-6.9c0.2-1.9,0-3,0.1-5c0.1-1.5-0.4-3.2,1.5-3.8
        C696.5,60,696.9,59.9,697.3,59.9z M704.1,78.1c-0.4,0.3-1,0.6-1.4,1c-0.6,0.6-1.3,1.3-1.4,2.1c-0.3,1-0.2,2.5-0.2,3.5
        c0,0.4-0.1,0.8-0.1,1.1c-0.1,0.5-0.1,2.4,0,2.8c0,1.1,0,2.2-0.1,3.3c0,0.5,0,1.5,0,2.1c0,0.4,0.3,0.6,0.6,0.5c0.1,0,0.1,0,0.2,0
        c1.5-0.2,2.7-0.6,3.8-1.6c1.1-1,2.1-2,2.9-3.1c0.6-0.9,1.1-1.8,1.3-2.9c0.1-0.4,0.2-2.4,0.3-2.8c0-0.4-0.1-2.2-0.1-2.7
        c0-0.2,0-0.4,0-0.7c0-0.5-0.1-1-0.3-1.3c-0.2-0.5-0.4-1-0.7-1.3c-0.4-0.5-1.4-0.7-2.1-0.7c-0.2,0-0.4,0-0.5,0.1
        C705.5,77.7,704.7,77.8,704.1,78.1z"/>
      <path class="st0" d="M731.6,69.6c0.9-0.1,1.8,0,2.8,0.1c0.6,0.1,1.2,0,1.7,0.3c1.3,0.3,2.5,1.2,3.7,1.9c0.7,0.4,1.3,0.9,1.7,1.8
        c0.5,0.9,0.6,1.2,0.9,2c0.3,0.8,0.6,1.9,0.7,2.7c0.2,0.8,0.3,1.7,0.4,2.5c0.3,3.8-0.6,7.4-2.4,10.7c-1.7,3-3.7,5.4-7.1,6.3
        c-0.4,0.1-0.9,0.1-1.4,0.2c-1,0.2-2,0.4-3,0.5c-0.7,0.1-1.2,0-1.9,0c-0.6,0-1.1-0.4-1.7-0.5c-0.7-0.1-1.1-0.3-1.8-0.6
        c-2.6-1.7-3.7-5.4-4.2-8.1c-0.2-0.9-0.2-1.6-0.3-2.5c-0.1-1.4,0-3,0.2-4.4c0.4-2.4,0.9-4.7,2.2-6.8c0.7-1.1,1.9-2,3-2.8
        c1.7-1.4,3.3-2.5,5.4-3C730.9,69.7,731.2,69.7,731.6,69.6z M727.1,80.6c-0.3,0.9-0.3,1.7-0.4,2.7c-0.2,1.1-0.4,2-0.4,3.1
        c0,0.3,0,0.5,0.1,0.8c0.4,1.6,0.1,4.2,2.3,4.8c0.3,0.2,0.6,0.1,0.9,0.1c0.5,0,1.1-0.1,1.6-0.2c1.9-0.4,2.7-1.4,3.8-3
        c0.3-0.5,0.8-1.1,1.1-1.8c0.7-1.5,0.8-3.2,0.6-4.8c0-0.4-0.1-0.8-0.2-1.2c-0.2-1.8-0.7-4.2-2.7-4.9c-0.3,0-0.7-0.1-1,0
        c-0.4,0-0.7,0.1-0.9,0.1C729.8,77,727.9,78.5,727.1,80.6z"/>
      <path class="st0" d="M750.6,68.2c1.7-0.1,3.6,1.4,3.9,2.9c0.1,0.7,0.2,1.2,0.3,1.8c0,0.4,0.1,0.8,0,1.4c-0.1,1.4,0.1,2.8,0,4.3
        c-0.1,0.8-0.2,1.7-0.3,2.6c-0.1,1.1,0.1,2.3,0,3.4c-0.1,0.5,0,1,0,1.4c0,0.4,0.1,0.7,0.2,1.2c0.1,0.6,0.2,1.2,0.3,1.8
        c0.1,0.4,0.8,0.9,1.3,0.9c0.2,0,0.5,0,0.8,0c0.4,0,0.8-0.1,0.9-0.2c0.4-0.3,0.9-0.4,1.4-0.6c0.8-0.4,0.9-0.5,1.8-1.1
        c0.3-0.1,0.9-0.4,1.2-0.7c0.6-0.8,0.2-2.9,0.4-3.7c0.3-1.1,0.2-2.2,0.2-3.2c0-0.7,0.1-1.4,0.1-2.2c0.1-1.6,0.3-3,0.5-4.4
        c0.1-1.2,0.4-3,0.6-4.1c0.1-0.7,0.1-1.5,0.5-2.1c0.5-0.6,1.8-1,2.6-1.1c0.2,0,0.4,0,0.7-0.1c0.5,0,1.1-0.1,1.4,0.1
        c0.3,0.1,0.6,0.3,0.9,0.5c0.7,0.5,0.9,1.2,1,1.8c0.1,1.1-0.4,2.2-0.5,3.5c-0.2,1.1-0.5,2.9-0.6,4c-0.2,1.6-0.2,3.1-0.2,4.7
        c0,1.1-0.3,2.2-0.2,3.2c0,0.6-0.1,1.1,0,1.7c0.1,0.8,0,1.7,0.1,2.5c0.1,0.7,0.2,1.5,0.2,2.2c0.1,2,0.2,4.3-2.2,5
        c-0.3,0.1-0.7,0.2-1.1,0.2c-0.8,0.1-1.7,0-2.2-0.6c-0.5-0.6-1-2.1-1.9-2c-0.6,0-1.2,0.4-1.6,0.7c-1.1,0.6-2,1.3-3.1,1.9
        c-1,0.6-2.1,0.8-3.2,0.9c-0.5,0-1,0.1-1.5,0c-0.7-0.1-1.3-0.2-1.9-0.3c-1.2-0.3-2.1-1.4-2.8-2.5c-0.4-0.7-0.9-1.6-1.1-2.5
        c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.8,0.1-1.6,0-2.3c0-0.6-0.1-1.6-0.1-2.2c-0.1-0.7,0.1-1.4,0.2-2.2c0.1-2,0.4-4.1,0.6-6.1
        c0.1-1-0.1-2,0-3c0-0.2,0-0.6,0-0.9c-0.1-0.7-0.2-1.4-0.2-2.1c-0.1-1.2,0-2.4,1-3c0.4-0.3,1.1-0.4,1.6-0.6
        C750.3,68.3,750.5,68.3,750.6,68.2z"/>
      <path class="st0" d="M781.6,59c1.5-0.1,2.9,1,3.1,2.5c0,0.1,0,0.1,0,0.1c0,0.5-0.5,2.5-0.4,3.6c0,0.3,0.1,0.5,0.2,0.6
        c0.4,0.2,0.9,0.2,1.4,0.2c0.4,0,0.8-0.1,1.2-0.2c0.8-0.1,1.5-0.2,2.1-0.3c0.8-0.1,1.5,0.1,2.3,0.7c0.8,0.6,1.2,1.5,1.3,2.4
        c0.1,1.1-0.4,2.1-1.5,2.6c-1.1,0.4-2.1,0.6-3,0.7c-0.5,0-1,0-1.4,0c-0.5,0-1.1-0.2-1.6-0.1c-0.4,0-0.6,0.2-0.9,0.4
        c-0.6,0.5-0.5,1.7-0.4,2.4c0,0.4,0.1,0.9,0,1.3c-0.1,1.3-0.1,2.6-0.2,3.8c-0.1,1.1-0.1,2.3,0,3.5c0.1,0.9,0.1,1.7,0.3,2.6
        c0.1,0.5,0.4,1.1,0.6,1.6c0.3,0.6,1.2,0.9,1.9,0.8c0.2,0,0.4,0,0.5-0.1c0.6-0.2,1.1-0.4,1.7-0.7c0.7-0.4,1.3-0.6,1.7-0.7
        c0.7-0.1,1.1,0.3,1.5,1c0.3,0.5,0.4,0.9,0.5,1.5c0.1,1.4-0.7,2.9-1.5,3.7c-0.9,0.9-1.9,1.2-3.1,1.6c-0.5,0.2-0.9,0.3-1.4,0.3
        c-0.7,0.1-1.4,0-2.2-0.1c-1.7-0.2-3.5-0.5-4.8-1.7c-1.4-1.3-2.1-2.6-2.4-4.3c-0.1-0.4-0.1-1.1-0.2-1.7c0-0.6-0.1-1.2-0.1-1.7
        c0-0.1,0-0.3,0-0.4c-0.1-1.4,0-2.6,0-4c0-0.7-0.1-1.3,0-2c0.1-1.6,0.5-3.3,0.4-4.9c0-0.1,0-0.2,0-0.2c-0.1-1.1-0.6-1.1-1.2-1.1
        c-0.2,0-0.4,0-0.5,0c-0.2,0-0.4,0-0.6,0c-1.1,0-1.3-0.8-1.6-1.8c-0.1-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.4c0-0.4,0.1-0.7,0.2-1
        c0.4-1.1,0.8-1.4,1.8-1.5c0.1,0,0.2,0,0.3,0c0.3,0,0.5,0,0.8,0c0.3,0,0.6-0.1,0.9-0.4c0.4-0.4,0.4-1,0.4-1.5
        c0.1-1.1,0.1-4.1,1.1-4.9c0.5-0.3,2.4-1.2,3-1.3C781.4,59,781.5,59,781.6,59z"/>
      <path class="st0" d="M705,114.9c0.9-0.1,2,0.3,2.8,1c0.7,0.5,1.2,1.3,1.2,2.2c0,0.2,0,0.4-0.1,0.7c-0.2,0.8-0.7,1.5-1,2.3
        c-0.2,0.4-0.7,1.5-0.9,2c-0.2,0.4-0.5,0.8-0.6,1.2c-0.2,0.6-0.3,1.2-0.5,1.8c-0.3,0.9-0.7,1.7-1,2.6c-0.8,2.5-1.4,5.1-2.3,7.6
        c-0.7,2-0.9,4.4-1.8,6.3c-0.3,0.6-0.6,1.4-0.9,2c-0.5,0.9-0.8,1.7-1.3,2.6c-0.3,0.4-0.6,0.9-0.9,1.3c-0.3,0.6-0.6,0.9-0.9,1.4
        c-0.3,0.4-0.6,0.9-0.9,1.3c-0.5,0.7-1.1,1.3-1.8,2c-0.8,0.8-1.6,1.5-2.4,2.2c-0.6,0.5-1.2,0.9-1.9,1.3c-0.9,0.5-2,0.9-2.9,1.3
        c-1,0.4-1.9,0.7-3,0.9c-0.1,0-0.1,0-0.2,0c-1.8,0.1-3.7-1.4-3.8-3.2c0-0.4,0.1-0.8,0.2-1.2c0.5-1.2,2-2.1,3.1-2.5
        c0.6-0.2,1.2-0.2,1.7-0.6c0.8-0.5,1.9-1,2.6-1.6c1-0.8,1.9-1.3,2.7-2.3c0.6-0.8,1.8-2,1.7-3c0-0.1,0-0.2,0-0.2
        c-0.3-1-0.7-2.4-0.8-2.9c-0.2-0.7-0.5-2.3-1.1-3.4c-0.2-0.6-0.3-1.2-0.6-1.7c-0.2-0.5-0.2-0.9-0.3-1.3c-0.2-0.5-0.2-0.9-0.3-1.3
        c-0.4-1-0.9-1.9-1.2-2.9c-0.5-1.4-0.7-2.4-1.3-3.8c-0.2-0.7-0.4-1.3-0.6-1.9c-0.3-1-0.5-2-0.8-2.9c-0.2-0.5-0.3-0.9-0.3-1.5
        c-0.1-0.7,0-1.4,0.5-2.1c0.3-0.4,0.8-0.5,1.2-0.6c0.2-0.1,0.4-0.1,0.6-0.1c0.5,0,1.1,0,1.6,0.2c0.6,0.2,1.2,0.5,1.7,0.9
        c0.3,0.3,0.4,0.7,0.6,1.1c0.3,0.3,0.4,0.9,0.5,1.3c0.3,1,0.5,1.9,0.8,2.9c0.2,0.8,0.7,1.7,1,2.5c0.4,1.1,0.9,2.8,1.4,3.9
        c0.4,1,0.7,2.4,1.2,3.4c0.1,0.2,0.3,0.8,0.6,0.8c0.4,0,0.5-0.7,0.6-0.9c0.3-1.2,1-3,1.3-4.1c0.6-2,1.1-4.7,1.8-6.6
        c0.5-1.5,1.3-3.7,2-5.2c0.5-0.9,0.8-2.4,1.9-3C704.4,115,704.7,114.9,705,114.9z"/>
      <path class="st0" d="M732.8,121.8c0.5,1.3,0.6,2.7,0.9,3.6c0.1,0.3,0.1,0.6,0.1,0.9c0,0.5,0,1.1,0,1.7c0,1.3,0.1,2.6-0.3,3.8
        c-0.5,1.3-0.9,2.8-1.6,4.1c-0.6,1.2-1.5,2.4-2.2,3.6c-0.8,1.4-2.2,2.3-3.5,3.1c-0.9,0.5-1.8,1-2.7,1.3c-0.9,0.4-1.9,0.6-2.9,0.6
        c-2,0.2-4-0.3-5.8-1.1c-1.1-0.6-2.2-1.3-2.9-2.3c-0.5-0.4-0.7-0.5-1-1c-0.3-0.4-0.7-1-0.8-1.4c-0.1-0.3-0.3-0.7-0.4-1
        c-0.1-0.4-0.2-0.7-0.4-1.2c0-0.1,0-0.3,0-0.4c0-0.5,0-1.1-0.1-1.6c0-0.2,0-0.4,0-0.5c-0.2-2.2,0-4.5,0.4-6.7
        c0.5-2.1,1.2-4.1,2.1-6.1c0.7-1.8,2.1-3.1,3.5-4.3c0.5-0.5,1.2-0.9,2-1.4c0.5-0.2,1.1-0.2,1.7-0.4c1.3-0.3,2.4-0.7,3.6-0.8
        c0.4,0,0.8-0.1,1.3,0c1.1,0.2,2.3,0.4,3.4,0.9c0.7,0.4,1.4,1,2.1,1.4c0.3,0.3,0.7,0.4,1.1,0.8c0.5,0.6,0.3,0.5,1.1,1
        C732.2,119.1,732.3,120.6,732.8,121.8z M718.2,123.5c-0.5,1.1-0.9,1.9-1.1,3c-0.4,1.3-0.9,2.8-1,4.3c-0.1,0.6,0,1.3-0.1,1.9
        c0,0.1,0,0.2,0,0.2c0,0.5,0.2,0.8,0.3,1.3c0.2,1.2,0.8,2.6,2.1,2.8c0.5,0.1,1.1,0.2,1.6,0.1c0.1,0,0.1,0,0.2,0
        c0.6,0,1.3-0.4,1.9-0.6c1.3-0.7,1.5-1.1,2.3-2.3c0.6-1,1-2.2,1.5-3.3c0.4-0.8,0.5-1.5,0.5-2.4c0.1-0.4,0.1-1,0.1-1.4
        c0-0.1,0-0.2,0-0.3c0-0.6-0.1-1.2-0.2-1.9c-0.1-0.8-0.7-1.7-1.1-2.3c-0.4-0.4-0.8-0.6-1.3-0.8c-0.9-0.2-1.6-0.4-2.3-0.4
        c-0.5,0-0.9,0.2-1.5,0.4C719.3,122.3,718.6,122.7,718.2,123.5z"/>
      <path class="st0" d="M752.9,137.6c-0.5,0-0.9,1-1.5,1.5c-0.8,0.7-1.7,1.4-2.5,2c-1.1,0.8-2.1,0.9-3.3,1.4c-0.3,0.1-0.7,0.2-1,0.3
        c-0.7,0.1-1.3-0.2-2.3-0.2c-1.2,0-1.9-0.3-3-1.3c-0.9-0.9-1.4-2.3-1.8-3.4c-0.2-0.5-0.3-1-0.3-1.5c-0.1-1.3,0.1-2.7,0-4
        c-0.1-1.5,0.1-3,0.1-4.5c0-0.6,0.2-1.1,0.2-1.6c0.1-1,0.1-2,0.1-3c0-0.1,0-0.2,0-0.3c0-0.4-0.1-0.6-0.1-1c0-0.1,0-0.2,0-0.2
        c0-1.9,0-3.8,0.4-5.6c0.1-0.7-0.1-1.5,0.6-2.1c0.4-0.4,1-0.6,1.7-0.7c1.9-0.1,4.3,1,4.4,2.6c0,0.1,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2
        c0,0.4,0.2,0.6,0.2,0.9c0,0.1,0,0.2,0,0.4c-0.1,1-0.2,2.2-0.2,3.3c0,1.3,0,2.5-0.1,3.8c0,1-0.1,2-0.2,3c0,1-0.1,2,0,3.1
        c0,0.7,0,1.5,0.1,2.3c0,0.5,0.1,1,0.3,1.5c0.2,0.5,0.5,1.1,1.1,1.1c0.3,0,0.7,0.1,1,0c0.2,0,0.4-0.1,0.5-0.2
        c0.9-0.5,1.2-0.8,1.7-1.2c0.3-0.2,0.7-0.5,1-0.8c0.4-0.4,0.9-1.1,1.3-1.4c0.3-0.3,1.1-1,1.3-1.7c0.4-0.8,0.4-3.1,0.4-4.1
        c0.1-1.1,0.4-2.1,0.4-3.2c0-1.2,0.1-2.3,0.1-3.5c0.1-0.7,0.1-2.2,0-2.9c-0.1-1.7,0.3-3.2,2.1-4.4c0.3-0.2,0.7-0.3,1.1-0.3
        c1-0.1,2.2,0.3,2.8,1c0.7,0.7,0.8,1.8,0.9,2.7c0.1,1.2,0,3.2-0.1,4.4c-0.1,1.5,0.1,3-0.1,4.5c-0.1,1.7-0.3,3.5-0.2,5.2
        c0,1.4-0.2,2.8-0.1,4.2c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.5c0.1,0.8,0.1,1.4,0,2.3c0,0.8,0.2,1.7,0,2.6c-0.3,1-1.6,1.4-2.9,1.5
        c-0.9,0.1-1.8,0-2.4-0.4c-0.7-0.4-1-1-1.2-1.9c0-0.1,0-0.2,0-0.3c0-0.2,0-0.5-0.1-0.7C753.2,137.8,753.2,137.6,752.9,137.6
        L752.9,137.6z"/>
      <path class="st0" d="M769.9,110.2c1.1-0.1,2,0.6,2.4,1.8c0.1,0.3,0,0.8,0.5,0.9c0.1,0,0.1,0.1,0.2,0c0.7-0.1,1.4-0.8,1.9-1.1
        c1.1-0.6,1.7-1,2.9-1.1c0.2,0,0.4,0,0.7-0.1c0.6,0,1.2-0.1,1.7,0.1c1.2,0.3,2.2,1.3,2.3,2.4c0,0.5-0.1,0.9-0.4,1.4
        c-0.3,0.4-0.4,1-0.7,1.4c-0.4,0.5-0.8,0.6-1.3,0.6c-0.5,0-1,0-1.5,0c-0.2,0-0.5,0.1-0.7,0.2c-1,0.3-2,0.7-2.8,1.4
        c-0.5,0.3-0.7,0.7-1.1,1.2c-0.5,0.6-0.5,0.8-1,1.4c-0.3,0.4-0.4,0.8-0.6,1.2c-0.2,0.4-0.2,1-0.2,1.6c0,0.5,0.1,1.1,0.2,1.6
        c0,0.3,0,0.5,0,0.7c-0.1,1.3,0,2.6-0.2,3.8c0,0.2,0,0.5,0,0.7c0,0.2,0,0.5,0.1,0.7c0,0.1,0,0.2,0,0.2c0,0.5,0.2,0.8,0.2,1.3
        c0,0.1,0,0.1,0,0.2c0,1.1,0.1,2,0.1,3.1c0,0.5,0.3,1.2,0.3,1.8c0,0.1,0,0.3,0,0.4c-0.2,1.4-2.4,3-3.9,3.2c-0.1,0-0.1,0-0.2,0
        c-0.5,0-1-0.2-1.4-0.4c-1.5-0.8-1.9-2.3-2-3.9c-0.1-0.9-0.1-1.8-0.1-2.7c0-0.4-0.1-0.8-0.1-1.2c-0.1-0.8-0.1-1.6,0-2.4
        c0.1-0.8,0-1.6,0-2.4c0.1-0.8,0.1-1.7,0.2-2.6c0.1-2.4,0-4.3,0.1-6.7c0.1-1.1,0.1-2.3,0.3-3.4c0-0.5,0.3-0.9,0.4-1.5
        c0.1-0.8,0.3-1.3,0.7-2.1c0.4-1.1,1.8-1.4,2.7-1.6C769.7,110.2,769.8,110.2,769.9,110.2z"/>
    </g>
  </g>
</g>
</svg>
  </div>
</template>

<script>
export default {
  name: 'logo',
  data () {
    return {}
  }
}
</script>

<style type="text/css" scoped>
  svg {
    width:16rem;
  }
  .st0{fill:#231F20;}
  .st1{fill:#FFFFFF;}
  .st2{fill:none;}
  .st3{fill:#DCDDDE;}
  @media (max-width: 767px) {
    svg {
      width:16rem;
    }
  }
</style>
