<template>
  <div>
    <div
      class="contentBoardQuestion board mt-2"
    >
      <span v-for="(element, index) in question" :key="index">
        <span v-if="element === '<blank>'">
          <draggable class="board-draggable" v-model="cards[index]" :group="'words-'+dragIndex.toString()" @change="onMove($event, index)"  :options="{disabled : disable}">
            <DragCard
              v-for="(card, index1) in cards[index]"
              :key="index1"
              class="card"
              :id="card.id"
              :title="card.title"
              :correct="card.correct"
              :explanationCorrect="card.explanationCorrect"
              :explanationIncorrect="card.explanationIncorrect"
              :paint="paint"
            />
          </draggable>
          <span v-if="!cards[index].length"> ____________ </span>
        </span>
        <span v-else>
        {{element}}
        </span>
      </span>
      <template v-if="paint!='ignore'">
        <p
          v-for="(number, index) in blankIndexes"
          :key="'exp-'+(index+1).toString()"
        >
        <b-alert
          class="my-4"
          :variant="cards[number][0].index === (index + 1).toString() ? 'success' : 'danger'"
          show>
          <h4 v-if="cards[number][0].index === (index + 1).toString()" class="font-weight-bold text-uppercase">Correct!</h4>
          <h4 v-else class="font-weight-bold text-uppercase">Incorrect!</h4>
          <div class='mb-2'>
            <p>
              <template v-if="cards[number][0].index === (index + 1).toString()">
                <font-awesome-icon :icon="['far', 'smile']"></font-awesome-icon>
                {{cards[number][0].explanationCorrect}}
              </template>
              <template v-else>
                {{cards[number][0].explanationIncorrect}}
              </template>
            </p>
          </div>
        </b-alert>
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import DragCard from '@/components/customObjects/DragCard'
import draggable from 'vuedraggable'
export default {
  name: 'BoardQuestions',
  props: ['id', 'question', 'dragIndex', 'answerCards'],
  data () {
    return {
      emit: true,
      paint: 'ignore',
      cards: [],
      blanks: 0,
      blanksFilled: -1,
      blankIndexes: [],
      disable: false
    }
  },
  mounted: function () {
    this.beginning()
  },
  methods: {
    beginning () {
      this.blanks = 0
      this.blankIndexes = []
      for (let i = 0; i < this.question.length; i++) {
        if (this.question[i] === '<blank>') {
          this.blanks += 1
          this.blankIndexes.push(i)
        }
      }
      if (this.blanks) {
        this.blanksFilled = 0
      }
      this.checkCards()
    },
    checkPaint () {
      if (this.blanksFilled === this.blanks) {
        for (let i = 0; i < this.blankIndexes.length; i++) {
          if (this.cards[this.blankIndexes[i]].length && this.cards[this.blankIndexes[i]][0].index === (i + 1).toString()) {
            continue
          } else {
            this.paint = 'no'
            this.disable = true
            this.$emit('disableDraggable')
            if (this.emit) {
              this.$emit('newCard', this.blankIndexes.map((index) => { return this.cards[index][0] }))
              this.emit = false
            }
            return
          }
        }
        if (this.emit) {
          this.$emit('newCard', this.blankIndexes.map((index) => { return this.cards[index][0] }))
          this.emit = false
        }
        this.paint = 'yes'
        this.disable = true
        this.$emit('disableDraggable')
      } else {
        this.paint = 'ignore'
        this.emit = true
      }
    },
    onMove (evt, index) {
      if (evt.added) {
        this.blanksFilled += 1
        if ((this.cards[index].length - 1) > 0) {
          const newIndex = { 0: 1, 1: 0 }
          this.$emit('restoreCard', this.cards[index][newIndex[evt.added.newIndex]])
          this.cards[index] = [this.cards[index][evt.added.newIndex]]
          this.cards.push([])
          this.blanksFilled -= 1
        }
        this.checkPaint()
      }
      if (evt.removed) {
        this.blanksFilled -= 1
        this.checkPaint()
      }
    },
    checkCards () {
      this.cards = this.question.map(_ => { return [] })
      this.blanksFilled = 0
      if (this.answerCards) {
        for (let i = 0; i < this.answerCards.length; i++) {
          this.cards[this.blankIndexes[i]].push(this.answerCards[i])
          this.blanksFilled += 1
        }
        this.checkPaint()
      }
    }
  },
  watch: {
    answerCards (newCard) {
      this.checkCards()
    },
    question (newQuestion) {
      this.beginning()
    }
  },
  components: {
    DragCard,
    draggable
  }
}
</script>

<style lang="scss" scoped>
  .card {
    display: inline-block;
  }

  .board-draggable {
      display: inline;
  }
</style>
