<template>
  <div>
    <b-table
      v-if="glossary.length"
      striped
      responsive
      no-border-collapse
      hover
      :sticky-header="'77vh'"
      :items="glossary"
      :fields="fields"
    >
      <template #cell(key_term)="data">
        <a :id="getHash(data.item.key_term)"></a>
        {{ data.item.key_term }}
      </template>
      <template #cell(short_definition)="data">
        <template v-if="data.item.related_to">
          <b-link :to="{name: 'glossary', hash: '#' + data.item.related_to}">{{data.item.short_definition}}</b-link>
        </template>
        <template v-else>
          {{data.item.short_definition}}
        </template>
      </template>
      <template #cell(explanation)="data">
        <span v-html="data.item.explanation"></span>
      </template>
    </b-table>
  </div>
</template>

<script>
import replaceSpecialChars from 'replace-special-characters'

export default {
  name: 'Glossary',
  props: ['glossary'],
  data () {
    return {
      fields: [
        { key: 'key_term', label: 'Key term' },
        { key: 'short_definition', label: 'Short definition' },
        { key: 'synonyms', label: 'Synonyms' },
        { key: 'research_terms', label: 'Research terms' },
        { key: 'explanation', label: 'Explanation' },
        { key: 'examples', label: 'Examples' }]
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.$emit('glossaryScrollTo')
    })
  },
  methods: {
    getHash: function (txt = '') {
      if (txt.length < 1) {
        return ''
      }
      return replaceSpecialChars(txt.replace(/\s+/g, '-'))
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
