<template>
  <div class="initial">
   <p class="font-weight-bold">{{word}}</p>
   <p>{{getGlossaryDefinition(word)}}</p>
   <a>See More</a>
  </div>
</template>

<script>
export default {
  name: 'KeyTerm',
  props: ['word', 'glossary'],
  data () {
    return {
      ignore: false
    }
  },
  mounted: function () {
  },
  methods: {
    getGlossaryDefinition (word) {
      for (let i = 0; i < this.glossary.length; i++) {
        if (this.glossary[i].word.toLowerCase() === word.toLowerCase()) {
          return this.glossary[i].meaning[1]
        }
      }
      return ''
    }
  },
  computed: {
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
  .hey {
    min-height: 10%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 1%;
    display: block;
  }

  .text {
    margin: 0 auto;
  }

  .initial {
    position: relative;
  }

  .img {
    display: block;
    width:100%;
  }

  .dialog {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
