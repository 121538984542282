import jwt from 'jsonwebtoken'
import axios from 'axios'
export default {
  methods: {
    async createJwt () {
      const secret = process.env.VUE_APP_SA_PRIVATE_KEY
      const date = new Date()
      const seconds = Math.floor(date.getTime() / 1000)
      const expSeconds = seconds + 60 * 60
      const payload = {
        iss: process.env.VUE_APP_SA_EMAIL,
        scope: 'https://www.googleapis.com/auth/drive',
        aud: 'https://oauth2.googleapis.com/token',
        exp: expSeconds,
        iat: seconds
      }
      const token = jwt.sign(payload, secret, { algorithm: 'RS256' })
      const oAuthPayload = {
        grant_type: 'urn:ietf:params:oauth:grant-type:jwt-bearer',
        assertion: token
      }
      await axios.post('https://oauth2.googleapis.com/token', oAuthPayload)
        .then((res) => { localStorage.setItem('gapi.access_token', res.data.access_token) })
        .catch((err) => { console.log(err) })
      return token
    }
  }
}
