<template>
  <div class="contentTextArea">
    <b-form-group>
      <b-form-textarea
        id="textarea-large"
        v-model="selected"
        size="lg"
        placeholder="Please, enter your answer"
        @change="writeAnswer"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Textarea',
  props: {
    id: String,
    name: String,
    spreadname: String,
    userId: String,
    question: String,
    login: Boolean
  },
  mounted: async function () {
    await this.initialParseTextArea()
  },
  data: function () {
    return {
      questions: [],
      explanations: [],
      value: [],
      document: {},
      selected: null,
      answerIndex: null,
      answerSheetIndex: -1
    }
  },
  methods: {
    async initialParseTextArea () {
      const config = {
      }
      if (!window.navigator.onLine) {
        config.timeout = 2
      }
      const data = {
        type_of_question: 'TextArea',
        spreadname: this.spreadname
      }
      await axios.post(process.env.VUE_APP_BACK_URL + 'lesson/' + this.id + '/answer/', data, config).then(async response => {
        this.document = response.data
        await this.$store.commit('addQuestion', [process.env.VUE_APP_BACK_URL + 'lesson/' + this.id + '/answer/', response.data])
        await this.$store.commit('deleteOfflineAnswer', this.id)
        await this.parseTextArea()
      }
      ).catch(async error => {
        console.log(error)
        this.document = await this.$store.state.questions[process.env.VUE_APP_BACK_URL + 'lesson/' + this.id + '/answer/']
        if (this.document) {
          await this.parseTextArea()
        }
      })
    },
    async parseTextArea () {
      for (let k = 0; k < this.document.sheets.length; k++) {
        if (this.document.sheets[k].properties.title === this.spreadname + '-Answers') {
          this.answerSheetIndex = k
        }
      }
      if (window.navigator.onLine) {
        await this.createSheetIfItNotExists()
      }
      await this.getAnswer()
    },
    async writeAnswer (input) {
      if (this.name || this.login) {
        const data = {
          spreadname: this.spreadname,
          answersIndex: this.answerSheetIndex,
          answer: input,
          name: this.name,
          userId: this.userId,
          question: this.question
        }
        let config = {}
        if (this.login) {
          config = {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token')
            }
          }
        }
        await this.$store.commit('addOfflineAnswer', [this.id, this.spreadname, this.name, this.userId, input])
        axios.patch(process.env.VUE_APP_BACK_URL + 'lesson/' + this.id + '/answer/', data, config)
        // })
        //   .catch((error) => { console.log(error) })
      }
    },
    async getAnswer () {
      const offlineAnswers = await this.$store.state.offlineAnswers
      if (this.name && this.userId && this.document.sheets[this.answerSheetIndex].data[0].rowData && this.answerSheetIndex > -1) {
        for (let k = 0; k < this.document.sheets[this.answerSheetIndex].data[0].rowData.length; k++) {
          if (Object.prototype.hasOwnProperty.call(this.document.sheets[this.answerSheetIndex].data[0].rowData[k], 'values')) {
            if (this.document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === this.name &&
            this.document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === this.userId) {
              this.selected = this.document.sheets[this.answerSheetIndex].data[0].rowData[k].values[2].formattedValue
              this.answerIndex = this.questions.indexOf(this.selected)
              if (this.answerIndex === -1) {
                this.answerIndex = null
              }
              break
            } else if (Object.prototype.hasOwnProperty.call(offlineAnswers, this.id) &&
                Object.prototype.hasOwnProperty.call(offlineAnswers[this.id], this.spreadname) &&
                Object.prototype.hasOwnProperty.call(offlineAnswers[this.id][this.spreadname], this.name) &&
                Object.prototype.hasOwnProperty.call(offlineAnswers[this.id][this.spreadname][this.name], this.userId)) {
              this.selected = offlineAnswers[this.id][this.spreadname][this.name][this.userId]
              this.answerIndex = this.questions.indexOf(this.selected)
              if (this.answerIndex === -1) {
                this.answerIndex = null
              }
              break
            }
          }
        }
      }
    },
    async createSheetIfItNotExists () {
      let createSheets = false
      const prop = {
        requests: [
        ]
      }
      if (this.answerSheetIndex < 0) {
        const request = {
          addSheet: {
            properties: {
              title: this.spreadname + '-Answers',
              gridProperties: {
                rowCount: 200,
                columnCount: 12
              }
            }
          }
        }
        prop.requests.push(request)
        createSheets = true
      }
      if (createSheets) {
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
        }
        await axios.post('https://sheets.googleapis.com/v4/spreadsheets/' + this.spreadsheet + ':batchUpdate', prop, config)
          .then(async (_) => {
            await axios.get('https://sheets.googleapis.com/v4/spreadsheets/' + this.spreadsheet + '?includeGridData=true', config).then(async response => {
              this.document = response.data
              for (let k = 0; k < this.document.sheets.length; k++) {
                if (this.document.sheets[k].properties.title === this.spreadname + '-Answers') {
                  this.answerSheetIndex = k
                  return
                }
              }
            })
          })
      }
    }
  },
  watch: {
    name: async function () {
      if (this.document && Object.prototype.hasOwnProperty.call(this.document, 'sheets')) {
        await this.getAnswer()
      }
    },
    userId: async function () {
      if (this.document && Object.prototype.hasOwnProperty.call(this.document, 'sheets')) {
        await this.getAnswer()
      }
    }
  }
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  #fluidMainLessonInfo {
    background-color: #ffe96f;
  }
  .HEADING_1 {
    padding: 2rem 0;
    display: block;
  }
  .HEADING_2 {
    padding: 1rem 0;
    display: block;
  }
  .HEADING_3 {
    padding: 0.5rem 0;
    display: block;
  }
  .contentLink {
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;
  }
  // h3 {
  //   margin: 40px 0 0;
  // }

  // ul {
  //   list-style-type: none;
  //   padding: 0;
  // }

  // li {
  //   display: inline-block;
  //   margin: 0 10px;
  // }

  // a {
  //   color: #42b983;
  // }

  // .page-break {
  //   page-break-after: always;
  // }

  // .decimal {
  //   counter-increment: section;
  //   content: counter(section)". ";
  // }

  // table,
  // th {
  //   border: 1px solid black;
  // }

</style>
