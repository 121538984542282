<template>
  <div class="contentCollapsable" :class="visible ? 'opened' : 'closed'">
    <template v-if="l_esson.content.length">
      <div v-for="(content, index) in l_esson.content" :key="index" :class="{'d-none':  content && content.type === 'ONLYINTERNALLINK'}">
        <template v-if="content && content.customObject > customObject">
          <div v-for="(elem, index1) in content.content" :key="index1">
            <template v-if="elem && elem.type=='STARTCUSTOM'">
            <template v-if="customObjects[content.customObject].type=='content'">
              <b-collapse v-model="visible" class="d-print-block">
                <regular-text
                  :document="document"
                  :lists="lists"
                  :headingParStyles="headingParStyles"
                  :headingTextStyles="headingTextStyles"
                  :customObjects="customObjects"
                  :listsCounter="listsCounter"
                  :l_esson="customObjects[content.customObject]"
                  :customObject="content.customObject"
                  :glossary="glossary"
                  :glossaryUrls="glossaryUrls"
                  :lessonId="lessonId"
                  :id="id"
                  :name="name"
                  :answerLink="answerLink"
                  :userId="userId"
                  :getImage="getImage"
                  :nameSectionMenu="nameSectionMenu"
                  :login="login"
                  @changeSection="changeSection"
                  @movingto="movingto"
                  @listCounterUpdate="listsIndex"
                  @ready="updateDocLoaded"
                />
              </b-collapse>
            </template>
            </template>
          </div>
        </template>
        <template v-else>
          <template v-if="content && content.type === 'HEADING_2'">
            <div @click="visible = !visible" class="heading-link">
              <template v-if="content.listId">
                {{listsIndex(content.listId, content.nestingLevel)}}
              </template>
              <div v-for="(elem, index) in content.content" :key="index">
                <template v-if="elem.type === 'BR'">
                  <br/>
                </template>
                <template v-else>
                  <h2>
                    <font-awesome-icon v-if="elem.content.length && elem.content!='\n'" :icon="visible ? 'minus-square' : 'plus-square'" class="branch h2 d-print-none" :class="{open: visible}"/>
                    <paragraphContent
                      :content="content"
                      :getImage="getImage"
                      :nameSectionMenu="nameSectionMenu"
                      :headingParStyles="headingParStyles"
                      :headingTextStyles="headingTextStyles"
                      :glossary="glossary"
                      :glossaryUrls="glossaryUrls"
                      @changeSection="changeSection"
                      @movingto="movingto"
                    />
                  </h2>
                </template>
              </div>
            </div>
          </template>
          <template v-if="content && content.type === 'HEADING_3'">
            <div class="heading-link">
              <h3 @click="visible = !visible">
                <template v-if="content.listId">
                  {{listsIndex(content.listId, content.nestingLevel)}}
                </template>
                <span v-for="(elem, index) in content.content" :key="index">
                  <template v-if="elem.type === 'BR'">
                    <br/>
                  </template>
                  <template v-else>
                    <font-awesome-icon v-if="elem.content.length && elem.content!='\n'" :icon="visible ? 'minus-square' : 'plus-square'" class="branch h3 d-print-none" :class="{open: visible}"/>
                    <paragraphContent
                        :content="content"
                        :getImage="getImage"
                        :nameSectionMenu="nameSectionMenu"
                        :headingParStyles="headingParStyles"
                        :headingTextStyles="headingTextStyles"
                        :glossary="glossary"
                        :glossaryUrls="glossaryUrls"
                        @changeSection="changeSection"
                        @movingto="movingto"
                      />
                  </template>
                </span>
              </h3>
            </div>
          </template>
          <template v-if="content && content.type === 'HEADING_4'">
            <div class="heading-link">
              <h4 @click="visible = !visible">
                <template v-if="content.listId">
                  {{listsIndex(content.listId, content.nestingLevel)}}
                </template>
                <span v-for="(elem, index) in content.content" :key="index">
                  <template v-if="elem.type === 'BR'">
                    <br/>
                  </template>

                  <template v-else-if="elem.type === 'fontawesome'">
                    <template v-if="['clock', 'smile'].includes(elem.content)">
                      <font-awesome-icon :icon="['far', elem.content]"/>
                    </template>
                    <template v-else>
                      <template v-if="elem.content === 'pen'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <path id="pencil" d="M18.218.219a.75.75,0,0,1,1.062,0l4.5,4.5a.75.75,0,0,1,0,1.062l-15,15a.75.75,0,0,1-.252.165l-7.5,3a.75.75,0,0,1-.975-.975l3-7.5a.75.75,0,0,1,.165-.252l15-15ZM16.81,3.75,20.249,7.19,22.188,5.25,18.749,1.811Zm2.379,4.5L15.749,4.811,6,14.56V15h.75a.75.75,0,0,1,.75.75v.75h.75a.75.75,0,0,1,.75.75V18h.439ZM4.548,16.012l-.159.159L2.1,21.9l5.731-2.292.159-.159a.75.75,0,0,1-.487-.7V18H6.75A.75.75,0,0,1,6,17.25V16.5H5.25a.75.75,0,0,1-.7-.487Z" transform="translate(0 0.001)" fill="#ab005c"/>
                        </svg>
                      </template>
                      <template v-else>
                        <font-awesome-icon :icon="elem.content"/>
                      </template>
                    </template>
                  </template>

                  <template v-else>
                    <font-awesome-icon v-if="elem.content.length && elem.content!='\n'" :icon="visible ? 'minus-square' : 'plus-square'" class="branch h4 d-print-none" :class="{open: visible}"/>
                    <paragraphContent
                        :content="content"
                        :getImage="getImage"
                        :nameSectionMenu="nameSectionMenu"
                        :headingParStyles="headingParStyles"
                        :headingTextStyles="headingTextStyles"
                        :glossary="glossary"
                        :glossaryUrls="glossaryUrls"
                        @changeSection="changeSection"
                        @movingto="movingto"
                      />
                  </template>
                </span>
              </h4>
            </div>
          </template>
          <template v-if="content && content.type === 'HEADING_5'">
            <h5 @click="visible = !visible" :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType, content.content[0].paragraphStyle, content.content[0].textStyle, true)">
              <template v-if="content.listId">
                {{listsIndex(content.listId, content.nestingLevel)}}
              </template>
              <div v-for="(elem, index) in content.content" :key="index" >
                <template v-if="elem.type === 'BR'">
                  <br/>
                </template>
                <template v-else>
                  <font-awesome-icon v-if="elem.content.length && elem.content!='\n'" :icon="'caret-right'" class="branch h5" :class="{open: visible}" />
                  <paragraphContent
                      :content="content"
                      :getImage="getImage"
                      :nameSectionMenu="nameSectionMenu"
                      :headingParStyles="headingParStyles"
                      :headingTextStyles="headingTextStyles"
                      :glossary="glossary"
                      :glossaryUrls="glossaryUrls"
                      @changeSection="changeSection"
                      @movingto="movingto"
                    />
                </template>
              </div>
            </h5>
          </template>
          <template v-if="content && content.type === 'HEADING_6'">
            <h6 @click="visible = !visible" :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType, content.content[0].paragraphStyle, content.content[0].textStyle, true)">
              <template v-if="content.listId">
                {{listsIndex(content.listId, content.nestingLevel)}}
              </template>
              <span v-for="(elem, index) in content.content" :key="index" >
                <template v-if="elem.type === 'BR'">
                  <br/>
                </template>
                <template v-else>
                  <font-awesome-icon v-if="elem.content.length && elem.content!='\n'" :icon="'caret-right'" class="branch h6" :class="{open: visible}" />
                  <paragraphContent
                      :content="content"
                      :getImage="getImage"
                      :nameSectionMenu="nameSectionMenu"
                      :headingParStyles="headingParStyles"
                      :headingTextStyles="headingTextStyles"
                      :glossary="glossary"
                      :glossaryUrls="glossaryUrls"
                      @changeSection="changeSection"
                      @movingto="movingto"
                    />
                </template>
              </span>
            </h6>
          </template>
          <template v-if="content && content.type === 'NORMAL_TEXT'">
            <p @click="visible = !visible"
              :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType, content.content[0].paragraphStyle, {}, true)"
              class="normal-txt collapsable-view">
              <template v-if="content.listId">
                <template v-if="formatGlyph(content.listId, content.nestingLevel, 1) === '★'">
                  <font-awesome-icon :icon="['far', 'star']"></font-awesome-icon>
                </template>
                <template v-else>
                  <span
                    :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType,
                    {}, content.bulletStyle, false)"
                    class="pre-item"
                    :class="{'circle': formatGlyph(content.listId, content.nestingLevel, 1) === '●', 'circle-ii': formatGlyph(content.listId, content.nestingLevel, 1) === '○', 'star': formatGlyph(content.listId, content.nestingLevel, 1) === '★'}">{{listsIndex(content.listId, content.nestingLevel)}}</span>
                </template>
              </template>
              <span
                v-for="(elem, index) in content.content"
                :style="applyHeadingStyle(elem.paragraphStyle.namedStyleType, {}, elem.textStyle, false)"
                :key="index"
                class="p-content"
                :class="(elem.type==='fontawesome' && elem.content==='pen')?'fa-span':''">
                <paragraphContent
                      :content="content"
                      :getImage="getImage"
                      :nameSectionMenu="nameSectionMenu"
                      :headingParStyles="headingParStyles"
                      :headingTextStyles="headingTextStyles"
                      :glossary="glossary"
                      :glossaryUrls="glossaryUrls"
                      @changeSection="changeSection"
                      @movingto="movingto"
                    />
              </span>
            </p>
          </template>
          <template v-if="content && content.type === 'LIST'">
            <ul @click="visible = !visible"
              :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType, content.content[0].paragraphStyle, {}, true, content.listId)"
              class="normal-txt collapsable-view">
              <li>
              <template v-if="content.listId">
                <template v-if="formatGlyph(content.listId, content.nestingLevel, 1) === '★'">
                  <font-awesome-icon :icon="['far', 'star']"></font-awesome-icon>
                </template>
                <template v-else>
                  <span
                    :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType,
                    {}, content.bulletStyle, false)"
                    class="pre-item"
                    :class="{'circle': formatGlyph(content.listId, content.nestingLevel, 1) === '●', 'circle-ii': formatGlyph(content.listId, content.nestingLevel, 1) === '○', 'star': formatGlyph(content.listId, content.nestingLevel, 1) === '★'}">{{listsIndex(content.listId, content.nestingLevel)}}</span>
                </template>
              </template>
              <paragraphContent
                      :content="content"
                      :getImage="getImage"
                      :nameSectionMenu="nameSectionMenu"
                      :headingParStyles="headingParStyles"
                      :headingTextStyles="headingTextStyles"
                      :glossary="glossary"
                      :glossaryUrls="glossaryUrls"
                      @changeSection="changeSection"
                      @movingto="movingto"
                    />
              </li>
            </ul>
          </template>
          <template v-if="content && content.type === 'BR'">
            <br/>
          </template>
          <template v-if="content && content.type === 'TABLE'">
            <b-table-simple responsive bordered>
              <b-tr v-for="(trItem, trIndex) in content.content.tableRows" :key="trIndex">
                <b-td v-for="(tdItem, tdIndex) in trItem.tableCells" :key="tdIndex">
                  <div v-for="(cont, contIndex) in tdItem.content" :key="contIndex">
                  <template>
                    <div v-if="cont.listId"
                    :style="applyHeadingStyle(cont.content[0].paragraphStyle.namedStyleType,
                    {}, cont.bulletStyle, false)">
                    {{listsIndex(cont.listId, cont.nestingLevel)}}
                  </div>
                    <div
                      v-for="(elem, index) in cont.content"
                      :style="applyHeadingStyle(elem.paragraphStyle.namedStyleType, {}, elem.textStyle, false)"
                      :key="index">
                      <template v-if="elem.type === 'BR'">
                        <br/>
                      </template>
                      <template v-else-if="elem.type === 'IMAGE'">
                        <async-image v-if="elem.content.inlineObjectId" :imageId="elem.content.inlineObjectId" :getImage="getImage"></async-image>
                      </template>
                      <template v-else-if="elem.isLink">
                        <a :href="elem.textStyle.link.url" target="_blank" class="new-tab-link">{{elem.content}} <font-awesome-icon :icon="['fas', 'external-link-alt']"></font-awesome-icon></a>
                      </template>
                      <template v-else>
                        <div>{{elem.content}}</div>
                      </template>
                    </div>
                  </template>
                  </div>
                </b-td>
              </b-tr>
            </b-table-simple>
          </template>
        </template>
      </div>
      <template v-if="visible && this.l_esson.componentOptions === ''">
        <div class="only-lesson mt-3 mt-md-5">
          <b-button variant="outline-primary" class="d-print-none close-collapsable-item" @click="visible = !visible">Close</b-button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import RegularText from '@/components/customObjects/RegularText'
import paragraphContent from '@/components/lessonSubElements/paragraphContent'
import AsyncImage from '@/components/customObjects/AsyncImage'
export default {
  name: 'Collapsable',
  props: {
    document: Object,
    lists: Object,
    headingParStyles: Object,
    headingTextStyles: Object,
    customObjects: Array,
    listsCounter: Object,
    l_esson: Object,
    customObject: Number,
    glossary: Array,
    glossaryUrls: Array,
    lessonId: String,
    name: String,
    answerLink: String,
    id: String,
    userId: String,
    type: String,
    getImage: Function,
    nameSectionMenu: String,
    login: Boolean
  },
  data: function () {
    return {
      visible: !!(this.l_esson.componentOptions === 'opened')
    }
  },
  methods: {
    dynamicStyle (redComp = null,
      greenComp = null,
      blueComp = null,
      bold = false,
      size = null,
      italic = false,
      underline = false,
      strikethrough = false,
      bgRedComp = null,
      bgGreenComp = null,
      bgBlueComp = null) {
      let newRed = 0
      let newGreen = 0
      let newBlue = 0
      let newBgRed = 0
      let newBgGreen = 0
      let newBgBlue = 0
      let color = ''
      let backgroundColor = ''
      if (redComp === null && greenComp === null && blueComp === null) {
        color = 'inherit'
      } else {
        newRed = redComp * 255
        newGreen = greenComp * 255
        newBlue = blueComp * 255
        color = `rgb(${newRed}, ${newGreen}, ${newBlue})`
      }
      if (bgRedComp === null && bgGreenComp === null && bgBlueComp === null) {
        backgroundColor = 'inherit'
      } else {
        newBgRed = bgRedComp * 255
        newBgGreen = bgGreenComp * 255
        newBgBlue = bgBlueComp * 255
        backgroundColor = `rgb(${newBgRed}, ${newBgGreen}, ${newBgBlue})`
      }
      let weight = 'inherit'
      let style = 'inherit'
      let decoration = 'inherit'
      if (bold) {
        weight = 'bold'
      }
      if (italic) {
        style = 'italic'
      }
      if (underline || strikethrough) {
        decoration = ''
        if (underline) {
          decoration = decoration + 'underline '
        }
        if (strikethrough) {
          decoration = decoration + 'line-through '
        }
      }
      let fontsize = 'inherit'
      if (size) {
        fontsize = `${2 * size}pt`
      }
      const dinStyle = {
        color,
        'font-weight': weight,
        'font-size': fontsize,
        'font-style': style,
        'white-space': 'pre-line',
        'text-decoration': decoration,
        'background-color': backgroundColor
      }
      return dinStyle
    },
    paragraphDynamicStyle (indent = 'inherit', alignment = 'inherit', spaceAbove = 'inherit', spaceBelow = 'inherit') {
      if (alignment === 'CENTER') {
        alignment = 'center'
      } else if (alignment === 'JUSTIFIED') {
        alignment = 'justify'
      } else if (alignment === 'END') {
        alignment = 'right'
      }
      let marginTop = spaceAbove.magnitude ? spaceAbove.magnitude : 'inherit'
      if (marginTop !== 'inherit') {
        marginTop = `${marginTop}pt`
      }
      let marginBelow = spaceBelow.magnitude ? spaceBelow.magnitude : 'inherit'
      if (marginBelow !== 'inherit') {
        marginBelow = `${marginBelow}pt`
      }
      if (indent !== 'inherit') {
        indent = `${indent}pt`
      }
      return {
        'padding-left': indent,
        'text-align': alignment,
        'margin-top': marginTop,
        'margin-bottom': marginBelow
      }
    },
    tableCellStyle (paddingLeft = 0, paddingRight = 0, paddingTop = 0, paddingBottom = 0, minRowHeight = 0) {
      return {
        'padding-left': `${paddingLeft}pt`,
        'padding-right': `${paddingRight}pt`,
        'padding-bottom': `${paddingBottom}pt`,
        'padding-top': `${paddingTop}pt`,
        'min-height': `${minRowHeight}pt`
      }
    },
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
      return this.formatGlyph(listId, nestingLevel, this.listsCounter[listId][nestingLevel])
    },
    romanize (num) {
      if (isNaN(num)) {
        return NaN
      }
      const digits = String(+num).split('')
      const key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
        '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
        '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'
      ]
      let roman = ''
      let i = 3
      while (i--) {
        roman = (key[+digits.pop() + (i * 10)] || '') + roman
      }
      return Array(+digits.join('') + 1).join('M') + roman
    },
    ConvertArabicToLetters (num) {
      var letters = ''
      while (num > 0) {
        num--
        letters = String.fromCharCode(97 + (num % 26)) + letters
        num = Math.floor(num / 26)
      }
      return letters
    },
    formatGlyph (listId, nestingLevel = 0, count) {
      if (Object.prototype.hasOwnProperty.call(this.lists[listId].listProperties.nestingLevels[nestingLevel], 'glyphSymbol')) {
        return this.lists[listId].listProperties.nestingLevels[nestingLevel].glyphSymbol
      }
      switch (this.lists[listId].listProperties.nestingLevels[nestingLevel].glyphType) {
        case 'DECIMAL':
          return count.toString() + '.'
        case 'ROMAN':
          return this.romanize(count) + '.'
        case 'ALPHA':
          return this.ConvertArabicToLetters(count) + '.'
        default:
          return this.romanize(count) + '.'
      }
    },
    formatContent (content = {}) {
      if (Object.prototype.hasOwnProperty.call(content, 'table')) {
        const obj = {
          type: 'TABLE',
          content: this.formatContentTable(content.table)
        }
        if (this.currentCustomObject.nesting > -1) {
          obj.customObject = this.currentCustomObject.indent
          this.customObjects[this.currentCustomObject.indent].content.push(obj)
        }
        return obj
      } else {
        return this.formatContentParagraph(content)
      }
    },
    formatContentTable (content = {}) {
      const tableRows = []
      for (let x = 0; x < content.tableRows.length; x++) {
        const tableCells = []
        for (let y = 0; y < content.tableRows[x].tableCells.length; y++) {
          const newContent = []
          for (let z = 0; z < content.tableRows[x].tableCells[y].content.length; z++) {
            newContent.push(this.formatContentParagraph(content.tableRows[x].tableCells[y].content[z]))
          }
          tableCells.push(
            {
              content: newContent,
              tableCellStyle: content.tableRows[x].tableCells[y].tableCellStyle
            }
          )
        }
        tableRows.push(
          {
            tableCells,
            tableRowStyle: content.tableRows[x].tableRowStyle
          }
        )
      }
      return {
        tableRows,
        tableStyle: content.tableStyle
      }
    },
    formatContentParagraph (content = {}) {
      if (Object.prototype.hasOwnProperty.call(content, 'paragraph')) {
        if (Object.prototype.hasOwnProperty.call(content.paragraph, 'paragraphStyle')) {
          if (Object.prototype.hasOwnProperty.call(content.paragraph.paragraphStyle, 'namedStyleType')) {
            const contentArray = content.paragraph.elements.map((_, index) => { return this.formatContentText(content, index) })
            const obj = {
              type: content.paragraph.paragraphStyle.namedStyleType,
              content: contentArray
            }
            if (Object.prototype.hasOwnProperty.call(content.paragraph, 'bullet')) {
              obj.listId = content.paragraph.bullet.listId
              obj.bulletStyle = content.paragraph.bullet.textStyle
              if (Object.prototype.hasOwnProperty.call(content.paragraph.bullet, 'nestingLevel')) {
                obj.nestingLevel = content.paragraph.bullet.nestingLevel
              } else {
                obj.nestingLevel = 0
              }
            }
            if (this.currentCustomObject.nesting > -1) {
              obj.customObject = this.currentCustomObject.indent
              this.customObjects[this.currentCustomObject.indent].content.push(obj)
            }
            return obj
          }
        }
      }
    },
    formatContentText (content = {}, indent = 0) {
      if (Object.prototype.hasOwnProperty.call(content, 'paragraph')) {
        if (Object.prototype.hasOwnProperty.call(content.paragraph, 'paragraphStyle')) {
          if (Object.prototype.hasOwnProperty.call(content.paragraph.paragraphStyle, 'namedStyleType')) {
            if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent], 'pageBreak')) {
              return ({
                type: 'PAGE_BREAK',
                content: '',
                textStyle: {},
                paragraphStyle: content.paragraph.paragraphStyle
              })
            }
            if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent], 'inlineObjectElement') && Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent].inlineObjectElement.textStyle, 'link')) {
              return ({
                type: 'IMAGELINK',
                content: content.paragraph.elements[indent].inlineObjectElement,
                textStyle: {},
                paragraphStyle: content.paragraph.paragraphStyle,
                link: content.paragraph.elements[indent].inlineObjectElement.link.url || ''
              })
            }
            if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent], 'inlineObjectElement')) {
              return ({
                type: 'IMAGE',
                content: content.paragraph.elements[indent].inlineObjectElement,
                textStyle: {},
                paragraphStyle: content.paragraph.paragraphStyle
              })
            }
            if (Object.prototype.hasOwnProperty.call(content.paragraph.paragraphStyle, 'namedStyleType')) {
              if (content.paragraph.elements[indent].textRun.content.slice(0, 7) === '#start-') {
                this.currentCustomObject.indent += 1
                this.customObjects.push({
                  type: content.paragraph.elements[indent].textRun.content.slice(7).replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', ''),
                  start: this.contentCount,
                  end: null,
                  content: []
                })
                this.currentCustomObject.nesting += 1
                return ({
                  type: 'STARTCUSTOM',
                  content: '',
                  textStyle: {},
                  paragraphStyle: content.paragraph.paragraphStyle,
                  customObject: this.currentCustomObject.indent
                })
              }
              if (content.paragraph.elements[indent].textRun.content.slice(0, 5) === '#end-') {
                let customObjectIndent = -1
                for (let i = this.currentCustomObject.indent; i >= 0; i--) {
                  if (content.paragraph.elements[indent].textRun.content.slice(5).replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '') === this.customObjects[i].type) {
                    this.customObjects[i].end = this.contentCount
                    this.currentCustomObject.nesting -= 1
                    customObjectIndent = i
                    break
                  }
                }
                return ({
                  type: 'ENDCUSTOM',
                  content: '',
                  textStyle: {},
                  paragraphStyle: content.paragraph.paragraphStyle,
                  customObject: customObjectIndent
                })
              }
              if (content.paragraph.elements[indent].textRun.content === '\n') {
                return ({
                  type: 'BR',
                  content: content.paragraph.elements[indent].textRun.content,
                  textStyle: {},
                  paragraphStyle: content.paragraph.paragraphStyle
                })
              } else if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent].textRun.textStyle, 'link')) {
                return ({
                  isLink: true,
                  type: content.paragraph.paragraphStyle.namedStyleType,
                  content: content.paragraph.elements[indent].textRun.content,
                  textStyle: content.paragraph.elements[indent].textRun.textStyle,
                  paragraphStyle: content.paragraph.paragraphStyle
                })
              } else if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent].textRun, 'suggestedInsertionIds')) {
                return ({
                  type: 'SUGGESTION',
                  content: '',
                  textStyle: {},
                  paragraphStyle: {}
                })
              } else {
                return ({
                  type: content.paragraph.paragraphStyle.namedStyleType,
                  content: content.paragraph.elements[indent].textRun.content,
                  textStyle: content.paragraph.elements[indent].textRun.textStyle,
                  paragraphStyle: content.paragraph.paragraphStyle
                })
              }
            }
          }
        }
      }
    },
    dynamicFontStyle (fontFamily = 'Arial', weight = 400) {
      return {
        'font-family': fontFamily,
        'font-weight': weight
      }
    },
    headingStyle (paragraphStyle = {}, textStyle = {}, mode = true) {
      const firstStyle = this.dynamicStyle(
        textStyle.foregroundColor ? textStyle.foregroundColor.color.rgbColor.red : null,
        textStyle.foregroundColor ? textStyle.foregroundColor.color.rgbColor.green : null,
        textStyle.foregroundColor ? textStyle.foregroundColor.color.rgbColor.blue : null,
        textStyle.bold,
        textStyle.fontSize ? textStyle.fontSize.magnitude : null,
        textStyle.italic,
        textStyle.underline,
        textStyle.strikethrough,
        textStyle.backgroundColor ? (textStyle.backgroundColor.color ? textStyle.backgroundColor.color.rgbColor.red : null) : null,
        textStyle.backgroundColor ? (textStyle.backgroundColor.color ? textStyle.backgroundColor.color.rgbColor.green : null) : null,
        textStyle.backgroundColor ? (textStyle.backgroundColor.color ? textStyle.backgroundColor.color.rgbColor.blue : null) : null
      )
      const secondStyle = this.paragraphDynamicStyle(
        paragraphStyle.indentStart ? paragraphStyle.indentStart.magnitude : 'inherit',
        paragraphStyle.alignment,
        paragraphStyle.spaceAbove,
        paragraphStyle.spaceBelow
      )
      let thirdStyle = {}
      if (Object.prototype.hasOwnProperty.call(textStyle, 'weightedFontFamily')) {
        thirdStyle = this.dynamicFontStyle(textStyle.weightedFontFamily.fontFamily, textStyle.weightedFontFamily.weight)
      }
      if (mode) {
        return secondStyle
      } else {
        if (Object.prototype.hasOwnProperty.call(thirdStyle, 'font-family')) {
          firstStyle['font-family'] = thirdStyle['font-family']
        }
        if (Object.prototype.hasOwnProperty.call(thirdStyle, 'font-family') && firstStyle.weight === 'inherit') {
          firstStyle.weight = thirdStyle.weight
        }
        return firstStyle
      }
    },
    applyHeadingStyle (headingKey = null, paragraphStyle = {}, textStyle = {}, mode = true, listId = null) {
      let headingStyle = {}
      if (headingKey) {
        if (mode) {
          headingStyle = this.headingParStyles[headingKey] || {}
        } else {
          headingStyle = this.headingTextStyles[headingKey] || {}
        }
      }
      const currentParagraphStyle = this.headingStyle(paragraphStyle, textStyle, mode) || {}
      const finalStyle = JSON.parse(JSON.stringify(headingStyle))
      const keys = Object.keys(currentParagraphStyle)
      for (let i = 0; i < keys.length; i++) {
        if (currentParagraphStyle[keys[i]] !== 'inherit') {
          finalStyle[keys[i]] = currentParagraphStyle[keys[i]]
        }
      }
      if (listId) {
        finalStyle['list-style'] = 'none'
        finalStyle['text-indent'] = '-1.5rem'
        finalStyle['margin-left'] = '1.5rem'
      }
      finalStyle.cursor = 'pointer'
      return finalStyle
    },
    partOfCustomObject (index) {
      for (let i = 0; i < this.customObjects.length; i++) {
        if (index > this.customObjects[i].start && index < this.customObjects[i].end) {
          return true
        }
      }
      return false
    },
    cleanText (text) {
      const textWithoutPunctuation = text.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').replace(/\s{2,}/g, ' ')
      const cleanText = textWithoutPunctuation.toLowerCase()
      return cleanText
    },
    getGlossaryDefinition (content) {
      const definitiontype = content.split('-')[0]
      const word = content.split('-').slice(1).join(' ').toLowerCase()
      for (let i = 0; i < this.glossary.length; i++) {
        if (this.glossary[i].word.toLowerCase() === word.toLowerCase()) {
          if (definitiontype === 'short') {
            return this.glossary[i].meaning[1]
          } else if (definitiontype === 'synonym') {
            return this.glossary[i].meaning[2]
          } else if (definitiontype === 'explanation') {
            return this.glossary[i].meaning[2]
          } else if (definitiontype === 'long') {
            return this.glossary[i].meaning[2]
          } else {
            return this.glossary[i].meaning[4]
          }
        }
      }
      return ''
    },
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.visible = true
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    updateDocLoaded (customObject) {
      this.$emit('ready', customObject)
    }
  },
  components: {
    'async-image': AsyncImage,
    'regular-text': RegularText,
    paragraphContent
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/styles/_colors.scss';
  h2, h3 {
    font-weight: bold;
    cursor: pointer;
  }
  .heading-link > div {
    h2 {
      color: $black;
      > svg {
        margin: 0.4rem 1rem 0 0;
        color: $fuchsia;
        position: absolute;
      }
      > span {
        margin-left: 3rem;
        display: inline-block;
      }
    }
  }
  h3 {
    color: $fuchsia;
  }

  .new-tab-link {
    svg {
      width: 1rem;
      height: 100%;
      margin-bottom: 0.2rem;
    }
  }

  @media (max-width: 767px) {
  #app {
    h2, h3 {
      font-size: 24pt;
      >svg {
        font-size: 24pt;
      }
    }
  }
  }
</style>
