<template>
  <div class="container exdoc my-4">
    <div>
      <p>
        {{this.intro}}
      </p>
    </div>
    <div class="mb-3" v-for="(strategy, index) in strategies" :key="strategy+index">
      <h3 class="strat">{{strategies[index]}}</h3>
      <div>
        <p>{{explanations[index]}}</p>
      </div>
      <template v-if="showTips[index]" >
        <collapsable
          :document="{}"
          :lists="{}"
          :headingParStyles="{}"
          :headingTextStyles="{}"
          :customObjects="tips[index]"
          :listsCounter="{}"
          :l_esson="l_esson"
          :customObject="0"
          :glossary="[]"
          :glossaryUrls="[]"
        />
      </template>
    </div>
  </div>
</template>

<script>
import createJWT from '@/mixins/createJWT'
import axios from 'axios'
import Collapsable from '@/components/customObjects/Collapsable'
export default {
  mixins: [createJWT],
  name: 'TeachingStategies',
  props: {
    id: {
      type: String,
      default: '1G7j_uBQhqxENC_zUB-aoGyGDpzidunZ0p-jXyhGhp7Y'
    },
    course: {
      type: String,
      default: '1'
    },
    lesson: {
      type: String,
      default: '1'
    },
    spreadname: {
      type: String,
      default: 'NULL'
    }
  },
  created: async function () {
    await this.createJwt()
    this.initialParseExDoc()
  },
  data: function () {
    return {
      intro: '',
      strategies: [],
      tips: [],
      explanations: [],
      showTips: [],
      answerSheetIndex: -1,
      l_esson: {
        type: 'collapsable',
        start: 4,
        end: 9,
        content: [
          {
            type: 'NORMAL_TEXT',
            content: [
              {
                type: 'STARTCUSTOM',
                content: '',
                textStyle: {},
                paragraphStyle: {
                  namedStyleType: 'NORMAL_TEXT',
                  direction: 'LEFT_TO_RIGHT'
                },
                customObject: 0,
                parentCustomObject: -1
              }
            ],
            customObject: 0,
            parentCustomObject: -1
          },
          {
            type: 'HEADING_4',
            content: [
              {
                type: 'HEADING_4',
                content: 'Tips\n',
                textStyle: {},
                paragraphStyle: {
                  headingId: 'h.qd0wwly3sy01',
                  namedStyleType: 'HEADING_4',
                  lineSpacing: 100,
                  direction: 'LEFT_TO_RIGHT',
                  spacingMode: 'NEVER_COLLAPSE',
                  spaceBelow: {
                    magnitude: 10,
                    unit: 'PT'
                  }
                }
              }
            ],
            customObject: 0,
            parentCustomObject: -1
          },
          {
            type: 'NORMAL_TEXT',
            content: [
              {
                type: 'STARTCUSTOM',
                content: '',
                textStyle: {},
                paragraphStyle: {
                  namedStyleType: 'NORMAL_TEXT',
                  direction: 'LEFT_TO_RIGHT'
                },
                customObject: 1,
                parentCustomObject: 0
              }
            ],
            customObject: 1,
            parentCustomObject: 0
          },
          {
            type: 'NORMAL_TEXT',
            content: [
              {
                type: 'NORMAL_TEXT',
                content: 'Hola',
                textStyle: {
                  foregroundColor: {
                    color: {
                      rgbColor: {
                        red: 0.67058824,
                        green: 0,
                        blue: 0.36078431
                      }
                    }
                  },
                  link: {
                    url: 'https://docs.google.com/document/u/0/d/1VYXGq-08p_oBWUziofTJmJSnujnYvDVERjnO_yQA6wY/edit'
                  }
                },
                paragraphStyle: {
                  namedStyleType: 'NORMAL_TEXT',
                  lineSpacing: 100,
                  direction: 'LEFT_TO_RIGHT',
                  spacingMode: 'NEVER_COLLAPSE',
                  spaceBelow: {
                    magnitude: 6,
                    unit: 'PT'
                  }
                }
              },
              {
                type: 'BR',
                content: '\n',
                textStyle: {},
                paragraphStyle: {
                  namedStyleType: 'NORMAL_TEXT',
                  lineSpacing: 100,
                  direction: 'LEFT_TO_RIGHT',
                  spacingMode: 'NEVER_COLLAPSE',
                  spaceBelow: {
                    magnitude: 6,
                    unit: 'PT'
                  }
                }
              }
            ],
            customObject: 1,
            parentCustomObject: 0
          },
          {
            type: 'NORMAL_TEXT',
            content: [
              {
                type: 'ENDCUSTOM',
                content: '',
                textStyle: {},
                paragraphStyle: {
                  namedStyleType: 'NORMAL_TEXT',
                  lineSpacing: 100,
                  direction: 'LEFT_TO_RIGHT',
                  spacingMode: 'NEVER_COLLAPSE',
                  spaceBelow: {
                    magnitude: 6,
                    unit: 'PT'
                  }
                },
                customObject: 0,
                parentCustomObject: -1
              }
            ],
            customObject: 0,
            parentCustomObject: -1
          }
        ],
        nameSectionMenu: '',
        parentCustomObject: -1
      },
      customObject: 0,
      customObjects: [
        {
          type: 'collapsable',
          start: 4,
          end: 9,
          content: [
            {
              type: 'NORMAL_TEXT',
              content: [
                {
                  type: 'STARTCUSTOM',
                  content: '',
                  textStyle: {},
                  paragraphStyle: {
                    namedStyleType: 'NORMAL_TEXT',
                    direction: 'LEFT_TO_RIGHT'
                  },
                  customObject: 1,
                  parentCustomObject: 0
                }
              ],
              customObject: 0,
              parentCustomObject: -1
            },
            {
              type: 'HEADING_2',
              content: [
                {
                  type: 'HEADING_2',
                  content: 'Learning goals\n',
                  textStyle: {},
                  paragraphStyle: {
                    headingId: 'h.qd0wwly3sy01',
                    namedStyleType: 'HEADING_2',
                    lineSpacing: 100,
                    direction: 'LEFT_TO_RIGHT',
                    spacingMode: 'NEVER_COLLAPSE',
                    spaceBelow: {
                      magnitude: 10,
                      unit: 'PT'
                    }
                  }
                }
              ],
              customObject: 0,
              parentCustomObject: -1
            },
            {
              type: 'NORMAL_TEXT',
              content: [
                {
                  type: 'STARTCUSTOM',
                  content: '',
                  textStyle: {},
                  paragraphStyle: {
                    namedStyleType: 'NORMAL_TEXT',
                    direction: 'LEFT_TO_RIGHT'
                  },
                  customObject: 1,
                  parentCustomObject: 0
                }
              ],
              customObject: 1,
              parentCustomObject: 0
            },
            {
              type: 'NORMAL_TEXT',
              content: [
                {
                  isLink: true,
                  type: 'NORMAL_TEXT',
                  content: '[See separate Google Doc]',
                  textStyle: {
                    underline: true,
                    foregroundColor: {
                      color: {
                        rgbColor: {
                          red: 0.06666667,
                          green: 0.33333334,
                          blue: 0.8
                        }
                      }
                    },
                    link: {
                      url: 'https://docs.google.com/document/u/0/d/1VYXGq-08p_oBWUziofTJmJSnujnYvDVERjnO_yQA6wY/edit'
                    }
                  },
                  paragraphStyle: {
                    namedStyleType: 'NORMAL_TEXT',
                    lineSpacing: 100,
                    direction: 'LEFT_TO_RIGHT',
                    spacingMode: 'NEVER_COLLAPSE',
                    spaceBelow: {
                      magnitude: 6,
                      unit: 'PT'
                    }
                  }
                },
                {
                  type: 'BR',
                  content: '\n',
                  textStyle: {},
                  paragraphStyle: {
                    namedStyleType: 'NORMAL_TEXT',
                    lineSpacing: 100,
                    direction: 'LEFT_TO_RIGHT',
                    spacingMode: 'NEVER_COLLAPSE',
                    spaceBelow: {
                      magnitude: 6,
                      unit: 'PT'
                    }
                  }
                }
              ],
              customObject: 1,
              parentCustomObject: 0
            },
            {
              type: 'NORMAL_TEXT',
              content: [
                {
                  type: 'ENDCUSTOM',
                  content: '',
                  textStyle: {},
                  paragraphStyle: {
                    namedStyleType: 'NORMAL_TEXT',
                    lineSpacing: 100,
                    direction: 'LEFT_TO_RIGHT',
                    spacingMode: 'NEVER_COLLAPSE',
                    spaceBelow: {
                      magnitude: 6,
                      unit: 'PT'
                    }
                  },
                  customObject: 0,
                  parentCustomObject: -1
                }
              ],
              customObject: 0,
              parentCustomObject: -1
            }
          ],
          nameSectionMenu: '',
          parentCustomObject: -1
        },
        {
          type: 'content',
          start: 6,
          end: 8,
          content: [
            {
              type: 'NORMAL_TEXT',
              content: [
                {
                  type: 'STARTCUSTOM',
                  content: '',
                  textStyle: {},
                  paragraphStyle: {
                    namedStyleType: 'NORMAL_TEXT',
                    direction: 'LEFT_TO_RIGHT'
                  },
                  customObject: 1,
                  parentCustomObject: 0
                }
              ],
              customObject: 1,
              parentCustomObject: 0
            },
            {
              type: 'NORMAL_TEXT',
              content: [
                {
                  type: 'NORMAL_TEXT',
                  content: '[See separate Google Doc]',
                  textStyle: {
                    foregroundColor: {
                      color: {
                        rgbColor: {
                          red: 0,
                          green: 0,
                          blue: 0
                        }
                      }
                    }
                  },
                  paragraphStyle: {
                    namedStyleType: 'NORMAL_TEXT',
                    lineSpacing: 100,
                    direction: 'LEFT_TO_RIGHT',
                    spacingMode: 'NEVER_COLLAPSE',
                    spaceBelow: {
                      magnitude: 6,
                      unit: 'PT'
                    }
                  }
                },
                {
                  type: 'BR',
                  content: '\n',
                  textStyle: {},
                  paragraphStyle: {
                    namedStyleType: 'NORMAL_TEXT',
                    lineSpacing: 100,
                    direction: 'LEFT_TO_RIGHT',
                    spacingMode: 'NEVER_COLLAPSE',
                    spaceBelow: {
                      magnitude: 6,
                      unit: 'PT'
                    }
                  }
                }
              ],
              customObject: 1,
              parentCustomObject: 0
            }
          ],
          nameSectionMenu: '',
          parentCustomObject: 0
        }
      ]
    }
  },
  methods: {
    initialParseExDoc () {
      this.makeGlossary(this.id)
    },
    async makeGlossary (glossary) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
      }
      if (!window.navigator.onLine) {
        config.timeout = 2
      }
      await axios.get('https://sheets.googleapis.com/v4/spreadsheets/' + glossary + '?includeGridData=true', config).then(async response => {
        const document = response.data
        await this.$store.commit('addExternalDocuments', ['https://sheets.googleapis.com/v4/spreadsheets/' + glossary, response.data])
        this.processIntro(document)
        this.processExternalDoc(document)
      }).catch(async error => {
        console.log(error)
        const document = await this.$store.state.externalDocuments['https://sheets.googleapis.com/v4/spreadsheets/' + glossary]
        if (document) {
          this.processIntro(document)
          this.processExternalDoc(document)
        }
      })
    },
    processExternalDoc (document) {
      for (let k = 0; k < document.sheets.length; k++) {
        if (document.sheets[k].properties.title === this.spreadname) {
          this.answerSheetIndex = k
          break
        }
      }
      for (let k = 0; k < document.sheets[this.answerSheetIndex].data[0].rowData.length; k++) {
        if (document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === 'Course' && document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === 'Lesson') {
          continue
        }
        if ((document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === 'All' || document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === this.course) &&
        (document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === 'All' || document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === this.lesson)) {
          this.strategies.push(document.sheets[this.answerSheetIndex].data[0].rowData[k].values[2].formattedValue.replace(/\n/g, ' '))
          this.explanations.push(document.sheets[this.answerSheetIndex].data[0].rowData[k].values[3].formattedValue)
          const tipsObj = JSON.parse(JSON.stringify(this.customObjects))
          if (document.sheets[this.answerSheetIndex].data[0].rowData[k].values[4] && document.sheets[this.answerSheetIndex].data[0].rowData[k].values[4].formattedValue) {
            tipsObj[1].content[1].content[0].content = document.sheets[this.answerSheetIndex].data[0].rowData[k].values[4].formattedValue
          }
          this.tips.push(tipsObj)
          let showtip = false
          if (document.sheets[this.answerSheetIndex].data[0].rowData[k].values[4] && document.sheets[this.answerSheetIndex].data[0].rowData[k].values[4].formattedValue) {
            showtip = true
          }
          this.showTips.push(showtip)
        }
      }
    },
    processIntro (document) {
      for (let k = 0; k < document.sheets.length; k++) {
        if (document.sheets[k].properties.title === 'Introduction') {
          this.answerSheetIndex = k
          break
        }
      }
      for (let k = 0; k < document.sheets[this.answerSheetIndex].data[0].rowData.length; k++) {
        if (document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === 'Course' && document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === 'Lesson') {
          continue
        }
        if ((document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === 'All' || document.sheets[this.answerSheetIndex].data[0].rowData[k].values[0].formattedValue === this.course) &&
        (document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === 'All' || document.sheets[this.answerSheetIndex].data[0].rowData[k].values[1].formattedValue === this.lesson)) {
          this.intro = document.sheets[this.answerSheetIndex].data[0].rowData[k].values[2].formattedValue.replace(/\n/g, ' ')
        }
      }
    }
  },
  components: {
    Collapsable
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/styles/_colors.scss';

</style>
