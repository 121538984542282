<template>
  <div>
    <Login></Login>
  </div>
</template>

<script>
import Login from '@/components/Login'

export default {
  data () {
    return {}
  },
  components: {
    Login
  }
}
</script>
