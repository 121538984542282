<template>
  <div>
    <div class="contentDocument">
      <Intersect @enter="showFloatMenu = false" @leave="showFloatMenu = true">
        <div></div>
      </Intersect>
      <b-toast v-model="isReady" variant="info" id="offline-toast" title="Offline ready!" toaster="b-toaster-top-right">
        <template>
          The current Lesson is ready to be used offline.
      </template>
    </b-toast>
      <a name="top-lesson"></a>
      <b-modal
        ref="modal-1"
        title="Please, enter your name!"
        @show="resetModal"
        @hidden="resetModal"
        @ok="submitName">
        <div>
          <b-form-input v-model="inputName" placeholder="Please, enter your name"></b-form-input>
        </div>
      </b-modal>
    </div>
    <secondary-menu :lesson="lesson"></secondary-menu>
    <div v-if="loading" class="d-flex justify-content-center mb-3">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else class="contentDocument" :class="type" id="the-lesson" v-scroll="handleScroll">
      <lesson-nav ref="secmenu" :lesson="lesson" @initialchange="initialChange" :type="type" :showFloatMenu="showFloatMenu"></lesson-nav>
      <b-container class="contentLesson">
        <b-row>
          <b-col>
            <b-container class="pt-5" v-if="l_esson.content.length">
              <div v-for="(content, index) in l_esson.content" :key="index" :class="{'d-none': content && content.type === 'ONLYINTERNALLINK'}">
                <template v-if="content && content.customObject > -1">
                  <template v-if="customObjects[content.customObject].parentCustomObject === -1">
                    <div v-for="(elem, index1) in content.content" :key="index1">
                      <template v-if="elem && elem.type=='STARTCUSTOM'">
                      <componentsContent
                        :elem="elem"
                        :customObjects="customObjects"
                        :document="document"
                        :lists="lists"
                        :headingParStyles="headingParStyles"
                        :headingTextStyles="headingTextStyles"
                        :listsCounter="listsCounter"
                        :content="content"
                        :glossary="glossary"
                        :glossaryUrls="glossaryUrls"
                        :name="name"
                        :answerLink="answerLink"
                        :lessonpk="lessonpk"
                        :id="id"
                        :userId="userId"
                        :type="type"
                        :index="index"
                        :index1="index1"
                        :getImage="getImage"
                        :nameSectionMenu="l_esson.nameSectionMenu"
                        :login="login"
                        @listCounterUpdate="listsIndex"
                        @changeSection="changeSection"
                        @movingto="movingto"
                        @ready="updateDocLoaded"
                        />
                      </template>
                    </div>
                  </template>
                </template>
              </div>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
      <div class="text-right d-print-none">
        <b-button
          id="scrollToTopBtn"
          class="m-4 btn-goToTop"
          @click="scrollToTop">
          Go to top
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import axios from 'axios'
import Intersect from 'vue-intersect'
import SecondaryMenu from '@/components/SecondaryMenu'
import lessonNavigation from '@/components/lessonNavigation'
// import headingsContent from '@/components/lessonSubElements/headingsContent'
import componentsContent from '@/components/lessonSubElements/componentsContent'
import parseLessons from '@/mixins/parseLesson'
import createJWT from '@/mixins/createJWT'

Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

export default {
  mixins: [parseLessons, createJWT],
  name: 'Lesson',
  props: {
    coursesslug: String,
    lessonsslug: String,
    type: String,
    selectedsection: {
      type: String,
      default: 'Teach'
    },
    mode: {
      type: String,
      default: 'Student'
    }
  },
  created: async function () {
    this.login = await this.$store.state.isLoggedIn
    this.initialParseLesson()
    const vm = this
    this.$root.$on('MoveTo', (movetosection, ref, wait) => {
      if (vm.$refs[ref]) {
        vm.scroll = true
        vm.movingto('', movetosection, ref, wait)
      }
    })
    this.$root.$on('Scroll', (ref) => {
      if (vm.scroll) {
        vm.scrollMeTo(ref, vm)
        vm.scroll = false
      }
    })
    if (!['teachers', 'blackboard', 'projector'].includes(this.type) && !this.login) {
      this.$refs['modal-1'].show()
    }
  },
  mounted: async function () {
  },
  updated () {
  },
  beforeDestroy () {
    this.$root.$off('MoveTo')
    this.$root.$off('Scroll')
    clearInterval(this.polling)
    this.listsCounter = {}
    this.loading = false
    this.isReady = false
    clearInterval(this.refreshTok)
  },
  data: function () {
    return {
      showFloatMenu: false,
      document: {
        title: '',
        body: {
          content: []
        }
      },
      lists: {},
      headingParStyles: {},
      customObjects: [],
      currentCustomObject: { indent: -1, nesting: -1, parent: null },
      contentCount: 0,
      headingTextStyles: {},
      listsCounter: {},
      lesson: {
        title: [],
        menu: [],
        content: []
      },
      l_esson: {
        content: []
      },
      name: '',
      inputName: '',
      glossary: [],
      glossaryCount: {},
      glossaryUrls: [process.env.VUE_APP_GLOSSARY_URL],
      selectedLanguage: 'En',
      languages: {
        En: 1,
        Es: 2
      },
      answerLink: '',
      lessonpk: -1,
      userId: null,
      loading: true,
      shouldMakeGlossary: true,
      docLoaded: { root: false, glossary: false },
      isReady: false,
      polling: null,
      refreshTok: null,
      login: false
    }
  },
  methods: {
    handleScroll: function () {
      var scrollToTopBtn = document.getElementById('scrollToTopBtn')
      var rootElement = document.documentElement
      var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
      if (scrollToTopBtn !== null) {
        if ((rootElement.scrollTop / scrollTotal) > 0.10) {
          scrollToTopBtn.classList.add('showBtn')
        } else {
          scrollToTopBtn.classList.remove('showBtn')
        }
      }
    },
    printLesson: function () {
      window.print()
    },
    // 'https://docs.googleapis.com/v1/documents/1tJqsqAEyCYzNf7Z46IKOwUx1ZEd1t2xk'
    async initialParseLesson () {
      const newconfig = {}
      if (!window.navigator.onLine) {
        newconfig.timeout = 2
      }
      await axios.get(process.env.VUE_APP_BACK_URL + 'courses/' + this.coursesslug + '/lessons/' + this.lessonsslug + '/', newconfig)
        .then(response => {
          this.$store.commit('addChoiceBackLesson', [process.env.VUE_APP_BACK_URL + 'courses/' + this.coursesslug + '/lessons/' + this.lessonsslug, response.data])
          this.studentLink = response.data.studentDocLink
          if (this.type === 'teachers') {
            this.studentLink = response.data.teacherDocLink
          } else if (this.type === 'blackboard') {
            this.studentLink = response.data.teacherBlackboardDocLink
          } else if (this.type === 'projector') {
            this.studentLink = response.data.teacherProjectorDocLink
          }
          this.answerLink = response.data.answerDocLink
          this.glossaryUrls.push(response.data.glossaryDocLink)
          this.id = response.data.lessonOrder.toString()
          this.lessonpk = response.data.pk.toString()
        })
        .catch(error => {
          console.log(error)
          const response = this.$store.state.choiceBackLessons[process.env.VUE_APP_BACK_URL + 'courses/' + this.coursesslug + '/lessons/' + this.lessonsslug]
          if (response) {
            this.studentLink = response.studentDocLink
            if (this.type === 'teachers') {
              this.studentLink = response.teacherDocLink
            } else if (this.type === 'blackboard') {
              this.studentLink = response.teacherBlackboardDocLink
            } else if (this.type === 'projector') {
              this.studentLink = response.teacherProjectorDocLink
            }
            this.answerLink = response.answerDocLink
            this.glossaryUrls.push(response.glossaryDocLink)
            this.id = response.lessonOrder.toString()
            this.lessonpk = response.pk.toString()
          }
        })
      axios.get(process.env.VUE_APP_DOCUMENTS_URL + this.studentLink, {}).then(async response => {
        this.document = response.data
        this.$store.commit('addDocument', [process.env.VUE_APP_DOCUMENTS_URL + this.studentLink, response.data])
        this.parseDocument(response)
        this.loading = false
        this.docLoaded.root = true
        if (Object.values(this.docLoaded).every(Boolean)) {
          const keys = {
            teachers: 'teacherDocOffline',
            student: 'studentDocOffline',
            projector: 'teacherProjectorDocOffline',
            blackboard: 'teacherBlackboardOffline'
          }
          this.isReady = await this.$store.dispatch('makeAvailableOffline', [this.coursesslug, this.lessonsslug, keys[this.type]])
        }
      }).catch(error => {
        console.log(error)
        this.document = this.$store.state.documents[process.env.VUE_APP_DOCUMENTS_URL + this.studentLink]
        if (this.document) {
          this.parseDocument()
        } else {
          this.l_esson = {
            content: [
              undefined,
              { type: 'TITLE', content: [{ content: 'PAGE NOT FOUND \n', paragraphStyle: {}, textStyle: {}, type: 'TITLE' }] },
              { type: 'NORMAL_TEXT', content: [{ type: 'NORMAL_TEXT', content: 'NOT FOUND', textStyle: {}, paragraphStyle: { namedStyleType: 'NORMAL_TEXT', direction: 'LEFT_TO_RIGHT' } }], customObject: -1, parentCustomObject: -1 }
            ]
          }
        }
        this.loading = false
      })
      // }
    },
    tableCellStyle (paddingLeft = 0, paddingRight = 0, paddingTop = 0, paddingBottom = 0, minRowHeight = 0) {
      return {
        'padding-left': `${paddingLeft}pt`,
        'padding-right': `${paddingRight}pt`,
        'padding-bottom': `${paddingBottom}pt`,
        'padding-top': `${paddingTop}pt`,
        'min-height': `${minRowHeight}pt`
      }
    },
    resetModal () {
      this.inputName = ''
      this.listsCounter = {}
    },
    async submitName () {
      this.loading = true
      this.name = this.inputName
      if (this.name && this.name.length) {
        this.userId = this.getId(this.name)
      }
      this.listsCounter = {}
      this.loading = false
    },
    cleanText (text) {
      if (text) {
        const textWithoutPunctuation = text.replace(/[.,/#!$%^&*;:{}=\-_`~()]/g, '').replace(/\s{2,}/g, ' ')
        const cleanText = textWithoutPunctuation.toLowerCase()
        return cleanText
      } else {
        return ''
      }
    },
    byteToHex (byte) {
      return ('0' + byte.toString(16)).slice(-2)
    },
    generateId (len = 40) {
      var arr = new Uint8Array(len / 2)
      window.crypto.getRandomValues(arr)
      return Array.from(arr, this.byteToHex).join('')
    },
    getId (name) {
      const names = this.$store.state.names
      if (Object.prototype.hasOwnProperty.call(names, name)) {
        const id = names[name]
        return id
      } else {
        const id = this.generateId()
        this.$store.commit('addName', [name, id])
        return id
      }
    },
    changeSection (sectionTitle) {
      let selectedItem = ''
      if (sectionTitle.length >= 1) {
        selectedItem = sectionTitle.toLowerCase().trim().replace(/ /g, '-')
      }
      if (selectedItem !== this.$route.query.section) {
        let name = 'Lesson'
        switch (this.type) {
          case 'student':
            name = 'Lesson'
            break
          case 'teachers':
            name = 'teachersLessons'
            break
          case 'blackboard':
            name = 'teachersLessonsBlackBoard'
            break
          case 'projector':
            name = 'teachersLessonsProjector'
            break
          default:
            break
        }
        this.$router.push({ name: name, params: { coursesslug: this.$route.params.coursesslug, lessonsslug: this.$route.params.lessonsslug }, query: { section: selectedItem } })
      }
    },
    async movingto (sectionTitle, movetosection, ref, wait = true) {
      const newSectiontitle = sectionTitle[0].toUpperCase() + sectionTitle.slice(1) + '\n'
      this.$refs.secmenu.changeContent(newSectiontitle)
      if (wait) {
        await this.sleep(600)
        this.$router.replace({
          ...this.$router.currentRoute,
          params: { coursesslug: this.$route.params.coursesslug, lessonsslug: this.$route.params.lessonsslug },
          query: { internallink: this.$route.query.internallink, section: sectionTitle }
        })
      }
      this.$root.$emit('Scroll', ref)
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    async initialChange () {
      if (this.$route.query.internallink) {
        // const newSectiontitle = this.$route.query.section[0].toUpperCase() + this.$route.query.section.slice(1) + '\n'
        // this.$refs.secmenu.changeContent(newSectiontitle)
        // this.scrollToTop()
        await this.$nextTick()
        this.polling = setInterval(() => {
          this.internallink()
        }, 5000)
      } else if (this.$route.query.section) {
        const newSectiontitle = this.$route.query.section[0].toUpperCase() + this.$route.query.section.slice(1) + '\n'
        this.$refs.secmenu.changeContent(newSectiontitle)
        this.scrollToTop()
      }
    },
    capitalize (string) {
      return string && string[0].toUpperCase() + string.slice(1)
    },
    moveToLink (content) {
      const ref = content.split('-')[0]
      this.$root.$emit('MoveTo', '', ref, true)
    },
    scrollMeTo (ref, help = this) { /* https://stackoverflow.com/questions/42645964/vue-js-anchor-to-div-within-the-same-component */
      if (help.$refs[ref] && help.$refs[ref].length) {
        const element = help.$refs[ref][0]
        const top = element.offsetTop
        window.scrollTo(0, top)
      }
    },
    sleep (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async updateDocLoaded (customObject) {
      this.docLoaded[customObject] = true
      if (Object.values(this.docLoaded).every(Boolean)) {
        const keys = {
          teachers: 'teacherDocOffline',
          student: 'studentDocOffline',
          projector: 'teacherProjectorDocOffline',
          blackboard: 'teacherBlackboardOffline'
        }
        this.isReady = await this.$store.dispatch('makeAvailableOffline', [this.coursesslug, this.lessonsslug, keys[this.type]])
      }
    },
    async internallink () {
      if (this.isReady || !window.navigator.onLine) {
        if (this.$route.query.internallink) {
          this.$root.$emit('MoveTo', '', this.$route.query.internallink, true)
        }
        clearInterval(this.polling)
      }
    },
    async refreshTokenAxios () {
      this.login = await this.$store.state.isLoggedIn
      // this.refreshTok = setInterval(async () => {
      //   await axios.post(process.env.VUE_APP_BACK_URL + '/token/refresh/', {
      //     refresh: localStorage.getItem('refreshtoken')
      //   }).then(
      //     (refreshRes) => {
      //       localStorage.setItem('token', refreshRes.data.access)
      //       this.login = true
      //     }
      //   )
      //     .catch(
      //       () => {
      //         this.login = false
      //       }
      //     )
      // }, 5 * 60 * 1000)
    }
  },
  components: {
    // 'yellow-box': YellowBox,
    // Quiz,
    // TextAreaQuestion,
    // 'quiz-select': QuizSelect,
    // DragNDrop,
    // Blackboard,
    // Glossary,
    // Collapsable,
    'secondary-menu': SecondaryMenu,
    // 'section-content': Section,
    // ExternalDocument,
    // 'teaching-strategies': TeachingStrategies,
    // DialogBox,
    // ExternalWordDocument,
    // DialogBoxLeft,
    // ImageText,
    // ImageTextLeft,
    // ImageLightBlue,
    'lesson-nav': lessonNavigation,
    // 'key-term': KeyTerm,
    // headingsContent,
    componentsContent,
    Intersect
  },
  watch: {
    '$route.query.section' () {
      if (this.$route.query.section) {
        const newSectiontitle = this.$route.query.section[0].toUpperCase() + this.$route.query.section.slice(1) + '\n'
        this.$refs.secmenu.changeContent(newSectiontitle)
        this.scrollToTop()
      }
    },
    '$route.query.internallink' () {
      this.polling = setInterval(() => {
        this.internallink()
      }, 5000)
    },
    '$store.state.isLoggedIn': async function () {
      this.login = await this.$store.state.isLoggedIn
      if (!['teachers', 'blackboard', 'projector'].includes(this.type)) {
        this.$router.go()
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/assets/styles/_colors.scss';

  .btn-goToTop {
    width: 5rem;
    max-width: 5rem;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 100;
    opacity: 0;
    transform: translateY(100px);
    transition: all .5s ease;
    border-color: $fuchsia;
    background-color: $fuchsia;
    color: white;
    font-size: 14pt;
    line-height: 1.2;
    &.showBtn {
      opacity: 1;
      transform: translateY(0)
    }
    &:hover {
      background-color: $pink;
    }
  }

  .page-break-style {
    page-break-after: always;
  }

</style>
