<template>
  <div class="my-4">
    <b-container>
      <div v-if="online">
        <p v-if="$store.state.userData"> Hi {{$store.state.userData.first_name}} {{$store.state.userData.last_name}} ! </p>
        <router-link :to="{name: 'My Page'}">
          <b-button class="my-3 button" pill block>Go to my page</b-button>
        </router-link>
        <b-button class="my-3 button" pill block @click="logout">Logout</b-button>
      </div>
      <div v-else>
        <div class="error mb-3" v-if="error">{{error}}</div>
        <p class="title">Username</p>
        <b-form-input class="mb-2 login-form" v-model="username" type="text"></b-form-input>
        <p class="title">Password</p>
        <b-form-input class="login-form" v-model="password" type="password"></b-form-input>
        <b-button class="my-3 button" pill block @click="submit">Login</b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Login',
  props: {},
  mounted: async function () {
    this.online = await this.$store.state.isLoggedIn
  },
  data: function () {
    return {
      username: '',
      password: '',
      error: null,
      online: false
    }
  },
  methods: {
    submit: function () {
      axios.post(process.env.VUE_APP_BACK_URL + 'token/', { username: this.username, password: this.password })
        .then((res) => {
          localStorage.setItem('token', res.data.access)
          localStorage.setItem('refreshtoken', res.data.refresh)
          this.error = null
          this.$store.dispatch('LoginIn')
          this.$router.push('/my-page')
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.error = 'Wrong password or username'
          } else {
            this.error = err
          }
        })
    },
    logout: function () {
      localStorage.removeItem('refreshtoken')
      localStorage.removeItem('token')
      this.$store.dispatch('Logout')
    }
  },
  watch: {
    '$store.state.isLoggedIn': async function () {
      this.online = await this.$store.state.isLoggedIn
    }
  }
}
</script>

<style scoped lang="scss">

.button {
  background-color: #c4377c;
  font-size: 18pt;
}
.login-form {
  background-color: #f2f2f2;
  color: #8d8d8d;
  font-size: 18pt;
  border-radius: 50rem;
}

.error {
  font-weight: bold;
  color: darkred;
}
</style>
