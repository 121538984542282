<template>
  <ul class="lesson-list">
    <li v-for="(item, index) in lessons" :key="'les' + item.title + index.toString()" class="mb-3">
      <b-link :to="'courses/'+item.courseSlug + '/lessons/' + item.lessonSlug + '/students'">{{item.title}}</b-link>
      <span v-if="item.avilableOffline" class="ml-1 ml-md-4 downloaded-icon" data-toggle="tooltip" data-placement="top" title="Accessible offline">
        <font-awesome-icon icon="download"/>
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'lessonLists',
  data: function () {
    return {}
  },
  props: {
    lessons: Array
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_colors.scss";

.lesson-list {
  & li > a {
    font-size: 1.83rem;
    text-decoration: none;
    color: $blue;
  }
  & li::marker {
    font-size: 1.83rem;
    color: $blue;
  }
}
.downloaded-icon {
  & svg {
    font-size: 15pt;
    color: #555555;
    display: inline-block;
    vertical-align: baseline;
  }
}

@media (max-width: 767px) {
  .lesson-list {
    & li > a, li::marker {
      font-size: 17pt;
    }
  }
}
</style>
