<template>
  <div class="contentWhiteBox">
    <template v-if="l_esson.content.length">
      <div>
        <div v-for="(content, index) in l_esson.content" :key="index" :class="{'d-none':  content && content.type === 'ONLYINTERNALLINK'}">
        <template v-if="content && content.customObject > customObject">
          <template v-if="customObjects[content.customObject].parentCustomObject === customObject">
            <div v-for="(elem, index1) in content.content" :key="index1">
              <template v-if="elem && elem.type=='STARTCUSTOM'">
                <componentsContent
                  :elem="elem"
                  :customObjects="customObjects"
                  :document="document"
                  :lists="lists"
                  :headingParStyles="headingParStyles"
                  :headingTextStyles="headingTextStyles"
                  :listsCounter="listsCounter"
                  :content="content"
                  :glossary="glossary"
                  :glossaryUrls="glossaryUrls"
                  :name="name"
                  :answerLink="answerLink"
                  :lessonpk="lessonpk"
                  :id="id"
                  :userId="userId"
                  :type="type"
                  :index="index"
                  :index1="index1"
                  :getImage="getImage"
                  :nameSectionMenu="nameSectionMenu"
                  @listCounterUpdate="listsIndex"
                  @changeSection="changeSection"
                  @movingto="movingto"
                  @ready="updateDocLoaded"
                />
              </template>
            </div>
          </template>
        </template>
          <template v-else>
            <headingsContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="l_esson.nameSectionMenu"
            :name="name"
            :id="id"
            :lessonpk="lessonpk"
            :userId="userId"
            :type="type"
            :customObjects="customObjects"
            :customObject="customObject"
            :lists="lists"
            :listsCounter="listsCounter"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            :index="index"
            @listCounterUpdate="listsIndex"
            @changeSection="changeSection"
            @movingto="movingto"
          />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'WhiteBox',
  props: {
    document: Object,
    lists: Object,
    headingParStyles: Object,
    headingTextStyles: Object,
    customObjects: Array,
    listsCounter: Object,
    l_esson: Object,
    customObject: Number,
    glossary: Array,
    glossaryUrls: Array,
    userId: String,
    lessonpk: String,
    getImage: Function,
    name: String,
    id: String,
    type: String,
    answerLink: String,
    nameSectionMenu: String
  },
  data: function () {
    return {
      visible: true,
      scroll: false
    }
  },
  created () {
    const vm = this
    this.$root.$on('MoveTo', (movetosection, ref, wait) => {
      if (vm.$refs[ref]) {
        vm.scroll = true
        vm.movingto(vm.l_esson.nameSectionMenu, movetosection, ref, wait)
      }
    })
    this.$root.$on('Scroll', (ref) => {
      if (vm.scroll) {
        vm.scrollMeTo(ref, vm)
        vm.scroll = false
      }
    })
  },
  beforeDestroy () {
    this.$root.$off('MoveTo')
    this.$root.$off('Scroll')
  },
  methods: {
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
    },
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    updateDocLoaded (customObject) {
      this.$emit('ready', customObject)
    },
    scrollMeTo (ref, help = this) { /* https://stackoverflow.com/questions/42645964/vue-js-anchor-to-div-within-the-same-component */
      if (help.$refs[ref] && help.$refs[ref].length) {
        const element = help.$refs[ref][0]
        const top = element.offsetTop
        window.scrollTo(0, top)
      }
    }
  },
  components: {
    componentsContent: () => import('@/components/lessonSubElements/componentsContent'),
    headingsContent: () => import('@/components/lessonSubElements/headingsContent')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/styles/_colors.scss';
  #fluidMainLessonInfo {
    background-color: #ffe96f;
  }
  .HEADING_1 {
    padding: 2rem 0;
    display: block;
  }
  .HEADING_2 {
    padding: 1rem 0;
    display: block;
  }
  .HEADING_3 {
    padding: 0.5rem 0;
    display: block;
  }
  .contentLink {
    color: inherit;
    text-decoration: inherit;
    font-weight: inherit;
  }
  .contentWhiteBox {
    border-radius: 5px;
    border: 1pt solid $gray;
    background-color: #ffffff;
    padding: 2rem;
    z-index: 1;
  }
@media (max-width: 767px) {
  .contentWhiteBox {
    padding: 1rem;
  }
}
</style>
