<template>
  <div>
    <b-toast v-model="isReady" variant="info" id="offline-toast" title="Offline ready!" toaster="b-toaster-top-right">
        <template>
          The current content is ready to be used offline.
      </template>
    </b-toast>
    <div v-if="loading" class="d-flex justify-content-center mb-3">
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <div v-else>
    <b-container id="staticContent" class="pt-4 pt-md-5" v-if="l_esson.content.length" v-scroll="handleScroll">
      <b-row>
        <b-col>
          <div v-for="(content, index) in l_esson.content" :key="index" :class="{'d-none': content && content.type === 'ONLYINTERNALLINK'}">
            <template v-if="content && content.customObject > -1">
              <template v-if="customObjects[content.customObject].parentCustomObject === -1">
                <div v-for="(elem, index1) in content.content" :key="index1">
                  <template v-if="elem && elem.type=='STARTCUSTOM'">
                    <componentsContent
                        :elem="elem"
                        :customObjects="customObjects"
                        :document="document"
                        :lists="lists"
                        :headingParStyles="headingParStyles"
                        :headingTextStyles="headingTextStyles"
                        :listsCounter="listsCounter"
                        :content="content"
                        :glossary="glossary"
                        :glossaryUrls="glossaryUrls"
                        :name="name"
                        :answerLink="answerLink"
                        :lessonpk="lessonpk"
                        :id="id"
                        :userId="userId"
                        :type="type"
                        :index="index"
                        :index1="index1"
                        :getImage="getImage"
                        :nameSectionMenu="l_esson.nameSectionMenu"
                        :login="login"
                        @listCounterUpdate="listsIndex"
                        @changeSection="changeSection"
                        @movingto="movingto"
                        @ready="updateDocLoaded"
                        />
                  </template>
                </div>
              </template>
            </template>
            <template v-else>
              <headingsContent
                :content="content"
                :getImage="getImage"
                :nameSectionMenu="l_esson.nameSectionMenu"
                :name="name"
                :id="id"
                :lessonpk="id"
                :userId="userId"
                :type="type"
                :customObjects="customObjects"
                :lists="lists"
                :customObject="-1"
                :listsCounter="listsCounter"
                :headingParStyles="headingParStyles"
                :headingTextStyles="headingTextStyles"
                :glossary="glossary"
                :glossaryUrls="glossaryUrls"
                :index="index"
                @listCounterUpdate="listsIndex"
                @changeSection="changeSection"
                @movingto="movingto"
              />
            </template>
          </div>
        </b-col>
      </b-row>
    </b-container>
    </div>
    <template v-if="arrayPages.includes($route.params.documentid)">
    <Navigation
      :pages="arrayPages"
      :position="arrayPages.indexOf($route.params.documentid)"></Navigation>
    </template>
    <div class="text-right d-print-none">
      <b-button
        id="scrollToTopBtn"
        class="m-4 btn-goToTop"
        @click="scrollToTop">
        Go to top
      </b-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import componentsContent from '@/components/lessonSubElements/componentsContent'
// import Collapsable from '@/components/customObjects/Collapsable'
import createJWT from '@/mixins/createJWT'
import parseLessons from '@/mixins/parseLesson'
import Navigation from '@/components/NavigationStaticPage'

Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

export default {
  mixins: [createJWT, parseLessons],
  name: 'staticContent',
  data () {
    return {
      type: '',
      documentIdContent: '1JWZDJM1MqEyklu3wjgCjoctwgjvYDDFAEDVeqb-2xU0',
      document: {
        title: '',
        body: {
          content: []
        }
      },
      lists: {},
      loading: true,
      headingParStyles: {},
      headingTextStyles: {},
      lesson: {
        title: [],
        menu: [],
        content: []
      },
      l_esson: {
        content: []
      },
      currentCustomObject: { indent: -1, nesting: -1, parent: null },
      customObjects: [],
      listsCounter: {},
      glossary: [],
      glossaryCount: {},
      glossaryUrls: [process.env.VUE_APP_GLOSSARY_URL],
      selectedLanguage: 'En',
      languages: {
        En: 1,
        Es: 2
      },
      answerLink: '',
      lessonpk: '',
      userId: null,
      name: '',
      id: null,
      contentCount: 0,
      shouldMakeGlossary: true,
      docLoaded: { root: false, glossary: false },
      isReady: false,
      login: false,
      arrayPages: [
        '1snngMuXvtAyJaXNuvDKyTh43ZkWNNIXi2Dq8FdMLQ9Y',
        '1wg3VQ2v3YprkG5s1xcGNVeIpnqVgQcCcbPSevuGtTSk',
        '1luc4ZB9bVAFGnBhqsCb607MWYexx93A4P42bTT4PkDg',
        '1ZTgtqj_h6DBIRXlSfFPjWuDICipWYDaG5fp3KZtUQf8',
        '10ybBR8HI9ZJoXhGhyrYa4Ej9pBm1eS6admwSlEWCXpA',
        '1eI19kSR5s3IedeLzXkIe2A0rBMzCozVSNFS-HpQaZoo'
      ]
    }
  },
  mounted: function () {},
  created: function () {
    this.documentIdContent = (Object.prototype.hasOwnProperty.call(this.$route.params, 'documentid')) ? this.$route.params.documentid : '1JWZDJM1MqEyklu3wjgCjoctwgjvYDDFAEDVeqb-2xU0'
    this.getDocumentContent()
    this.loading = true
  },
  methods: {
    async getDocumentContent () {
      if (window.navigator.onLine) {
        await this.createJwt()
      }
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}` }
      }
      if (!window.navigator.onLine) {
        config.timeout = 2
      }
      await axios.get(process.env.VUE_APP_DOCUMENTS_URL + this.documentIdContent, config).then(async response => {
        this.document = response.data
        await this.$store.commit('addDocument', [process.env.VUE_APP_DOCUMENTS_URL + this.documentIdContent, response.data])
        this.parseDocument(response)
        this.loading = false
        this.docLoaded.root = true
        if (Object.values(this.docLoaded).every(Boolean)) {
          this.isReady = true
        }
      }).catch(async error => {
        console.log(error)
        this.document = await this.$store.state.documents[process.env.VUE_APP_DOCUMENTS_URL + this.documentIdContent]
        if (this.document) {
          this.parseDocument()
        }
        this.loading = false
      })
    },
    tableCellStyle (paddingLeft = 0, paddingRight = 0, paddingTop = 0, paddingBottom = 0, minRowHeight = 0) {
      return {
        'padding-left': `${paddingLeft}pt`,
        'padding-right': `${paddingRight}pt`,
        'padding-bottom': `${paddingBottom}pt`,
        'padding-top': `${paddingTop}pt`,
        'min-height': `${minRowHeight}pt`
      }
    },
    listsIndex (listId, nestingLevel = 0) {
      if (!this.listsCounter[listId]) {
        this.listsCounter[listId] = {}
      }
      if (!this.listsCounter[listId][nestingLevel]) {
        this.listsCounter[listId][nestingLevel] = 0
      }
      this.listsCounter[listId][nestingLevel] += 1
    },
    changeSection (sectionTitle) {
      const newSectiontitle = sectionTitle[0].toUpperCase() + sectionTitle.slice(1) + '\n'
      this.$refs.secmenu.changeContent(newSectiontitle)
      this.scrollToTop()
    },
    updateDocLoaded (customObject) {
      this.docLoaded[customObject] = true
      if (Object.values(this.docLoaded).every(Boolean)) {
        this.isReady = true
      }
    },
    async movingto (sectionTitle, movetosection, ref, wait = true) {
      if (sectionTitle !== undefined) {
        const newSectiontitle = sectionTitle[0].toUpperCase() + sectionTitle.slice(1) + '\n'
        this.$refs.secmenu.changeContent(newSectiontitle)
      }
      if (wait) {
        await this.sleep(600)
      }
      this.$root.$emit('Scroll', ref)
      let name = 'Lesson'
      switch (this.type) {
        case 'student':
          name = 'Lesson'
          break
        case 'teachers':
          name = 'teachersLessons'
          break
        case 'blackboard':
          name = 'teachersLessonsBlackBoard'
          break
        case 'projector':
          name = 'teachersLessonsProjector'
          break
        default:
          break
      }
      if (wait) {
        this.$router.push({ name: name, params: { coursesslug: this.$route.params.coursesslug, lessonsslug: this.$route.params.lessonsslug }, query: { section: sectionTitle, internallink: ref } })
      }
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    handleScroll: function () {
      var scrollToTopBtn = document.getElementById('scrollToTopBtn')
      var rootElement = document.documentElement
      var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
      if (scrollToTopBtn !== null) {
        if ((rootElement.scrollTop / scrollTotal) > 0.10) {
          scrollToTopBtn.classList.add('showBtn')
        } else {
          scrollToTopBtn.classList.remove('showBtn')
        }
      }
    }
  },
  components: {
    componentsContent,
    headingsContent: () => import('@/components/lessonSubElements/headingsContent'),
    Navigation
  }
}
</script>

<style lang="scss" scoped>

@import '@/assets/styles/_colors.scss';

.btn-goToTop {
  width: 5rem;
  max-width: 5rem;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 100;
  opacity: 0;
  transform: translateY(100px);
  transition: all .5s ease;
  border-color: $fuchsia;
  background-color: $fuchsia;
  color: white;
  font-size: 14pt;
  line-height: 1.2;
  &.showBtn {
    opacity: 1;
    transform: translateY(0)
  }
  &:hover {
    background-color: $pink;
  }
}
</style>

<style type="text/css">
  #staticContent .contentCollapsable.opened .collapse.show .contentRegularText {
    margin-left: 0 !important
  }
</style>
