import axios from 'axios'
import createJWT from '@/mixins/createJWT'

export default {
  methods: {
    parseDocument () {
      if (this.document.namedStyles) {
        for (let i = 0; i < this.document.namedStyles.styles.length; i++) {
          this.headingParStyles[this.document.namedStyles.styles[i].namedStyleType] = this.headingStyle(
            this.document.namedStyles.styles[i].paragraphStyle,
            this.document.namedStyles.styles[i].textStyle,
            true
          )
          this.headingTextStyles[this.document.namedStyles.styles[i].namedStyleType] = this.headingStyle(
            this.document.namedStyles.styles[i].paragraphStyle,
            this.document.namedStyles.styles[i].textStyle,
            false
          )
        }
      }
      this.lists = this.document.lists
      const notContent = ['SUBTITLE', 'TITLE']
      const _document = JSON.parse(JSON.stringify(this.document))
      const _base = _document.body.content
      for (let x = 0; x < _base.length; x++) {
        let save = true
        if (Object.prototype.hasOwnProperty.call(_base[x], 'paragraph')) {
          if (Object.prototype.hasOwnProperty.call(_base[x].paragraph, 'paragraphStyle')) {
            if (Object.prototype.hasOwnProperty.call(_base[x].paragraph.paragraphStyle, 'namedStyleType')) {
              if (notContent.includes(_base[x].paragraph.paragraphStyle.namedStyleType)) {
                if (_base[x].paragraph.paragraphStyle.namedStyleType === 'TITLE') {
                  this.lesson.title.push(_base[x].paragraph.elements[0].textRun.content)
                }
                save = false
              }
              if (_base[x].paragraph.paragraphStyle.namedStyleType === 'HEADING_1') {
                if (Object.prototype.hasOwnProperty.call(_base[x].paragraph.elements[0], 'textRun')) {
                  this.lesson.menu.push({
                    content: _base[x].paragraph.elements[0].textRun.content,
                    anchor: ''
                  })
                }
              }
            }
          }
        }
        if (save) {
          this.lesson.content.push(_base[x])
        }
        this.contentCount = x
        this.l_esson.content.push(this.formatContent(_base[x]))
      }
      for (let i = 0; i < this.customObjects.length; i++) {
        if (this.customObjects[i].type === 'glossary') {
          this.glossaryUrls.push(this.customObjects[i].content[1].content[0].content.replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', ''))
        }
      }
      if (this.shouldMakeGlossary) {
        this.makeGlossary()
      }
    },
    formatContent (content = {}) {
      if (Object.prototype.hasOwnProperty.call(content, 'table')) {
        const obj = {
          type: 'TABLE',
          content: this.formatContentTable(content.table)
        }
        if (this.currentCustomObject.nesting > -1) {
          obj.customObject = this.currentCustomObject.indent
          obj.parentCustomObject = this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
          this.customObjects[obj.customObject].parentCustomObject = this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
          this.customObjects[obj.customObject].content.push(obj)
          if (obj.parentCustomObject != null && obj.parentCustomObject > -1) {
            this.customObjects[obj.customObject].parentCustomObject = this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
            this.customObjects[obj.parentCustomObject].content.push(obj)
          }
        }
        return obj
      } else {
        return this.formatContentParagraph(content, true)
      }
    },
    formatContentTable (content = {}) {
      let columnsToIgnore = 0
      const rowsToIgnore = {}
      const rowsColumnsToIgnore = {}
      let rowIndextoIgnore = []
      const tableRows = []
      for (let x = 0; x < content.tableRows.length; x++) {
        const tableCells = []
        for (let y = 0; y < content.tableRows[x].tableCells.length; y++) {
          const newContent = []
          for (let z = 0; z < content.tableRows[x].tableCells[y].content.length; z++) {
            newContent.push(this.formatContentParagraph(content.tableRows[x].tableCells[y].content[z], false))
          }
          if (rowIndextoIgnore.includes(y)) {
            rowsToIgnore[y] -= 1
            if (rowsToIgnore[y] === 0) {
              rowIndextoIgnore = rowIndextoIgnore.filter(k => k !== y)
            }
            columnsToIgnore = rowsColumnsToIgnore[y]
            continue
          }
          if (content.tableRows[x].tableCells[y].tableCellStyle.rowSpan > 1) {
            rowIndextoIgnore.push(y)
            rowsToIgnore[y] = content.tableRows[x].tableCells[y].tableCellStyle.rowSpan - 1
            rowsColumnsToIgnore[y] = content.tableRows[x].tableCells[y].tableCellStyle.columnSpan - 1
          }
          if (columnsToIgnore > 0) {
            columnsToIgnore -= 1
            continue
          }
          tableCells.push(
            {
              content: newContent,
              tableCellStyle: content.tableRows[x].tableCells[y].tableCellStyle
            }
          )
          columnsToIgnore = content.tableRows[x].tableCells[y].tableCellStyle.columnSpan - 1
        }
        tableRows.push(
          {
            tableCells,
            tableRowStyle: content.tableRows[x].tableRowStyle
          }
        )
      }
      return {
        tableRows,
        tableStyle: content.tableStyle
      }
    },
    isEmptyLine (contentArray) {
      if (contentArray.length === 2 && contentArray[0].content === '' && (['STARTCUSTOM', 'ENDCUSTOM'].includes(contentArray[0].type))) {
        return true
      }
      return false
    },
    isOnlyInternalLink (contentArray) {
      if (contentArray.length > 0 && contentArray[0].type === 'internallink') {
        return true
      }
      return false
    },
    formatContentParagraph (content = {}, isNotTable = true) {
      if (Object.prototype.hasOwnProperty.call(content, 'paragraph')) {
        if (Object.prototype.hasOwnProperty.call(content.paragraph, 'paragraphStyle')) {
          if (Object.prototype.hasOwnProperty.call(content.paragraph.paragraphStyle, 'namedStyleType')) {
            const contentArray = content.paragraph.elements.map((_, index) => { return this.formatContentText(content, index) })
            const newContentArray = this.addInlinecustomObject(contentArray)
            let obj
            if (this.isEmptyLine(newContentArray)) {
              obj = {
                type: 'IGNORE',
                content: newContentArray
              }
            } else if (this.isOnlyInternalLink(newContentArray)) {
              obj = {
                type: 'ONLYINTERNALLINK',
                content: newContentArray
              }
            } else {
              obj = {
                type: content.paragraph.paragraphStyle.namedStyleType,
                content: newContentArray
              }
            }
            if (Object.prototype.hasOwnProperty.call(content.paragraph, 'bullet')) {
              obj.listId = content.paragraph.bullet.listId
              obj.bulletStyle = content.paragraph.bullet.textStyle
              obj.nestingLevel = this.getNestingLevel(content)
              obj.type = 'LIST'
            }
            if (this.currentCustomObject.nesting > -1 && isNotTable) {
              obj.customObject = this.currentCustomObject.indent
              obj.parentCustomObject = this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
              this.customObjects[obj.customObject].parentCustomObject = this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
              this.customObjects[obj.customObject].content.push(obj)
              this.customObjects[obj.customObject].parentCustomObject = this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
              if (obj.parentCustomObject != null && obj.parentCustomObject > -1) {
                this.customObjects[obj.customObject].parentCustomObject = this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
                this.customObjects[obj.parentCustomObject].content.push(obj)
                this.customObjects[obj.customObject].parentCustomObject = this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
              }
            }
            return obj
          }
        }
      }
    },
    getNestingLevel (content) {
      if (Object.prototype.hasOwnProperty.call(content.paragraph.bullet, 'nestingLevel')) {
        return content.paragraph.bullet.nestingLevel
      } else {
        return 0
      }
    },
    formatContentText (content = {}, indent = 0) {
      if (Object.prototype.hasOwnProperty.call(content, 'paragraph')) {
        if (Object.prototype.hasOwnProperty.call(content.paragraph, 'paragraphStyle')) {
          if (Object.prototype.hasOwnProperty.call(content.paragraph.paragraphStyle, 'namedStyleType')) {
            if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent], 'pageBreak')) {
              return ({
                type: 'PAGE_BREAK',
                content: '',
                textStyle: {},
                paragraphStyle: content.paragraph.paragraphStyle
              })
            }
            if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent], 'inlineObjectElement') && Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent].inlineObjectElement.textStyle, 'link')) {
              return ({
                type: 'IMAGELINK',
                content: content.paragraph.elements[indent].inlineObjectElement,
                textStyle: {},
                paragraphStyle: content.paragraph.paragraphStyle,
                link: content.paragraph.elements[indent].inlineObjectElement.textStyle.link.url || ''
              })
            }
            if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent], 'inlineObjectElement')) {
              return ({
                type: 'IMAGE',
                content: content.paragraph.elements[indent].inlineObjectElement,
                textStyle: {},
                paragraphStyle: content.paragraph.paragraphStyle
              })
            }
            if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent], 'horizontalRule')) {
              return ({
                type: 'HORIZONTAL_LINE',
                content: '',
                textStyle: {},
                paragraphStyle: content.paragraph.paragraphStyle
              })
            }
            if (Object.prototype.hasOwnProperty.call(content.paragraph.paragraphStyle, 'namedStyleType')) {
              if (content.paragraph.elements[indent].textRun.content.slice(0, 7) === '#start-') {
                this.currentCustomObject.parent = JSON.parse(JSON.stringify(this.currentCustomObject))
                this.currentCustomObject.indent = this.customObjects.length
                const baseQuery = content.paragraph.elements[indent].textRun.content.split('_')
                const componentNameStart = baseQuery[0].split('~')[0]
                const componentOptions = (baseQuery[0].split('~').length > 1) ? baseQuery[0].split('~')[1].replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '') : ''
                const nameSectionMenu = (baseQuery.length > 1) ? baseQuery[1] : ''
                const typeofCustomObject = componentNameStart.slice(7).replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '')
                if (typeofCustomObject === 'external-word-document') {
                  this.docLoaded[this.currentCustomObject.indent] = false
                }
                this.customObjects.push({
                  type: typeofCustomObject,
                  start: this.contentCount,
                  end: null,
                  content: [],
                  nameSectionMenu: nameSectionMenu.replace(/(?:\r\n|\r|\n)/g, ''),
                  componentOptions: componentOptions
                })
                this.currentCustomObject.nesting += 1
                return ({
                  type: 'STARTCUSTOM',
                  content: '',
                  textStyle: {},
                  paragraphStyle: content.paragraph.paragraphStyle,
                  customObject: this.currentCustomObject.indent,
                  parentCustomObject: this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
                })
              }
              if (content.paragraph.elements[indent].textRun.content.slice(0, 5) === '#end-') {
                for (let i = this.customObjects.length - 1; i >= 0; i--) {
                  if (content.paragraph.elements[indent].textRun.content.slice(5).replace(/(\r\n|\n|\r)/gm, '').replace('\u000B', '') === this.customObjects[i].type) {
                    this.customObjects[i].end = this.contentCount
                    this.currentCustomObject = JSON.parse(JSON.stringify(this.currentCustomObject.parent))
                    break
                  }
                }
                return ({
                  type: 'ENDCUSTOM',
                  content: '',
                  textStyle: {},
                  paragraphStyle: content.paragraph.paragraphStyle,
                  customObject: this.currentCustomObject.indent,
                  parentCustomObject: this.currentCustomObject.parent != null ? this.currentCustomObject.parent.indent : null
                })
              }
              if (content.paragraph.elements[indent].textRun.content === '\n') {
                return ({
                  type: 'BR',
                  content: content.paragraph.elements[indent].textRun.content,
                  textStyle: {},
                  paragraphStyle: content.paragraph.paragraphStyle
                })
              } else if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent].textRun.textStyle, 'link')) {
                return ({
                  isLink: true,
                  type: content.paragraph.paragraphStyle.namedStyleType,
                  content: content.paragraph.elements[indent].textRun.content,
                  textStyle: content.paragraph.elements[indent].textRun.textStyle,
                  paragraphStyle: content.paragraph.paragraphStyle
                })
              } else if (Object.prototype.hasOwnProperty.call(content.paragraph.elements[indent].textRun, 'suggestedInsertionIds')) {
                return ({
                  type: 'SUGGESTION',
                  content: '',
                  textStyle: {},
                  paragraphStyle: {}
                })
              } else {
                return ({
                  type: content.paragraph.paragraphStyle.namedStyleType,
                  content: content.paragraph.elements[indent].textRun.content,
                  textStyle: content.paragraph.elements[indent].textRun.textStyle,
                  paragraphStyle: content.paragraph.paragraphStyle
                })
              }
            }
          }
        }
      }
    },
    async getImage (id) {
      if (this.document && this.document.inlineObjects && Object.prototype.hasOwnProperty.call(this.document.inlineObjects, id) && this.document.inlineObjects[id].inlineObjectProperties.embeddedObject.imageProperties) {
        const imageUrl = this.document.inlineObjects[id].inlineObjectProperties.embeddedObject.imageProperties.contentUri
        const base64ImageUrl = btoa(unescape(encodeURIComponent(imageUrl)))
        try {
          const imageInfo = await axios.get(process.env.VUE_APP_IMAGES_URL + base64ImageUrl)
          return [imageInfo.data.image_url, this.document.inlineObjects[id].inlineObjectProperties.embeddedObject.description]
        } catch (err) {
          console.log(err)
        }
        return [this.document.inlineObjects[id].inlineObjectProperties.embeddedObject.imageProperties.contentUri, this.document.inlineObjects[id].inlineObjectProperties.embeddedObject.description]
      } else {
        return ['', '']
      }
    },
    partOfCustomObject (index) {
      for (let i = 0; i < this.customObjects.length; i++) {
        if (index > this.customObjects[i].start && index < this.customObjects[i].end) {
          return true
        }
      }
      return false
    },
    async makeGlossary () {
      if (window.navigator.onLine) {
        await this.createJwt()
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('gapi.access_token')}`
        }
      }
      if (!window.navigator.onLine) {
        config.timeout = 2
      }
      let glossary = this.glossaryUrls
      glossary = [...new Set(glossary)]
      glossary = glossary.filter(function (value) { return value !== '' })
      for (let i = 0; i < glossary.length; i++) {
        await axios.get('https://sheets.googleapis.com/v4/spreadsheets/' + glossary[i] + '?includeGridData=true', config).then(async response => {
          const document = response.data
          await this.$store.commit('addGlossary', ['https://sheets.googleapis.com/v4/spreadsheets/' + glossary[i], response.data])
          this.parseGlossary(document)
          this.docLoaded.glossary = true
          if (Object.values(this.docLoaded).every(Boolean)) {
            const keys = {
              teachers: 'teacherDocOffline',
              student: 'studentDocOffline',
              projector: 'teacherProjectorDocOffline',
              blackboard: 'teacherBlackboardOffline'
            }
            this.isReady = await this.$store.dispatch('makeAvailableOffline', [this.coursesslug, this.lessonsslug, keys[this.type]])
          }
        })
          .catch(async error => {
            console.log(error)
            const document = await this.$store.state.glossaries['https://sheets.googleapis.com/v4/spreadsheets/' + glossary[i]]
            if (document) {
              this.parseGlossary(document)
            }
          })
      }
    },
    addInlinecustomObject (contentArray, isNotTable) {
      const newContentArray = []
      const regularType = ['NORMAL_TEXT', 'HEADING_1', 'HEADING_2', 'HEADING_3', 'HEADING_4']
      for (let i = 0; i < contentArray.length; i++) {
        if (regularType.includes(contentArray[i].type) && Object.prototype.hasOwnProperty.call(contentArray[i], 'content')) {
          let currentContent = ''
          let readingInlineObjectName = false
          for (let j = 0; j < contentArray[i].content.length; j++) {
            if (readingInlineObjectName === false && contentArray[i].content[j] === '<') {
              if (currentContent.length) {
                const newObject = JSON.parse(JSON.stringify(contentArray[i]))
                newObject.content = currentContent
                newContentArray.push(
                  newObject
                )
                currentContent = ''
              }
              readingInlineObjectName = true
            } else if (readingInlineObjectName && contentArray[i].content[j] === '>') {
              const customType = currentContent.split('-')
              const allowedCustomTypes = ['glossary', 'fontawesome', 'sectionchanger', 'print', 'gototop', 'sectionchangerback', 'internallink', 'moveto', 'sectionchangerforward', 'pathsamewindow', 'pathnewwindow']
              const newObject = JSON.parse(JSON.stringify(contentArray[i]))
              if (allowedCustomTypes.includes(customType[0])) {
                newObject.content = customType.slice(1).join('-')
                newObject.type = customType[0]
                newObject.hide = true
                newContentArray.push(
                  newObject
                )
              } else {
                newObject.content = '<' + currentContent + '>'
                newContentArray.push(
                  newObject
                )
              }
              currentContent = ''
              readingInlineObjectName = false
            } else {
              currentContent += contentArray[i].content[j]
            }
          }
          if (currentContent.length) {
            const newObject = JSON.parse(JSON.stringify(contentArray[i]))
            newObject.content = currentContent
            newContentArray.push(
              newObject
            )
            currentContent = ''
          }
        } else {
          newContentArray.push(contentArray[i])
        }
      }
      return newContentArray
    },
    parseGlossary (document) {
      for (let k = 0; k < document.sheets[0].data[0].rowData.length; k++) {
        if (document.sheets[0].data[0].rowData[k].values[0].formattedValue === 'Word' && document.sheets[0].data[0].rowData[k].values[1].formattedValue === 'Meaning') {
          continue
        }
        const meanings = []
        for (let j = 0; j < document.sheets[0].data[0].rowData[k].values.length; j++) {
          if (Object.prototype.hasOwnProperty.call(document.sheets[0].data[0].rowData[k].values[j], 'formattedValue')) {
            meanings.push(document.sheets[0].data[0].rowData[k].values[j].formattedValue.trim())
          } else {
            meanings.push('')
          }
        }
        if (Object.prototype.hasOwnProperty.call(document.sheets[0].data[0].rowData[k].values[0], 'formattedValue')) {
          const data = {
            word: document.sheets[0].data[0].rowData[k].values[0].formattedValue.trim(),
            meaning: meanings
          }
          this.glossary.push(data)
        }
      }
    },
    headingStyle (paragraphStyle = {}, textStyle = {}, mode = true) {
      const firstStyle = this.dynamicStyle(
        textStyle.foregroundColor ? textStyle.foregroundColor.color.rgbColor.red : null,
        textStyle.foregroundColor ? textStyle.foregroundColor.color.rgbColor.green : null,
        textStyle.foregroundColor ? textStyle.foregroundColor.color.rgbColor.blue : null,
        textStyle.bold,
        textStyle.fontSize ? textStyle.fontSize.magnitude : null,
        textStyle.italic,
        textStyle.underline,
        textStyle.strikethrough,
        textStyle.backgroundColor ? (textStyle.backgroundColor.color ? textStyle.backgroundColor.color.rgbColor.red : null) : null,
        textStyle.backgroundColor ? (textStyle.backgroundColor.color ? textStyle.backgroundColor.color.rgbColor.green : null) : null,
        textStyle.backgroundColor ? (textStyle.backgroundColor.color ? textStyle.backgroundColor.color.rgbColor.blue : null) : null
      )
      const secondStyle = this.paragraphDynamicStyle(
        paragraphStyle.indentStart ? paragraphStyle.indentStart.magnitude : 'inherit',
        paragraphStyle.alignment,
        paragraphStyle.spaceAbove,
        paragraphStyle.spaceBelow,
        paragraphStyle.borderBottom,
        paragraphStyle.borderLeft,
        paragraphStyle.borderRight,
        paragraphStyle.borderTop
      )
      let thirdStyle = {}
      if (Object.prototype.hasOwnProperty.call(textStyle, 'weightedFontFamily')) {
        thirdStyle = this.dynamicFontStyle(textStyle.weightedFontFamily.fontFamily, textStyle.weightedFontFamily.weight)
      }
      if (mode) {
        return secondStyle
      } else {
        if (Object.prototype.hasOwnProperty.call(thirdStyle, 'font-family')) {
          firstStyle['font-family'] = thirdStyle['font-family']
        }
        if (Object.prototype.hasOwnProperty.call(thirdStyle, 'font-family') && firstStyle.weight === 'inherit') {
          firstStyle.weight = thirdStyle.weight
        }
        return firstStyle
      }
    },
    dynamicStyle (redComp = null,
      greenComp = null,
      blueComp = null,
      bold = false,
      size = null,
      italic = false,
      underline = false,
      strikethrough = false,
      bgRedComp = null,
      bgGreenComp = null,
      bgBlueComp = null) {
      let newRed = 0
      let newGreen = 0
      let newBlue = 0
      let newBgRed = 0
      let newBgGreen = 0
      let newBgBlue = 0
      let color = ''
      let backgroundColor = ''
      if (redComp === null && greenComp === null && blueComp === null) {
        color = 'inherit'
      } else {
        newRed = redComp * 255
        newGreen = greenComp * 255
        newBlue = blueComp * 255
        color = `rgb(${newRed}, ${newGreen}, ${newBlue})`
      }
      if (bgRedComp === null && bgGreenComp === null && bgBlueComp === null) {
        backgroundColor = 'inherit'
      } else {
        newBgRed = bgRedComp * 255
        newBgGreen = bgGreenComp * 255
        newBgBlue = bgBlueComp * 255
        backgroundColor = `rgb(${newBgRed}, ${newBgGreen}, ${newBgBlue})`
      }
      let weight = 'inherit'
      let style = 'inherit'
      let decoration = 'inherit'
      if (bold) {
        weight = 'bold'
      }
      if (italic) {
        style = 'italic'
      }
      if (underline || strikethrough) {
        decoration = ''
        if (underline) {
          decoration = decoration + 'underline '
        }
        if (strikethrough) {
          decoration = decoration + 'line-through '
        }
      }
      let fontsize = 'inherit'
      if (size) {
        fontsize = `${2 * size}pt`
      }
      const dinStyle = {
        color,
        'font-weight': weight,
        'font-size': fontsize,
        'font-style': style,
        'white-space': 'pre-line',
        'text-decoration': decoration,
        'background-color': backgroundColor
      }
      return dinStyle
    },
    paragraphDynamicStyle (indent = 'inherit', alignment = 'inherit', spaceAbove = 'inherit', spaceBelow = 'inherit', borderBottom = {}, borderLeft = {}, borderRight = {}, borderTop = {}) {
      if (alignment === 'CENTER') {
        alignment = 'center'
      } else if (alignment === 'JUSTIFIED') {
        alignment = 'justify'
      } else if (alignment === 'END') {
        alignment = 'right'
      }
      let marginTop = spaceAbove.magnitude ? spaceAbove.magnitude : 'inherit'
      if (marginTop !== 'inherit') {
        marginTop = `${marginTop}pt`
      }
      let marginBelow = spaceBelow.magnitude ? spaceBelow.magnitude : 'inherit'
      if (marginBelow !== 'inherit') {
        marginBelow = `${marginBelow}pt`
      }
      if (indent !== 'inherit') {
        indent = `${indent}pt`
      }
      let borderBottomColorRed = 0
      let borderBottomColorBlue = 0
      let borderBottomColorGreen = 0
      let borderBottomColorStyle = 'none'
      let borderBottomColorWidth = 'medium'
      if (borderBottom) {
        if (borderBottom.color && borderBottom.color.color && borderBottom.color.color.rgbColor) {
          if (borderBottom.color.color.rgbColor.red) {
            borderBottomColorRed = 255 * borderBottom.color.color.rgbColor.red
          }
          if (borderBottom.color.color.rgbColor.blue) {
            borderBottomColorBlue = 255 * borderBottom.color.color.rgbColor.blue
          }
          if (borderBottom.color.color.rgbColor.green) {
            borderBottomColorGreen = 255 * borderBottom.color.color.rgbColor.green
          }
          if (borderBottom.dashStyle) {
            borderBottomColorStyle = borderBottom.dashStyle.toLowerCase()
          }
          if (borderBottom.width && borderBottom.width.magnitude) {
            borderBottomColorWidth = borderBottom.width.magnitude.toString() + borderBottom.width.unit.toLowerCase()
          }
        }
      }
      let borderLeftColorRed = 0
      let borderLeftColorBlue = 0
      let borderLeftColorGreen = 0
      let borderLeftColorStyle = 'none'
      let borderLeftColorWidth = 'medium'
      if (borderLeft) {
        if (borderLeft.color && borderLeft.color.color && borderLeft.color.color.rgbColor) {
          if (borderLeft.color.color.rgbColor.red) {
            borderLeftColorRed = 255 * borderLeft.color.color.rgbColor.red
          }
          if (borderLeft.color.color.rgbColor.blue) {
            borderLeftColorBlue = 255 * borderLeft.color.color.rgbColor.blue
          }
          if (borderLeft.color.color.rgbColor.green) {
            borderLeftColorGreen = 255 * borderLeft.color.color.rgbColor.green
          }
          if (borderLeft.dashStyle) {
            borderLeftColorStyle = borderLeft.dashStyle.toLowerCase()
          }
          if (borderLeft.width && borderLeft.width.magnitude) {
            borderLeftColorWidth = borderLeft.width.magnitude.toString() + borderLeft.width.unit.toLowerCase()
          }
        }
      }
      let borderRightColorRed = 0
      let borderRightColorBlue = 0
      let borderRightColorGreen = 0
      let borderRightColorStyle = 'none'
      let borderRightColorWidth = 'medium'
      if (borderRight) {
        if (borderRight.color && borderRight.color.color && borderRight.color.color.rgbColor) {
          if (borderRight.color.color.rgbColor.red) {
            borderRightColorRed = 255 * borderRight.color.color.rgbColor.red
          }
          if (borderRight.color.color.rgbColor.blue) {
            borderRightColorBlue = 255 * borderRight.color.color.rgbColor.blue
          }
          if (borderRight.color.color.rgbColor.green) {
            borderRightColorGreen = 255 * borderRight.color.color.rgbColor.green
          }
          if (borderRight.dashStyle) {
            borderRightColorStyle = borderRight.dashStyle.toLowerCase()
          }
          if (borderRight.width && borderRight.width.magnitude) {
            borderRightColorWidth = borderRight.width.magnitude.toString() + borderRight.width.unit.toLowerCase()
          }
        }
      }
      let borderTopColorRed = 0
      let borderTopColorBlue = 0
      let borderTopColorGreen = 0
      let borderTopColorStyle = 'none'
      let borderTopColorWidth = 'medium'
      if (borderTop) {
        if (borderTop.color && borderTop.color.color && borderTop.color.color.rgbColor) {
          if (borderTop.color.color.rgbColor.red) {
            borderTopColorRed = 255 * borderTop.color.color.rgbColor.red
          }
          if (borderTop.color.color.rgbColor.blue) {
            borderTopColorBlue = 255 * borderTop.color.color.rgbColor.blue
          }
          if (borderTop.color.color.rgbColor.green) {
            borderTopColorGreen = 255 * borderTop.color.color.rgbColor.green
          }
          if (borderTop.dashStyle) {
            borderTopColorStyle = borderTop.dashStyle.toLowerCase()
          }
          if (borderTop.width && borderTop.width.magnitude) {
            borderTopColorWidth = borderTop.width.magnitude.toString() + borderTop.width.unit.toLowerCase()
          }
        }
      }
      return {
        'padding-left': indent,
        'text-align': alignment,
        'margin-top': marginTop,
        'margin-bottom': marginBelow,
        'border-bottom': borderBottomColorWidth + ' ' + borderBottomColorStyle + ` rgb(${borderBottomColorRed}, ${borderBottomColorGreen}, ${borderBottomColorBlue})`,
        'border-top': borderTopColorWidth + ' ' + borderTopColorStyle + ` rgb(${borderTopColorRed}, ${borderTopColorGreen}, ${borderTopColorBlue})`,
        'border-right': borderRightColorWidth + ' ' + borderRightColorStyle + ` rgb(${borderRightColorRed}, ${borderRightColorGreen}, ${borderRightColorBlue})`,
        'border-left': borderLeftColorWidth + ' ' + borderLeftColorStyle + ` rgb(${borderLeftColorRed}, ${borderLeftColorGreen}, ${borderLeftColorBlue})`
      }
    },
    dynamicFontStyle (fontFamily = 'Arial', weight = 400) {
      return {
        'font-family': fontFamily,
        'font-weight': weight
      }
    },
    listsIndex (listId, nestingLevel = 0) {
      if (!this.listsCounter[listId]) {
        this.listsCounter[listId] = {}
      }
      if (!this.listsCounter[listId][nestingLevel]) {
        this.listsCounter[listId][nestingLevel] = 0
      }
      this.listsCounter[listId][nestingLevel] += 1
    }
  },
  mixins: [
    createJWT
  ]
}
