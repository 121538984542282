var data = [
  {
    health_actions: 'hand washing',
    health_problem_or_goal: 'Covid-19, respiratory infections, diarrhoea',
    claimed_effects: 'prevents Covid 19, prevents respiratory infections, prevents diarrhoea',
    actual_effects: 'Hand washing by school children can reduce Covid-19, respiratory infections, and diarrhoea.',
    evidence_summary: 'Respiratory infections are infectious diseases of the respiratory (breathing) system. The respiratory system includes the nose, throat, and lungs. Respiratory infections include the common cold, flu, and pneumonia (lung infection). In reliable comparisons, researchers have found that handwashing by schoolchildren (aged six to twelve years) in developing countries can reduce respiratory infections and diarrhoea.',
    references: 'Mbakaya BC , Lee PH , Lee RL. Hand hygiene intervention strategies to reduce diarrhoea and respiratory infections among schoolchildren in developing countries: a systematic review. Int J Environ Res Public Health 2017; 14:371. https://doi.org/10.3390/ijerph14040371',
    lessons: [
      null,
      'L1',
      'L5'
    ],
    principle: [
      null,
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'wearing facemasks',
    health_problem_or_goal: 'Covid-19',
    claimed_effects: 'prevents Covid-19',
    actual_effects: 'The use of facemasks probably provides protection against respiratory viral infections (including Covid-19). It is uncertain whether cloth masks protect against infection.',
    evidence_summary: 'Covid-19 is caused by a virus that is carried in the air (the SARS-CoV-2 virus). Reliable comparisons have shown that the use of facemasks provides some protection against respiratory viral infections in general. This includes the virus that causes Covid-19. The effectiveness may vary depending on the type of facemask used and on how many people are using them. Tight masks that filter the air provide the best protection. It is not certain how much protection medical/surgical masks provide. There are not enough reliable comparisons of masks, especially cloth masks, to say how much protection they provide in community settings.',
    references: 'Kim MS, Seong D, Li H, Chung SK, Park Y, Lee M, Lee SW, Yon DK, Kim JH, Lee KH, Solmi M, Dragioti E, Koyanagi A, Jacob L, Kronbichler A, Tizaoui K, Cargnin S, Terrazzino S, Hong SH, Abou Ghayda R, Radua J, Oh H, Kostev K, Ogino S, Lee IM, Giovannucci E, Barnett Y, Butler L, McDermott D, Ilie PC, Shin JI, Smith L. Comparative effectiveness of N95, surgical or medical, and non-medical facemasks in protection against respiratory virus infection: A systematic review and network meta-analysis. Reviews in medical virology. 2022;:e2336. Tran TQ, Mostafa EM, Tawfik GM, Soliman M, Seshini M, Randi M, Dong VS, Ravikulan R, Alhijazeen S, Farrag DA, Dumre SP, Nguyen Tien H, Hirayama K. Efficacy of face masks against respiratory infectious diseases: a systematic review and network analysis of randomized-controlled trials. Journal of breath research. 2021;',
    lessons: [
      null,
      'L1',
      'L7',
      'L9',
      'L10'
    ],
    principle: [
      null,
      'P2',
      'P4',
      'P6',
      'P8'
    ]
  },
  {
    health_actions: 'social distancing',
    health_problem_or_goal: 'Covid-19',
    claimed_effects: 'prevents spread of Covid-19 ',
    actual_effects: 'Keeping a space of 1 meter or more between yourself and other people you do not live with, and avoiding crowded areas, reduces the spread of Covid-19.',
    evidence_summary: 'The virus that causes Covid-19 spreads from person to person through close contact. In reliable comparisons, researchers have found that transmission of viruses like the virus that causes Covid-19 is lower with physical distancing of one meter or more, compared with less than one meter. Protection against infection is increased as the distance in increased.',
    references: 'Chu DK, Akl EA, Duda S, Solo K, Yaacoub S, Schünemann HJ; COVID-19 Systematic Urgent Review Group Effort (SURGE) study authors. Physical distancing, face masks, and eye protection to prevent person-to-person transmission of SARS-CoV-2 and COVID-19: a systematic review and meta-analysis. Lancet. 2020 Jun 27;395(10242):1973-1987. https://doi.org/10.1016/S0140-6736(20)31142-9',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null,
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'locking down',
    health_problem_or_goal: 'Covid-19',
    claimed_effects: 'prevents spread of Covid-19 ',
    actual_effects: 'Lockdowns enforced by governments decrease Covid cases, deaths and spread in some settings. However, lockdowns also cause harms including increased poverty, and disruption of education and routine medical services.',
    evidence_summary: 'Lockdowns are actions enforced by governments during the Covid-19 pandemic to keep residents at home to minimize the contacts between people. Research, that includes some comparisons, showed that in some settings, lockdowns and stay-at-home orders have been effective in controlling Covid-19 cases, deaths and transmission. It is not clear that lockdowns would have the same effect in all settings. Also, lockdowns have been shown to have harmful effects including increased poverty, education interruption, increase in mental health problems, and decrease in other medical services such as cancer diagnosis and routine vaccinations.',
    references: 'Iezadi S, Gholipour K, Azami-Aghdash S, Ghiasi A, Rezapour A, Pourasghari H, Pashazadeh F. Effectiveness of non-pharmaceutical public health interventions against COVID-19: A systematic review and meta-analysis. PLoS One. 2021 Nov 23;16(11):e0260371. doi: 10.1371/journal.pone.0260371. PMID: 34813628; PMCID: PMC8610259.',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null,
      'P1',
      'P6',
      'P7',
      'P9'
    ]
  },
  {
    health_actions: 'sleeping under bed nets',
    health_problem_or_goal: 'malaria',
    claimed_effects: 'prevents malaria',
    actual_effects: 'Insecticide‐treated bed nets compared to no nets or untreated nets reduce malaria-related illness and child deaths.',
    evidence_summary: 'Insecticide‐treated nets are mosquito nets that are treated with an insecticide that kills mosquitoes that come into contact with it, or repels them. They are draped over a bed or sleeping area to protect against mosquito bites and malaria. In many places some mosquitos are no longer killed by the insecticide that is used. However, in reliable comparisons with no nets or untreated nets, researchers have shown that insecticide-treated bed nets continue to reduce malaria-related illness and child deaths.',
    references: 'Pryce  J, Richardson  M, Lengeler  C. Insecticide‐treated nets for preventing malaria. Cochrane Database of Systematic Reviews 2018, Issue 11. Art. No.: CD000363. DOI: 10.1002/14651858.CD000363.pub3. https://doi.org/10.1002/14651858.CD000363.pub3',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null,
      'P2',
      'P5'
    ]
  },
  {
    health_actions: 'exercising regularly',
    health_problem_or_goal: 'health, weight loss, heart disease',
    claimed_effects: 'improves health, helps weight loss, prevents heart disease',
    actual_effects: 'Exercise is effective for losing weight, and reducing the chance of getting heart disease, especially when combined with a diet. ',
    evidence_summary: 'Being overweight or obese is associated with serious health conditions, including diabetes, heart disease, and stroke. In reliable comparisons, researchers have shown that that exercise has a positive effect on body weight and other risk factors for heart disease in people who are overweight, particularly when combined with diet. They also show that exercise improves health even if no weight is lost.',
    references: 'Shaw  KA, Gennat  HC, O\'Rourke  P, Del Mar  C. Exercise for overweight or obesity. Cochrane Database of Systematic Reviews 2006, Issue 4. Art. No.: CD003817. https://doi.org/10.1002/4651858.CD003817.pub3',
    lessons: [
      null,
      'L1',
      'L2',
      'L6',
      'L8',
      'L9',
      'L10'
    ],
    principle: [
      null,
      'P3',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'stopping bleeding',
    health_problem_or_goal: 'severe injuries/wounds',
    claimed_effects: 'saves lives',
    actual_effects: 'Not stopping blood loss from wounds saves lives.',
    evidence_summary: 'People with uncontrolled bleeding can die within minutes. Direct pressure or tourniquets can stop the bleeding and may improve recovery and prevent deaths. In comparisons, researchers found that stopping bleeding from severe wounds reduced deaths and sickness from blood loss. However, the evidence of how best to stop bleeding is uncertain.',
    references: 'Nathan P. Charlton, Janel M. Swain, Jan L. Brozek, Maja Ludwikowska, Eunice Singletary, David Zideman, Jonathan Epstein, Andrea Darzi, Anna Bak, Samer Karam, Zbigniew Les, Jestin N. Carlson, Eddy Lang &amp; Robby Nieuwlaat (2021) Control of Severe, Life-Threatening External Bleeding in the Out-of-Hospital Setting: A Systematic Review, Prehospital Emergency Care, 25:2, 235-267, DOI: 10.1080/10903127.2020.1743801 ',
    lessons: [
      null,
      'L1',
      'L3',
      'L8'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'eating vegetables and fruit ',
    health_problem_or_goal: 'health, heart disease, cancer',
    claimed_effects: 'improves health, prevents heart disease, prevents cancer',
    actual_effects: 'Regularly eating a variety of fruits and vegetables and having a healthy diet is associated with living longer, less heart disease, less overweight, and less heart disease.',
    evidence_summary: 'In reliable comparisons, researchers have found that regularly eating a variety of fruits and vegetables is associated with living longer, less heart disease, less overweight, and less heart disease. Regularly eating whole grains or cereal, legumes (e.g., beans, peas, soybeans, peanuts), and fish are also associated with those outcomes. Eating read meat and processed meat is associated with a higher risk of those outcomes.',
    references: 'Scientific Report of the 2020 Dietary Guidelines Advisory Committee https://www.dietaryguidelines.gov/2020-advisory-committee-report PART D. CHAPTER 8: DIETARY PATTERNS  p.13-14 https://www.dietaryguidelines.gov/sites/default/files/2020-07/PartD_Ch8_DietaryPatterns_first-print.pdf  (Table D8.1, p.39)',
    lessons: [
      null,
      'L1',
      'L8'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'taking vitamin supplements',
    health_problem_or_goal: 'health, heart disease, cancer',
    claimed_effects: 'prevents cancer, prevents heart disease, prevents dementia, prevents death',
    actual_effects: 'Vitamin supplements have little or no benefit in preventing cancer, heart disease, dementia and death. Some vitamin supplements can have harmful effects.',
    evidence_summary: 'Many healthy people take vitamin and mineral supplements, sometimes as multivitamins, because they believe that they are good for their health. Vitamins and minerals are necessary for our bodies to function well. Most people who have a varied diet get the vitamins and minerals that they need in their food. In reliable comparisons, researchers found that vitamin and mineral supplements were associated with little or no benefit in preventing cancer, heart disease, cognitive decline, dementia and death, with the exception of a possible small benefit for cancer incidence with multivitamin use. Some supplements may slightly increase the risk of death.',
    references: 'O’Connor EA, Evans CV, Ivlev I, et al. Vitamin and Mineral Supplements for the Primary Prevention of Cardiovascular Disease and Cancer: Updated Evidence Report and Systematic Review for the US Preventive Services Task Force. JAMA. 2022;327(23):2334–2347. doi:10.1001/jama.2021.15650. Rutjes AWS, Denton DA, Di Nisio M, Chong LY, Abraham RP, Al‐Assaf AS, Anderson JL, Malik MA, Vernooij RWM, Martínez G, Tabet N, McCleery J. Vitamin and mineral supplementation for maintaining cognitive function in cognitively healthy people in mid and late life. Cochrane Database of Systematic Reviews 2018, Issue 12. Art. No.: CD011906. DOI: 10.1002/14651858.CD011906.pub2. ',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null,
      'P1',
      'P3',
      'P4',
      'P6',
      'P7'
    ]
  },
  {
    health_actions: 'wearing helmets',
    health_problem_or_goal: 'head injury',
    claimed_effects: 'prevents serious head injury',
    actual_effects: 'Helmet use reduces the risk of head injury and death in motorcyclists involved in traffic crashes.',
    evidence_summary: 'Motorcyclists are at high risk of head injury in traffic crashes. In reliable comparisons, researchers found that helmet use greatly reduces the risk of head injury and death, compared with no helmet use. Helmets may also protect against facial injuries, and do not increase the risk of neck injuries. The research supports recommendations that helmets are encouraged worldwide for motorcycle rider safety.',
    references: 'Liu BC, Ivers R, Norton R, Boufous S, Blows S, Lo SK. Helmets for preventing injury in motorcycle riders. Cochrane Database of Systematic Reviews 2008, Issue 1. Art. No.: CD004333. DOI: 10.1002/14651858.CD004333.pub3',
    lessons: [
      null,
      'L1',
      'L7',
      'L9'
    ],
    principle: [
      null,
      'P2',
      'P5',
      'P7'
    ]
  },
  {
    health_actions: 'using health apps',
    health_problem_or_goal: 'physical activity',
    claimed_effects: 'increases health behaviours eg physical activity',
    actual_effects: 'Mobile phone apps make little or no difference in increasing the amount of physical activity people do.',
    evidence_summary: 'There are many mobile phone apps available that aim to help people increase the amount of physical activity they do. In reliable comparisons between people using such apps and others not using these apps, researchers found that apps made little or no difference to physical activity, measured as steps taken per day. Short-term physical activity programmes (less than three months) may be more effective than apps with programmes longer than 3 months.',
    references: 'Romeo A, Edney S, Plotnikoff R, Curtis R, Ryan J, Sanders I, Crozier A, Maher C Can Smartphone Apps Increase Physical Activity? Systematic Review and Meta-Analysis J Med Internet Res 2019;21(3):e12053 doi: 10.2196/12053',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null,
      'P2',
      'P5',
      'P6',
      'P7'
    ]
  },
  {
    health_actions: 'drinking herbal tea',
    health_problem_or_goal: 'stomach sickness',
    claimed_effects: 'cures stomach sickness',
    actual_effects: 'There is no evidence from comparisons in people with stomach sickness that herbal teas cure stomach sickness. ',
    evidence_summary: 'People have used various plants and herbs for many different reasons for thousands of years. Using herbs or plants to prevent or cure disease is complicated because they contain many different chemicals that can have both helpful and harmful effects. Chloranthaceae is an example of a traditional remedy for stomach sickness. Although laboratory studies have shown potential ways in which Chlorantaceae might reduce stomachache, there is no evidence from comparisons in people with stomach sickness.',
    references: 'Radice M, Tasambay A, Pérez A, Diéguez-Santana K, Sacchetti G, Buso P, Buzzi R, Vertuani S, Manfredini S, Baldisserotto A. Ethnopharmacology,  phytochemistry and pharmacology of the genus Hedyosmum (Chlorantaceae): A review. J Ethnopharmacol. 2019 Nov 15;244:111932. doi: 10.1016/j.jep.2019.111932. Epub 2019 May 22. PMID: 31128149.',
    lessons: [
      null,
      'L1',
      'L3'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'physical education at schools',
    health_problem_or_goal: 'physical activity',
    claimed_effects: 'increases physical activity',
    actual_effects: 'Promoting physical activity at school may slightly improve physical fitness but may have little to no effect on increasing physical activity or body weight.',
    evidence_summary: 'Not getting enough exercise is a risk factor for many serious and long‐lasting diseases. Promoting physical activity at school is thought to be a way to increase activity levels of all children. In reliable comparisons with standard physical ediucation, researchers found that school‐based promotion of physical activity probably resulted in little to no increase in time spent doing moderate to intense physical activity and may not reduce time spent sitting down. However, school‐based promotion of physical activity may slightly improve physical fitness and may very slightly reduce body weight. Researchers were very uncertain whether school‐based physical activity programmes affect health‐related quality of life or if they have any harmful effects.',
    references: 'Neil-Sztramko SE, Caldwell H, Dobbins M. School‐based physical activity programs for promoting physical activity and fitness in children and adolescents aged 6 to 18. Cochrane Database of Systematic Reviews 2021, Issue 9. Art. No.: CD007651. DOI: 10.1002/14651858.CD007651.pub3.',
    lessons: [
      null,
      'L1',
      'L9'
    ],
    principle: [
      null,
      'P2',
      'P6',
      'P7'
    ]
  },
  {
    health_actions: 'not smoking cigarettes',
    health_problem_or_goal: 'cancer, heart disease, lung diseases',
    claimed_effects: 'prevents cancer, prevents heart disease, prevents lung diseases',
    actual_effects: 'People who smoke have a greater risk than people who do not smoke for many different health problems, including lung cancer and other cancers, heart disease, and lung diseases.',
    evidence_summary: 'In comparisons, researchers have found that people who smoke have a greater risk than people who do not smoke for many different health problems, including lung cancer and other cancers, heart disease, and lung diseases.',
    references: 'Dai X, Gil GF, Reitsma MB, Ahmad NS, Anderson JA, Bisignano C, Carr S, Feldman R, Hay SI, He J, Iannucci V, Lawlor HR, Malloy MJ, Marczak LB, McLaughlin SA, Morikawa L, Mullany EC, Nicholson SI, O\'Connell EM, Okereke C, Sorensen RJD, Whisnant J, Aravkin AY, Zheng P, Murray CJL, Gakidou E. Health effects associated with smoking: a Burden of Proof study. Nat Med. 2022 Oct;28(10):2045-2055. doi: 10.1038/s41591-022-01978-x. Epub 2022 Oct 10. PMID: 36216941; PMCID: PMC9556318.',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'having talking therapy',
    health_problem_or_goal: 'depression',
    claimed_effects: 'reduces depression',
    actual_effects: 'Talking therapies reduce the symptoms of depression in adults.',
    evidence_summary: 'Depression is common in adults and children. It affects quality of life, and functioning at work or school. Researchers have shown in many reliable comparisons that talking (psychological) therapies reduce the symptoms of depression in adults by a similar amount to depression medicines, and more than no treatment. The different types of talking therapies such as cognitive behavioural therapy (CBT), interpersonal psychotherapy, and problem-solving therapy; they are all similarly effective and can be delivered in individual, group, and guided self-help format. There is less evidence from comparisons of talking therapies for depression in children so it is not certain whether they reduce symptoms of depression in children. ',
    references: 'Cuijpers, P. (2017). Four decades of outcome research on psychotherapies for adult depression: An overview of a series of meta-analyses. Canadian Psychology / Psychologie canadienne, 58(1), 7–19. https://doi.org/10.1037/cap0000096 and Cox GR, Callahan P, Churchill R, Hunot V, Merry SN, Parker AG, Hetrick SE. Psychological therapies versus antidepressant medication, alone and in combination for depression in children and adolescents. Cochrane Database of Systematic Reviews 2014, Issue 11. Art. No.: CD008324. DOI: 10.1002/14651858.CD008324.pub3.',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null,
      'P2',
      'P6'
    ]
  },
  {
    health_actions: 'having talking therapy',
    health_problem_or_goal: 'anxiety',
    claimed_effects: 'reduces anxiety',
    actual_effects: 'Talking therapies, especially cognitive behavioural therapy, may help children and young people overcome difficulties with anxiety.',
    evidence_summary: 'Many children and young people have problems with anxiety. Anxiety may result in them having difficulty with friendships, family life, and school. In reliable comparisons with no treatment, researchers have found that talking therapies such as cognitive behavioural therapy (CBT) may help children and young people to overcome anxiety by using new ways of thinking about their fears. CBT can be provided individually, in a group and with parents. ',
    references: 'James AC, Reardon T, Soler A, James G, Creswell C. Cognitive behavioural therapy for anxiety disorders in children and adolescents. Cochrane Database of Systematic Reviews 2020, Issue 11. Art. No.: CD013162. DOI: 10.1002/14651858.CD013162.pub2.',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null,
      'P2',
      'P6'
    ]
  },
  {
    health_actions: 'providing clean water',
    health_problem_or_goal: 'diarrhoea',
    claimed_effects: 'prevents diarrhoea',
    actual_effects: 'Clean water, sanitation, and handwashing with soap reduce diarrhoeal disease.',
    evidence_summary: 'Diarrhoea is defined as the passage of three or more loose or liquid stools (poo) per day. Diarrhoea is usually a symptom of an infection in the intestinal tract, which can be caused by a variety of bacteria, viruses and parasities. Infection is spread through contaminated food or drinking-water, or from person-to-person as a result of inadequate hygiene. Diarrhoeal disease is the second leading cause of death in children under five years old, and is responsible for killing around 525 000 children every year. Interventions to prevent diarrhoea, include safe drinking-water, use of improved sanitation (disposal of faeces  (poop) and sewage), and hand washing with soap. In relaible comparisons, researchers have found that clean water, sanitation, and handwashing with soap reduce diarrhoeal disease.',
    references: 'Wolf J, Hunter PR, Freeman MC, Cumming O, Clasen T, Bartram J, Higgins JPT, Johnston R, Medlicott K, Boisson S, Prüss-Ustün A. Impact of drinking water, sanitation and handwashing with soap on childhood diarrhoeal disease: updated meta-analysis and meta-regression. Trop Med Int Health. 2018 May;23(5):508-525. https://onlinelibrary.wiley.com/doi/full/10.1111/tmi.13051',
    lessons: [
      null,
      'L1',
      'L5'
    ],
    principle: [
      null,
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'wearing eyeglasses',
    health_problem_or_goal: 'poor vision',
    claimed_effects: 'improves vision',
    actual_effects: 'Eyeglasses improve eyesight for people who are near sighted or far sighted.',
    evidence_summary: 'This is an example of an obvious effect. People who are near sighted or far sighted see better immediately when they wear corrective eyeglasses and they see worse when they take off the glasses.',
    references: '',
    lessons: [
      null,
      'L1',
      'L3',
      'L8'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'brushing teeth with toothpaste',
    health_problem_or_goal: 'cavities in teeth',
    claimed_effects: 'prevents cavities in teeth',
    actual_effects: 'Brushing your teeth with toothpaste that contains fluoride reduces your chances of having tooth decay.',
    evidence_summary: 'Tooth decay (caries or cavities) is a widespread disease, affecting billions of people worldwide. for a long time, flouride has been used to prevent tooth decay. The typical strength of regular or family toothpaste is around 1000 to 1500 parts per million (ppm) fluoride, but this can vary. In reliable comparisons, researchers have shown that toothpaste containing 1000 to 1250 ppm fluoride is more effective than non‐fluoride toothpaste. The stronger the fluoride concentration, the more decay is prevented. Most studies did not measure harmful effects of toothpaste use, but when reported, effects such as soft tissue damage and tooth staining were minimal.',
    references: 'Walsh  T, Worthington  HV, Glenny  AM, Marinho  VCC, Jeroncic  A. Fluoride toothpastes of different concentrations for preventing dental caries. Cochrane Database of Systematic Reviews 2019, Issue 3. Art. No.: CD007868. https://doi.org/10.1002/14651858.CD007868.pub3',
    lessons: [
      null,
      'L1',
      'L3'
    ],
    principle: [
      null,
      'P3',
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'taking painkillers,e.g., paracetamol (Panadol, Tylenol, acetaminophen) ',
    health_problem_or_goal: 'pain, low back pain, headache',
    claimed_effects: 'reduces pain',
    actual_effects: 'The commonly used painkiller, paracetamol, only occasionally improves pain from tension type headaches, and does not work any better than placebo for lower back pain. ',
    evidence_summary: 'Paracetamol is a commonly used painkiller that is available without prescription (over the counter) in most parts of the world. The usual dose for an adult is 1000 mg taken by mouth. In reliable comparisons, researchers found no difference between paracetamol and placebo in the immediate and short term relief of acute low back pain, quality of life, function, recovery, and sleep quality. Compared with placebo, paracetamol had similar adverse events. Researchers also found in reliable comparisons that paracetamol may only occasionally relieve headache pain, and only slightly more often than placebo. Headaches are a common problem and can be of different types. About 1 in 5 people experience tension‐type headaches. They stop people concentrating and working properly. When these headaches do occur, they get better over time, even without treatment. People with frequent tension‐type headaches have between two and 14 headaches every month. Tension‐type headache stops people concentrating and working properly. When these headaches occur, they get better over time, even without treatment.',
    references: 'Saragiotto BT, Machado GC, Ferreira ML, Pinheiro MB, Abdel Shaheed C, Maher CG. Paracetamol for low back pain. Cochrane Database of Systematic Reviews 2016, Issue 6. Art. No.: CD012230. DOI: 10.1002/14651858.CD012230 Stephens G, Derry S, Moore RA. Paracetamol (acetaminophen) for acute treatment of episodic tension‐type headache in adults. Cochrane Database of Systematic Reviews 2016, Issue 6. Art. No.: CD011889. DOI: 10.1002/14651858.CD011889.pub2. ',
    lessons: [
      null,
      'L1',
      'L7',
      'L10'
    ],
    principle: [
      null,
      'P2',
      'P3',
      'P4',
      'P6',
      'P7'
    ]
  },
  {
    health_actions: 'taking fish oil supplements (containing omega 3 fatty acids)',
    health_problem_or_goal: 'brain function, mental health, heart disease',
    claimed_effects: 'improves brain function, prevents depression and anxiety, prevents heart disease',
    actual_effects: '\'Taking omega 3 fatty acids, sometimes sold as \'\'fish oil\'\' do not improve brain function in young people, and do not help symptoms of anxiety or depression. Certain types of omega 3 fatty acids may help long term memory and executive functions, and may reduce the chnace of getting heart disease in people who ar at risk.\'',
    evidence_summary: 'Omega-3 fatty acids are often sold as “fish oil.” In reliable comparisons, researchers have not found any cognitive effects of omega-3 supplements in young people. But it is possible that specific types of omega-3 might improve long-term memory (recall), working memory, and problem solving. Researchers have found that omega-3 probably has little or no effect in preventing depression or anxiety symptoms. In reliable comparisons, researchers found that fish oil supplements may reduce the risk of heart disease in people who have a high risk. The effect on people who have a low risk is uncertain, but likely to be very small if there is an effect.',
    references: 'Emery S, Häberling I, Berger G, Walitza S, Schmeck K, Albert T, Baumgartner N, Strumberger M, Albermann M, Drechsler R. Omega-3 and its domain-specific effects on cognitive test performance in youths: A meta-analysis. Neurosci Biobehav Rev. 2020 May;112:420-436. doi: 10.1016/j.neubiorev.2020.02.016. Epub 2020 Feb 15. PMID: 32070694. Deane KHO, Jimoh OF, Biswas P, O\'Brien A, Hanson S, Abdelhamid AS, Fox C, Hooper L. Omega-3 and polyunsaturated fat for prevention of depression and anxiety symptoms: systematic review and meta-analysis of randomised trials. Br J Psychiatry. 2021 Mar;218(3):135-142. doi: 10.1192/bjp.2019.234. PMID: 31647041. Wu G, Ji Q, Huang H, Zhu X. The efficacy of fish oil in preventing coronary heart disease: A systematic review and meta-analysis. Medicine (Baltimore). 2021 Sep 17;100(37):e27253. doi: 10.1097/MD.0000000000027253. PMID: 34664872; PMCID: PMC8448057.',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'removing splinter',
    health_problem_or_goal: 'splinters',
    claimed_effects: 'reduces pain',
    actual_effects: 'Removing a splinter reduces pain.',
    evidence_summary: 'People who have a splinter that is causing pain immediately experience less pain when the splinter is removed.',
    references: 'This is an example of an obvious effect.',
    lessons: [
      null,
      'L1',
      'L3',
      'L8'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'not eating sugary food and drinks',
    health_problem_or_goal: 'health, teeth cavities, overweight, acne, heart disease',
    claimed_effects: 'improves health, prevents cavities, controls weight, prevents acne, prevents heart disease ',
    actual_effects: 'People who eat a lot of sugary food and have a higher risk of obesity and several diseases. Children have a higher risk of cavities.',
    evidence_summary: 'In comparisons, researchers have found that regularly eating sugar-sweetened drinks or foods is associated with an increased risk of dying earlier, heart disease, overweight, diabetes, and colon cancer. Researchers have found that drinking 100% juice (daily), candy (more than once a week), soft drinks and sweet drinks (at bedtime) are associated with caries in 6- to 12-year-old children.',
    references: 'Scientific Report of the 2020 Dietary Guidelines Advisory Committee https://www.dietaryguidelines.gov/2020-advisory-committee-report Part D. Chapter 8: Dietary patterns  p.13-14 https://www.dietaryguidelines.gov/sites/default/files/2020-07/PartD_Ch8_DietaryPatterns_first-print.pdf  (Table D8.1, p.39) Mahboobi Z, Pakdaman A, Yazdani R, Azadbakht L, Montazeri A. Dietary free sugar and dental caries in children: A systematic review on longitudinal studies. Health Promot Perspect. 2021 Aug 18;11(3):271-280. doi: 10.34172/hpp.2021.35. PMID: 34660221; PMCID: PMC8501477.',
    lessons: [
      null,
      'L1',
      'L10'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'taking digitalis/foxglove',
    health_problem_or_goal: 'heart disease',
    claimed_effects: 'reduces symptoms of heart failure, harmful effects',
    actual_effects: 'Digitalis is a heart medication extracted from the leaves of foxgloves. It is carefully processed and given to people in regulated amounts. Eating or drinking a tea made from any part of the foxglove plant is dangerous because it is like taking an uncontrolled amount of heart medication.',
    evidence_summary: 'Digitalis is a medication that is extracted from the leaves of the foxglove plant. It contains substances that stimulate heart muscle and has been used for over 200 years to treat heart failure—a inability of the injured heart to pump blood adequately. In reliable comparisons with placebo, researchers have found that digitalis can help to reduce the symptoms of heart failure and reduces the need to go to hospital. The are many other medications that may be used in heart failure.  Although digitalis is extracted from the leaves, all parts of the foxglove are poisonous. Eating any part of the plant or making tea from the leaves is like taking an unregulated dose of heart medicine. This can cause the heart rate to slow down or become irregular; both are dangerous and life threatening.',
    references: 'Hood Jr. WB, Dans AL, Guyatt GH, Jaeschke R, McMurray JJV. Digitalis for treatment of heart failure in patients in sinus rhythm. Cochrane Database of Systematic Reviews 2014, Issue 4. Art. No.: CD002901. DOI: 10.1002/14651858.CD002901.pub3 and https://www.poison.org/articles/foxglove ',
    lessons: [
      null,
      'L1'
    ],
    principle: [
      null,
      'P1',
      'P4',
      'P6'
    ]
  },
  {
    health_actions: 'giving dexamethasone',
    health_problem_or_goal: 'Covid-19',
    claimed_effects: 'improves recovery from severe Covid-19, reduces deaths from severe Covid-19',
    actual_effects: 'The steroid, dexamethasone, can increase recovery and reduce deaths from Covid-19.',
    evidence_summary: 'Dexamethasone is a steroid treatment that has been used for a long time in many diseases. In reliable comparisons with standard care and placebo, researchers found that dexamethasone use in severe Covid-19 can increase clinical recovery and lower deaths.',
    references: 'Selvarajan S, Anandaradje A, Shivabasappa S, Melepurakkal Sadanandan D, Nair NS, George M. Efficacy of pharmacological interventions in COVID-19: A network meta-analysis. British journal of clinical pharmacology. 2022; and WHO Rapid Evidence Appraisal for COVID-19 Therapies (REACT) Working Group, Sterne JAC, Murthy S, Diaz JV, Slutsky AS, Villar J, Angus DC, Annane D, Azevedo LCP, Berwanger O, Cavalcanti AB, Dequin PF, Du B, Emberson J, Fisher D, Giraudeau B, Gordon AC, Granholm A, Green C, Haynes R, Heming N, Higgins JPT, Horby P, Jüni P, Landray MJ, Le Gouge A, Leclerc M, Lim WS, Machado FR, McArthur C, Meziani F, Møller MH, Perner A, Petersen MW, Savovic J, Tomazini B, Veiga VC, Webb S, Marshall JC. Association Between Administration of Systemic Corticosteroids and Mortality Among Critically Ill Patients With COVID-19: A Meta-analysis. JAMA. 2020;324(13):E1-E12. ',
    lessons: [
      null,
      'L7',
      'L10'
    ],
    principle: [
      null,
      'P1',
      'P2',
      'P5',
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'using seatbelts',
    health_problem_or_goal: 'injuries',
    claimed_effects: 'prevents serious injury and death',
    actual_effects: 'Seatbelt use greatly reduces the overall risk of injury in traffic accidents, especially facial, abdominal and spinal injuries. It is uncertain if seatbelts reduce, neck, chest, upper limb and lower limb injuries.',
    evidence_summary: 'Seatbelts are generally considered to reduce injuries to passengers in traffic accidents. In many countries it is compulsory to wear a seatbelt when travelling in a motor vehicle. In comparisons between seatbelt users and non-users, researchers found that seatbelt use greatly reduced the overall risk of injury in traffic accidents. The main injuries that were reduced were facial injuries, abdominal injuries and spinal injuries. However, it was uncertain if seatbelts reduced the number of head injuries, neck injuries, chest injuries, upper limb injuries and lower limb injuries.',
    references: 'Fouda Mbarga N, Abubakari AR, Aminde LN, Morgan AR. Seatbelt use and risk of major injuries sustained by vehicle occupants during motor-vehicle crashes: a systematic review and meta-analysis of cohort studies. BMC public health. 2018 Dec;18(1):1-1.',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null,
      'P4',
      'P6',
      'P7'
    ]
  },
  {
    health_actions: 'drinking herbal tea/valerian',
    health_problem_or_goal: 'sleep, difficulty sleeping',
    claimed_effects: 'improves sleep',
    actual_effects: 'Herbal medicines, like valerian, do not improve sleep. ',
    evidence_summary: 'Valerian is a flowering plant, the root of which is dried and used as an herbal remedy for treating sleep problems. Chinese herbal medicines contain many different herbs. In comparisons with placebo, researchers found that neither valerian nor chinese herbal medicine had more effect on sleep than placebo in healthy individuals nor in people with difficulties sleeping. ',
    references: 'Taibi DM, Landis CA, Petry H, Vitiello MV. A systematic review of valerian as a sleep aid: safe but not effective. Sleep medicine reviews. 2007 Jun 1;11(3):209-30; andYeung WF, Chung KF, Poon MM, Ho FY, Zhang SP, Zhang ZJ, Ziea ET, Wong VT. Chinese herbal medicine for insomnia: a systematic review of randomized controlled trials. Sleep medicine reviews. 2012 Dec 1;16(6):497-507. ',
    lessons: [
      null,
      'L3'
    ],
    principle: [
      null,
      'P3',
      'P4',
      'P6'
    ]
  },
  {
    health_actions: 'teaching critical thinking at schools',
    health_problem_or_goal: 'health choices',
    claimed_effects: 'improves health choices',
    actual_effects: 'Educational interventions to improve people’s understanding of key concepts for evaluating claims about health actions and making informed choices can improve their knowledge and skills.',
    evidence_summary: 'In reliable comparisons, researchers have found that educational interventions to improve people’s understanding of key concepts for evaluating claims about health actions can improve people’s knowledge and skills, at least in the short term. Effects on confidence, attitude and behaviour are uncertain. Long-term effects and effects on health are uncertain.',
    references: 'Cusack L, Del Mar CB, Chalmers I, Gibson E, Hoffmann TC. Educational interventions to improve people\'s understanding of key concepts in assessing the effects of health interventions: a systematic review. Syst Rev. 2018;7(1):68. https://doi.org/10.1186/s13643-018-0719-4',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'charging taxes to pay for medicines ',
    health_problem_or_goal: 'access to medicines',
    claimed_effects: 'improves access to medicines',
    actual_effects: 'Paying taxes for medicines increases availability of medicines.',
    evidence_summary: 'Funds to pay for medicines can be paid for using taxes or insurance fees. In comparisons, researchers have found that the cost of medicines being covered by these types of funds improves use of medicines, reduces use of other health services, and improves health outcomes.',
    references: 'Kesselheim AS, Huybrechts KF, Choudhry NK, Fulchino LA, Isaman DL, Kowal MK, Brennan TA. Prescription drug insurance coverage and patient health outcomes: a systematic review. Am J Public Health. 2015 Feb;105(2):e17-30. doi: 10.2105/AJPH.2014.302240. PMID: 25521879; PMCID: PMC4318289.',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'charging taxes to pay for clean water',
    health_problem_or_goal: 'clean water',
    claimed_effects: 'improves access to clean water',
    actual_effects: 'Paying taxes for water treatment increases access to clean water.',
    evidence_summary: 'Researchers have found that even paying a very small fee puts people off from using water treatment technologies to obtain cleaner water. Governments can use taxes to provide clean water technology for everyone, without a fee. ',
    references: 'Null C, Kremer M, Miguel E, Hombrados JG, Meeks R, Zwane AP. Willingness to pay for cleaner water in less developed countries: systematic review of experimental evidence. The International Initiative for Impact Evaluation (3iE). 2012. https://3ieimpact.org/evidence-hub/publications/systematic-reviews/willingness-pay-cleaner-water-less-developed-countries',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'charging taxes to pay for waste management',
    health_problem_or_goal: 'waste management',
    claimed_effects: 'reduces exposure to waste',
    actual_effects: 'Paying taxes for waste management reduces exposure to waste. ',
    evidence_summary: 'Latrines are a safe way of handling human waste, and reducing contamination of water used for drinking and cooking. Researchers have found that latrine construction and subsidies improve latrine use. Latrine construction with or without education is more effective than education alone for improving latrine coverage or access.',
    references: 'Igaki S, Duc NTM, Nam NH, Nga TTT, Bhandari P, Elhamamsy A, Lotify CI, Hewalla ME, Tawfik GM, Mathenge PG, Hashizume M, Huy NT. Effectiveness of community and school-based sanitation interventions in improving latrine coverage: a systematic review and meta-analysis of randomized controlled interventions. Environ Health Prev Med. 2021 Feb 24;26(1):26. doi: 10.1186/s12199-021-00934-4. PMID: 33627071; PMCID: PMC7903680.',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'imposing age restrictions on car driving',
    health_problem_or_goal: 'injuries',
    claimed_effects: 'prevents injuries in car accidents',
    actual_effects: 'Driving restrictions seem to reduce fatal crashes  for 16-year-old drivers, but do not make a difference for older teens. Research suggests they may even increase fatal crashes for 18-year-old drivers. Researchers have not yet found why.',
    evidence_summary: 'Motor vehicle crashes are the leading cause of teenage deaths in the United States, and crash rates are highest among teens younger than 18 years old. In comparisons of stronger with weaker or no Graduated Driver Licensing programmes, researchers found that more restrictive programmes were associated with a lower incidence of fatal crashes for 16-year-old drivers, but with a higher fatal crash incidence for 18-year-old drivers  There was no difference in fatal crashes for 17 and 19-year-olds between the different levels of restrictions. It is not yet understood why the effect is different for different aged teenage drivers.',
    references: 'Masten SV, Foss RD, Marshall SW. Graduated Driver Licensing and Fatal Crashes Involving 16- to 19-Year-Old Drivers. JAMA. 2011;306(10):1098–1103. doi:10.1001/jama.2011.1277',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null,
      'P1',
      'P2',
      'P6',
      'P7'
    ]
  },
  {
    health_actions: 'having Covid-19 vaccines',
    health_problem_or_goal: 'Covid-19',
    claimed_effects: 'prevents serious illness with Covid-19',
    actual_effects: '',
    evidence_summary: 'COVID-19 has caused extensive health and economic burdens across the world. Vaccines became available at the end of 2020. Researchers needed to urgently find out how effective the new vaccines were. In reliable comparisons, they found that the main types of Covid vaccines (mRNA, inactivated, protein subunit, and viral vector vaccines) protect against infection COVID-19. They also found that viral vector vaccines can decrease mortality, but comparable data on inactivated, mRNA, and protein subunit vaccines were imprecise.  Serious adverse events were rare. ',
    references: 'Korang SK, von Rohden E, Veroniki AA, Ong G, Ngalamika O, Siddiqui F, Juul S, Nielsen EE, Feinberg JB, Petersen JJ, Legart C, Kokogho A, Maagaard M, Klingenberg S, Thabane L, Bardach A, Ciapponi A, Thomsen AR, Jakobsen JC, Gluud C. Vaccines to prevent COVID-19: A living systematic review with Trial Sequential Analysis and network meta-analysis of randomized clinical trials. PloS one. 2022;17(1):e0260733. ',
    lessons: [
      null,
      'L3',
      'L5',
      'L7',
      'L10'
    ],
    principle: [
      null,
      'P2',
      'P6'
    ]
  },
  {
    health_actions: 'having childhood vaccines',
    health_problem_or_goal: 'measles, mumps, rubella, chickenpox',
    claimed_effects: 'prevents measles, prevents mumps, prevents rubella, prevents chickenpox',
    actual_effects: 'Vaccines prevent measles, mumps, rubella, and chickenpox, with very little risk of unwanted effects.',
    evidence_summary: 'Measles, mumps, rubella (German measles) and varicella (chickenpox) are infectious diseases caused by viruses. They are most common in children and young adults. They are not always serious, but can cause disability (such as deafness), complications and death. If pregnant women catch rubella, it may cause loss (miscarriage) of, or harm to, their unborn babies. In comparisons of very large numbers of children, researchers have found that the MMR (measles, mumps, rubella) vaccine and the chickenpox vaccine protect people against all of these infections. Some people think the MMR vaccine causes unwanted effects such as autism, swelling of the brain (encephalitis), meningitis, learning difficulties, type 1 diabetes, and other conditions. As a result, the number of children being vaccinated has fallen. Researchers have shown that this is not the case and there no evidence of an increased risk of autism, encephalitis or other serious harmful effects.',
    references: 'Di Pietrantonj  C, Rivetti  A, Marchione  P, Debalini  MG, Demicheli  V. Vaccines for measles, mumps, rubella, and varicella in children. Cochrane Database of Systematic Reviews 2020, Issue 4. Art. No.: CD004407. https://doi.org/10.1002/14651858.CD004407.pub4',
    lessons: [
      null,
      'L5'
    ],
    principle: [
      null,
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'providing clean water',
    health_problem_or_goal: 'diarrhoea',
    claimed_effects: 'prevents diarrhoea',
    actual_effects: 'Clean and improved water supply reduces diarrhoeal disease and deaths in children.',
    evidence_summary: 'Globally, diarrhoeal diseases are a major cause of infant and child illness and deaths. In comparisons done 30 to 40 years ago, researchers found that improved water supply and sanitation facilities resulted in substantial reductions in cases of diarrhoea and many other diseases, particularly some that are caused by parasites. The researchers also found that improved water supply and sanitation facilities resulted in a reduction in deaths due to diarrhoea, and due to all causes. ',
    references: 'Esrey SA, Potash JB, Roberts L, Shiff C. Effects of improved water supply and sanitation on ascariasis, diarrhoea, dracunculiasis, hookworm infection, schistosomiasis, and trachoma. Bull World Health Organ. 1991;69(5):609-21. PMID: 1835675; PMCID: PMC2393264.',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'closing schools',
    health_problem_or_goal: 'Covid-19',
    claimed_effects: 'prevents spread of Covid-19',
    actual_effects: 'School closures reduce the spread of Covid-19 but are costly, and are associated with adverse health outcomes and health behaviours in children and young people.',
    evidence_summary: 'In the early months of the Covid-19 pandemic, many governments made the decision to close schools to prevent the spread of Covid-19. In comparisons and other types of studies, researchers found that school closures reduced the spread of Covid-19 but were very costly and associated with adverse mental health symptoms and health behaviours among children and adolescents. These included  anxiety and depressive symptoms, delayed presentations to emergency departments, potential reductions in vaccination coverage, reduced child protection, higher screen time usage, greater social media use, and lower physical activity. However, the researchers could not separate the associations between school closure and these health outcomes and behaviours from broader lockdown measures. ',
    references: 'Juneau CE, Pueyo T, Bell M, Gee G, Collazzo P, Potvin L. Lessons from past pandemics: a systematic review of evidence-based, cost-effective interventions to suppress COVID-19. Systematic reviews. 2022;11(1):90, and Viner R, Russell S, Saulle R, Croker H, Stansfield C, Packer J, Nicholls D, Goddings AL, Bonell C, Hudson L, Hope S, Ward J, Schwalbe N, Morgan A, Minozzi S. School Closures During Social Lockdown and Mental Health, Health Behaviors, and Well-being Among Children and Adolescents During the First COVID-19 Wave: A Systematic Review. JAMA Pediatr. 2022 Apr 1;176(4):400-409. doi: 10.1001/jamapediatrics.2021.5840. PMID: 35040870.',
    lessons: [
      null,
      'L8',
      'L9',
      'L10'
    ],
    principle: [
      null,
      'P1',
      'P4',
      'P6',
      'P9'
    ]
  },
  {
    health_actions: 'imposing age restrictions on tobacco sales',
    health_problem_or_goal: 'smoking',
    claimed_effects: 'reduces underage smoking',
    actual_effects: 'Age restricting the sale of tobacco by law does not reduce youth tobacco use without the addition of enforcement programmes.',
    evidence_summary: 'Smoking in teenagers may lead to them becoming long term smokers and being at risk of dveloping serious medical problems later in life, including heart and lung diease, and cancer. Age restricting the sale of tobacco is one way governments discourage teenagers from starting smoking. However, in comparisons, researchers found that restricting sales by law, without sufficient enforcement, had little or no impact on youth tobacco use. Enforcement programmes that disrupted the sale of tobacco to minors reduced smoking among youth. It is also unclear whether reducing teenage tobacco use results in reduced tobacco use in adults.',
    references: 'Stead LF, Lancaster T. Interventions for preventing tobacco sales to minors. Cochrane Database of Systematic Reviews 2005, Issue 1. Art. No.: CD001497. DOI: 10.1002/14651858.CD001497.pub2. Accessed 03 November 2022, and DiFranza JR. Which interventions against the sale of tobacco to minors can be expected to reduce smoking?. Tobacco Control. 2012 Jul 1;21(4):436-42.',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null,
      'P2',
      'P6'
    ]
  },
  {
    health_actions: 'imposing age restrictions on alcohol sales',
    health_problem_or_goal: 'alcohol consumption, drinking',
    claimed_effects: 'reduces underage drinking',
    actual_effects: 'Restrictions for selling alcohol to adolescents may reduce underage drinking if they are enforced, but the effect is uncertain.',
    evidence_summary: 'The misuse of alcohol is an important risk factor for ill health, injury (e.g. through violent behaviour or road traffic collisions), death and social problems around the world. Researchers have reported four studies that examined the relationship between adolescent drinking and the ability of minors to purchase alcohol from commercial outlets in their communities. Three studies did not find an association in either the U.S. or in New Zealand. However, a very large study in a rural population in Oregon, U.S., found that adolescents were more likely to drink and to binge drink if they lived in a community where a higher proportion of adolescents reported that they had purchased alcohol from commercial outlets. ',
    references: 'Bryden A, Roberts B, McKee M, Petticrew M. A systematic review of the influence on alcohol use of community level availability and marketing of alcohol. Health Place. 2012 Mar;18(2):349-57. doi: 10.1016/j.healthplace.2011.11.003. Epub 2011 Nov 25. PMID: 22154843.',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null,
      'P2',
      'P4',
      'P6'
    ]
  },
  {
    health_actions: 'eating lots of carrots',
    health_problem_or_goal: 'eyesight, vision',
    claimed_effects: 'improves eyesight',
    actual_effects: 'It is unlikely that eating carrots improves eyesight.',
    evidence_summary: 'Vitamin A can be used to reverse the effects of night blindness in people with a deficiency. Carrots do not have vitamin A but have ß-carotene, which is converted to vitamin A. Vitamin A is important for vision, but carrots are not a direct source of it. Researchers have found that if there is no deficiency, consuming more vitamin A does not improve night vision.',
    references: 'Byun SS, Spaide RF. Carrots, Blueberries, and Spinach-Vision Superfoods. Retina. 2021 May 1;41(5):895-897. doi: 10.1097/IAE.0000000000003089. PMID: 33394962..',
    lessons: [
      null,
      'L2'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'drinking lots of water',
    health_problem_or_goal: 'diabetes',
    claimed_effects: 'prevents diabetes',
    actual_effects: 'It is unclear from research whether drinking water may prevent or help diabetes. Fair comparisons are needed to find out.',
    evidence_summary: 'Diabetes is a common disease that causes a person\'s blood sugar level to become too high, and that results in significant long term health problems. There are 2 main types of diabetes: type 1 diabetes – where the body\'s immune system attacks and destroys the cells that produce insulin (a hormone that helps our body control blood sugar levels); and type 2 diabetes – where the body does not produce enough insulin, or the body\'s cells do not react to insulin. In small comparisons, researchers found different results, so there is not enough evidence to say whether drinking water improves blood sugar control, and therefore prevent or help diabetes. Larger fair comparisons are needed.',
    references: 'Naumann J, Biehler D, Lüty T, Sadaghiani C. Prevention and therapy of type 2 diabetes—what is the potential of daily water intake and its mineral nutrients?. Nutrients. 2017 Aug 22;9(8):914.',
    lessons: [
      null,
      'L2'
    ],
    principle: [
      null,
      'P2',
      'P6',
      'P8'
    ]
  },
  {
    health_actions: 'eating oranges',
    health_problem_or_goal: 'flu',
    claimed_effects: 'cures flu',
    actual_effects: 'It is unlikely that eating oranges cures flu.',
    evidence_summary: 'One orange contains about 60-70mg of vitamin C. This is much less than the amounts of Vitamin C that have been shown to reduce the duration and severity of symptoms from the common cold or respiratory tract infections (ref common cold summaries). There is not direct evidence from randomized trials of vitamin C for influenza.',
    references: 'Abioye AI, Bromage S, Fawzi W. Effect of micronutrient supplements on influenza and other respiratory tract infections among adults: a systematic review and meta-analysis. BMJ Glob Health. 2021 Jan;6(1):e003176. doi: 10.1136/bmjgh-2020-003176. PMID: 33472840; PMCID: PMC7818810.',
    lessons: [
      null,
      'L3'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'eating oranges',
    health_problem_or_goal: 'common cold',
    claimed_effects: 'prevents common cold, cures common cold',
    actual_effects: 'One orange contains about  60-70mg of Vitamin C. This is much less than the amounts of Vitamin C that have been shown to reduce the duration and severity of common cold symptoms (see Vitamin C for common cold below)',
    evidence_summary: 'Vitamin C has been widely sold and used to prevent and treat the common cold. Common cold symptoms include runny nose, congestion, sneezing, sore throat, cough, and sometimes headache, fever, and red eyes. Symptoms vary from person to person and cold to cold. There are over 200 viruses that can cause these symptoms. In reliable comparisons, researchers have found a reduction in the length and severity of symptoms when vitamin C is taken regularly to prevent the common cold, but either no, or a very small preventive effect. Reliable comparisons of high doses of vitamin C starting after the onset of symptoms to treat the common cold have not shown a consistent effect on the length or severity of common cold symptoms. One orange contains about  60-70mg of Vitamin C. This is much less than the amounts of Vitamin C that have been shown to reduce the duration and severity of common cold symptoms (see Vitamin C for coomon cold below)',
    references: 'Hemilä  H, Chalker  E. Vitamin C for preventing and treating the common cold. Cochrane Database of Systematic Reviews 2013, Issue 1. Art. No.: CD000980. https://doi.org/10.1002/14651858.CD000980.pub4 and Abioye AI, Bromage S, Fawzi W. Effect of micronutrient supplements on influenza and other respiratory tract infections among adults: a systematic review and meta-analysis. BMJ Glob Health. 2021 Jan;6(1):e003176. doi: 10.1136/bmjgh-2020-003176. PMID: 33472840; PMCID: PMC7818810.',
    lessons: [
      null,
      'L3',
      'L5'
    ],
    principle: [
      null,
      'P3',
      'P4',
      'P6'
    ]
  },
  {
    health_actions: 'vitamin C',
    health_problem_or_goal: 'common cold',
    claimed_effects: 'prevents common colds, cures  common colds',
    actual_effects: 'Taking vitamin C regularly reduces the duration and severity of symptoms but does not have an important preventive effect. Starting to take Vitamin C after a cold has started has no effect.',
    evidence_summary: 'Vitamin C has been widely sold and used to prevent and treat the common cold. Common cold symptoms include runny nose, congestion, sneezing, sore throat, cough, and sometimes headache, fever, and red eyes. Symptoms vary from person to person and cold to cold. There are over 200 viruses that can cause these symptoms. Vitamin C has been proposed for treating respiratory infections since the 1930s. It became popular in the 1970s when Nobel prize winner Linus Pauling claimed that vitamin C would prevent and alleviate the common cold. Since then, in reliable comparisons, researchers have found a reduction in the length and severity of symptoms when vitamin C is taken regularly to prevent the common cold, but either no, or a very small preventive effect. Reliable comparisons of high doses of vitamin C starting after the onset of symptoms to treat the common cold have not shown a consistent effect on the length or severity of common cold symptoms.',
    references: 'Hemilä  H, Chalker  E. Vitamin C for preventing and treating the common cold. Cochrane Database of Systematic Reviews 2013, Issue 1. Art. No.: CD000980. https://doi.org/10.1002/14651858.CD000980.pub4 and Abioye AI, Bromage S, Fawzi W. Effect of micronutrient supplements on influenza and other respiratory tract infections among adults: a systematic review and meta-analysis. BMJ Glob Health. 2021 Jan;6(1):e003176. doi: 10.1136/bmjgh-2020-003176. PMID: 33472840; PMCID: PMC7818810.',
    lessons: [
      null,
      'L5',
      'L7'
    ],
    principle: [
      null,
      'P3',
      'P4',
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'drinking water frequently',
    health_problem_or_goal: 'Covid-19',
    claimed_effects: 'prevents Covid-19',
    actual_effects: 'There is no reliable evidence that drinking water frequently prevents Covid-19',
    evidence_summary: 'Posts shared on social media claimed drinking water every15 minutes prevents Covid infection. The logic is that this will help to wash the virus down into the stomach where it can be killed by stomach acid. In fact, there is no reliable evidence that drinking water frequently prevents people from getting Covid-19',
    references: 'check ihealth facts',
    lessons: [
      null,
      'L3'
    ],
    principle: [
      null,
      'P3',
      'P6'
    ]
  },
  {
    health_actions: 'sunlight',
    health_problem_or_goal: 'Covid-19',
    claimed_effects: 'prevents Covid-19',
    actual_effects: 'There is no evidence that sunlight can prevent or treat Covid-19.',
    evidence_summary: 'There are claims on social media that sunlight can prevent or even treat COVID-19. Although some studies have suggested that temperature may influence transmission of the Covid-19 virus (how the virus spreads from one person to another), the evidence overall is uncertain. COVID-19 virus transmission may have reduced with warmer weather in some countries, but those reductions might be due to people being outside more, the effects of isolating people who are infected, restricting travel, physical distancing, hand washing, and other measures. It is far more likely that those measures reduced the rate of transmission, not sunlight.',
    references: '',
    lessons: [
      null,
      'L5'
    ],
    principle: [
      null,
      'P6',
      'P7'
    ]
  },
  {
    health_actions: 'taking chloroquine or hydroxychloroquine',
    health_problem_or_goal: 'Covid-19',
    claimed_effects: 'prevents Covid-19, improves recovery from Covid-19',
    actual_effects: 'Chloroquine and hydrochloroquine do not prevent, reduce symptoms, or prevent deaths from Covid-19. These medicines have harmful effects and should not be used. ',
    evidence_summary: 'Chloroquine and hydroxychloroquine (which is less toxic than chloroquine) are medicines that have been used for many years to treat malaria and rheumatoid conditions, such as arthritis At the start of the COVID-19 pandemic, there were suggestions that chloroquine or hydroxychloroquine might be useful to help prevent and treat COVID-19. In a large number of reliable comparisons, researchers have found that there is no evidence that hydroxychloroquine or chloroquine can prevent COVID-19, or improve symptoms or prevents deaths from mild, moderate or severe COVID-19. Researchers also found that the treatment of COVID-19 with hydroxychloroquine or chloroquine could have harmful effects.',
    references: 'Deng J, Zhou F, Heybati K, Ali S, Zuo QK, Hou W, Dhivagaran T, Ramaraju HB, Chang O, Wong CY, Silver Z. Efficacy of chloroquine and hydroxychloroquine for the treatment of hospitalized COVID-19 patients: a meta-analysis. Future Virol. 2021 Nov:10.2217/fvl-2021-0119. doi: 10.2217/fvl-2021-0119. Epub 2021 Dec 3. PMID: 34887938; PMCID: PMC8647998.',
    lessons: [
      null,
      'L8',
      'L9',
      'L10'
    ],
    principle: [
      null,
      'P1',
      'P2',
      'P4',
      'P6'
    ]
  },
  {
    health_actions: 'using a parachute',
    health_problem_or_goal: 'injury',
    claimed_effects: 'prevent serious injury when jumping out of a plane',
    actual_effects: 'Using a parachute when jumping out of a plane reduces the risk of death or a serious injury.',
    evidence_summary: 'This is an example of an obvious effect. Parachute use is likely to reduce the risk of death or major trauma by more than a 1000-fold under normal conditions, based on a risk of death or major trauma of less than 1 per 1000 with a parachute compared with a risk of greater than 999 per 1000 without a parachute.',
    references: 'Oxman AD. Improving the health of patients and populations requires humility, uncertainty, and collaboration. JAMA. 2012;308(16):1691-2. https://doi.org/10.1001/jama.2012.14477',
    lessons: [
      null,
      'L3',
      'L7'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'oral rehydration solution',
    health_problem_or_goal: 'diarrhoea',
    claimed_effects: 'improves recovery from loss of body water (dehydration)',
    actual_effects: 'Oral rehydration solution with low levels of salt and sugar is effective for rehdrating children with watery diarrhoea. Lower amounts of salt and sugar are needed in the solution than were used before 2001.',
    evidence_summary: 'Diarrhoea is a leading cause of childhood death in middle and low income countries. Children with diarrhoea lose body water and sometimes become dehydrated. A solution of sugar and salt dissolved in water is widely used to treat dehydration caused by diarrhoea. In reliable comparisons, researchers found that a solution containing smaller amounts of sugar and salt than the amounts previously used was better for treating dehydration in children.',
    references: 'Hahn S, Kim Y, Garner P. Reduced osmolarity oral rehydration solution for treating dehydration caused by acute diarrhoea in children. Cochrane Database of Systematic Reviews 2002, Issue 1. Art. No.: CD002847. DOI: 10.1002/14651858.CD002847. ',
    lessons: [
      null,
      'L7',
      'L10'
    ],
    principle: [
      null,
      'P1',
      'P2',
      'P4',
      'P6',
      'P8'
    ]
  },
  {
    health_actions: 'stretching',
    health_problem_or_goal: 'stitch',
    claimed_effects: 'prevents stitch',
    actual_effects: 'Stretching before exercise does not have an important effect on developing a \'stitch\'.',
    evidence_summary: 'A \'stitch\' is the pain people sometimes get in their side when they run or exercise. In one very small comparison, researchers reached no conclusion about the effect of stretching on developing a \'stitch\' when exercising.',
    references: '\'Plunkett BT, Hopkins WG. Investigation of the side pain\'\' stitch\'\' induced by running after fluid ingestion. Medicine and science in sports and exercise. 1999 Aug 1;31(8):1169-75.\'',
    lessons: [
      null,
      'L8'
    ],
    principle: [
      null,
      'P3',
      'P4',
      'P8'
    ]
  },
  {
    health_actions: 'stretching',
    health_problem_or_goal: 'muscle soreness',
    claimed_effects: 'prevents muscle soreness',
    actual_effects: 'Stretching before or after exercise does not have an important effect on muscle soreness after physical activity.',
    evidence_summary: 'Many people stretch before or after physical activities such as sport. One reason for this is to reduce soreness after exercise. Fair comparisons of the effects of stretching before or after physical activities have produced very consistent findings regarding muscle soreness. They showed there was little or no effect of stretching on the muscle soreness experienced in the week after the physical activity.',
    references: 'Herbert RD, de Noronha M, Kamper SJ. Stretching to prevent or reduce muscle soreness after exercise. Cochrane Database Syst Rev. 2011 Jul 6;(7):CD004577. https://doi.org/10.1002/14651858.CD004577.pub3',
    lessons: [
      null,
      'L8'
    ],
    principle: [
      null,
      'P4',
      'P7'
    ]
  },
  {
    health_actions: 'hormone replacement therapy',
    health_problem_or_goal: 'heart disease',
    claimed_effects: 'prevents heart disease',
    actual_effects: 'HRT does not prevent deaths or heart disease in women after menopause. HRT increases the chance of stroke or blood clots in veins. ',
    evidence_summary: 'Hormone therapy (HRT) is used for controlling symptoms of menopause, like hot flashes. It has also been used for the prevention of heart disease in women after the menopause. In reliable comparisons with placebo, researchers found no evidence that hormone therapy for six months or more provides any protective effects against death from any cause, death specifically from heart disease, non‐fatal heart attack or angina. Rather, in post‐menopausal women, HRT increased the risk of stroke and obstruction of a vein by a blood clot (venous thromboembolism).',
    references: 'Boardman HMP, Hartley L, Eisinga A, Main C, Roqué i Figuls M, Bonfill Cosp X, Gabriel Sanchez R, Knight B. Hormone therapy for preventing cardiovascular disease in post‐menopausal women. Cochrane Database of Systematic Reviews 2015, Issue 3. Art. No.: CD002229. DOI: 10.1002/14651858.CD002229.pub4',
    lessons: [
      null,
      'L6'
    ],
    principle: [
      null,
      'P1',
      'P6',
      'P7',
      'P9'
    ]
  },
  {
    health_actions: 'green tea',
    health_problem_or_goal: 'overweight',
    claimed_effects: 'helps weight loss',
    actual_effects: 'Green tea does not have an important effect on weight loss or maintaining weight loss.',
    evidence_summary: 'Green tea has a long history of many uses, one of which is helping overweight people to lose weight and to maintain weight loss. Some people believe that green tea helps weight loss by increasing a person\'s energy output. Green tea weight loss preparations contain extracts of green tea that contain a higher concentration of ingredients (catechins and caffeine) than the typical green tea beverage prepared from a tea bag and boiling water. In reliable comparisons with another beverage, researchers found that the loss in weight in the group given a green tea preparation was very small and is not likely to be important compared to the control group. Studies that examined the effect of green tea preparations on maintaining weight loss also did not show any benefit compared to the use of a control preparation.',
    references: 'Jurgens  TM, Whelan  AM, Killian  L, Doucette  S, Kirk  S, Foy  E. Green tea for weight loss and weight maintenance in overweight or obese adults. Cochrane Database of Systematic Reviews 2012, Issue 12. Art. No.: CD008650. https://doi.org/10.1002/14651858.CD008650.pub2',
    lessons: [
      null,
      'L2',
      'L3',
      'L5',
      'L10'
    ],
    principle: [
      null,
      'P3',
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'drinking lots of water',
    health_problem_or_goal: 'overweight',
    claimed_effects: 'helps weight loss',
    actual_effects: 'It is unclear whether drinking lots of water is associated with weight gain or with weight loss.',
    evidence_summary: 'Water is often recommended to replace sugar-containing beverages for prevention of childhood obesity. However, in non-randomised comparisons, researchers found no clear results about whether more water consumption is associated with weight gain or weight loss. Reliable comparisons are needed to find whether drinking lots of water helps with weight loss.',
    references: 'Muckelbauer R, Barbosa CL, Mittag T, Burkhardt K, Mikelaishvili N, Müller-Nordhorn J. Association between water consumption and body weight outcomes in children and adolescents: a systematic review. Obesity (Silver Spring). 2014 Dec;22(12):2462-75. doi: 10.1002/oby.20911. PMID: 25401930.',
    lessons: [
      null,
      'L2'
    ],
    principle: [
      null,
      'P2',
      'P3',
      'P4',
      'P6'
    ]
  },
  {
    health_actions: 'applying creams for pain, gels for pain, ointments for pain',
    health_problem_or_goal: 'ankle sprain, muscle pain, joint pain',
    claimed_effects: 'reduce pain',
    actual_effects: 'Creams, gels and ointments containing pain relieving medication called NSAIDs can provide good pain relief in new musculoskeletal injuries like a sprained ankle or pulled muscles. There is no evidence that other types of pain relief creams provide good levels of pain relief.',
    evidence_summary: 'Acute musculoskeletal pain describes pain in joints and muscles, often caused by injuries like a sprained ankle or a muscle pull. These injuries usually get better over two or three weeks without treatment, but can be very painful while they last. Creams, gels and ointments containing pain relieving medication called non-steroidal anti-inflammatory drug, (NSAIDs like ibuprofen, naproxen, diclofenac) penetrate the skin, enter tissues or joints, and reduce processes causing pain in the tissue. Levels of the medication in the blood with creams are very much lower than with the same drug taken by mouth. This reduces the risk of harmful effects. In reliable comparisons, researchers have found that creams and gels containing NSAIDs can provide good levels of pain relief in new injuries such as sprains, strains, and overuse injuries. There is no good evidence that creams containing other types of pain medication called salicylate give useful pain relief.',
    references: 'Derry S, Moore RA, Gaskell H, McIntyre M, Wiffen PJ. Topical NSAIDs for acute musculoskeletal pain in adults. Cochrane Database of Systematic Reviews 2015, Issue 6. Art. No.: CD007402. DOI: 10.1002/14651858.CD007402.pub3, and Derry S, Matthews PRL, Wiffen PJ, Moore RA. Salicylate‐containing rubefacients for acute and chronic musculoskeletal pain in adults. Cochrane Database of Systematic Reviews 2014, Issue 11. Art. No.: CD007403. DOI: 10.1002/14651858.CD007403.pub3',
    lessons: [
      null,
      'L10'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'taking Vioxx',
    health_problem_or_goal: 'joint pain',
    claimed_effects: 'relieves pain',
    actual_effects: 'A pain relief medication called Vioxx provides good pain relief for joint pain. However, Vioxx increases heart attacks and strokes in people who take it.',
    evidence_summary: 'Vioxx (rofecoxib) is a non‐steroidal anti‐inflammatory drug (NSAID) used to treat rheumatoid arthritis. It is thought to be safer on the stomach than other NSAIDS, like Naprosyn (naproxen). In reliable comparisons, researchers found that more people with rheumatoid arthritis who took Vioxx for 8 weeks had relief from their symptoms than people who took a \'sugar pill\' or placebo, and that it worked just as well as Naprosyn. However, Vioxx was withdrawn from the market in 2004 after it was shown that long‐term use (greater than 18 months) increased the risk of heart attack and stroke.',
    references: 'Garner SE, Fidan D, Frankish RR, et al. Rofecoxib for rheumatoid arthritis. Cochrane Database of Systematic Reviews 2005; 1:CD003685. https://doi.org/10.1002/14651858.CD003685.pub2',
    lessons: [
      null,
      'L3'
    ],
    principle: [
      null,
      'P1'
    ]
  },
  {
    health_actions: 'applying toothpaste',
    health_problem_or_goal: 'acne (spots, pimples)',
    claimed_effects: 'makes pimples (spots) go away',
    actual_effects: 'There is no evidence that toothpaste is a good treatment for pimples (spots/acne), and it can cause irritation to skin.',
    evidence_summary: 'A hint found on many websites is that toothpaste can dry up individual spots. While toothpaste does have antibacterial substances, it also contains substances that can irritate and damage your skin so it may not be preferable. ',
    references: 'Kameswararao K, Sujani C, Koteswararao NV, Rajarao A, Satyanarayanamma PN. A Brief Review on Acne Vulgaris. Research journal of Pharmacology and Pharmacodynamics. 2019 Jul 1;11(3):109-19, and How the Internet is Changing us, our Patients and our Practice. Indian Dermatology Online Journal. 2021 Jul;12(4):593.',
    lessons: [
      null,
      'L2',
      'L8',
      'L10'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'applying mushed avocado',
    health_problem_or_goal: 'acne (spots, pimples)',
    claimed_effects: 'prevents pimples, makes pimples go away',
    actual_effects: 'There is no evidence that avocado flesh or oil prevent or improve pimples (acne/spots).',
    evidence_summary: 'Many internet sites recommend using avocado flesh or oil to prevent or treat acne and pimples. There are no reliable comparisons that show avocado improves acne. ',
    references: '',
    lessons: [
      null,
      'L8',
      'L10'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'holding under running water',
    health_problem_or_goal: 'burns',
    claimed_effects: 'reduces pain, improves healing',
    actual_effects: 'Application of 20 minutes of cold running water within the first three hours of injury reduces pain and improves healing of burn injuries.',
    evidence_summary: 'Burn injuries are a leading cause of poor health that can result in disability and poor quality of life for survivors. In reliable comparisons with chemical cooling methods, researchers found that the application of 20 min of cold running water (CRW) within the first three hours of injury improves outcomes for patients with a burn injury.',
    references: 'Griffin B, Cabilan CJ, Ayoub B, Xu HG, Palmieri T, Kimble R, Singer Y. The effect of 20 minutes of cool running water first aid within three hours of thermal burn injury on patient outcomes: A systematic review and meta-analysis. Australas Emerg Care. 2022 Dec;25(4):367-376. doi: 10.1016/j.auec.2022.05.004. Epub 2022 Jun 7. PMID: 35688782.',
    lessons: [
      null,
      'L10'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'applying sugar paste',
    health_problem_or_goal: 'burns',
    claimed_effects: 'improves healing',
    actual_effects: 'In the situations where cool water and other conventional treatments are not available, alternative dressings such as boiled potato peel, banana leaf, aloe vera, honey, sugar paste, and papaya may improve healing of burn injuries.',
    evidence_summary: 'Burns are a common source of injuries worldwide. They may be minor or can result in serious loss of health. Cool running water and other treatments used in high-resource settings may not always be readily available. In comparisons, researchers have found some evidence that the use of alternative dressings such as boiled potato peel, banana leaf, aloe vera, honey, sugar paste, and papaya may improve healing of burn injuries. Researchers also found that honey heals partial thickness burns around 4 to 5 days more quickly than conventional dressings. ',
    references: 'Bitter CC, Erickson TB. Management of burn injuries in the wilderness: lessons from low-resource settings. Wilderness &amp; environmental medicine. 2016 Dec 1;27(4):519-25 and Jull AB, Cullum N, Dumville JC, Westby MJ, Deshpande S, Walker N. Honey as a topical treatment for wounds. Cochrane Database of Systematic Reviews 2015, Issue 3. Art. No.: CD005083. DOI: 10.1002/14651858.CD005083.pub4.',
    lessons: [
      null,
      'L2'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'applying cow dung',
    health_problem_or_goal: 'burns',
    claimed_effects: 'improves healing, harmful effects',
    actual_effects: 'There is no research showing that cow dung heals burns, and it is possible it will cause infection.',
    evidence_summary: 'In some countries, many people believe that cow dung helps heal burns. There are no fair comparisons of using cow dung to treat burns. However, cow dung contains bacteria that can cause infections. In addition, the use of cow dung can increase wound infection because of a reaction to the cow dung. Even though cow dung is applied to cool down and relieve pain, it is difficult to remove and must be peeled away, which can enlarge the area and depth of the wound. This can make the healing process longer and increases suffering.',
    references: 'Hamdiya A, Pius A, Ken A, Ekow HW. The trend of acute burns pre-hospital management. Journal of Acute Disease. 2015 Aug 1;4(3):210-3. https://doi.org/10.1016/j.joad.2015.03.002',
    lessons: [
      null,
      'L3'
    ],
    principle: [
      null,
      'P1',
      'P4'
    ]
  },
  {
    health_actions: 'using inhalers',
    health_problem_or_goal: 'asthma',
    claimed_effects: 'prevents breathing problems, improves breathing',
    actual_effects: 'Inhaler devices with asthma medicines (beta2-agonists) prevent breathing problems and improve breathing in people with asthma.',
    evidence_summary: 'Physical exercise may trigger symptoms such as cough, chest tightness and shortness of breath in people with asthma. Sometimes people who do not have asthma still experience asthma‐like symptoms during exercise. There are a number of treatments for asthma. One type, called beta2‐agonists open up the airways (small tubes in the lungs), making it easier for people to breathe. Often these treatments are taken using an inhaler device. Two kinds of beta2‐agonists are available: short‐acting (SABA, e.g., salbutamol and terbutaline) and long‐acting (LABA, e.g., formoterol and salmeterol). In reliable comparisons, researchers found that beta2‐agonists－both SABA and LABA－are effective and safe in preventing the symptoms of exercise-induced asthma. Used over a long time, inhaled beta2‐agonists can have less effect. In asthma treatment, beta2-agonists are usually used with other medications such as corticosteroids.',
    references: 'Bonini M, Di Mambro C, Calderon MA, Compalati E, Schünemann H, Durham S, Canonica GW. Beta2‐agonists for exercise‐induced asthma. Cochrane Database of Systematic Reviews 2013, Issue 10. Art. No.: CD003564. DOI: 10.1002/14651858.CD003564.pub3.',
    lessons: [
      null,
      'L2'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'permitting or prohibiting the sale of medicines',
    health_problem_or_goal: 'any',
    claimed_effects: 'helpful effects, harmful effects',
    actual_effects: 'Authorization of medicines by regulatory agencies helps to ensure the effectiveness and safety of medicines before they can be sold, but not all medicines that are authorized are safe or cost-effective.',
    evidence_summary: 'Drug regulatory authorities award marketing authorizations that license pharmaceutical companies to market medicinal products when there is sufficient evidence that the product has a favourable benefit-to-harm balance. This helps to ensure that medicines that can be sold are safe and effective. However, practices vary around the world and at least 353 medicines were withdrawn after first being approved between 1950 and 2015 because of serious adverse effects. Also, regulators who provide marketing authorization often do not require comparisons with other medicines that are already available, and they accept short-term outcomes and outcomes that are not directly important to people, such as blood tests (surrogate outcomes). On the other hand, governments or insurance agencies that pay for medicines typically make decisions based on the benefits, harms, and costs of new medicines compared to other available treatments (cost-effectiveness). Not all medicines that are authorized are cost effective.',
    references: 'Onakpoya IJ, Heneghan CJ, Aronson JK. Worldwide withdrawal of medicinal products because of adverse drug reactions: a systematic review and analysis. Crit Rev Toxicol. 2016;46(6):477-89. https://doi.org/10.3109/10408444.2016.1149452 Ofori-Asenso R, Hallgreen CE, De Bruin ML. Improving Interactions Between Health Technology Assessment Bodies and Regulatory Agencies: A Systematic Review and Cross-Sectional Survey on Processes, Progress, Outcomes, and Challenges. Front Med (Lausanne). 2020 Oct 16;7:582634. doi: 10.3389/fmed.2020.582634. PMID: 33178721; PMCID: PMC7596325.',
    lessons: [
      null,
      'L9'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'taking ACT',
    health_problem_or_goal: 'malaria',
    claimed_effects: 'cures malaria',
    actual_effects: 'ACT can cure malaria and are relatively safe with few serious side effects. Minor side effects are more common but can be difficult to distinguish from the symptoms of malaria itself.',
    evidence_summary: 'Malaria is a major cause of illness and death in many parts of the world. It is spread by the bite of mosquitoes infected with a parasite called Plasmodium. The Plasmodium species, P. falciparum, is the most common cause of malaria worldwide and causes most deaths. Uncomplicated malaria is the mild form of the disease which, if left untreated, can progress rapidly to become life threatening. The drugs traditionally used to treat uncomplicated malaria have become ineffective in many parts of the world due to the development of drug resistance. Artemisinin‐based Combination Therapy (ACTs) combine an artemisinin‐derivative (a relatively new group of drugs which are very effective) with another longer‐lasting drug to try and reduce the risk of further resistance developing. In reliable comparisons, researchers have found that ACTs are highly effective for treating P. falciparum in most places where they have been studied. The second most common form of malaria, P. vivax, can also be treated with ACTs but requires additional treatment to cure the patient completely. This is because the P. vivax parasite can lie dormant in the liver for months or years before becoming active again. The ACTs are relatively safe with few serious side effects. Minor side effects are more common but can be difficult to distinguish from the symptoms of malaria itself.',
    references: 'Sinclair  D, Zani  B, Donegan  S, Olliaro  P, Garner  P. Artemisinin‐based combination therapy for treating uncomplicated malaria. Cochrane Database of Systematic Reviews 2009, Issue 3. Art. No.: CD007483. https://doi.org/10.1002/14651858.CD007483.pub2',
    lessons: [
      null,
      'L7'
    ],
    principle: [
      null,
      'P1',
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'taking antiretroviral therapy',
    health_problem_or_goal: 'HIV/AIDS',
    claimed_effects: 'reduces suffering and deaths, harmful effects',
    actual_effects: 'Antiretroviral therapy can reduce death and suffering from HIV/AIDS, but also has harmful effects.',
    evidence_summary: 'HIV-related illness, AIDS and death are big problems in many countries. Medications called anti-retroviral therapy (ART) were developed to try and reduce the impact of the HIV virus in people who are infected with it. In reliable comparisons, researchers have found that ART is effective in slowing down the progression of AIDS and in reducing HIV‐related illnesses and death. They have also found that starting ART early in HIV-infected people reduces deaths in HIV‐infected individuals who have not received antiretroviral treatment before and who do not have any symptoms of HIV illness. Some people taking ARTs experience unwanted effects. These include fatigue, confusion, taste disturbances, and nausea.',
    references: 'Siegfried  N, Uthman  OA, Rutherford  GW. Optimal time for initiation of antiretroviral therapy in asymptomatic, HIV‐infected, treatment‐naive adults. Cochrane Database of Systematic Reviews 2010, Issue 3. Art. No.: CD008272. https://doi.org/10.1002/14651858.CD008272.pub2 Al-Dakkak I, Patel S, McCann E, et al. The impact of specific HIV treatment-related adverse events on adherence to antiretroviral therapy: a systematic review and meta-analysis. AIDS Care 2013;25(4):400-14. https://doi.org/10.1080/09540121.2012.712667',
    lessons: [
      null,
      'L7'
    ],
    principle: [
      null,
      'P1',
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'eating lots of carrots',
    health_problem_or_goal: 'overweight',
    claimed_effects: 'helps weight loss',
    actual_effects: 'Eating carrots is unlikely to help people lose weight.',
    evidence_summary: 'While eating lots of fruit and vegetables is an important part of a healthy diet, there is no evidence that eating carrots helps weight loss.',
    references: '',
    lessons: [
      null
    ],
    principle: [
      null,
      'P6',
      'P7',
      'P8'
    ]
  },
  {
    health_actions: 'having surgery',
    health_problem_or_goal: 'broken collar bone',
    claimed_effects: 'reduces pain, improves function, harmful effects',
    actual_effects: 'Surgery may not give better results for a broken collar bone than non.surgical treatments.',
    evidence_summary: 'Fractures (breaks) of the clavicle (collar bone) are a common injury in sports-people and older adults. They usually result from a fall directly onto the outer side of the shoulder, and usually involve the middle part of the bone. They are usually treated non‐surgically with a sling for two to six weeks. However, the results can be unsatisfactory for more serious fractures. Surgical treatment involves putting the bone back in place and fixing it with a metal plate or rod. In reliable comparisons of surgical with non-surgical‐surgical treatments,  researchers have found that surgery may not result in benefits over non-surgical treatment. However, researchers are uncertain, and more comparisons are needed.',
    references: 'Lenza M, Buchbinder R, Johnston RV, Ferrari BAS, Faloppa F. Surgical versus conservative interventions for treating fractures of the middle third of the clavicle. Cochrane Database of Systematic Reviews 2019, Issue 1. Art. No.: CD009363. DOI: 10.1002/14651858.CD009363.pub3',
    lessons: [
      null,
      'L6',
      'L10'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'surgery ',
    health_problem_or_goal: 'torn meniscus cartilage in older people',
    claimed_effects: 'reduces pain, improves function',
    actual_effects: 'Surgery is no better than sham surgery for older people with a tear in worn cartilage in the knee (meniscus).',
    evidence_summary: 'A randomised trial showed that surgery is no better than sham surgery for older patients with a tear in worn cartilage in the knee (meniscus) that is torn. For middle-aged people with a tear in a worn meniscus, surgery does not reduce pain or improve function compared to exercise therapy or placebo.',
    references: 'Rotini M, Papalia G, Setaro N, Luciani P, Marinelli M, Specchia N, Gigante A. Arthroscopic surgery or exercise therapy for degenerative meniscal lesions: a systematic review of systematic reviews. Musculoskelet Surg. 2022 Sep 3. doi: 10.1007/s12306-022-00760-z. Epub ahead of print. PMID: 36057031. Sihvonen R, Paavola M, Malmivaara A, Itälä A, Joukainen A, Nurmi H, Kalske J, Järvinen TL; Finnish Degenerative Meniscal Lesion Study (FIDELITY) Group. Arthroscopic partial meniscectomy versus sham surgery for a degenerative meniscal tear. N Engl J Med. 2013 Dec 26;369(26):2515-24. doi: 10.1056/NEJMoa1305189. PMID: 24369076.',
    lessons: [
      null,
      'L6',
      'L10'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'breathing in steam',
    health_problem_or_goal: 'Covid-19, flu, common cold',
    claimed_effects: 'reduces symptoms, harmful effects',
    actual_effects: 'Breathing in steam probably does not have an important effect on the common cold, Covid-19 or the flu, and can have harmful effects like lip and nose irritation.  ',
    evidence_summary: 'Some people think breathing in (inhaling) warm, damp air relieves symptoms of the common cold. In reliable comparisons, researchers found that heated, humidified air administered using a special device might relieve symptoms, but the studies were small, and the results were inconsistent, so the benefits of steam for the common cold are uncertain. There were also some harmful effects, including nose and lip irritation, light-headedness, increased mucus congestion, and discomfort from the mask delivering heated, humidified air. There have not been reliable comparisons of inhaling steam without using a special device, or of inhaling steam for influenza, COVID-19, or other respiratory infections.',
    references: 'Singh M, Singh M, Jaiswa  N, Chauhan A. Heated, humidified air for the common cold. Cochrane Database of Systematic Reviews 2017; 8:CD001728.',
    lessons: [
      null,
      'L3',
      'L7',
      'L8'
    ],
    principle: [
      null,
      'P1',
      'P3',
      'P6'
    ]
  },
  {
    health_actions: 'taking Tamiflu',
    health_problem_or_goal: 'flu',
    claimed_effects: 'prevents flu, reduces symptoms, harmful effects',
    actual_effects: 'Tamiflu (oseltamivir) shortens how long flu lasts by less than a day, and it can prevent flu. It does not reduce hospitalisations, and it is uncertain whether it reduces pneumonia or other complications of flu. Harmful effects of Tamiflu include nausea, vomiting, and headaches.',
    evidence_summary: 'Oseltamivir (Tamiflu) and zanamivir (Relenza) are medicines that have been stockpiled in many countries to treat and prevent seasonal and pandemic influenza, including avian influenza (bird flu). There have been many reliable comparisons of these medicines, mostly sponsored by the companies that sell them. In these comparisons, researchers have found that both medicines shorten the length of flu symptoms by less than a day. Tamiflu also reduces the risk of developing flu symptoms if taken to prevent influenza. Tamiflu does not affect the number of hospitalisations and there is no reliable evidence of its effect on pneumonia or other complications of influenza. Harmful effects of Tamiflu include nausea, vomiting, and headaches. There are not fair comparisons of Tamiflu for bird flu or for other respiratory infections.',
    references: 'Jefferson  T, Jones  MA, Doshi  P, Del Mar  CB, Hama  R, Thompson  MJ, Spencer  EA, Onakpoya  IJ, Mahtani  KR, Nunan  D, Howick  J, Heneghan  CJ. Neuraminidase inhibitors for preventing and treating influenza in adults and children. Cochrane Database of Systematic Reviews 2014, Issue 4. Art. No.: CD008965. DOI: 10.1002/14651858.CD008965.pub4.',
    lessons: [
      null,
      'L3'
    ],
    principle: [
      null,
      'P1',
      'P9'
    ]
  },
  {
    health_actions: 'taking antibiotics',
    health_problem_or_goal: 'sore throat',
    claimed_effects: 'cures sore throats, reduces symptoms, harmful effects',
    actual_effects: 'Antibiotics probably slightly reduce the number of people experiencing sore throat caused by infection by bacteria, and slightly reduce the likelihood of headache, and some sore throat complications. A decision to use antibiotics should depend on whether the underlying cause of the sore throat is likely to be bacterial, and the balance between modest symptom reduction, the potential hazards of antibiotic resistance and harmful effects.',
    evidence_summary: 'Sore throats are pain or discomfort in the throat, often caused by bacteria or viruses. Fever and headache are also common accompanying symptoms. People usually recover after three or four days without treatment, although some develop complications. A serious but rare complication is rheumatic fever, which affects the heart and joints. Other complications include infection of the sinuses, ear, tonsils, and kidney. Antibiotics affect infections caused by bacteria, but not those caused by viruses. They can have harmful effects that include diarrhoea and rash. In addition, communities build resistance to them. In reliable comparisons, researchers found that antibiotics probably reduce the number of people experiencing sore throat, and reduce the likelihood of headache, and some sore throat complications. As the effect on symptoms can be small, clinicians must judge on an individual basis whether it is clinically justifiable to use antibiotics to produce this effect, and whether the underlying cause of the sore throat is likely to be of bacterial origin. Also, the balance between modest symptom reduction and the potential hazards of antibiotic resistance must be considered.   ',
    references: 'Spinks A, Glasziou PP, Del Mar CB. Antibiotics for treatment of sore throat in children and adults. Cochrane Database of Systematic Reviews 2021, Issue 12. Art. No.: CD000023. DOI: 10.1002/14651858.CD000023.pub5.',
    lessons: [
      null,
      'L8',
      'L9',
      'L10'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'blood letting',
    health_problem_or_goal: 'infections, many conditions',
    claimed_effects: 'cures infections and other conditions, harmful effects',
    actual_effects: 'There is no evidence that bloodletting cures infections or other conditions such as high blood pressure. Blood letting can have harmful effects. ',
    evidence_summary: 'Historically, blood-letting was used as a treatment for many conditions, including fever and infection. More recently, comparisons have been done by researchers to find if blood-letting therapy (BLT) could be used to reduce high blood pressure. No definite conclusions regarding the effect and safety of BLT as a complementary and alternative approach for treatment of high blood pressure could be drawn due to the generally poor quality (less reliable) comparisons. More reliable comparisons are needed.',
    references: 'Xiong XJ, Wang PQ, Li SJ. Blood-Letting Therapy for Hypertension: A Systematic Review and Meta-Analysis of Randomized Controlled Trials. Chin J Integr Med. 2019 Feb;25(2):139-146. doi: 10.1007/s11655-018-3009-2. Epub 2018 Jun 25. PMID: 29959751.',
    lessons: [
      null,
      'L3'
    ],
    principle: [
      null
    ]
  },
  {
    health_actions: 'screening for breast cancer',
    health_problem_or_goal: 'breast cancer',
    claimed_effects: 'prevents death from breast cancer, harmful effects',
    actual_effects: 'It is uncertain whether or not breast cancer screening reduces breast cancer deaths. However, it does result in some people being diagnosed with, and treated for, a breast cancer that would not have led to ill health or death. The anxiety and unnecessary treatment are harmful effects of breast cancer screening.',
    evidence_summary: 'Screening with mammography uses X‐ray imaging to find breast cancer before a lump can be felt. In reliable comparisons with no screening, researchers found that it was uncertain whether screening reduced breast cancer deaths or not. They also found that screening will result in some women getting a cancer diagnosis even though their cancer would not have led to death or sickness. Currently, it is not possible to tell which women these are, and they are therefore likely to have breasts or lumps removed and to receive radiotherapy unnecessarily. Harmful effects include psychological distress including anxiety and uncertainty for years because of false positive findings.',
    references: 'Gøtzsche PC, Jørgensen KJ. Screening for breast cancer with mammography. Cochrane Database of Systematic Reviews 2013, Issue 6. Art. No.: CD001877. DOI: 10.1002/14651858.CD001877.pub5.',
    lessons: [
      null,
      'L4'
    ],
    principle: [
      null,
      'P1',
      'P2',
      'P4',
      'P6'
    ]
  },
  {
    health_actions: 'tamoxifen',
    health_problem_or_goal: 'breast cancer',
    claimed_effects: 'prevents breast cancer, reduces deaths, harmful effects',
    actual_effects: 'Tamoxifen can prevent some types of breast cancer. It can also be used with other treatments like chemotherapy and radiotherapy to treat some types of breast cancer. Its harmful effects include an increased risk of cancer of the womb, and blood clots.',
    evidence_summary: 'Breast cancer is the most frequent type of cancer and the second most frequent cause of cancer deaths in women. Cancer prevention agents (CPAs) are medicines that can prevent and treat some breast cancers. Tamoxifen is one type of CPA. In reliable comparisons, researchers have found that Tamoxifen reduces the number of cases of breast cancer developing in women who are at a higher risk of getting it (e.g., if they have a strong family history of breast cancer). However, Tamoxifen can also have harmful effects such as increasing the risk for cancer of the womb and blood clots. Now, there are other types of CPAs that may have a similar or better effect at reducing breast cancer cases, and have fewer harmful effects; more research is needed to be sure of this. Researchers have also found in reliable comparisons that Tamoxifen treatment, together with other treatments like chemotherapy and radiotherapy, substantially improve the 10-year survival of women with some types of breast cancers called ER-positive tumours.',
    references: 'Mocellin S, Goodwin A, Pasquali S. Risk‐reducing medications for primary breast cancer: a network meta‐analysis. Cochrane Database of Systematic Reviews 2019, Issue 4. Art. No.: CD012191. DOI: 10.1002/14651858.CD012191.pub2, and Early Breast Cancer Trialists\' Collaborative Group.Tamoxifen for early breast cancer: an overview of the randomised trials. Lancet 1998, Vol 351, Issue 9114, P1451-1467. Pub:May 16, 1998 DOI:https://doi.org/10.1016/S0140-6736(97)11423-4 (can we find something better?)',
    lessons: [
      null,
      'L4',
      'L7'
    ],
    principle: [
      null,
      'P1',
      'P2',
      'P5',
      'P7',
      'P8',
      'P9'
    ]
  },
  {
    health_actions: 'eating a diet rich in Vitamin C, eating oranges',
    health_problem_or_goal: 'scurvy',
    claimed_effects: 'prevents scurvy, cures scurvy',
    actual_effects: 'Vitamin C contained in oranges prevents and cures a disease called scurvy',
    evidence_summary: 'In 1753, a ship\'s surgeon, called James Lind, carried out the first documented comparison of different treatments for a disease called scurvy that was common in sailors who had been at sea for a long time. Lind\'s comparison would not be considered reliable by today\'s standards, but he did try to make sure the participants were similar at the start of his comparison. He also observed quite dramatic effects that helped him be more certain of his results. He found that the sailors who were given oranges and lemons recovered fastest and best from scurvy. Much later, in the twentieth century, it was discovered that scurvy is caused by a lack of vitamin C in the body. Oranges and lemons contain Vitamin C.',
    references: 'Lind J. A treatise of the scurvy in three parts: containing an inquiry into the nature,causes and cure, of the disease together with a critical and chronologicalview of what has been published on the subject. Stewart-cp;-gutherie-d-[eds.] -sands,-murray-and-cochran:-edinburgh 1953; null(null): 145‐148.',
    lessons: [
      null,
      'L3'
    ],
    principle: [
      null,
      'P2',
      'P6'
    ]
  }
]

export default data
