<template>
  <div>
    <static-content></static-content>
  </div>
</template>

<script>
import staticContent from '@/components/staticContent'

export default {
  name: 'staticContentView',
  data () {
    return {}
  },
  components: {
    'static-content': staticContent
  }
}
</script>

<style lang="scss" scoped>

</style>
