<template>
  <footer class="mt-5 pt-3 d-print-none">
    <b-container>
      <b-row>
        <b-col>
          <ul class="list-unstyled">
            <li>
              <b-link :to="{name: 'glossary'}">Glossary</b-link>
            </li>
            <template v-if="showMenu === 'TRUE'">
            <li>
              <b-link :to="{name: 'otherContent', params: {documentid: '1gdgH3QxWKOZceNAxDoqtZyLTbxsRkBYc75MG9cDXCYs'}}">About</b-link>
            </li>
            </template>
            <template v-else>
            <li>
              <b-link :to="{name: 'otherContent', params: {documentid: '1GRxFbZpAoAOX5Lul0T17u3aTrvrJG9jDT7aUSHvZ2cw'}}">About</b-link>
            </li>
            </template>
            <li>
              <b-link :to="{name: 'Home'}">Home</b-link>
            </li>
          </ul>
          <p>
            BE SMART ABOUT YOUR HEALTH is licensed under a <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International license</a>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'mainFooterLanding',
  data () {
    return {
      showMenu: process.env.VUE_APP_SHOW_TEACHERS
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_colors.scss';

footer {
  background-color: #ededed;
  & .btn-secondaryLanding {
    color: $fuchsia;
    border-color: $fuchsia;
    font-size: 1.063rem;
  }
}
</style>
