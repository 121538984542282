<template>
  <div>
    <gDocsSheetStudents/>
  </div>
</template>
<script>
// @ is an alias to /src
import gDocsSheetStudents from '@/components/gDocsSheetStudents'

export default {
  name: 'Students',
  components: {
    gDocsSheetStudents
  }
}
</script>
