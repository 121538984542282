<template>
  <span>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 195 214.2" style="enable-background:new 0 0 195 214.2;" xml:space="preserve">
      <g>
        <path class="st0" d="M179.3,150.5c9.9-15.3,15.7-33.4,15.7-53c0-17.2-4.5-33.4-12.3-47.5c-1.2-13.8-9.9-25.4-24-31.2 c-2.2-0.9-4.4-1.6-6.6-2.1c-2.2-1.5-4.6-2.9-6.9-4.3c0,0,0,0,0,0c-1.1-0.6-2.2-1.2-3.3-1.8c-0.1-0.1-3.9-1.9-3.9-1.9 c-5.9-2.7-12.2-4.8-18.7-6.3c0,0-2.5-0.5-3.7-0.8c-0.2,0-2.9-0.5-4-0.7c-0.2,0-3.9-0.5-5.5-0.6c-0.9-0.1-3.8-0.3-4.3-0.3 C100.4,0,99.2,0,98.1,0c-0.2,0-0.9,0-1.1,0c-1.5,0-4.5,0.1-4.6,0.1C71.1,1.2,49.9,9.2,32.7,24.5C20.9,35,12.2,47.8,6.8,61.6 C2.4,72.7,0,84.8,0,97.5C0,151.3,43.6,195,97.5,195c7.1,0,14-0.8,20.7-2.2c1.1,4.7,4.5,8.9,9.5,11.3l12.3,6c4,2,8.3,3.2,12.6,3.8 c1.7,0.2,3.3,0.3,5,0.3c3,0,6.1-0.4,9-1.1c9.2-2.2,16.7-7.9,20.7-15.5c3.7-7.1,4.2-15.3,1.2-23.1L179.3,150.5z"/>
        <path class="st1" d="M156.4,145.4c-0.5-1.3-0.4-2.8,0.3-3.9c0.7-1.2,1.9-2,3.5-2.3c2.8-0.6,6,1.3,7.1,4l12.6,33.2 c4.7,12.3-2.6,25-16.2,28.4c-6.6,1.6-13.6,0.8-19.9-2.3l-12.3-6c-3.7-1.8-5.6-5.5-4.5-8.9l10.3-25.8l-32-15.7 c-1.1-0.6-1.9-1.5-2.3-2.5c-0.3-1-0.2-2,0.4-2.9c1.1-1.6,3.7-2,5.8-0.9l55.3,27.2L156.4,145.4z"/>
        <path class="st1" d="M90.7,70.5c-0.8-1.3-2.1-2.2-3.7-2.5c-1.5-0.3-3.1,0-4.4,0.8c-1.3,0.8-2.2,2.1-2.5,3.7 c-0.7,3.1,1.3,6.2,4.5,6.9c0.4,0.1,0.8,0.1,1.2,0.1c2.7,0,5.1-1.9,5.7-4.6C91.8,73.3,91.6,71.8,90.7,70.5z"/>
        <path class="st1" d="M151,40.2c-1.5-0.3-3.1,0-4.4,0.8c-1.3,0.8-2.2,2.1-2.5,3.7c-0.3,1.5,0,3.1,0.8,4.4c0.8,1.3,2.1,2.2,3.7,2.5 c0.4,0.1,0.8,0.1,1.2,0.1c2.7,0,5.1-1.9,5.7-4.6C156.2,44,154.2,40.9,151,40.2z"/>
        <path class="st2" d="M119.8,126.3c1.2-0.3,5.3,0,8,0.3c3,0.3,5.8,0.5,8.5,0.5c4.2,0,7.8-0.6,10.1-2.8c1.6-1.5,1.6-4,0.1-5.5 c-1.5-1.6-3.9-1.6-5.5-0.1c-1.5,1.1-9,0.5-12.6,0.2c-6.3-0.5-10-0.8-12.5,0.7c-1.8,1.1-2.4,3.5-1.3,5.4 C115.7,126.6,118,127.3,119.8,126.3z"/>
        <path class="st2" d="M62.9,77.4c-2.2,10.2,4.4,20.2,14.6,22.4c10.2,2.2,20.2-4.4,22.4-14.6C102,75,95.5,65,85.3,62.8 C75.1,60.7,65,67.2,62.9,77.4z"/>
        <path class="st2" d="M141,72.3c10.2,2.2,20.2-4.4,22.4-14.6c2.2-10.2-4.4-20.2-14.6-22.4c-10.2-2.2-20.2,4.4-22.4,14.6 C124.2,60.1,130.8,70.1,141,72.3z"/>
        <path class="st2" d="M52.2,71.5c0.3,0.1,0.6,0.1,0.9,0.1c1.7,0,3.2-1.1,3.6-2.8c2.1-8,7.5-12.4,16.2-13.2c2-0.2,3.6-2,3.4-4 c-0.2-2-2-3.6-4-3.4c-11.8,1-19.9,7.6-22.7,18.7C49,69,50.2,71,52.2,71.5z"/>
        <path class="st1" d="M125.6,166.9l0.4-1.1l-24.8-12.2c-3.3-1.6-5.9-4.4-7-7.7c-1.1-3.3-0.6-6.7,1.4-9.5c3.5-5,11.2-6.5,17.5-3.4 l35.5,17.4l-0.9-2.3c-1.3-3.5-1-7.4,0.9-10.5c1.9-3.2,5.1-5.3,9.1-6.1c5-1,10.2,0.7,13.9,3.9c12-23.5,12.3-51.8,0.3-75.7 c2.1-10-1.2-20.8-9.6-27.9c-6.6-5.6-14.9-7.7-22.8-6.5c0,0-0.1-0.1-0.1-0.1c-7.1,0.4-14.2,3.2-19.1,9.4c-1.7,2.1-4.7,2.5-6.8,0.8 c-2.1-1.7-2.5-4.7-0.8-6.8c3.4-4.3,7.9-7.6,12.9-9.9c-27.8-10-60.1-4.8-83.7,16.1C14,59.7,6.7,99.3,21.3,131.7 c1.9,2.6,4,5.2,6.3,7.7C52.8,167.2,91.4,176.7,125.6,166.9z M126.4,49.9c2.2-10.2,12.2-16.7,22.4-14.6 c10.2,2.2,16.7,12.2,14.6,22.4c-2.2,10.2-12.2,16.7-22.4,14.6C130.8,70.1,124.2,60.1,126.4,49.9z M116,119.5 c2.5-1.5,6.2-1.3,12.5-0.7c3.6,0.3,11.1,0.9,12.6-0.2c1.6-1.5,4-1.4,5.5,0.1c1.5,1.6,1.4,4-0.1,5.5c-2.3,2.2-5.9,2.8-10.1,2.8 c-2.7,0-5.5-0.2-8.5-0.5c-2.7-0.2-6.8-0.6-8-0.3c-1.8,1-4.1,0.4-5.2-1.4C113.5,123,114.1,120.6,116,119.5z M85.3,62.8 C95.5,65,102,75,99.9,85.2c-2.2,10.2-12.2,16.7-22.4,14.6c-10.2-2.2-16.7-12.2-14.6-22.4C65,67.2,75.1,60.7,85.3,62.8z M49.5,67 c2.8-11.1,10.9-17.7,22.7-18.7c2-0.2,3.8,1.3,4,3.4c0.2,2-1.3,3.8-3.4,4c-8.7,0.7-14.1,5.1-16.2,13.2c-0.4,1.7-1.9,2.8-3.6,2.8 c-0.3,0-0.6,0-0.9-0.1C50.2,71,49,69,49.5,67z"/>
        <path class="st1" d="M121.5,177.3l4.1-10.4c-34.3,9.8-72.8,0.3-98.1-27.5c-2.3-2.5-4.3-5-6.3-7.7c3.4,7.5,7.9,14.6,13.7,21.1 C57.2,177.9,91.3,186.3,121.5,177.3z"/>
        <path class="st3" d="M121.5,177.3l4.1-10.4c-34.3,9.8-72.8,0.3-98.1-27.5c-2.3-2.5-4.3-5-6.3-7.7c3.4,7.5,7.9,14.6,13.7,21.1 C57.2,177.9,91.3,186.3,121.5,177.3z"/>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'smallLogo',
  data () {
    return {}
  }
}
</script>

<style type="text/css" scoped>
  #Layer_1 {
    width: 2.5rem;
  }
  .st0{fill:#231F20;}
  .st1{fill:#FFFFFF;}
  .st2{fill:none;}
  .st3{fill:#DCDDDE;}
</style>
