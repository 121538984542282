
<template>
<span>
  <template v-if="content.listId">
    <template v-if="formatGlyph(content.listId, content.nestingLevel, 1) === '★'">
      <font-awesome-icon :icon="['far', 'star']"></font-awesome-icon>
    </template>
    <template v-else>
      <span
        :style="applyHeadingStyle(content.content[0].paragraphStyle.namedStyleType,
        {}, content.bulletStyle, false)"
        class="pre-item"
        :class="{'circle': formatGlyph(content.listId, content.nestingLevel, 1) === '●', 'circle-ii': formatGlyph(content.listId, content.nestingLevel) === '○', 'star': formatGlyph(content.listId, content.nestingLevel) === '★'}">{{listsIndex(content.listId, content.nestingLevel)}}</span>
    </template>
  </template>
</span>
</template>

<script>
import headingsStyle from '@/mixins/headingsStyle'
export default {
  name: 'bulletContent',
  props: {
    content: Object,
    lists: Object,
    listsCounter: Object,
    headingParStyles: Object,
    headingTextStyles: Object
  },
  data: function () {
    return {}
  },
  mixins: [headingsStyle],
  methods: {
    // 'https://docs.googleapis.com/v1/documents/1tJqsqAEyCYzNf7Z46IKOwUx1ZEd1t2xk'
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
      return this.formatGlyph(listId, nestingLevel, this.listsCounter[listId][nestingLevel])
    },
    romanize (num) {
      if (isNaN(num)) {
        return NaN
      }
      const digits = String(+num).split('')
      const key = ['', 'C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM',
        '', 'X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC',
        '', 'I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'
      ]
      let roman = ''
      let i = 3
      while (i--) {
        roman = (key[+digits.pop() + (i * 10)] || '') + roman
      }
      return Array(+digits.join('') + 1).join('M') + roman
    },
    ConvertArabicToLetters (num) {
      var letters = ''
      while (num > 0) {
        num--
        letters = String.fromCharCode(97 + (num % 26)) + letters
        num = Math.floor(num / 26)
      }
      return letters
    },
    formatGlyph (listId, nestingLevel = 0, count = 0) {
      if (Object.prototype.hasOwnProperty.call(this.lists[listId].listProperties.nestingLevels[nestingLevel], 'glyphSymbol')) {
        return this.lists[listId].listProperties.nestingLevels[nestingLevel].glyphSymbol
      }
      switch (this.lists[listId].listProperties.nestingLevels[nestingLevel].glyphType) {
        case 'DECIMAL':
          return this.getGlyphFormat(listId, nestingLevel, count.toString())
        case 'ZERO_DECIMAL':
          return this.getGlyphFormat(listId, nestingLevel, (count < 10 ? '0' : '') + count.toString())
        case 'ROMAN':
          return this.getGlyphFormat(listId, nestingLevel, this.romanize(count))
        case 'ALPHA':
          return this.getGlyphFormat(listId, nestingLevel, this.ConvertArabicToLetters(count))
        case 'UPPER_ALPHA':
          return this.getGlyphFormat(listId, nestingLevel, this.ConvertArabicToLetters(count).toUpperCase())
        default:
          return this.getGlyphFormat(listId, nestingLevel, this.romanize(count))
      }
    },
    getGlyphFormat (listId, nestingLevel = 0, symbol) {
      if (Object.prototype.hasOwnProperty.call(this.lists[listId].listProperties.nestingLevels[nestingLevel], 'glyphFormat')) {
        const format = this.lists[listId].listProperties.nestingLevels[nestingLevel].glyphFormat.replace(/[0-9]/g, '').replace('%', '')
        if ((format.match(/\./g) || []).length > 1) {
          let text = ''
          for (let i = nestingLevel; i > -1; i--) {
            text = this.listsCounter[listId][i] + '.' + text
          }
          return text + ' '
        }
        switch (format) {
          case '':
            return symbol + ' '
          case ')':
            return symbol + ') '
          case '.':
            return symbol + '. '
          case '()':
            return '(' + symbol + ') '
          default:
            return symbol + '. '
        }
      } else {
        return symbol + '. '
      }
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/assets/styles/_colors.scss';

  .btn-goToTop {
    width: 5rem;
    max-width: 5rem;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 100;
    opacity: 0;
    transform: translateY(100px);
    transition: all .5s ease;
    border-color: $fuchsia;
    background-color: $fuchsia;
    color: white;
    font-size: 14pt;
    &.showBtn {
      opacity: 1;
      transform: translateY(0)
    }
    &:hover {
      background-color: $pink;
    }
  }

  .page-break-style {
    page-break-after: always;
  }
</style>
