import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueBootstrap from 'bootstrap-vue'
import VueGtag from 'vue-gtag'
import './registerServiceWorker'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSmile, faClock, faStar, faFrown } from '@fortawesome/free-regular-svg-icons'
import { faPrint, faPen, faUsers, faProjectDiagram, faUser, faDesktop, faChalkboardTeacher, faCaretRight, faCaretDown, faFileAlt, faEdit, faUserFriends, faComment, faHandPointUp, faKey, faList, faListAlt, faFile, faHome, faPlusSquare, faBars, faTimesCircle, faMinusSquare, faArrowLeft, faArrowUp, faArrowRight, faCheck, faBookmark, faArrowCircleDown, faDownload, faExternalLinkAlt, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

Vue.config.productionTip = false
Vue.use(VueBootstrap)

Vue.use(VueGtag, {
  config: { id: 'G-L3NCQTFFWN' }
}, router)

library.add(faPrint, faPen, faUsers, faProjectDiagram, faUser, faDesktop, faChalkboardTeacher, faCaretRight, faCaretDown, faFileAlt, faClock, faEdit, faUserFriends, faComment, faHandPointUp, faKey, faList, faListAlt, faFile, faHome, faPlusSquare, faStar, faBars, faTimesCircle, faMinusSquare, faArrowLeft, faArrowUp, faSmile, faArrowRight, faCheck, faBookmark, faArrowCircleDown, faDownload, faFrown, faExternalLinkAlt, faSearch)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// console.log('TEST', process.env)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
