<template>
  <div>
    <b-container fluid id="mainHeaderSection">
      <b-container>
        <h2 class="pb-3">For teachers</h2>
      </b-container>
    </b-container>
    <div class="teacher-min">
    <template v-if="loading">
      <b-container>
        <b-spinner class="m-auto" />
      </b-container>
    </template>
    <template v-else>
      <b-container>
        <b-row>
          <b-col
            md="7"
            cols="12">
            <!--  -->
            <div id="blackText" class="generalContent pt-5">
              <b-row>
                <b-col cols="12" class="items">
                  <ul class="list-unstyled">
                    <!-- <li class="mb-5">
                      <h3><b-link :to="{name: 'otherContent', params: {'documentid': '1JWZDJM1MqEyklu3wjgCjoctwgjvYDDFAEDVeqb-2xU0'}}">About these resources</b-link></h3>
                    </li> -->
                    <li class="mb-2">
                      <h3><b-link :to="{name: 'otherContent', params: {'documentid': '1CGcdUQh9HJKsOFjCCrkTA-T82r8ilB0ULEsSUaU0b8k'}}">Introduction</b-link></h3>
                    </li>
                    <li class="mb-2">
                      <h3><b-link :to="{name: 'otherContent', params: {'documentid': '1VeQM6_QRIqe0MNABV7w7AUcIm3sHOTdgR93KNrYT_8U'}}">Planning for the term</b-link></h3>
                    </li>
                    <li class="mb-5">
                      <h3><b-link :to="{name: 'otherContent', params: {'documentid': '1WsDwJy_v0Rz48OA1dP_aA1ueUoNaE2a69VucK9x9Uzk'}}">Using the resources offline</b-link></h3>
                    </li>
                  </ul>
                </b-col>
              </b-row>
            </div>
            <!--  -->
          </b-col>
          <b-col
            md="5"
            cols="12">
            <b-img src="/img/780x300.png" alt="teachers image description" fluid class="d-none d-md-block"></b-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="generalContent">
              <b-row>
              <b-col
                cols="12"
                class="mb-5 items">
                <h3 @click="blackboardVisible = !blackboardVisible">
                  <font-awesome-icon :icon="blackboardVisible?'minus-square':'plus-square'" class="branch mr-3 d-print-none" role="button"></font-awesome-icon>
                  <span>Blackboard lesson plans</span>
                </h3>
                <b-collapse
                  id="blackboard"
                  v-model="blackboardVisible"
                  class="mt-4">
                  <p>This set of lesson plans is designed for use in a classroom equipped with a blackboard or ﬂip chart.</p>
                  <ul class="lesson-list">
                    <li v-for="(lesson,id) in blackboardLessons" :key="'bl'+id" class="mb-3">
                      <span v-if="lesson.valid">
                        <b-link
                          :to="{name: 'teachersLessonsBlackBoard', params: {coursesslug: lesson.courseSlug, lessonsslug: lesson.lessonSlug}, query:{section:'lesson'}}">
                          {{lesson.title}}
                        </b-link>
                        <span v-if="lesson.avilableOffline" class="ml-1 ml-md-3 downloaded-icon" data-toggle="tooltip" data-placement="top" title="Accessible offline">
                          <font-awesome-icon icon="download"/>
                        </span>
                      </span>
                      <span v-else>{{lesson.title}} (Not available)</span>
                    </li>
                  </ul>
                </b-collapse>
              </b-col>
              <b-col
                cols="12"
                class="mb-5 items">
                <h3
                  @click="projectorVisible = !projectorVisible">
                  <font-awesome-icon :icon="projectorVisible?'minus-square':'plus-square'" class="branch mr-3 d-print-none" role="button"></font-awesome-icon>
                  <span>Projector lesson plans</span>
                </h3>
                <b-collapse
                  id="projector"
                  v-model="projectorVisible"
                  class="mt-4">
                  <p>This set of lesson plans is designed for use in a classroom equipped with a projector.</p>
                  <ul>
                    <li v-for="(lesson,id) in projectorDocLessons" :key="'pr'+id" class="mb-4">
                      <span v-if="lesson.valid">
                        <b-link
                          :to="{name: 'teachersLessonsProjector', params: {coursesslug: lesson.courseSlug, lessonsslug: lesson.lessonSlug}, query:{section:'lesson'}}">
                          {{lesson.title}}
                        </b-link>
                        <span v-if="lesson.avilableOffline" class="ml-1 ml-md-3 text-muted downloaded-icon" data-toggle="tooltip" data-placement="top" title="Accessible offline">
                          <font-awesome-icon icon="download"/>
                        </span>
                      </span>
                      <span v-else>{{lesson.title}} (Not available)</span>
                    </li>
                  </ul>
                  <!-- <h4 class="mb-3">Presentations</h4>
                  <ul>
                    <li v-for="(lesson,id) in projectorLessons" :key="'ppt'+id" class="mb-4">
                      <span v-if="lesson.valid">
                        <a
                          :href="lesson.projectorLink">
                          {{lesson.title}}
                        </a>
                      </span>
                      <span v-else>{{lesson.title}} (Not available)</span>
                    </li>
                  </ul> -->
                </b-collapse>
              </b-col>
              <b-col
                cols="12"
                class="mb-5 items">
                <h3 @click="computerVisible = !computerVisible">
                  <font-awesome-icon :icon="computerVisible?'minus-square':'plus-square'" class="branch mr-3 d-print-none" role="button"></font-awesome-icon>
                  <span>Computer-based lesson plans</span>
                </h3>
                <b-collapse
                  id="computer"
                  v-model="computerVisible"
                  class="mt-4">
                  <p>This set of lesson plans is designed for use in a classroom equipped with computers for students.</p>
                  <ul>
                    <li v-for="(lesson,id) in computerRoomLessons" :key="'cr'+id" class="mb-4">
                      <span v-if="lesson.valid">
                        <b-link
                          :to="{name: 'teachersLessons', params: {coursesslug: lesson.courseSlug, lessonsslug: lesson.lessonSlug}, query:{section:'lesson'}}">
                          {{lesson.title}}
                        </b-link>
                        <span v-if="lesson.avilableOffline" class="ml-1 ml-md-3 text-muted downloaded-icon" data-toggle="tooltip" data-placement="top" title="Accessible offline">
                          <font-awesome-icon icon="download"/>
                        </span>
                      </span>
                      <span v-else>{{lesson.title}} (Not available)</span>
                    </li>
                  </ul>
                </b-collapse>
              </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'generalContent',
  data () {
    return {
      computerRoomLessons: [],
      blackboardLessons: [],
      projectorLessons: [],
      projectorDocLessons: [],
      computerVisible: false,
      projectorVisible: false,
      blackboardVisible: false,
      loading: true
    }
  },
  created: function () {
    this.initialParseList()
  },
  methods: {
    async initialParseList () {
      const newconfig = {}
      if (!window.navigator.onLine) {
        newconfig.timeout = 2
      }
      this.loading = true
      axios.get(process.env.VUE_APP_BACK_URL + 'all-courses/', newconfig)
        .then(async response => {
          await this.$store.commit('addStudentListOfLessons', response.data)
          response.data = await this.$store.state.StudentListoflessons
          for (let school = 0; school < response.data.length; school++) {
            // for (let course = 0; course < response.data[school].courses.length; course++) {
            // TODO: This must show the lessons available to the selected course instead of the hardcoded one.
            const course = 1
            for (let lesson = 0; lesson < response.data[school].courses[course].lessons.length; lesson++) {
              this.computerRoomLessons.push({
                title: response.data[school].courses[course].lessons[lesson].title,
                docId: response.data[school].courses[course].lessons[lesson].pk,
                lessonSlug: response.data[school].courses[course].lessons[lesson].slug,
                courseSlug: response.data[school].courses[course].slug,
                valid: this.validId(response.data[school].courses[course].lessons[lesson].teacherDocLink),
                avilableOffline: response.data[school].courses[course].lessons[lesson].teacherDocOffline
              })
              this.blackboardLessons.push({
                title: response.data[school].courses[course].lessons[lesson].title,
                docId: response.data[school].courses[course].lessons[lesson].pk,
                lessonSlug: response.data[school].courses[course].lessons[lesson].slug,
                courseSlug: response.data[school].courses[course].slug,
                valid: this.validId(response.data[school].courses[course].lessons[lesson].teacherBlackboardDocLink),
                avilableOffline: response.data[school].courses[course].lessons[lesson].teacherBlackboardOffline
              })
              this.projectorLessons.push({
                title: response.data[school].courses[course].lessons[lesson].title,
                docId: response.data[school].courses[course].lessons[lesson].pk,
                projectorLink: 'https://docs.google.com/presentation/d/' + response.data[school].courses[course].lessons[lesson].teacherProjectorPresentationLink,
                valid: this.validId(response.data[school].courses[course].lessons[lesson].teacherProjectorPresentationLink),
                avilableOffline: response.data[school].courses[course].lessons[lesson].teacherProjectorPresentationOffline
              })
              this.projectorDocLessons.push({
                title: response.data[school].courses[course].lessons[lesson].title,
                docId: response.data[school].courses[course].lessons[lesson].pk,
                lessonSlug: response.data[school].courses[course].lessons[lesson].slug,
                courseSlug: response.data[school].courses[course].slug,
                valid: this.validId(response.data[school].courses[course].lessons[lesson].teacherProjectorDocLink),
                avilableOffline: response.data[school].courses[course].lessons[lesson].teacherProjectorDocOffline
              })
            }
            // }
          }
          this.loading = false
        }).catch(async error => {
          console.log(error)
          const response = await this.$store.state.StudentListoflessons
          if (response) {
            for (let school = 0; school < response.length; school++) {
              // for (let course = 0; course < response[school].courses.length; course++) {
              const course = 1
              for (let lesson = 0; lesson < response[school].courses[course].lessons.length; lesson++) {
                this.computerRoomLessons.push({
                  title: response[school].courses[course].lessons[lesson].title,
                  docId: response[school].courses[course].lessons[lesson].pk,
                  lessonSlug: response[school].courses[course].lessons[lesson].slug,
                  courseSlug: response[school].courses[course].slug,
                  valid: this.validId(response[school].courses[course].lessons[lesson].teacherDocLink),
                  avilableOffline: response[school].courses[course].lessons[lesson].teacherDocOffline
                })
                this.blackboardLessons.push({
                  title: response[school].courses[course].lessons[lesson].title,
                  docId: response[school].courses[course].lessons[lesson].pk,
                  lessonSlug: response[school].courses[course].lessons[lesson].slug,
                  courseSlug: response[school].courses[course].slug,
                  valid: this.validId(response[school].courses[course].lessons[lesson].teacherBlackboardDocLink),
                  avilableOffline: response[school].courses[course].lessons[lesson].teacherBlackboardOffline
                })
                this.projectorDocLessons.push({
                  title: response[school].courses[course].lessons[lesson].title,
                  docId: response[school].courses[course].lessons[lesson].pk,
                  lessonSlug: response[school].courses[course].lessons[lesson].slug,
                  courseSlug: response[school].courses[course].slug,
                  valid: this.validId(response[school].courses[course].lessons[lesson].teacherProjectorDocLink),
                  avilableOffline: response[school].courses[course].lessons[lesson].teacherProjectorDocOffline
                })
                this.projectorLessons.push({
                  title: response[school].courses[course].lessons[lesson].title,
                  docId: response[school].courses[course].lessons[lesson].pk,
                  projectorLink: 'https://docs.google.com/presentation/d/' + response[school].courses[course].lessons[lesson].teacherProjectorPresentationLink,
                  valid: this.validId(response[school].courses[course].lessons[lesson].teacherProjectorPresentationLink),
                  avilableOffline: response[school].courses[course].lessons[lesson].teacherProjectorPresentationOffline
                })
              }
              // }
            }
          }
          this.loading = false
        })
    },
    validId (docId) {
      if (docId && docId.length && docId.length > 43) {
        return true
      }
      return false
    }
  },
  components: {

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_colors.scss';

#mainHeaderSection {
  background-color: $yellow;
  h2 {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 28pt;
    margin-bottom: 0;
  }
}

.image.container>.row>.col-12{
  padding: 0;
}
.image-header {
  background-image: url('/img/780x300.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  >.row>.air-space {
    margin: 5rem 0rem !important;
  }
}
#blackText.generalContent {
  a {
    text-decoration: underline;
  }
  a:active {
    color: $black !important;
  }
}
.generalContent {
  h3,h3>a {
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 2.5;
    display: inline;
    cursor: pointer;
  }
  h3 {
    svg {
      display: inline-block;
      position: absolute;
    }
    span {
      margin-left: 3rem;
      display: block;
    }
  }
  a, a:visited {
    color: $black;
    text-decoration: none;
  }
  li::marker {
    font-size: 1.83rem;
    color: $blue;
    font-weight: bold;
  }
  li>a, li>a:visited,
  li>span {
    font-size: 1.83rem;
    font-weight: normal;
    color: $blue;
    .downloaded-icon {
      & svg {
        font-size: 15pt;
        color: #555555;
        display: inline-block;
        vertical-align: baseline;
      }
    }
  }
  li>a {
    text-decoration: underline;
  }

}
.btn-menu-pointer {
  border: 0px;
  border-radius: 0px;
  color: #fff;
  background-color: $fuchsia;
  border-color: $fuchsia;
}

.branch {
  color: $fuchsia;
  font-size: 2.25rem;
}

.teacher-min {
  min-height: 77vh;
}
@media (max-width: 767px) {
  .generalContent {
    li {
      > h3,h3>a {
        font-size: 1.83rem !important;
        line-height: 1.5;
      }
    }
    .items {
      > h3,h3>a {
        > .branch {
          font-size: 25pt;
        }
        font-size: 20pt !important;
        line-height: 1.5;
      }
    }
  }
}

@media (max-width: 767px) {
  #mainHeaderSection {
    h2 {
      font-size: 24pt;
    }
  }
  .generalContent {
    h3,h3>a {
      font-size: 1.83rem;
    }
    li::marker {
      font-size: 17pt;
    }
    li>a, li>a:visited,
    li>span {
      font-size: 17pt;
    }
  }
}
</style>
