import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../views/Landing'
import store from '@/store'
import aboutResources from '../views/aboutResources'
import staticContent from '../views/staticContent'
import gDocsSheetStudents from '../views/gDocsSheetStudents'
import Lesson from '../views/students/Lesson'
import forTeachers from '../views/forTeachers'
import teacherLesson from '../views/teachers/Lesson'
import LessonBlackboard from '../views/teachers/LessonBlackboard'
import LessonProjector from '../views/teachers/LessonProjector'
import Glossary from '../components/Glossary.vue'
import myPage from '../views/myPage.vue'
import Login from '../views/Login.vue'
import HealthAction from '../views/HealthAction.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about-resources',
    name: 'AboutResources',
    component: aboutResources
  },
  {
    path: '/static-pages/:documentid',
    name: 'otherContent',
    component: staticContent
  },
  {
    path: '/students',
    name: 'Students',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: gDocsSheetStudents
  },
  {
    path: '/courses/:coursesslug/lessons/:lessonsslug/students',
    props: true,
    name: 'Lesson',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Lesson
  },
  {
    path: '/glossary',
    name: 'glossary',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Glossary
  },
  {
    path: '/my-page',
    name: 'My Page',
    component: myPage
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/health-actions',
    name: 'HealthActions',
    component: HealthAction
  }
]
if (process.env.VUE_APP_SHOW_TEACHERS === 'TRUE') {
  routes.push(
    {
      path: '/',
      name: 'Home',
      component: Landing
    },
    {
      path: '/teachers',
      name: 'Teachers',
      component: forTeachers
    },
    {
      path: '/courses/:coursesslug/lessons/:lessonsslug/teachers',
      props: true,
      name: 'teachersLessons',
      component: teacherLesson
    },
    {
      path: '/courses/:coursesslug/lessons/:lessonsslug/blackboard',
      props: true,
      name: 'teachersLessonsBlackBoard',
      component: LessonBlackboard
    },
    {
      path: '/courses/:coursesslug/lessons/:lessonsslug/projector',
      props: true,
      name: 'teachersLessonsProjector',
      component: LessonProjector
    }
  )
} else {
  routes.push(
    {
      path: '/',
      name: 'Home',
      component: Landing
    }
  )
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored
  next()
}
router.beforeEach(waitForStorageToBeReady)

export default router
