<template>
  <div>
    <b-container>
      <h2 class="my-5">About these resources</h2>
      <p>People everywhere are bombarded with claims about what might protect, improve, or harm their health. Believing and acting on unreliable claims can be harmful and wasteful. On the other hand, not believing and acting on reliable claims can also be harmful and wasteful. To avoid unnecessary harm and waste, they must be able to think critically about what to believe and what to do. This has become even more important with the Covid-19 pandemic, which has been accompanied by an “infodemic” - an over-abundance of information – some accurate and some not – that makes it hard for people to find trustworthy information when they need it.</p>
      <p>We have developed these resources to help enable lower-secondary school students to think critically about what claims to believe and what to do. The resources include:</p>
      <ul>
        <li>
          Lesson plans for teachers in three different situations:
          <ul>
            <li>
              Where students have access to computers in a computer lab or smart classroom,
            </li>
            <li>
              Where the teacher has a projector, but the students do not have access to computers, and
            </li>
            <li>
              Where there is not a projector and the students do not have access to computers
            </li>
          </ul>
        </li>
        <li>
          Interactive lessons for students with access to computers
        </li>
        <li>
          Slides for teachers with a projector
        </li>
        <li>
          Additional resources, including:
          <ul>
            <li>
              A glossary with examples, illustrations, and translations,
            </li>
            <li>
              A collection of health claims that can be used as additional examples, and
            </li>
            <li>
              Information about the teaching strategies used in these lessons and some other teaching strategies for critical thinking.
            </li>
          </ul>
        </li>
      </ul>
      <p>The lesson plan for each lesson includes:</p>
      <ul>
        <li>
          An overview of the lesson, including the learning goals for the lesson and the teaching strategies that are used,
        </li>
        <li>
          An introduction to the lesson, including any key terms that are used and common misunderstandings to watch out for, and
        </li>
        <li>
          Guidance, including what to do before the lesson and during the lesson.
        </li>
      </ul>
      <p>All the lessons include a structured activity to engage students and help them to think critically and a wrap-up to make sure that students understood and achieved the learning goals and that there are not important misunderstandings. Most of the lessons start with a quiz to reinforce the learning goals of previous lessons and assess how well they are doing.</p>
      <p>We are developing these resources using a human-centred design approach. This entails involving teachers, students, and others in the design process in multiple cycles of prototyping, collecting feedback, and making improvements. We are testing the resources in Kenya, Rwanda, and Uganda. After revising the resources, we will evaluate the extent to which using the resources improves students’ abilities to think critically about things people can do to protect or improve health.</p>
      <p>More information about this project, who we are, and other resources can be found on our website: <a href="www.informedhealthchoices.org" target="_blank">www.informedhealthchoices.org</a>. Feedback about how to improve these resources is welcome and can be sent to: <a href="mailto:contact@informedhealthchoices.org?subject=Hello!">contact@informedhealthchoices.org</a>.</p>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'aboutResources',
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
h2 {
  font-weight: bold;
  font-size: 2.50rem;
}
ul>li {
  font-size: 1.83rem;
}
</style>
