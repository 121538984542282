<template>
  <div class="mt-5">
    <template v-if="false">
    <b-row>
      <b-col>
        <b-form-file
          id="input-ris-file-key"
          plain
          @change="loadFile($event)"></b-form-file>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <pre>
          {{ finalData }}
        </pre>
      </b-col>
    </b-row>
  </template>
      <b-row>
        <b-col>
          <b-form-select
            v-model="filters.selectLesson"
            :options="filterOptions.lessons"></b-form-select>
        </b-col>
        <b-col>
          <b-form-select
            v-model="filters.selectPrinciple"
            :options="filterOptions.principles"></b-form-select>
        </b-col>
        <b-col>
          <div class="input-group mb-3">
            <b-form-input
            class="searchBox"
            v-model="filters.searchbox"
            placeholder="Search"></b-form-input>
            <div id="search-group" class="input-group-append">
              <span id="search-icon" class="input-group-text">
                <font-awesome-icon icon="search"/>
              </span>
            </div>
          </div>
        </b-col>
      </b-row>
    <b-table
      ref="table"
      :fields="fieldsTable"
      :items="itemsProvider"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      selectable
      @row-clicked="onRowSelected"
      class="mt-5">
      <template #cell(show_details)="row">
        <b-button size="sm" @click="row.toggleDetails" variant="outline-secondary-details" :class="row.detailsShowing ? 'opened' : 'closed' " pill class="mr-2">
          {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
        </b-button>
      </template>

      <template #row-details="row">
        <b-card style="background-color: #eee">
          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Claimed effects</b></b-col>
            <b-col>{{ row.item.claimed_effects }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Actual effects</b></b-col>
            <b-col>{{ row.item.actual_effects }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>Evidence summary</b></b-col>
            <b-col>{{ row.item.evidence_summary }}</b-col>
          </b-row>

          <b-row class="mb-2">
            <b-col sm="3" class="text-sm-right"><b>References:</b></b-col>
            <b-col>{{ row.item.references }}</b-col>
          </b-row>

        </b-card>
      </template>
    </b-table>

    <div>
      Sorting By: <b>{{ sortBy }}</b>, Sort Direction:
      <b>{{ sortDesc ? 'Descending' : 'Ascending' }}</b>
    </div>

  </div>
</template>

<script>
import data from './data'

export default {
  name: 'HealthActionTable',
  data () {
    return {
      fieldsTable: [
        { key: 'health_actions', label: 'Heath actions', sortable: true },
        { key: 'health_problem_or_goal', label: 'Health problem or goal', sortable: true },
        { key: 'show_details', label: 'Show details', sortable: false }],
      sortBy: 'health_actions',
      sortDesc: false,
      filterOptions: {
        lessons: [
          { value: null, text: 'LESSON' },
          { value: 'L1', text: 'Lesson 1' },
          { value: 'L2', text: 'Lesson 2' },
          { value: 'L3', text: 'Lesson 3' },
          { value: 'L4', text: 'Lesson 4' },
          { value: 'L5', text: 'Lesson 5' },
          { value: 'L6', text: 'Lesson 6' },
          { value: 'L7', text: 'Lesson 7' },
          { value: 'L8', text: 'Lesson 8' },
          { value: 'L9', text: 'Lesson 9' },
          { value: 'L10', text: 'Lesson 10' }
        ],
        principles: [
          { value: null, text: 'PRINCIPLE' },
          { value: 'P1', text: 'HARMFUL EFFECTS' },
          { value: 'P2', text: 'OBVIOUS EFFECTS' },
          { value: 'P3', text: 'PERSONAL EXPERIENCE' },
          { value: 'P4', text: 'COMMONLY-USED' },
          { value: 'P5', text: 'NEW' },
          { value: 'P6', text: 'COMPARISONS' },
          { value: 'P7', text: 'RANDOMLY-CREATED GROUPS' },
          { value: 'P8', text: 'LARGE-ENOUGH GROUPS' },
          { value: 'P9', text: 'CHOICES' }
        ]
      },
      filters: {
        selectLesson: null,
        selectPrinciple: null,
        searchbox: null
      },
      data: data,
      preData: null,
      finalData: []
    }
  },
  watch: {
    filters: {
      handler: function () {
        this.$refs.table.refresh()
      },
      deep: true
    },
    preData: function (data) {
      this.fileReferences = []
      const file = data
      const allLines = file.split(/\r\n|\n/)
      // Reading line by line
      let base = {
        health_actions: null,
        health_problem_or_goal: null,
        claimed_effects: null,
        actual_effects: null,
        evidence_summary: null,
        references: null,
        lessons: [null],
        principle: [null]
      }

      allLines.forEach((line) => {
        const _line = line.split('$')
        base.health_actions = _line[0]
        base.health_problem_or_goal = _line[1]
        base.claimed_effects = _line[2]
        base.actual_effects = _line[3]
        base.evidence_summary = _line[4]
        base.references = _line[5]
        if (_line[6] !== '') {
          base.principle.push('P1')
        }
        if (_line[7] !== '') {
          base.principle.push('P2')
        }
        if (_line[8] !== '') {
          base.principle.push('P3')
        }
        if (_line[9] !== '') {
          base.principle.push('P4')
        }
        if (_line[10] !== '') {
          base.principle.push('P5')
        }
        if (_line[11] !== '') {
          base.principle.push('P6')
        }
        if (_line[12] !== '') {
          base.principle.push('P7')
        }
        if (_line[13] !== '') {
          base.principle.push('P8')
        }
        if (_line[14] !== '') {
          base.principle.push('P9')
        }
        if (_line[15] !== '') {
          base.lessons.push('L1')
        }
        if (_line[16] !== '') {
          base.lessons.push('L2')
        }
        if (_line[17] !== '') {
          base.lessons.push('L3')
        }
        if (_line[18] !== '') {
          base.lessons.push('L4')
        }
        if (_line[19] !== '') {
          base.lessons.push('L5')
        }
        if (_line[20] !== '') {
          base.lessons.push('L6')
        }
        if (_line[21] !== '') {
          base.lessons.push('L7')
        }
        if (_line[22] !== '') {
          base.lessons.push('L8')
        }
        if (_line[23] !== '') {
          base.lessons.push('L9')
        }
        if (_line[24] !== '') {
          base.lessons.push('L10')
        }
        this.finalData.push(base)
        base = {
          health_actions: null,
          health_problem_or_goal: null,
          claimed_effects: null,
          actual_effects: null,
          evidence_summary: null,
          references: null,
          lessons: [null],
          principle: [null]
        }
      })
    }
  },
  methods: {
    onRowSelected: function (row) {
      this.$set(row, '_showDetails', !row._showDetails)
    },
    sortable: function (a, b) {
      if (a[`${this.sortBy}`].toLowerCase() > b[`${this.sortBy}`].toLowerCase()) {
        return (!this.sortDesc) ? 1 : -1
      }
      if (a[`${this.sortBy}`].toLowerCase() < b[`${this.sortBy}`].toLowerCase()) {
        return (this.sortDesc) ? 1 : -1
      }
      return 0
    },
    itemsProvider: function () {
      const items = this.data

      items.sort(this.sortable)

      if (this.filters.selectLesson === null && this.filters.selectPrinciple === null && (this.filters.searchbox === null || this.filters.searchbox === '')) {
        return items
      }

      var filtered = items.filter((el) => {
        return el.lessons.includes(this.filters.selectLesson) && el.principle.includes(this.filters.selectPrinciple)
      })

      if (this.filters.searchbox !== null) {
        filtered = items.filter((el) => {
          const txt = this.filters.searchbox.toLowerCase()
          const regexp = new RegExp(txt)
          return el.health_actions.toLowerCase().match(regexp) || el.health_problem_or_goal.toLowerCase().match(regexp) || el.evidence_summary.toLowerCase().match(regexp) || el.references.toLowerCase().match(regexp)
        })
      }
      return filtered
    },
    loadFile: function (event) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        this.preData = e.target.result
      }
      reader.readAsText(file)
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/_colors.scss';

.searchBox {
  font-style: italic;
}
#search-group {
  > #search-icon {
    background-color: $fuchsia;
    color: #fff;
  }
}

.btn-outline-secondary-details {
  color: $fuchsia;
  font-weight: bold;
  border-color: $fuchsia;
  &:hover {
    color: #fff;
    background-color: #ab005c54;
  }
  &:active {
    color: #fff;
    background-color: $fuchsia_strong;
  }
  &.opened {
    color: #fff;
    background-color: $fuchsia;
  }
}
.b-table-details {
  &>td {
    padding: 0;
    &>.card {
      border: 0;
      border-radius: 0;
    }
  }
}
.b-table-has-details {
  background-color: #eee;
  &>td {
    font-weight: bold;
  }
}

.table.b-table > thead > tr > [aria-sort="ascending"], .table.b-table > tfoot > tr > [aria-sort="ascending"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z'/%3E%3C/svg%3E");
}

.table.b-table > thead > tr > [aria-sort="descending"], .table.b-table > tfoot > tr > [aria-sort="descending"] {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'/%3E%3C/svg%3E");
}

.table.b-table > thead > tr > [aria-sort="none"], .table.b-table > tfoot > tr > [aria-sort="none"] {
    background-image: none;
}
</style>
