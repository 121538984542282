<template>
  <div>
    <div v-if="windowInnerWidth>=768" class="initial dialog-box-left">
      <b-row no-gutters>
        <b-col
          md="2"
          cols="12">
          <span class="text" v-for="(content, index) in text.content" :key="index">
            <span class="text" v-for="(content1, index1) in content.content" :key="index1">
              <div class="text" v-if="content1.type == 'IMAGE'">
                <async-image v-if="content1.content.inlineObjectId" :imageId="content1.content.inlineObjectId" :getImage="getImage"></async-image>
              </div>
              <div v-if="content1.type === 'IMAGELINK'">
                <a :href="content1.link" target="_blank">
                  <async-image v-if="content1.content.inlineObjectId" :imageId="content1.content.inlineObjectId" :getImage="getImage"></async-image>
                </a>
              </div>
            </span>
          </span>
        </b-col>
        <b-col
          md="10"
          cols="12" class="hey p-4">
          <div class="dialog">
            <span v-for="(content, index) in newText.content" :key="index">
              <template v-for="(content1, index1) in content.content">
              <span class="text" :key="index1">
                <span
                  :style="applyHeadingStyle(content1.paragraphStyle.namedStyleType, {}, content1.textStyle, false)"
                  :key="index"
                  :class="(content1.type==='fontawesome' && content1.content==='pen')?'fa-span':''">{{content1.content}}</span>
              </span>
              </template>
            </span>
          </div>
          <!-- <div class="pointer"></div> -->
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <DialogBox
        :text="text"
        :headingParStyles="headingParStyles"
        :headingTextStyles="headingTextStyles"
        :document="document"
        :getImage="getImage"
      />
    </div>
  </div>
</template>

<script>
import DialogBox from '@/components/customObjects/DialogBox'
import AsyncImage from '@/components/customObjects/AsyncImage'
import headingsStyle from '@/mixins/headingsStyle'
export default {
  name: 'DialogBoxLeft',
  props: ['text', 'document', 'getImage', 'headingParStyles', 'headingTextStyles'],
  data () {
    return {
      ignore: false,
      windowInnerWidth: window.innerWidth
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleresize())
  },
  mounted: function () {
    window.addEventListener('resize', this.handleresize())
  },
  mixins: [headingsStyle],
  methods: {
    filterNewText: function (content) {
      return content.filter(el => el.type !== 'STARTCUSTOM' && el.type !== 'IMAGE' && el.type !== 'IMAGELINK' && el.content.length > 0 && el.type !== 'BR')
    },
    handleresize () {
      this.windowInnerWidth = window.innerWidth
    }
  },
  computed: {
    newText: function () {
      const newText = JSON.parse(JSON.stringify(this.text))
      newText.content = newText.content.filter(el => el.type !== 'IGNORE' && el.type !== 'BR')
      newText.content = newText.content.map(el1 => { return { content: this.filterNewText(el1.content) } })
      return newText
    }
  },
  components: {
    'async-image': AsyncImage,
    DialogBox
  }
}
</script>

<style lang="scss" scoped>
  .hey {
    background-color: white;
    border-radius: 1rem;
    min-height: 10%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 1%;
    display: block;
    border: 1px solid #8a8a8a;
  }

  .hey::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-right: 16px solid #8a8a8a;
    margin-top: -16px;
    margin-left: -16px;
  }

  @media only screen and (max-width: 768px) {
    .hey:after  {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: 50%;
      top: 0;
      border-bottom: 16px solid #707070;
      border-right: 16px solid transparent;
      border-left: 16px solid transparent;
      z-index: 10;
      margin-left: -16px;
      margin-top: -32px;
    }
  }

  .text {
    margin: 0 auto;
  }

  .initial {
    // background: #eee;
    position: relative;
  }

  .img {
    display: block;
    width:100%;
  }

  .dialog {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
