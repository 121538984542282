<template>
  <div>
    <landing></landing>
  </div>
</template>

<script>
import landing from '@/components/landing'

export default {
  name: 'Landing',
  data () {
    return {}
  },
  components: {
    landing
  }
}
</script>

<style scoped>

</style>
