<template>
  <div :id="l_esson.nameSectionMenu" class="contentSection d-print-block">
    <template v-if="l_esson.content.length">
      <div v-for="(content, index) in l_esson.content" :key="index" :class="{'d-none':  content && content.type === 'ONLYINTERNALLINK'}">
        <template v-if="content && content.customObject > customObject">
          <template v-if="customObjects[content.customObject].parentCustomObject === customObject">
            <div v-for="(elem, index1) in content.content" :key="index1">
              <template v-if="elem && elem.type=='STARTCUSTOM'">
              <componentsContent
              :elem="elem"
              :customObjects="customObjects"
              :document="document"
              :lists="lists"
              :headingParStyles="headingParStyles"
              :headingTextStyles="headingTextStyles"
              :listsCounter="listsCounter"
              :content="content"
              :glossary="glossary"
              :glossaryUrls="glossaryUrls"
              :name="name"
              :answerLink="answerLink"
              :lessonpk="lessonpk"
              :id="id"
              :userId="userId"
              :type="type"
              :index="index"
              :index1="index1"
              :getImage="getImage"
              :nameSectionMenu="l_esson.nameSectionMenu"
              :login="login"
              @listCounterUpdate="listsIndex"
              @changeSection="changeSection"
              @movingto="movingto"
              @ready="updateDocLoaded"
              />
              </template>
            </div>
          </template>
        </template>
        <template v-else>
          <template v-if="!['STARTCUSTOM', 'ENDCUSTOM'].includes(content.content[0].type)">
          <headingsContent
            :content="content"
            :getImage="getImage"
            :nameSectionMenu="l_esson.nameSectionMenu"
            :name="name"
            :id="id"
            :lessonpk="lessonpk"
            :userId="userId"
            :type="type"
            :customObjects="customObjects"
            :customObject="customObject"
            :lists="lists"
            :listsCounter="listsCounter"
            :headingParStyles="headingParStyles"
            :headingTextStyles="headingTextStyles"
            :glossary="glossary"
            :glossaryUrls="glossaryUrls"
            :index="index"
            @listCounterUpdate="listsIndex"
            @changeSection="changeSection"
            @movingto="movingto"
          />
          </template>
        </template>
      </div>
    </template>
    <template v-else>
      <p>
        content not available
      </p>
    </template>
    <p style="page-break-before: always"></p>
  </div>
</template>

<script>
import headingsContent from '@/components/lessonSubElements/headingsContent'

export default {
  name: 'Section',
  props: {
    document: Object,
    lists: Object,
    headingParStyles: Object,
    headingTextStyles: Object,
    customObjects: Array,
    listsCounter: Object,
    l_esson: Object,
    customObject: Number,
    glossary: Array,
    glossaryUrls: Array,
    name: String,
    answerLink: String,
    id: String,
    lessonpk: String,
    userId: String,
    type: String,
    getImage: Function,
    login: Boolean
  },
  data: function () {
    return {
      visible: true,
      scroll: false
    }
  },
  mounted: function () {
    const divs = document.getElementsByClassName('contentSection')
    divs.forEach((element, index) => {
      if (index === 0) {
        element.classList.add('on')
      }
    })
  },
  created () {
    const vm = this
    this.$root.$on('MoveTo', (movetosection, ref, wait) => {
      if (vm.$refs[ref]) {
        vm.scroll = true
        vm.movingto(vm.l_esson.nameSectionMenu, movetosection, ref, wait)
      }
    })
    this.$root.$on('Scroll', (ref) => {
      if (vm.scroll) {
        vm.scrollMeTo(ref, vm)
        vm.scroll = false
      }
    })
  },
  beforeDestroy () {
    this.$root.$off('MoveTo')
    this.$root.$off('Scroll')
  },
  methods: {
    changeSection (content) {
      this.$emit('changeSection', content)
    },
    movingto (sectionmenu, movetosection, ref, wait = true) {
      this.$emit('movingto', sectionmenu, movetosection, ref, wait)
    },
    printLesson: function () {
      window.print()
    },
    listsIndex (listId, nestingLevel = 0) {
      this.$emit('listCounterUpdate', listId, nestingLevel)
    },
    capitalize (string) {
      return string && string[0].toUpperCase() + string.slice(1)
    },
    moveToLink (content) {
      const ref = content.split('-')[0]
      this.$root.$emit('MoveTo', this.l_esson.nameSectionMenu, ref, true)
    },
    getMoveToLink (content) {
      const word = content.split(/-(.+)/)[1]
      return word
    },
    scrollMeTo (ref, help = this) { /* https://stackoverflow.com/questions/42645964/vue-js-anchor-to-div-within-the-same-component */
      if (help.$refs[ref] && help.$refs[ref].length) {
        const element = help.$refs[ref][0]
        const top = element.offsetTop
        window.scrollTo(0, top)
      }
    },
    updateDocLoaded (customObject) {
      this.$emit('ready', customObject)
    }
  },
  components: {
    headingsContent,
    componentsContent: () => import('@/components/lessonSubElements/componentsContent')
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_colors.scss';
.contentSection {
  .main-title {
    margin-bottom: 3rem;
    font-weight: bold;
  }
}

.contentSection {
  display: none;
  &.on {
    display: block;
  }
}

@media (max-width: 767px) {
#app {
  h2 {
    &.main-title {
      margin-bottom: 1.5rem;
    }
  }
}
}
</style>

<style lang="scss">
@import '@/assets/styles/_colors.scss';
  #lesson.contentSection,
  #teach.contentSection {
    & .contentCollapsable {
      & .only-lesson {
        display: block;
      }
    }
  }

  #overview.contentSection,
  #background.contentSection {
    & .contentCollapsable {
      & .only-lesson {
        display: none;
      }
    }
  }

  .student {
    & .only-lesson {
      display: none;
    }
  }
</style>
