<template>
  <div class="initial image-text">
    <b-row no-gutters>
      <b-col cols="12" class="p-3">
        <b-row>
          <b-col
            md="10"
            cols="12" class="hey">
            <div class="dialog">
              <span v-for="(content, index) in newText.content" :key="index">
                <template v-for="(content1, index1) in content.content">
                <span class="text" :key="index1">
                  <span
                    :style="applyHeadingStyle(content1.paragraphStyle.namedStyleType, {}, content1.textStyle, false)"
                    :key="index"
                    :class="(content1.type==='fontawesome' && content1.content==='pen')?'fa-span':''">{{content1.content}}</span>
                </span>
                </template>
              </span>
            </div>
          </b-col>
          <b-col
            md="2"
            cols="12">
            <span class="text" v-for="(content, index) in text.content" :key="index">
              <span class="text" v-for="(content1, index1) in content.content" :key="index1">
                <div class="text" v-if="content1.type == 'IMAGE' ">
                  <async-image v-if="content1.content.inlineObjectId" :imageId="content1.content.inlineObjectId" :getImage="getImage" :fullImage="true"></async-image>
                </div>
                <div v-if="content1.type === 'IMAGELINK'">
                  <a :href="content1.link" target="_blank">
                    <async-image v-if="content1.content.inlineObjectId" :imageId="content1.content.inlineObjectId" :getImage="getImage" :fullImage="true"></async-image>
                  </a>
                </div>
              </span>
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import AsyncImage from '@/components/customObjects/AsyncImage'
import headingsStyle from '@/mixins/headingsStyle'

export default {
  name: 'WhiteImageText',
  props: ['text', 'document', 'getImage', 'headingParStyles', 'headingTextStyles'],
  data () {
    return {
      ignore: false
    }
  },
  mounted: function () {
  },
  mixins: [headingsStyle],
  methods: {
    filterNewText: function (content) {
      return content.filter(el => el.type !== 'STARTCUSTOM' && el.type !== 'IMAGE' && el.type !== 'IMAGELINK' && el.content.length > 0 && el.type !== 'BR')
    }
  },
  computed: {
    newText: function () {
      const newText = JSON.parse(JSON.stringify(this.text))
      newText.content = newText.content.filter(el => el.type !== 'IGNORE' && el.type !== 'BR')
      newText.content = newText.content.map(el1 => { return { content: this.filterNewText(el1.content) } })
      return newText
    }
  },
  components: {
    'async-image': AsyncImage
  }
}
</script>

<style lang="scss" scoped>
  .hey {
    min-height: 10%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 1%;
    display: block;
  }

  .text {
    margin: 0 auto;
  }

  .initial {
    background: white;
    position: relative;
  }

  .img {
    display: block;
    width:100%;
  }

  .dialog {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
